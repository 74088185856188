<template>
  <div class="container" v-if="propsAreReady">
    <AddAssetsPopUp
      v-if="assetFundPopupShow"
      :portfolioContent="updatedPf.contents"
      :assetShow="assetShow"
      :index="index"
      :translatedAssets="translatedAssets"
      :translatedUnivers="translatedUnivers"
      @save="popupSave"
      @close="closepopup"
    />
    <div class="section" style="margin-right: 10px">
      <h5 class="section-title">
        {{ $t('CURRENT_ALLOCATION_REFERENCE') }} - {{ note }}
      </h5>
      <DonutChart
        :dataProps="portfolio.contents"
        :widthProps="500"
        :heightProps="300"
        :alreadyDone="true"
        :indexProps="index"
        :onlyBaseProps="true"
        :translateAssetsProps="translatedAssets"
        :translateUniversProps="translatedUnivers"
      />
      <Volatility :portfolioContent="portfolio.contents" :index="index" />
      <button class="btn" style="visibility: hidden !important">
        <i class="fa fa-plus" />
      </button>
      <assetsWeights
        style="margin-top: 30px"
        :dataProps="portfolio.contents"
        :indexProps="index"
        :translateAssetsProps="translatedAssets"
        :translateUniversProps="translatedUnivers"
      />
    </div>
    <div class="section" style="margin-left: 10px">
      <h5 class="section-title"><span><i class="fa fa-edit float-end edit-icon"/></span>{{ $t('UPDATED_PF') }}</h5>
      <DonutChart
        :dataProps="updatedPf.contents"
        :widthProps="500"
        :heightProps="300"
        :indexProps="index"
        :onlyBaseProps="true"
        :translateAssetsProps="translatedAssets"
        :translateUniversProps="translatedUnivers"
        :refreshtoken="refreshtoken"
      />
      <Volatility
        :portfolioContent="updatedPf.contents"
        :index="index"
        :refreshtoken="refreshtokenVol"
      />
      <button class="btn" @click="assetFundPopupShow = true">
        <i class="fa fa-plus" />
      </button>
      <UpdatedAssetFundTable
        style="margin-top: 30px"
        :portfolioContent="updatedPf.contents"
        :weightAvailable="weightAvailable"
        :assetShow="assetShow"
        @weightUpd="weightUpd"
        @setAssetToZero="setAssetToZero"
        :index="index"
        :translatedAssets="translatedAssets"
        :translatedUnivers="translatedUnivers"
        @refresh="refreshVol"
      />
    </div>
  </div>
</template>
<script>
import DonutChart from '@/components/autre/donutchart.vue';
import UpdatedAssetFundTable from '@/components/gerant/assets/updateAssetFundTable.vue';
import AddAssetsPopUp from '@/components/gerant/assets/addAssetsPopUp.vue';
import Volatility from '@/components/gerant/assets/volatility.vue';

export default {
  components: {
    DonutChart,
    UpdatedAssetFundTable,
    AddAssetsPopUp,
    Volatility,
  },
  data() {
    return {
      updatedPf: {},
      assetShow: [],
      assetFundPopupShow: false,
      weightAvailable: 0,
      refreshtoken: '',
      refreshtokenVol: '',
    };
  },
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    note: {
      type: Number,
      required: true,
    },
    translatedAssets: {
      type: Array,
      required: true,
    },
    translatedUnivers: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
    portfolioEdit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    propsAreReady() {
      return (
        Object.keys(this.portfolio).length !== 0 &&
        this.translatedAssets.length !== 0 &&
        this.translatedUnivers.length !== 0
      );
    },
  },
  watch: {
    portfolio: function () {
      this.dataInit();
    },
  },
  methods: {
    weightUpd(uniK, assetK, weight, operator, index, weiAvailable) {
      let oldBaseWeight = this.updatedPf.contents[uniK].assets[assetK]
        .base_weights[index];
      let oldTotal = this.updatedPf.contents[uniK].total[index];

      this.weightAvailable = weiAvailable;
      this.updatedPf.contents[uniK].assets[assetK].base_weights[index] = weight;
      this.updatedPf.contents[uniK].total[index] =
        operator === '+'
          ? oldTotal + (weight - oldBaseWeight)
          : oldTotal - (oldBaseWeight - weight);
      this.refreshtoken = Date.now();
      this.$emit('updated', {
        univers: uniK,
        asset: assetK,
        index: index,
        weight: weight,
        total: this.updatedPf.contents[uniK].total[index],
        weightAvailable: this.weightAvailable,
      });
    },
    popupSave(selectedList) {
      this.assetShow = this.assetShow.concat(selectedList);
      this.assetFundPopupShow = false;
    },
    closepopup(close) {
      this.assetFundPopupShow = !close;
    },
    setAssetToZero(uniK, assetK, index) {
      let findIdx = this.assetShow.findIndex(element => element === assetK)
      if(findIdx !== -1) this.$set(this.assetShow, findIdx, false)

      this.weightAvailable -=
        this.updatedPf.contents[uniK].assets[assetK].base_weights[index] * 100;
      this.updatedPf.contents[uniK].total[index] -= this.updatedPf.contents[
        uniK
      ].assets[assetK].base_weights[index];
      this.updatedPf.contents[uniK].assets[assetK].base_weights[index] = 0;
      this.refreshtoken = Date.now();
      this.refreshVol();
      this.$emit('updated', {
        univers: uniK,
        asset: assetK,
        index: index,
        weight: 0,
        total: this.updatedPf.contents[uniK].total[index],
      });
    },
    dataInit() {
      this.updatedPf = Object.assign(
        {},
        this.updatedPf,
        JSON.parse(JSON.stringify(this.portfolioEdit))
      );
      let contents = this.updatedPf.contents;
      for (const uniV of Object.values(contents)) {
        for (const [assetK, assetV] of Object.entries(uniV.assets)) {
          if (assetV.base_weights[this.index] > 0) {
            this.assetShow.push(assetK)
          }
        }
        this.weightAvailable += Math.round(uniV.total[this.index] * 100);
      }
    },
    refreshVol() {
      this.refreshtokenVol = Date.now();
    },
  },
  mounted() {
    this.dataInit();
  },
};
</script>
<style scoped>
.container {
  display: flex;
}
.section {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  border: none;
  height: max-content;
  width: 50%;
}
.section-title {
  background-color: #e8e8e8;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  color: #707070;
}
.btn {
  border: none;
  background-color: #e8e8e8;
  color: #707070;
  display: block;
  margin-left: auto;
}
.edit-icon {
    float: right;
    color: #82d435;
    line-height: 20px;
    font-size: 20px;
}
</style>
