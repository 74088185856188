<template>
  <div>
    <notifications classe="n-light" group="foo" position="top center" :max="3" :width="400" />

    <div class="d-flex justify-content-between">
      <h5 class="align-self-center m-0">
          <i class="fa fa-check-circle" style="color:#36a9e1;"></i>
          {{$t('COMPLIANCE')}}
      </h5>
      <div>
        <span
          class="btn btn-primary next"
          style="cursor:pointer;"
          @click="btnClick()"
        >{{$t('SAVE')}}</span>
        <span
          class="btn btn-primary next"
          style="cursor:pointer; margin-left: 5px;"
          @click="btnClickCompte()"
        >{{$t('ACCOUNT')}}</span>
      </div>
        
    </div>
    <hr />
    <div>
      <ul class="nav nav-tabs myNavCom">
        <li class="nav-item">
          <span @click="changeNav('Info')" :class="Info">
            <i class="fa fa-id-card-o"></i>
            {{$t('REGULATORY_INFORMATION')}}
          </span>
        </li>
        <li class="nav-item">
          <span @click="changeNav('KYC')" :class="KYC">
            <i class="fa fa-question-circle"></i>
            {{$t('INVESTMENT_PROFILE_QUESTIONNAIRE')}}
          </span>
        </li>
      </ul>
      <div class="tab-content">
        <info v-if="isActive == 'Info'" />
        <KYC v-if="isActive == 'KYC'" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

import Notifications from "vue-notification";
Vue.use(Notifications);

import Info from "@/components/agent/dashboard/compliance/info.vue";
import KYC from "@/components/agent/dashboard/compliance/kyc.vue";

Vue.component("KYC", KYC);
Vue.component("info", Info);

export default {
  data() {
    return {
      Info: "nav-link active mySpanCom",
      KYC: "nav-link mySpanCom",
      isActive: "Info",
    };
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
      token: (state) => state.token,
      client: (state) => state.client_Info,
      portfolio: (state) => state.client_Portfolio,
      new_Compte: (state) => state.new_Compte,
    }),
  },
  methods: {
    ...mapActions(["setNewCompte"]),
    changeNav(val) {
      let styleNo = "nav-link mySpanCom";
      let style = "nav-link active mySpanCom";
      let arr = ["Info", "KYC"];
      for (const item of arr) {
        if (item == val) this[item] = style;
        else this[item] = styleNo;
      }

      this.isActive = val;
    },
    async btnClick() {
      let ctx = this;
      let token = window.localStorage.getItem("priradToken");
      let updateUser = await this.$http.post(
        this.baseUrl + "users/dashboard/update",
        ctx.client
      );
      let title = this.$t("COMPLIANCE");
      let group = "foo";
      let type = "";
      let text = this.$t("CUSTOMER_INFO_UPDATED");
      this.$notify({
        group,
        title,
        text,
        type,
      });
    },
    getYear(d) {
      let current = new Date(d);
      return current.getFullYear();
    },
    btnClickCompte() {
      let ctx = this;
      let others = [];
      let school = {};
      school["isCheck"] = false;
      school["data"] = [];
      if (this.portfolio.n_contracts > 1) {
        for (const [index, item] of Object.entries(ctx.portfolio.individuals)) {
          let lastLength = item.bt.length;
          let myObj = {
            name: item.name,
            value: Math.round(item.bt[lastLength - 1].value),
          };

          others.push(myObj);
        }
      }
      let kycExp = {};
      let kycRisk = {};
      let kycObj = {};
      if (this.$_.isEmpty(this.client.info.KYC.experience)) {
        kycExp["q1"] = "";
        kycExp["q2"] = "";
      } else {
        kycExp["q1"] = ctx.client.info.KYC.experience.q8;
        kycExp["q2"] = ctx.client.info.KYC.experience.q8;
      }

      if (this.$_.isEmpty(this.client.info.KYC.objectives)) {
        kycObj["preo"] = "";
        kycObj["choicePref"] = "";
      } else {
        kycObj["preo"] = ctx.client.info.KYC.objectives.q2;
        kycObj["choicePref"] = ctx.client.info.KYC.objectives.q7;
      }

      if (this.$_.isEmpty(this.client.info.KYC.risk)) {
        kycRisk["fluctuation"] = "";
      } else {
        kycRisk["fluctuation"] = ctx.client.info.KYC.risk.q4;
      }
      let isOddo = false;
      if (localStorage.getItem("priradCompany") == "ODDO") isOddo = true;

      // need to do it
      if (ctx.client.info.extra_info.situation.children.length > 0) {
        ctx.client.info.extra_info.situation.children.forEach((element) => {
          let year = this.getYear(element.birthday);
          let d = new Date();
          let tot = d.getFullYear() - year;
          if (tot < 18) {
            school.data.push({ year: 18 - tot, name: element.name });
          }
        });
      }

      school["prio"] = "4";

      let obj = {
        client: {
          name: ctx.client.lastname,
          prenom: ctx.client.name,
          phone: ctx.client.info.extra_info.general.telephone,
          mail: ctx.client.email,
        },
        apports: {
          initial: Math.round(ctx.client.current).toString(),
        },
        contr: {
          contr1: "",
          contr2: "",
          contr3: "",
        },
	  contraintes:{},
        objectifs: {
          transmi: {
            prio: "1",
          },
          immo: {
            duree: "15",
            percent: "0.5",
            isCheck: false,
            prio: "10",
          },
          retraite: {
            prio: "5",
          },
          precaution: {
            prio: "3",
          },
          autre: { prio: "2" },
          school: school,
        },
        kyc: {
          experience: kycExp,
          objectifs: kycObj,
          risk: kycRisk,
          suit: {
            situation: ctx.client.info.extra_info.situation.activity,
            depart: ctx.client.info.extra_info.situation.retirement_year,
            revenuTot: ctx.client.info.extra_info.assets.annual_revenue,
            revenue_type: ctx.client.info.extra_info.assets.revenue_type,
            evolution: "",
            patrimoine: ctx.client.info.extra_info.assets.total_assets,
            patMonthLoan: "",
            patTermLoan: "",
            monthlyExpenses: ctx.client.info.extra_info.assets.monthly_expenses,
            amountCheck: "",
            precoAmount: "",
            saveCheck: "",
          },
        },
        others: others,
        id: ctx.$route.params.id,
        username: ctx.client.username,
        isNew: false,
        extra_info: {
          oddo_constraint: isOddo,
        },
      };
      this.setNewCompte(obj);
      this.$router.push("/new");
    },
  },
};
</script>

<style>
.myNavCom {
  margin-top: 25px;
  margin-left: 20px;
}

.mySpanCom {
  cursor: pointer;
}
</style>
