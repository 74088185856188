function parseMoneyInput(num, space) {
    space = typeof space == 'undefined' ? " " : space;
    if (typeof (num) == "string") {
        num = num.replace(/[^0-9]/g, '')
    }
    if (num == "" && typeof (num) == "string")
        return "";
    num = parseInt(num);
    var n = num.toString(), p = n.indexOf('.');
    return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, function ($0, i) {
        return p < 0 || i < p ? ($0 + space) : $0;
    });
}

function parseYear(num) {
    if (typeof (num) == "string") {
        num = num.replace(/[^0-9]/g, '');
    }
    if (num == "" && typeof (num) == "string")
        return "";
    num = parseInt(num);
    if (num > 50)
        num = 50;
    return num;
}

function parseYearLimit(num) {
    if (typeof (num) == "string") {
        num = num.replace(/[^0-9]/g, '');
    }
    if (num == "" && typeof (num) == "string")
        return "";
    num = parseInt(num);

    return num;
}

function parsePercent(num) {
    if (typeof (num) == "string") {
        num = num.replace(/[^0-9\.]/g, '');
    }
    if (num == "" && typeof (num) == "string")
        return "";
    return num;
}

var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

function parseInt2(num) {
    num = num.replace(/[^0-9]/g, '');
    return parseInt(num);
}

function closest(num, arr) {
    var mid;
    var lo = 0;
    var hi = arr.length - 1;
    while (hi - lo > 1) {
        mid = Math.floor((lo + hi) / 2);
        if (arr[mid] < num) {
            lo = mid;
        } else {
            hi = mid;
        }
    }
    if (num - arr[lo] <= arr[hi] - num) {
        return arr[lo];
    }
    return arr[hi];
}

function range(start, stop, step) {
    if (typeof stop == 'undefined') {
        // one param defined
        stop = start;
        start = 0;
    }

    if (typeof step == 'undefined') {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    var result = [];
    for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
};
function transpose(array) {
    return array[0].map(function (col, i) {
        return array.map(function (row) {
            return row[i]
        })
    });
}
function minmax2D(arr) {
    var vals = [].concat.apply([], arr);
    var max = Math.max.apply(null, vals);
    var min = Math.min.apply(null, vals);
    return [min, max]
}

function arr2DToordered1D(arr) {
    var vals = [].concat.apply([], arr);
    var uvals = new Set(vals); // unique values
    return Array.from(uvals).sort(function (a, b) { return a - b });
}


/////////////////////////
/// Bilinear Interpolation
/////////////////////////

function bilinear(values, axes, Qs) {
    tmp = []
    values.forEach(function (val, idx, tmp_) {
        x_ = val[0]; y_ = val[1];
        // translate to index
        xs = findBorders2D(val, axes);
        x1_ = xs[0]; x2_ = xs[1]; y1_ = xs[2]; y2_ = xs[3];
        // index to real values
        x1 = axes[0][x1_]; x2 = axes[0][x2_];
        y1 = axes[1][y1_]; y2 = axes[1][y2_];
        q11 = Qs[x1_][y1_];
        q12 = Qs[x1_][y2_];
        q21 = Qs[x2_][y1_];
        q22 = Qs[x2_][y2_];

        tmp.push(bilinear_single(q11, q12, q21, q22, x1, x2, y1, y2, x_, y_));
    })
    return tmp
}

function findBorders2D(value, Axes) {
    var x1 = findIntervalLeftBorderIndex(value[0], Axes[0]);
    var y1 = findIntervalLeftBorderIndex(value[1], Axes[1]);
    var x2 = x1 + 1
    var y2 = y1 + 1
    return [x1, x2, y1, y2]
}
function findIntervalLeftBorderIndex(point, intervals) {
    //If point is beyond given intervals
    if (point < intervals[0])
        return 0
    if (point > intervals[intervals.length - 1])
        return intervals.length - 2 // index -2 is left border
    //If point is inside interval
    //Start searching on a full range of intervals
    var indexOfNumberToCompare
        , leftBorderIndex = 0
        , rightBorderIndex = intervals.length - 1
    //Reduce searching range till it find an interval point belongs to using binary search
    while (rightBorderIndex - leftBorderIndex !== 1) {
        indexOfNumberToCompare = leftBorderIndex + Math.floor((rightBorderIndex - leftBorderIndex) / 2)
        point >= intervals[indexOfNumberToCompare]
            ? leftBorderIndex = indexOfNumberToCompare
            : rightBorderIndex = indexOfNumberToCompare
    }
    return leftBorderIndex
}
// function repects positions from figure
// https://en.wikipedia.org/wiki/File:BilinearInterpolation.svg
function bilinear_single(q11, q12, q21, q22, x1, x2, y1, y2, x, y) {
    var x2x1, y2y1, x2x, y2y, yy1, xx1;
    x2x1 = x2 - x1;
    y2y1 = y2 - y1;
    x2x = x2 - x;
    y2y = y2 - y;
    yy1 = y - y1;
    xx1 = x - x1;
    return 1.0 / (x2x1 * y2y1) * (
        x2x * (q11 * y2y + q12 * yy1) +
        xx1 * (q21 * y2y + q22 * yy1)
    );
}

/////////////////////////////////
///// D3.Js
/////////////////////////////////

d3.selection.prototype.moveToFront = function () {
    return this.each(function () {
        this.parentNode.appendChild(this);
    });
};
