<template>
  <div>
    <h4 class="h4">{{ $t('CLIENTS') }}</h4>
    <div class="card">
      <div class="card-header">
        <statistiques :nbClientsProps="nbUsers" />
        <i class="icon-people iMarginR"></i>
        {{ $t('CUSTOMER_BASE') }}
        <div class="card-body p-0" style="margin-top: 15px">
          <div class="tab-content">
            <div class="tab-pane active">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_length">
                    <label>
                      {{ $t('NUMBER_TO_DISPLAY') }}
                      <select
                        name="tableClients_length"
                        id="tableClients_length"
                        class="form-control form-control-sm"
                        v-on:change="comboClick"
                      >
                        <option value="10">10</option>
                        <option v-if="nbUsers >= 25" value="25">25</option>
                        <option v-if="nbUsers >= 50" value="50">50</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tableClients_filter"
                    class="float-right"
                  >
                    <form onsubmit="event.preventDefault();" role="search_customer">
                      <input 
                        id="search_customer" 
                        type="search_customer" 
                        @keypress.enter="searchByName()" 
                        :placeholder="$t('NAME_OF_CUSTOMER')"
                        v-model="searchName" 
                        autofocus 
                        required 
                      />
                      <button class="searchBtn" type="submit" @click="searchByName()"><i class="fa fa-search"></i></button>    
                    </form>
                  </div>
                </div>
              </div>
              <div v-if="sortList.length">
                {{$t('SORTED_BY')}}
                <div class="sortBoxContainer">
                  <div v-for="(item, index) in sortList" :key="item.sort + index" class="sortBox" @click="deleteFromSort(item)">
                    {{$t(item.keyname)}} {{item.ascKey}}    
                    <i class="fa fa-times align-self-center" style="margin-left: 10px"/>
                  </div>
                  <div class="sortBox" style="margin-left: auto; background-color: #F3F1E0;"
                    @click="clearSort"
                  >
                    {{$t('CLEAR_ALL')}}
                    <i class="fa fa-times align-self-center" style="margin-left: 10px"/>  
                  </div>
                </div>
              </div>
              <tableClient :clientProps="clients" :sortList="sortList" @clicked="setSort" />
              <div class="col-sm12 col-md-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{disabled: page === 1}"
                    >
                      <button class="page-link" @click="prev()">{{ $t('PREVIOUS') }}</button>
                    </li>
                    <li class="paginate_button page-item active">
                      <span class="page-link">{{ page }}</span>
                    </li>
                    <li class="paginate_button page-item">
                      <span class="page-link">/</span>
                    </li>
                    <li class="paginate_button page-item">
                      <span class="page-link">{{ isInt }}</span>
                    </li>
                    <li
                      class="paginate_button page-item next enable"
                      :class="{disabled: nbUsers < limit * page}"
                    >
                      <button @click="next()"  class="page-link">
                        {{ $t('NEXT') }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import Statistiques from '@/components/agent/clients/statistiques.vue';
import Table from '@/components/agent/clients/table.vue';

Vue.component('tableClient', Table);
Vue.component('statistiques', Statistiques);

export default {
  data() {
    return {
      canShow: false,
      clients: [],
      page: 1,
      limit: 10,
      nbUsers: 0,
      searchName: '',
      pageMax: 1,
      sortList: []
    };
  },
  computed: {
    ...mapState({
      breadLink: (state) => state.breadLink,
      baseUrl: (state) => state.baseUrl,
    }),
    isInt() {
      let nb = this.pageMax % 1 === 0;
      let nbToReturn = this.pageMax;
      if (!nb) nbToReturn = Math.round((nbToReturn += 1));
      return nbToReturn;
    },
  },
  watch: {
    searchName: function() {
      if (this.searchName.length === 0){
        this.getUsers()
      }
    }
  },
  methods: {
    ...mapActions(['setBreadLink', 'setToken', 'setLogged']),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({ isLog: false, role: 0 });
        await this.$http.get(this.baseUrl + 'auth/logout');
        localStorage.removeItem('priradToken');
        localStorage.removeItem('priradRole');
        localStorage.removeItem('priradCompany');
        this.$router.push('/login');
      } else {
        let role = this.$getRole();
        if (role == 10) {
          this.getNbUser();
          this.getUsers();
        } else {
          this.setLogged({ isLog: false, role: 0 });
          await this.$http.get(this.baseUrl + 'auth/logout');
          localStorage.removeItem('priradToken');
          localStorage.removeItem('priradRole');
          localStorage.removeItem('priradCompany');
          this.$router.push('/login');
        }
      }
    },
    next() {
      try {
        this.page += 1;
        this.getUsers();
      } catch (error) {
        this.page -= 1;
      }
    },
    prev() {
      try {
        this.page -= 1;
        this.getUsers();
      } catch (error) {
        this.page += 1;
      }
    },
    async getUsers() {
      let clients = await this.$http.post(
        `${this.baseUrl}users/${this.page}/${this.limit}`,
        {
          sortList: this.sortList
        }
      );
      this.clients = clients.data.resultat;
    },
    async getNbUser() {
      let count = await this.$http.get(this.baseUrl + 'users/count');
      this.nbUsers = count.data.resultat;
      this.pageMax = Math.round(this.nbUsers / this.limit);
    },
    comboClick() {
      var lstCli = document.getElementById('tableClients_length');
      var strCli = lstCli.options[lstCli.selectedIndex].value;
      this.limit = strCli;
      this.page = 1;
      this.pageMax = this.nbUsers / this.limit;
      this.getUsers();
    },
    async searchByName() {
      let ctx = this;
      if (this.searchName.length > 0) {
        let clients = await this.$http.get(
          this.baseUrl + 'users/' + ctx.searchName
        );
        this.clients = clients.data.resultat;
      } else {
        this.getUsers();
      }
    },
    mapKey(value){
      let keyname = null

      switch(value){
        case 'flags.no_kyc':
          keyname = 'Kyc'
          break
        case 'lastname':
          keyname = 'CLIENT'
          break
        case 'created':
          keyname = 'SINCE'
          break
        case 'n_contracts':
          keyname = 'WALLETS'
          break
        case 'current':
          keyname = 'VALUE'
          break
        case 'gains.percent':
          keyname = '%_EARNINGS'
        
      }

      return keyname
    },
    async clearSort() {
      this.sortList = []
      this.getUsers()
    },
    async deleteFromSort(value){
      let findIdx = this.sortList.findIndex(el => el.sort === value.sort)
      this.sortList.splice(findIdx, 1)
      this.getUsers()
    },
    async setSort({sort, asc}) {

      let findIdx = this.sortList.findIndex(el => el.sort === sort)

      let ascKey = asc == -1 ? 'DESC' : 'ASC'
      if(findIdx !== -1){
        let tmp = this.sortList[findIdx]
        tmp.ascKey = ascKey
        tmp.asc = asc
        this.sortList[findIdx] = Object.assign({}, this.sortList[findIdx], tmp)
      } else {
        let keyname = this.mapKey(sort)
        this.sortList.push({
          keyname, 
          ascKey,
          asc,
          sort 
        })
      }

      this.page = 1;
      this.getUsers();
    },
  },
  mounted() {
    this.setBreadLink(['Prime Radiant', 'CRM', 'CLIENTS']);
  },
  created() {
    this.checkLog();
  },
};
</script>
<style scoped>
.h4 {
  margin-bottom: 15px;
}
form {
  position: relative;
  width: fit-content;
  background: #67c2ef;
  border-radius: 5px;
}
input, .searchBtn {
  height: 50px;
  border: 0;
  color: #2f2f2f;
}
input[type="search_customer"] {
  outline: 0; /* <-- should probably remove this for better accessibility, adding for demo aesthetics for now.*/
  width: 100%;
  background: #F3F1E0;
  padding: 0 20px;
  border-radius: 5px;
  appearance: none; 
  transition: all 0.3s cubic-bezier(0, 0, 0.43, 1.49);
  transition-property: width, border-radius;
  z-index: 1;
  position: relative;
}
.searchBtn {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  font-weight: bold;
  background: #67c2ef;
  color:#fff;
  border-radius: 0 5px 5px 0;
}
input:not(:placeholder-shown) {
  border-radius: 5px 0 0 5px;
  width: calc(100% - 50px);
}
input:not(:placeholder-shown) + button {
  display: block;
}
.sortBoxContainer {
  display: flex;
  margin-bottom: 20px;
}
.sortBoxContainer > div:not(:first-child) {
  margin-left: 5px;
}
.sortBox{
  padding: 5px;
  border: 1px solid #F3F1E0;
  box-shadow: 3px 3px 3px #F3F1E0;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
}
</style>
