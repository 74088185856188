<template>
  <div class="container-fluid">
    <div v-if="showFormPopup" class="form__popup">
      <form id="app" @submit="submitForm">
        <div>
          <span>{{$t('PORTFOLIO_DETAILS')}}</span>
          <span style="float:inline-end; cursor: pointer" @click="closeEditForm"><i class="fa fa-close"/></span>
        </div>
            <p v-if="formErr.length">
                <b>{{$t('PLEASE_CORRECT_ERRORS')}}</b>
                <ul>
                    <li v-for="error in formErr" :key="error">{{ error }}</li>
                </ul>
            </p>
            <p class="form__group field">
                <input
                id="pfName"
                v-model="pfName"
                type="text"
                name="pfName"
                class="form__field"
                :placeholder="$t('NAME')"
                >
                <label class="form__label" for="pfName">{{$t('NAME')}}</label>
            </p>
            <p class="form__group field">
                <input
                id="pfNote"
                v-model="pfNote"
                type="number"
                name="pfNote"
                class="form__field"
                min="0"
                placeholder="Note"
                >
                <label class="form__label" for="pfNote">Note</label>
            </p>
            <p style="text-align: end">
                <input class="btn" style="background-color: #E8E8E8; cursor: pointer" type="submit" value="Submit">
                <span style="font-size:15px; cursor: pointer; margin-left:10px" @click="closeEditForm">{{$t('CANCEL_')}}</span>
            </p>
        </form>
    </div>
    <transition name="fade">
      <PopUp
        v-show="popup.show"
        :message="popup.message"
        :question="popup.question"
				:type="popup.type"
        @answer="popup.func"
		@closePopUp="popup.show = false"
      />
    </transition>

    <notifications
      classe="n-light"
      group="foo"
      position="top center"
      :max="3"
      :width="400"
    />
    <div v-if="showPfCreation">
      <PfCreation @back="showPfCreation = false" @save="savedPfCreation" :portfolioProps="dataEdited" />
    </div>
    <div class="col-12">
		<div style="display:flex; margin-bottom: 10px;">
			<CustomSelect 
				v-if="showHistory"
				:list="formatDataForSelect" 
				:index="-1" 
				optionPlaceholder="Current" 
				@change="chooseReference" 
				style="min-width: 300px;"
			/>
			<button v-if="allShowFalse" class="btn__new" style="display: inline-block; max-height: 40px;" @click="showPfCreation = true">
				<i class="fa fa-plus" /> {{ $t('NEW_PF') }}
			</button>
		</div>
      <div
        v-if="!edited && !showPfCreation"
        class="row chart-wrapper"
        style="text-align: center; justify-content: center"
      >
        <div
          class="col-10 col-xl-4"
          v-for="item in orderedPortfolio"
        >
          <h4 style="background-color: #E8E8E8; padding: 10px;">
            {{ $t(dataEdited.portfolio_names[item.index]) }} -
            {{ dataEdited.portfolio_notes[item.index] }}
          </h4>
          <mypieChartDonut
            style="margin-bottom: 20px"
            :dataProps="dataEdited.contents"
            :widthProps="500"
            :heightProps="300"
            :alreadyDone="true"
            :indexProps="item.index"
            :onlyBaseProps="true"
            :translateAssetsProps="translated"
            :translateUniversProps="universtranslated"
            :refreshtoken="refreshtoken"
          />
          <div class="btn__container">
            <button
              class="btn btn__icon infobulle"
              @click="showEditForm(item.index)"
              :aria-label="$t('EDIT_PF_DETAILS')"
            >
              <i class="fa fa-edit" />
            </button>
            <button
              class="btn btn__icon infobulle"
              @click="showEdit(item.index)"
              :aria-label="$t('MODIFY_COMPARE_ASSETS')"
            >
              <i class="fa fa-balance-scale" />
            </button>
            <button
              class="btn btn__icon infobulle"
              @click="deletePf(item.index)"
              :aria-label="$t('DELETE_PF_INFO')"
            >
              <i class="fa fa-trash" />
            </button>
          </div>
          <Volatility :portfolioContent="dataEdited.contents" :index="item.index" />
	  
          <assetsWeights
            :dataProps="dataEdited.contents"
            :indexProps="item.index"
            :translateAssetsProps="translated"
            :translateUniversProps="universtranslated"
            :refreshtoken="refreshtoken"
          />
        </div>
      </div>
      <div
        v-if="!edited && !showPfCreation"
        style="text-align: center; margin-bottom: 15px"
      >
        <button
          class="btn"
          @click="revertReference()"
          style="margin-right: 20px; background-color:#e8e8e8;"
        >
          	{{ $t('CANCEL') }}
			<i class="fa fa-undo" style="margin-left: 5px;"></i>
        </button>
        <button
          class="btn btn-primary myNavInfo"
          @click="saveNewReference()"
        >
         	{{ $t('SAVE') }}
			<i class="fa fa-save" style="margin-left: 5px;"></i>
        </button>
      </div>
      <div
        v-if="edited"
        class="row chart-wrapper"
        style="text-align: center; justify-content: center"
      >
        <div
          v-if="!showcontraintes"
          class="row chart-wrapper"
          style="
            text-align: center;
            justify-content: center;
            flex-direction: column;
          "
        >
		<div class="btn_container">
			<button class="btn" @click="hideEdit">
				<i class="fa fa-arrow-circle-left" style="margin-right: 5px;"></i>
				{{ $t('BACK') }}
			</button>

			<button class="btn" style="background-color:#1f97d1; color: #fff;" @click="showContr">
				{{$t('SHOW_CONSTRAINTS')}}
				<i class="fa fa-arrow-circle-right" style="margin-left: 5px;"></i>
			</button>
		</div>
          <Comparaison
            :portfolio="portfolio"
            :portfolioEdit="dataEdited"
            :note="portfolio.portfolio_notes[nbEdited]"
            :translatedAssets="translated"
            :translatedUnivers="universtranslated"
            :index="nbEdited"
            @updated="updatedPfInEdition"
          />
        </div>
        <IndividualsContraintes
          v-if="showcontraintes && !showcontrcombi"
          :existingContraintesProps="lstContr[nbEdited]"
          :assetsTreeProps="portfolio.contents"
          :translateAssetsProps="translated"
          :translateUniversProps="universtranslated"
          :indexPortfolioProps="nbEdited"
          @showGroup="showGroupContr"
          @hideContr="hideContr"
          @needSave="needSave"
          @editContr="editIndividualsContraintes"
          @changeOrder="changeOrder"
        />
        <div v-if="showcontrcombi && showcontraintes" class="row">
          <groupesCombinaison
            @addgroupcombi="addGroupCombi"
            @selectgroupcombi="selectGroupCombi"
            @removegroupcombi="removeGroupCombi"
            :groupesProps="lstContr[nbEdited].combi"
          />
          <editGroupes
            :dataProps="
              checkedCombi === -1 ? {} : lstContr[nbEdited].combi[checkedCombi]
            "
            @removeassetInGroupCombi="removeAssetInGroupCombi"
            :refreshtoken="refreshtoken"
          />
          <allAssetsContraintes
            @clicked="addAssetInGroup"
            :translateAssetsProps="translated"
            :translateUniversProps="universtranslated"
            @fundClicked="addFundInGroup"
            :typeProps="
              checkedCombi === -1
                ? 'asset'
                : lstContr[nbEdited]['combi'][checkedCombi].type"
          />
          <div class="col-12" style="text-align: center">
            <span @click="hideGroupContr" class="button__back">{{
              $t('BACK')
            }}</span
            ><span style="border-right: 1px solid gray"></span
            ><span @click="backToAccount" class="button__back">{{
              $t('BACK_REFERENCE')
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import Notifications from 'vue-notification';
Vue.use(Notifications);
import PieChartDonut from '@/components/autre/donutchart.vue';
import AssetsWeights from '@/components/gerant/assets/assetsweights.vue';
import AssetsEdit from '@/components/gerant/assets/assetsedit.vue';
import ContrCombinaison from '@/components/gerant/assets/contrcombinaison.vue';
import GroupesCombinaison from '@/components/gerant/contraintes/groupes.vue';
import EditGroupes from '@/components/gerant/contraintes/editgroupes.vue';
import AllAssetsContraintes from '@/components/gerant/assets/allAssetsContraintes.vue';
import PopUp from '@/components/autre/popUp.vue';
import IndividualsContraintes from '@/components/gerant/individualsContraintes.vue';
import Comparaison from '@/components/gerant/main/comparaison.vue';
import Volatility from '@/components/gerant/assets/volatility.vue';
import PfCreation from '@/components/gerant/main/pfCreation.vue';
import CustomSelect from '@/components/autre/customSelect.vue';

Vue.component('allAssetsContraintes', AllAssetsContraintes);
Vue.component('contrcombinaison', ContrCombinaison);
Vue.component('mypieChartDonut', PieChartDonut);
Vue.component('assetsWeights', AssetsWeights);
Vue.component('assetsEdit', AssetsEdit);
Vue.component('groupesCombinaison', GroupesCombinaison);
Vue.component('editGroupes', EditGroupes);

export default {
    data() {
	return {
	    portfolio: [],
	    initPortfolio:[],
	    edited: false,
	    nbEdited: 0,
	    dataEdited: [],
	    refreshtoken: '',
	    showcontraintes: false,
	    lstContr: {},
	    showCombi: false,
	    showcontrcombi: false,
	    showPfCreation: false,
	    nbref: -1,
	    checkedCombi: -1,
	    combitoken: '',
	    hasToBeSaved: false,
	    allWeightUsed: true,
	    popup: {
		show: false,
		question: true,
		message: this.$i18n.t('KEEP_CHANGE'),
		func: this.saveOrCancel,
		type: 'default'
	    },
	    urlToRedirection: '',
	    universtranslated: [],
	    translated: [],
	    deleteIndex: null,
	    pfName: null, 
	    pfNote: null,
	    formErr: [],
	    showFormPopup: false,
	    popupIndex: null,
		actualPf: {}
	};
    },
    components: {
	PopUp,
	IndividualsContraintes,
	Comparaison,
	Volatility,
	PfCreation,
	CustomSelect
    },
    computed: {
	...mapState({
	    breadLink: (state) => state.breadLink,
	    baseUrl: (state) => state.baseUrl,
	    pfreference: (state) => state.pfreference,
	}),
	allShowFalse() {
	    let show = true;
	    
	    if (
		this.showcontraintes ||
		    this.showCombi ||
		    this.edited ||
		    this.showPfCreation
	    ) {
		show = false;
	    }
	    return show;
	},
	showHistory() {
	    let show = false;
	    
	    if (
		'historical' in this.portfolio &&
		    this.portfolio.historical.length > 0 &&
		    !this.edited &&
		    !this.showPfCreation
	    ) {
		show = true;
	    }
	    
	    return show;
	},
	orderedPortfolio(){
	    let arr = [];
	    let idx =  0;
	    for(let item of this.dataEdited.portfolio_notes){
		let tmp = {index: idx, note:item}
		arr.push(tmp);
		idx++;
	    }
	    return arr.sort((a,b) => a.note - b.note);
	},
	formatDataForSelect() {
		let tmp = []
		if("historical" in this.actualPf){
			for (const iterator of this.actualPf.historical) {
				tmp.push(this.parseDate(iterator.updatedAt))
			}
		}
		return tmp
	},
    },
    methods: {
	...mapActions(['setBreadLink', 'setLogged', 'setReferencePf']),
	async checkLog() {
	    let isLog = this.$getIsLogged();
	    if (!isLog) {
		this.setLogged({
		    isLog: false,
		    role: 0,
		});
		await this.$http.get(this.baseUrl + 'auth/logout');
		localStorage.removeItem('priradToken');
		localStorage.removeItem('priradRole');
		localStorage.removeItem('priradCompany');
		this.$router.push('/login');
	    } else {
		let role = this.$getRole();
		if (role == 20) {
		    this.callPortfolio();
		} else {
		    this.setLogged({
			isLog: false,
			role: 0,
		    });
		    await this.$http.get(this.baseUrl + 'auth/logout');
		    localStorage.removeItem('priradToken');
		    localStorage.removeItem('priradRole');
		    localStorage.removeItem('priradCompany');
		    this.$router.push('/login');
		}
	    }
	},
	formatPortfolio(value){
	    for (const [i, iterator] of Object.entries(
		    value.contents
		)) {
		    let tot = Array(value.portfolio_notes.length).fill(0);
		    for (const it of Object.entries(iterator.assets)) {
			it[1].base_weights.forEach((element, ii) => {
			    tot[ii] += element;
			});
		    }
		    iterator['total'] = tot;
	    }

	    return value;
	},
	async callPortfolio() {
		let data = await this.$http.get(
		    this.baseUrl +
			'users/dashboard/portfolios/portfolios/' +
			localStorage.getItem('priradCompany'),
			{
				headers: {
					populate: true
  				}
			}
		);
		let contents = this.formatPortfolio(data.data.resultat[0]);
		
		this.portfolio = JSON.parse(JSON.stringify(contents));
		this.dataEdited = JSON.parse(JSON.stringify(contents));
		this.actualPf = JSON.parse(JSON.stringify(contents));
	    
	    this.initPortfolio = JSON.parse(JSON.stringify(this.portfolio));
	    this.initContr();
	},
	showEdit(index) {
	    this.fundSelected = '';
	    this.nbEdited = index;
	    this.edited = true;
	},
	hideEdit() {
	    if (this.allWeightUsed) {
		this.edited = false;
		this.setReferencePf(this.dataEdited);
	    } else {
		this.errorMsgWeightUsed();
	    }
	},
	showContr(index) {
	    this.showcontraintes = true;
	    this.refreshtokenfunds = this.guidGenerator();
	},
	hideContr() {
	    this.showcontraintes = false;
	    this.lstFunds = [];
	},
	showCombiContr(index) {
	    this.showcontrcombi = true;
	},
	hideCombiContr() {
	    this.showcontrcombi = false;
	},
	editGroup(data) {
	    let dataAssets = this.dataEdited;
	    dataAssets.contents[data.group].assets[data.name]['base_weights'][
		data.index
	    ] = data.val;
	    dataAssets.contents[data.group].total[data.index] = data.total;
	    dataAssets.contents['Fonds Eur et Monetaire'].assets['Trésorerie Euro'][
		'base_weights'
	    ][data.index] = data.treso;
	    dataAssets.contents['Fonds Eur et Monetaire'].total[data.index] =
		data.totalTreso;
	    
	    this.hasToBeSaved = true;
	    this.dataEdited = dataAssets;
	    this.refreshtoken = this.guidGenerator();
	},
	guidGenerator() {
	    let S4 = function () {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	    };
	    return (
		S4() +
		    S4() +
		    '-' +
		    S4() +
		    '-' +
		    S4() +
		    '-' +
		    S4() +
		    '-' +
		    S4() +
		    S4() +
		    S4()
	    );
	},
	initContr() {
	    if ('contraintes' in this.portfolio) {
		let tmpContr = JSON.parse(JSON.stringify(this.portfolio.contraintes));
		if (!('funds' in tmpContr[0])) {
		    for (let item of Object.entries(tmpContr)) {
			item[1]['funds'] = {
			    single: {},
			    combi: [],
			    direct: {},
			    global: {
				fund: { min: 0, max: 10 },
				direct: { min: 0, max: 10 },
			    },
			};
			if (item[1].combi.length > 0) {
			    for (let group of item[1].combi) {
				if (!('type' in group)) {
				    group['type'] = 'asset';
				}
			    }
			}
		    }
		}
		this.lstContr = tmpContr;
	    } else {
		let contrs = {};
		for (let i = 0; i < this.portfolio.portfolio_names.length; i++) {
		    contrs[i] = {};
		    contrs[i]['funds'] = {
			single: {},
			combi: [],
			direct: {},
			global: { fund: { min: 0, max: 10 }, direct: { min: 0, max: 10 } },
		    };
		    contrs[i]['single'] = [
			{ name: 'GlobalFundLimit', min: 0, max: 10, forced: true },
			{ name: 'Trésorerie Euro', min: 0, max: 100, forced: true },
		    ];
		    contrs[i]['combi'] = [];
		}
		this.lstContr = contrs;
	    }
	    //	    this.combitoken = this.guidGenerator();
	},
	addContr(data) {
	    if (this.showcontrcombi) {
		let idx = this.lstContr[this.nbEdited]['combi'][
		    this.checkedCombi
		].assets.findIndex((item) => item == data.asset);
		if (idx == -1) {
		    this.lstContr[this.nbEdited]['combi'][this.checkedCombi].assets.push(
			data.asset
		    );
		}
		this.combitoken = this.guidGenerator();
		this.hasToBeSaved = true;
	    }
	},
	async saveNewReference() {
		if(!this.hasToBeSaved){
	    	this.popup.message = this.$t('NO_CHANGE_TO_SAVE');
			this.popup.question = false
			this.popup.func = ''
			this.popup.type = 'default'
	    	this.popup.show = true;

			return
		}

		const tmpSave = this.dataEdited.historical

		try {
			delete this.dataEdited.historical
				let data = await this.$http.post(
			this.baseUrl + 'users/dashboard/portfolios/newreference',
			{
					company: localStorage.getItem('priradCompany'),
					portfolios: this.dataEdited,
					old: this.initPortfolio,
					contraintes: this.lstContr,
			}
				);
			
				if(data.data.status === 500){
					throw new Error(data.data.error)
				}
			this.popup.func = this.redirectToOpimizationPage;
				this.popup.message = this.$t('ACCOUNT_SAVED') + ", " + this.$t('GO_TO_OPTIMIZATION');
			this.popup.question = true
			this.popup.type = 'success'
			this.popup.show = true;
	
			this.portfolio.historical = data.data.resultat.historical;
				this.hasToBeSaved = false;
		}catch(err){
			this.dataEdited.historical = tmpSave
			this.popup.func = '';
				this.popup.message = this.$t('AN_ERROR_HAS_OCCURRED');
				this.popup.question = false
				this.popup.type = 'error'
				this.popup.show = true;
		}

	},
	parseDate(input) {
	    let a = new Date(input);
	    let months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	    ];
	    let year = a.getFullYear();
	    let month = months[a.getMonth()];
	    let date = a.getDate();
	    let hour = a.getHours();
	    let min = a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes();
	    let sec = a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds();
	    let time =
		date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
	    return time;
	},
	chooseReference(selectedIndex) {
		this.nbref = selectedIndex
	    if (this.nbref != -1) {
		let value = this.formatPortfolio(this.portfolio.historical[this.nbref]);
		let pf = {
		    contents: value.contents,
		    portfolio_names: this.portfolio.historical[this.nbref].portfolio_names,
		    portfolio_notes: this.portfolio.historical[this.nbref].portfolio_notes,
		    contraintes: this.portfolio.historical[this.nbref].contraintes
		}
		this.portfolio = Object.assign({},this.portfolio, JSON.parse(JSON.stringify(pf)));
		this.dataEdited = Object.assign({},this.dataEdited, JSON.parse(JSON.stringify(pf)));
	    } else {
		this.portfolio = Object.assign({},this.portfolio, JSON.parse(JSON.stringify(this.actualPf)));
		this.dataEdited = Object.assign({},this.dataEdited, JSON.parse(JSON.stringify(this.actualPf)));
	    }
	    this.lstContr = {};
	    this.initContr();
	    
	    this.setReferencePf(this.dataEdited);
	    this.refreshtoken = this.guidGenerator();
	    this.refreshtokenfunds = this.guidGenerator();
	},
	revertReference() {
	    this.dataEdited = JSON.parse(JSON.stringify(this.initPortfolio))
	    this.portfolio = JSON.parse(JSON.stringify(this.initPortfolio))
	    this.initContr();
	    this.setReferencePf(this.dataEdited);
	    this.checkedCombi = -1;
	    this.nbref = -1;
	    this.refreshtoken = this.guidGenerator();
	    this.hasToBeSaved = false;
	    this.$notify({
		group: 'foo',
		title: this.$t('SAVE'),
		text: this.$t('UNSAVED_CHANGE'),
		type: '',
	    });
	},
	selectGroupCombi(index) {
	    this.checkedCombi = index;
	},
	removeGroupCombi(idx) {
	    this.lstContr[this.nbEdited].combi.splice(idx, 1);
	    if (this.checkedCombi == this.lstContr[this.nbEdited].combi.length) {
		this.checkedCombi -= 1;
	    }
	    this.hasToBeSaved = true;
	},
	removeAssetInGroupCombi(idx) {
	    this.lstContr[this.nbEdited].combi[this.checkedCombi].assets.splice(
		idx,
		1
	    );
	    this.hasToBeSaved = true;
	},
	addAssetInGroup(name) {
	    let idx = this.lstContr[this.nbEdited]['combi'][
		this.checkedCombi
	    ].assets.findIndex((item) => item == name);
	    if (idx == -1) {
		this.lstContr[this.nbEdited]['combi'][this.checkedCombi].assets.push(
		    name
		);
		this.hasToBeSaved = true;
	    }
	},
	addFundInGroup(element) {
	    let idx = this.lstContr[this.nbEdited]['combi'][
		this.checkedCombi
	    ].assets.findIndex((item) => item.isin == element.isin);
	    if (idx == -1) {
		this.lstContr[this.nbEdited]['combi'][this.checkedCombi].assets.push(
		    element
		);
		this.hasToBeSaved = true;
	    }
	},
	addGroupCombi() {
	    let group = {
		min: 0,
		max: 0,
		assets: [],
		name: 'Group',
		type: 'asset',
	    };
	    
	    this.lstContr[this.nbEdited].combi.push(group);
	    this.checkedCombi += 1;
	    this.hasToBeSaved = true;
	    
	    //	    this.combitoken = this.guidGenerator();
	},
	changeMinMax(data) {
	    let tmp = this.lstContr[this.nbEdited].combi[data.group];
	    tmp.min = data.type == 'min' ? data.value : tmp.min;
	    tmp.max = data.type == 'max' ? data.value : tmp.max;
	    this.lstContr[this.nbEdited].combi[data.group] = tmp;
	    this.hasToBeSaved = true;
	},
	showGroupContr() {
	    this.showcontrcombi = true;
	},
	hideGroupContr() {
	    this.showcontrcombi = false;
	    this.refreshtokenfunds = this.guidGenerator();
	},
	backToAccount() {
	    if (this.allWeightUsed) {
		this.hideContr();
		this.hideGroupContr();
		this.hideEdit();
	    } else {
		this.errorMsgWeightUsed();
	    }
	},
	saveOrCancel(yesNo) {
	    this.popup.show = false;
	    yesNo ? this.saveNewReference() : this.revertReference();
	},
	preventNav(e) {
	    if (!this.hasToBeSaved) return;
	    e.preventDefault();
	    // Custom message has been removed from browser
	    return '';
	},
	redirectToOpimizationPage(yesNo) {
		this.popup.show = false;
		if(yesNo) {
			this.$router.push('/optimisation')
		}
	},
	async getTranslatedUnivers() {
	    let univers_translated = await this.$http.get(
		this.baseUrl + 'univers/translated'
	    );
	    this.universtranslated = univers_translated.data.resultat;
	},
	async getTranslatedAssets() {
	    let assets_translated = await this.$http.get(
		this.baseUrl + 'assets/translated'
	    );
	    this.translated = assets_translated.data.resultat;
	},
	needSave() {
	    this.hasToBeSaved = true;
	},
	editIndividualsContraintes(contr) {
	    this.lstContr[this.nbEdited] = contr;
	},
	changeOrder(funds) {
	    this.dataEdited.contents[funds.group].assets[funds.asset].funds =
		funds.list;
	},
	updatedPfInEdition(val) {
	    this.dataEdited.contents[val.univers].assets[val.asset].base_weights[
		val.index
	    ] = val.weight;
	    this.dataEdited.contents[val.univers].total[val.index] = val.total;
	    this.allWeightUsed = val.weightAvailable != 100 ? false : true;
	    this.hasToBeSaved = true;
	},
	errorMsgWeightUsed() {
	    this.$notify({
		group: 'foo',
		title: this.$t('ERROR'),
		text: this.$t('PORTFOLIO_DOES_NOT_ADD_UP'),
		type: '',
	    });
	},
	savedPfCreation(value) {
	    
	    this.dataEdited = Object.assign({}, this.dataEdited, value);
	    
	    this.addNewContraintes();
	    this.showPfCreation = false;
	},
	addNewContraintes(){
	    let lg = this.dataEdited.portfolio_notes.length;
	    let contr = this.lstContr;
	    contr[lg-1] = {
		"funds": {
		    "combi":[],
		    "direct": {},
		    "single": {},
		    "global": {
			"direct":{"max":10,"min":0},
			"fund":{"max":10,"min":0}
		    }
		},
		"single": [{"name":"Trésorerie Euro","min":0,"max":100,"forced":true}],
		"combi": []
	    };

	    this.lstContr = Object.assign({}, this.lstContr, contr);	    
	},
	deletePf(index) {
	    this.deleteIndex = index;
	    this.popup.func = this.deletePfAnswer;
	    this.popup.message = this.$t('DELETE_PF');
			this.popup.type = 'warning'
	    this.popup.show = true;
	},
	deletePfAnswer(yesNo) {
	    yesNo ? this.deleteIdxFromBasePf() : this.resetPopup();
	    this.deleteIndex = null;
	},
	deleteIdxFromBasePf() {
	    delete this.dataEdited.contraintes[this.deleteIndex];
	    delete this.lstContr[this.deleteIndex];
	    this.dataEdited.portfolio_notes.splice(this.deleteIndex, 1);
	    this.dataEdited.portfolio_names.splice(this.deleteIndex, 1);
	    this.portfolio.portfolio_notes.splice(this.deleteIndex, 1);
	    this.portfolio.portfolio_names.splice(this.deleteIndex, 1);
	    
	    for (const [uniK, uniV] of Object.entries(this.dataEdited.contents)) {
		this.dataEdited.contents[uniK].total.splice(this.deleteIndex, 1)
		this.portfolio.contents[uniK].total.splice(this.deleteIndex, 1)
		for (const [assetK, assetV] of Object.entries(uniV.assets)) {
		    this.dataEdited.contents[uniK].assets[assetK].base_weights.splice(
			this.deleteIndex,
			1
		    );
		    this.dataEdited.contents[uniK].assets[assetK].optimized_weights.splice(
			this.deleteIndex,
			1
		    );
		    this.dataEdited.contents[uniK].assets[assetK].adjusted_weights.splice(
			this.deleteIndex,
			1
		    );
		    
		    this.portfolio.contents[uniK].assets[assetK].base_weights.splice(
			this.deleteIndex,
			1
		    );
		    this.portfolio.contents[uniK].assets[assetK].optimized_weights.splice(
            this.deleteIndex,
            1
          );
          this.portfolio.contents[uniK].assets[assetK].adjusted_weights.splice(
            this.deleteIndex,
            1
          );


          for (const [fundK, fundV] of Object.entries(assetV.funds)) {
            let key = this.dataEdited.contents[uniK].assets[assetK].funds[fundK]
              .stock
              ? 'stockOrder'
              : 'order';
            this.dataEdited.contents[uniK].assets[assetK].funds[fundK][
              key
            ].splice(this.deleteIndex, 1);
          }
        }
      }
      this.hasToBeSaved = true;
      this.refreshtoken = Date.now()
      this.resetPopup();
    },
      resetPopup() {
      this.popup.func = this.saveOrCancel;
      this.popup.message = this.$i18n.t('KEEP_CHANGE');
			this.popup.type = 'default'
      this.popup.show = false;
    },
    submitForm(e) {
      this.hasToBeSaved = true
	this.pfNote = Number(this.pfNote)
	this.dataEdited.portfolio_notes.splice(this.popupIndex,1,this.pfNote);
	this.dataEdited.portfolio_names.splice(this.popupIndex,1,this.pfName);
      e.preventDefault();
      this.refreshtoken = Date.now()
      this.closeEditForm()
    },
    showEditForm(idx){
      this.popupIndex = idx
      this.pfName = this.dataEdited.portfolio_names[idx]
      this.pfNote = this.dataEdited.portfolio_notes[idx]
      this.showFormPopup = true
    },
    closeEditForm(){
      this.pfName = null
      this.pfNote = null
      this.popupIndex = null
      this.showFormPopup = false
    },
  },
  beforeMount() {
    window.onbeforeunload = this.preventNav;
    this.getTranslatedUnivers();
    this.getTranslatedAssets();
  },
  mounted() {
    this.checkLog();
    this.setBreadLink(['Prime Radiant', 'MANAGER', 'REFERENCE_PORTFOLIOS']);
  },
  beforeDestroy() {
    // Don't forget to clean up event listeners
    window.onbeforeunload = null;
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasToBeSaved && this.allWeightUsed) {
      this.popup.show = true;
    } else if (!this.allWeightUsed) {
      this.errorMsgWeightUsed();
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.button__back {
  cursor: pointer;
  padding: 5px 10px;
}
.btn__new {
  margin-left: auto;
  display: block;
  border: none;
  background-color: #1f97d1;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
}
.btn__container {
  display: flex;
  grid-gap: 10px;
  justify-content: center;
}
.btn__icon {
  border: none;
  background-color: #e8e8e8;
  color: #707070;
  display: block;
}
.infobulle {
  position: relative;
  cursor: pointer;
}

.infobulle:hover::after,
.infobulle:focus::after {
  content: attr(aria-label);
  position: absolute;
  top: -2.4em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  white-space: nowrap;
  background-color: #e8e8e8;
  padding: 5px 10px;
  border-radius: 10px;
}
[aria-label]:hover:before,
[aria-label]:focus:before {
  content: '▼';
  position: absolute;
  top: -1em;
  left: 50%;
  transform: translateX(-50%); /* on centre horizontalement  */
  font-size: 10px;
  color: #707070;
}
[aria-label]:focus {
  outline: none;
}
.form {
    display: flex;
}
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #707070;
  padding: 7px 0;
  background: transparent;
  transition: all 0.2s;
  padding-left:5px;
}
.form__field::placeholder{
    color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 18px;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}

.form__field:focus {
  
  padding-bottom: 6px;  
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, black,#707070);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: #707070;
}

/* reset input */
.form__field:required .form__field:invalid{
  box-shadow:none;
}
.form__popup {
  position: fixed; 
  background-color: white;
  box-shadow: 0 0 3px #707070;
  border-radius: 10px;
  min-width: 500px;
  max-width: 1000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 15px;
}
.btn{
	border: none;
	width: fit-content;
	padding: 10px 20px;
}
.btn_container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 0 15px;
}
</style>
