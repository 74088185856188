<template>
  <div>
    <banner
      @refreshed="refresh"
      :dataChange="dataChangeFlag" />

  <div class="tab-pane active fade show">
    <div v-if="isLoading" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog modal-primary">
          <div class="modal-content">
            <div class="modal-body">
              <div class="loadcontent">
                <div class="loading">
                  <p>{{ $t('CALCULATING_PROJECTS') }}</p>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="well">
      <div>
        <div class="row">
          <div class="col-8">
            <GraphSimulation
              @done="refreshed"
              :neededRefresh="needrefresh"
              :dataProps="myObj"
              @clicked="getGraph"
              @ambitious="tooAmbitious"
              :isShowProps="plusMoins"
            />
          </div>
          <div class="col-4">
            <DataSimulation
              @refreshed="refresh"
              :graphProps="graph"
            />
          </div>
        </div>
        <hr />
        <div
          style="padding: 10px; cursor: pointer; width: 100%"
          @click="showProjects = !showProjects"
        >
          <strong>
            {{ $t('PROJECTS') }}
            <i
              :class="[showProjects ? 'fa fa-minus' : 'fa fa-plus']"
              style="float: right"
            />
          </strong>
        </div>
        <br />
          <transition-group name="fade" tag="div" class="row">
            <template v-if="showProjects">
                <div v-for="project in filterProjectList" :key="'project_simulation_'+project.Name" class="col-xl-6 col-12">
                    <ProjectSimulation
                      style="min-width: 500px;"
                      :quantilesfront="graph.response.quantilesProjFront[project.Name]"
                      :bins="graph.response.bins[project.Name]"
                      :obj="myObj"
                      :project="project"
                      :recyclage="recyclage"
                      @changed="slideAllocK"
                      @mixedSlider="slideMix"
                      @recycled="recycle"
                      :realEstateIsInProjects="realEstateIsInProjects" />
                  </div>
            </template>
          </transition-group>
        <hr />
        <br />
      </div>
      <div v-if="!comeFromClientProps" class="text-center">
        <span
          class="btn btn-primary"
          style="cursor: pointer"
          @click="btnClick()"
          >{{ $t('CONTINUE') }}</span
        >
      </div>
      <div v-if="comeFromClientProps" class="text-center">
        <span
          class="btn btn-primary"
          style="cursor: pointer"
          @click="saveSimulation()"
          >{{ $t('CONFIRM') }}</span
        >
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import GraphSimulation from '@/components/agent/nouveau_compte/graph_simulation.vue';
import DataSimulation from '@/components/agent/nouveau_compte/data_simulation.vue';
import ProjectSimulation from '@/components/agent/nouveau_compte/project_simulation.vue';
import Banner from '@/components/agent/nouveau_compte/banner.vue';

export default {
  components: {
    GraphSimulation,
    DataSimulation,
    ProjectSimulation,
    Banner
  },
  data() {
    return {
      myObj: {},
      graph: {},
      plusMoins: false,
      showProjects: true,
      recyclage: {},
      projets: {},
      needrefresh: false,
      isLoading: true,
      realEstateIsInProjects: false,
      dataChangeFlag: null
    };
  },
  props: {
    objectifsProps: {
      type: Object,
      required: false,
    },
    apportsProps: {
      type: Object,
      required: false,
    },
    currentProps: {
      type: Object,
      required: false,
    },
    comeFromClientProps: {
      type: Boolean,
      required: false,
      default: false,
    },
    createdAtProps: {
      type: String,
      required: false,
    },
    dateCreationClientProps: {
      type: String,
      required: false,
    },
  },
  methods: {
    ...mapActions(['setNewCompte']),
    formatInt(x) {
      if (typeof x === 'string') {
        x = x.replace(/ /g, '');
      }
      return parseInt(x);
    },
    editObjectifsProps(projet) {
      let objectifs = this.objectifsProps;
      let editedObjectifs = this.projectToObjectifs(objectifs, projet);
      this.objectifsProps = Object.assign(
        {},
        this.objectifsProps,
        editedObjectifs
      );
    },
    editObjectifsStore(projet) {
      let newAccount = this.$_.cloneDeep(this.newAccount)

      let objectifs = this.newAccount['objectifs'];
      let editedObjectifs = this.projectToObjectifs(objectifs, projet);
      newAccount['objectifs'] = Object.assign(
        {},
        this.newAccount['objectifs'],
        editedObjectifs
      );
      this.setNewCompte(newAccount);
    },
    projectToObjectifs(objectifs, projet) {
      let corr = {
        Retraite: 'retraite',
       Autre: 'autre',
        EpargnedePrecaution: 'precaution',
        ValorisationTransmission: 'transmi',
      };

      if (projet.TypeProjet == 'InvestissementImmobilier') {
        for (let item of objectifs.immo.data) {
          if (item.name == projet.Name) {
            item['proba'] = projet.Proba;
            item['esperance'] = projet.Esperance;
            item['note'] = projet.Note;

            if (this.comeFromClientProps) {
              item['allocKMoinsUn'] = projet.AllocKZero;
            }
          }
        }
      } else if (projet.TypeProjet == 'EtudesEnfants') {
        for (let item of objectifs.school.data) {
          if (item.name == projet.Name) {
            item['proba'] = projet.Proba;
            item['esperance'] = projet.Esperance;
            item['note'] = projet.Note;

            if (this.comeFromClientProps) {
              item['allocKMoinsUn'] = projet.AllocKZero;
            }
          }
        }
      } else if (projet.TypeProjet in corr) {
        objectifs[corr[projet.TypeProjet]]['proba'] = projet.Proba;
        objectifs[corr[projet.TypeProjet]]['esperance'] = projet.Esperance;
        objectifs[corr[projet.TypeProjet]]['note'] = projet.Note;
      }
      return objectifs;
    },
    epargneFromProps() {
      let epargne = {
        Fortune: this.formatInt(this.currentProps),
        FortuneDepart: this.formatInt(this.apportsProps.initial),
        VersmtPeriodik: this.formatInt(this.apportsProps.VersmtPeriodik),
        year: this.formatInt(this.apportsProps.duration),
        FreqVersmtPeriodik: 12,
        date: new Date(this.createdAtProps).toISOString(),
        dateCreation: this.dateCreationClientProps,
        Kyc_vol: this.newAccount.kyc_vol
      };
      return epargne;
    },
    epargneFromStore() {
      let epargne = {
        Fortune: this.formatInt(this.newAccount.apports.initial),
        FortuneDepart: this.formatInt(this.newAccount.apports.initial),
        VersmtPeriodik: this.formatInt(this.newAccount.apports.VersmtPeriodik),
        year: this.formatInt(this.newAccount.apports.duration),
        FreqVersmtPeriodik: 12,
        date: new Date().toISOString(),
        Kyc_vol: this.newAccount.kyc_vol
      };
      return epargne;
    },
    formatData() {
      let myObj = {};
      if (this.comeFromClientProps) {
        myObj['epargne'] = this.epargneFromProps();
        myObj['objectifs'] = JSON.parse(JSON.stringify(this.objectifsProps));
        delete myObj['objectifs']['oldDateProjects'];
      } else {
        myObj['epargne'] = this.epargneFromStore();
        myObj['objectifs'] = this.newAccount.objectifs;
        myObj['contraintes'] = {
          contr1: this.newAccount.contr.contr1,
          createdAt: new Date().toISOString(),
        };
      }
      this.myObj = Object.assign({}, this.myObj, myObj);
    },
    getGraph(val) {
      this.graph = Object.assign({}, this.graph, val);
      let projs = val.response.Projets
      for (let ite of Object.entries(projs)) {
        ite[1]['Name'] = ite[0];
      }

      let projets = Object.values(projs);
      let tmpforgroup = [];
      for (let iterator of projets) {
        if (
          iterator.Name !== ('contr1' || 'contr2' || 'contr3' || 'Recyclage')
        ) {
          tmpforgroup.push(iterator);

          if (!this.comeFromClientProps) {
            this.editObjectifsStore(iterator);
          }
        }
      }
      projets = this.groupByTwo(tmpforgroup);
      projets = this.addShowProperty(projets);
      this.projets = Object.assign({}, this.projets, projets);
      this.realEstateInProjects()
      this.isLoading = false;

      let newAccount = this.$_.cloneDeep(this.newAccount)

      newAccount['graph'] = Object.assign(
        {},
        this.newAccount['graph'],
        this.graph
      );
      this.setNewCompte(newAccount);
    },
    groupByTwo(array) {
      let newArray = [];
      let tmpArray = [];
      for (let i = 0; i < array.length; i++) {
        if (i !== 0 && i % 2 === 0) {
          newArray.push(tmpArray);
          // this.projDataCopy.push({ proj: tmpArr, show: false });
          tmpArray = [];
        }
        tmpArray.push(array[i]);
        // tmpArr.push(this.projData[i]);

        // To get the last one
        if (i === array.length - 1) {
          newArray.push(tmpArray);

          // newArray.push({ proj: tmpArr, show: false });
        }
      }
      return newArray;
    },
    addShowProperty(array) {
      let newArray = [];
      for (const iterator of array) {
        newArray.push({ data: iterator, show: true });
      }
      return newArray;
    },
    tooAmbitious() {
      this.$emit('clicked', {
        name: 'Contr',
        next: 'Obj',
      });
    },
    refreshed() {
      this.graph.allocK = {};
      this.graph.note = {};
      this.needrefresh = false;
    },
    newDataForRefresh(name, objectif, projets, allocK, notes, recycle, mix) {
      if (name in allocK) {
        objectif['allockZero'] = allocK[name];
      } else {
        objectif['allockZero'] =
          name in projets ? projets[name]['AllocKZero'] : 0;
      }

      objectif["allockZero"] = null



      if (name in notes) {
        objectif['note'] = notes[name];
      } else {
        objectif['note'] =
          name in projets ? projets[name]['Note'] : objectif['note'];
      }

      objectif['note'] = null

      if (name in mix) {
        objectif['mixSlider'] = mix[name];
      } else {
        objectif['mixSlider'] =
          name in projets ? projets[name]['Sliders'] : objectif['mixSlider'];
      }

      if (name in recycle) {
        objectif['recycle'] = recycle[name];
      } else {
        objectif['recycle'] = null;
      }

      return objectif;
    },
    refresh() {
      let res = this.graph.response.Projets
      let myObj = this.myObj;
      let allocK = 'allocK' in this.graph ? this.graph.allocK : {};
      let notes = 'risk' in this.graph ? this.graph.risk : {};
      let recycle = 'recycle' in this.graph ? this.graph.recycle : {};
        let mix = 'mixSlider' in this.graph ? this.graph.mixSlider : {};

      if (
        Object.keys(allocK).length != 0 ||
        Object.keys(notes).length != 0 ||
        Object.keys(recycle).length != 0 ||
        Object.keys(mix).length != 0
      ) {
        myObj.epargne['date'] = new Date().toISOString();
      }

      for (let iterator of Object.entries(myObj.objectifs)) {
        if ('data' in iterator[1]) {
          for (let objectif of iterator[1].data) {
            objectif = this.newDataForRefresh(
              objectif.name,
              objectif,
              res,
              allocK,
              notes,
              recycle,
              mix
            );
          }
        } else {
          let capital =
            iterator[0].charAt(0).toUpperCase() + iterator[0].slice(1);
          let name = 'name' in iterator[1] ? iterator[1].name : capital;
          iterator[1] = this.newDataForRefresh(
            name,
            iterator[1],
            res,
            allocK,
            notes,
            recycle,
            mix
          );
        }
      }

      this.myObj = Object.assign({}, this.myObj, myObj);
      this.needrefresh = true;
      this.isLoading = true;
    },
    recycle(val) {
      if (!('recycle' in this.graph)) {
        this.graph = Object.assign({}, this.graph, { recycle: {} });
      }
      let graph = this.graph;
      graph['recycle'][val.name] = val.data;
      let recycle = this.recyclage;
      recycle[val.name] = val.data;
      this.recycle = Object.assign({}, this.recycle, recycle);
      this.graph = Object.assign({}, this.graph, graph);

      let newAccount = this.$_.cloneDeep(this.newAccount)

      newAccount = Object.assign({}, this.newAccount, graph);
      this.setNewCompte(newAccount);
    },
    slideRisk(val) {
      if (!('risk' in this.graph)) {
        this.graph = Object.assign({}, this.graph, { risk: {} });
      }
      let graph = this.graph;
      graph.risk[val.name] = val.data;
      this.graph = Object.assign({}, this.graph, graph);

      let newAccount = this.$_.cloneDeep(this.newAccount)

      newAccount = Object.assign({}, this.newAccount, graph);
      this.setNewCompte(newAccount);
    },
    slideMix(val) {
      if (!('mixSlider' in this.graph)) {
        this.graph = Object.assign({}, this.graph, { mixSlider: {} });
      }
      let graph = this.graph;
      graph.mixSlider[val.name] = val.data;
      this.graph = Object.assign({}, this.graph, graph);
      
      let newAccount = this.$_.cloneDeep(this.newAccount)
      newAccount = Object.assign({}, this.newAccount, graph);
      this.setNewCompte(newAccount);
      this.dataChangeFlag = Date.now()
    },
    slideAllocK(val) {
      if (!('allocK' in this.graph)) {
        this.graph = Object.assign({}, this.graph, { allocK: {} });
      }

      if (!('initAllocK' in this.graph)) {
        this.graph = Object.assign({}, this.graph, { initAllocK: {} });
      }

      if (!('deltaAllocK' in this.graph)) {
        this.graph = Object.assign({}, this.graph, { deltaAllocK: {} });
      }

      let graph = this.graph;

      let diff = Math.abs(Math.round(val.init) - Math.round(val.data));
      if (diff % 1000 != 0) {
        let rounded = diff - (diff % 1000);
        val.data =
          val.init > val.data ? val.init - rounded : val.init + rounded;
      }

      graph['allocK'][val.name] = val.data;
      graph['initAllocK'][val.name] = val.init;

      let totDelta = 0;
      for (let iterator of Object.entries(graph['allocK'])) {
        let delta = graph.initAllocK[iterator[0]] - iterator[1];
        delta = delta > 0 ? -Math.abs(delta) : Math.abs(delta);
        delta = Math.round(delta / 1000) * 1000;
        totDelta += delta;
      }
      graph['deltaAllocK'] = totDelta;

      this.graph = Object.assign({}, this.graph, graph);
      let newAccount = this.$_.cloneDeep(this.newAccount)
      newAccount = Object.assign({}, this.newAccount, graph);
      this.setNewCompte(newAccount);
    },
    btnClick() {
      // this.$emit('clicked', {
      //   name: 'Simu',
      //   next: 'Res',
      // });
      this.$emit("next")
    },
    saveSimulation() {
      let projs = this.graph.response.Projets;
      for (let ite of Object.entries(projs)) {
        ite[1]['Name'] = ite[0];
      }
      let projets = Object.values(projs);
      for (let iterator of projets) {
        if (
          iterator.Name !== ('contr1' || 'contr2' || 'contr3' || 'Recyclage')
        ) {
          this.editObjectifsProps(iterator);
        }
      }
      this.$emit('save');
    },
    realEstateInProjects(){      
      for (const projet of Object.values(this.projets)) {
        if(this.realEstateIsInProjects) break
        for (const project of projet.data) {
          if(project.TypeProjet === "InvestissementImmobilier") {
            this.realEstateIsInProjects = true
            break
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      newAccount: (state) => state.new_Compte,
      filterProjectList(){
        let list = []
        for (const projects of Object.values(this.projets)) {
          for (const project of Object.values(projects.data)) {
            if(project.Name != 'contr1' && project.Name != 'contr2' && project.Name != 'contr3' && project.Name != 'Recyclage') list.push(project)
          }
        }
        return list
      }
    }),
  },
  created() {
    this.formatData();
    let ctx = this;
    this.$store.watch(
      function(state) {
        return state.new_Compte;
      },
      function(val) {
        ctx.setNewCompte(val);
      },
      {
        deep: true,
      }
    );
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loadcontent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadcontent .loading {
  width: 150px;
  height: 50px;
  position: relative;
}
.loadcontent .loading p {
  top: 0;
  padding: 0;
  margin: 0;
  color: #5389a6;
  font-family: 'Oxygen', sans-serif;
  animation: text 3.5s ease both infinite;
  font-size: 12px;
  letter-spacing: 1px;
}
@keyframes text {
  0% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
  40% {
    letter-spacing: 2px;
    transform: translateX(26px);
  }
  80% {
    letter-spacing: 1px;
    transform: translateX(32px);
  }
  90% {
    letter-spacing: 2px;
    transform: translateX(0px);
  }
  100% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
}
.loadcontent .loading span {
  background-color: #5389a6;
  border-radius: 50px;
  display: block;
  height: 16px;
  width: 16px;
  bottom: 0;
  position: absolute;
  transform: translateX(64px);
  animation: loading 3.5s ease both infinite;
}
.loadcontent .loading span:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #a6dcee;
  border-radius: inherit;
  animation: loading2 3.5s ease both infinite;
}
@keyframes loading {
  0% {
    width: 16px;
    transform: translateX(0px);
  }
  40% {
    width: 100%;
    transform: translateX(0px);
  }
  80% {
    width: 16px;
    transform: translateX(64px);
  }
  90% {
    width: 100%;
    transform: translateX(0px);
  }
  100% {
    width: 16px;
    transform: translateX(0px);
  }
}
@keyframes loading2 {
  0% {
    transform: translateX(0px);
    width: 16px;
  }
  40% {
    transform: translateX(0%);
    width: 80%;
  }
  80% {
    width: 100%;
    transform: translateX(0px);
  }
  90% {
    width: 80%;
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
    width: 16px;
  }
}
</style>
