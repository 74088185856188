<template>
  <div>
    <header class="app-header navbar">
      <button class="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link v-if="isLog.isLog" class="navbar-brand" :to="'/'" />
      <router-link v-else class="navbar-brand" :to="'/login'" />
      <button class="navbar-toggler sidebar-toggler d-md-down-none" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <ul class="nav navbar-nav d-md-down-none mr-auto"></ul>
      <ul class="nav navbar-nav ml-auto">
        <li style="min-width: 150px;">
          <CustomSelect 
            :list="getLang"
            :index="getLocalLangIdx"
            @change="languageChange($event)"
            :overrideSelectBtn="{backgroundColor: 'inherit', color: 'white', justifyContent: 'flex-start'}"
          />
        </li>
        <li class="nav-item dropdown">
          <span
            v-if="isLog.isLog"
            @click="disconnect"
            class="nav-link"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            style="cursor:pointer; margin-right:20px;"
          >
            <i class="icon-lock"></i>
          </span>
          <router-link v-else class="mx-5 pe-auto text-white" :to="'/login'">{{$t('LOGIN')}}</router-link>
        </li>
      </ul>
    </header>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import CustomSelect from '@/components/autre/customSelect.vue';

export default {
  components: {
    CustomSelect
  },
  data() {
    return {
      langs: [
        ["ENGLISH", "en"],
        ["FRENCH", "fr"],
      ],
    };
  },
  computed: {
    ...mapState({
      isLog: (state) => state.isLogged,
      baseUrl: (state) => state.baseUrl,
    }),
    getLocalLangIdx: function() {
      let idx = this.langs.findIndex(el => el[1] == this.$i18n.locale)
      return idx !== -1 ? idx : 0
    },
    getLang: function() {
      let tmp = []
      for (const lang of this.langs) {
        tmp.push(lang[0])
      }
      return tmp
    }
  },
  methods: {
    ...mapActions(["setLogged"]),
    async disconnect() {
      this.setLogged({ isLog: false, role: 0 });
      await this.$http.get(this.baseUrl + "auth/logout");
      localStorage.removeItem("priradToken");
      localStorage.removeItem("priradRole");
      localStorage.removeItem("priradCompany");
      localStorage.removeItem("priradApiToken");
      localStorage.removeItem("priradApiRole");
      this.$router.push("/login");
    },
    
    languageChange(event) {
      this.$i18n.locale = this.langs[event][1]
      document.cookie = `lang=${this.langs[event][1]}`
    },
  }
};
</script>
