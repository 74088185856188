<template>
  <div class="tab-pane active">
    <modal_conv @clicked="hideModal" v-if="isShow" :dataProps="comToPass" />
    <table
      class="table table-bordered table-striped table-hover table-condensed"
      style="font-size:12px;"
    >
      <thead>
        <tr>
          <th>{{ $t('CLASS') }}</th>
          <th>{{ $t('GRADE') }}</th>
          <th style="text-align: center;">{{ $t('COMMENTS') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in lstAssets" :key="index">
          <td>{{$t(item)}}</td>
          <td style="text-align:center;">
            <i :class="lstNotesArrow[index]"></i>
            {{lstNotes[index]}}
          </td>
          <td style="text-align: center;">
            <button
              @click="showCom(index)"
              type="button"
              class="btn btn-sm btn-link btn-seondary"
              style="padding:0px;"
            >
              <i class="fa fa-eye" style="color:#9da0a8;"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from "vue";

import Modal_conv from "@/components/agent/convictions/modal_com.vue";

Vue.component("modal_conv", Modal_conv);

export default {
  data() {
    return {
      lstAssets: [
	  "ACTIONS_EUROPE_CONVICTION",
	  "ACTIONS_EUROPE_SMIDS_CONVICTION",
	  "ACTIONS_ZONE_EURO_CONVICTION",
	  "ACTIONS_FRANCE_CONVICTION",
	  "ACTIONS_ENERGIES_CONVICTION",
	  "ACTIONS_EMERGENTS_CONVICTION",
	  "TRESORERIE_USD_CONVICTION"
      ],
      lstCom: [
          "ACTIONS_EUROPE_COMMS",
	  "ACTIONS_EUROPE_SMIDS_COMMS",
	  "ACTIONS_ZONE_EURO_COMMS",
	  "ACTIONS_FRANCE_COMMS",
	  "ACTIONS_ENERGIES_COMMS",
	  "ACTIONS_EMERGENTS_COMMS",
	  "TRESORERIE_USD_COMMS"
      ],
      lstNotesArrow: [
        "fa fa-arrow-up text-primary",
        "fa fa-arrow-up text-primary",
        "fa fa-arrow-up text-primary",
        "fa fa-arrow-up text-primary",
        "fa fa-arrow-up text-success",
        "fa fa-arrow-up text-success",
        "fa fa-arrow-down text-warning",
      ],
      lstNotes: ["+", "+", "+", "+", "++", "++", "-"],
      comToPass: "",
      isShow: false,
    };
  },
  methods: {
    showCom(val) {
      this.comToPass = this.lstCom[val];
      this.isShow = true;
    },
    hideModal() {
      this.isShow = false;
    },
  },
};
</script>
