<script>
import { Line } from "@/assets/js/graph/BaseChart.js";
export default {
  extends: Line,
  data() {
    return {
      data: {
        datasets: [
          {
            label: "BackTest",
            backgroundColor: "rgb(54, 162, 235)",
            borderColor: "rgb(54, 162, 235)",
            data: [],
            fill: false
          }
        ]
      },
      options: {
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                displayFormats: {
                  quarter: "MMM YY"
                },
                tooltipFormat: "MMMM D 'YY"
              },
              position: "bottom"
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                callback: function(value, index, values) {
                  return value + "%";
                }
              }
            }
          ]
        },
        elements: {
          line: {
            tension: 0
          },
          point: {
            radius: 0
          }
        }
      }
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true
    }
  },
  watch: {
    dataProps: function() {
      this.destroyIt();
      this.fillData();
      this.renderChart(this.data, this.options);
    },
  },
  methods: {
    fillData() {
      this.data = {
        datasets: [
          {
            label: "BackTest",
            backgroundColor: "rgb(54, 162, 235)",
            borderColor: "rgb(54, 162, 235)",
            data: [],
            fill: false
          }
        ]
      };

      let ctx = this;

      for (const item of Object.entries(this.dataProps)) {
        ctx.data.datasets[0].data.push({
          x: ctx.dateFormat(item[0]),
          y: this.$_.round(item[1], 2)
        });
      }
    },
    dateFormat: function(x) {
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      let current = new Date(x);

      return current;
    }
  },
  mounted() {
    this.fillData();
    this.passWidthHeight(895, 447);
    this.renderChart(this.data, this.options);
  }
};
</script>
