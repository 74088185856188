<template>
  <div style="max-height: 500px; overflow-y: auto; overflow-x: hidden">
    <table class="table table-bordered bg-white">
      <thead>
        <tr>
          <th  :colspan="colSpanSize" class="table__title">
            {{ $t(`${title}`) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th colspan="2"> 
            {{ $t('ASSET_CLASSES') }}
          </th>
          <th>
            {{ $t(`${title}`) }}
          </th>
          <th class="numeric">
            {{ $t('MIN') }}
          </th>
          <th class="numeric">
            {{ $t('MAX') }}
          </th>
          <th v-if="enableCondition">
            {{ $t('ENABLE') }}
          </th>
        </tr>
        <tr>
          <td colspan="3">{{ $t('GLOBAL') }}</td>
          <td>
            <input
              type="text"
              class="form-control text-center"
              @change="$emit('handleGlobal', 'min', listType, $event)"
              :value="globalCtr.min"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control text-center"
              @change="$emit('handleGlobal', 'max', listType, $event)"
              :value="globalCtr.max"
            />
          </td>
        </tr>
        <tr v-for="asset of list" :key="asset.name + '___' + asset.asset">
          <td class="border_right_none align_top_important">
              <i class="fa fa-times" style="cursor: pointer;" @click="$emit('emit-deleteConstraint',asset, listType)"></i>
          </td>
          <td class="text_position_start align_top_important">
            {{ translate(asset.asset) }}
          </td>
          <td>
            {{ asset.name }}
          </td>
          <td>
            {{ asset.min }}
          </td>
          <td>
            {{ asset.max }}
          </td>
          <td v-if="asset.hasOwnProperty('enable')" >
            <span v-if="asset.enable">
              <i class="fa fa-check" aria-hidden="true"></i>
            </span>
            <span v-else>
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      translated: [],
      universtranslated: [],
      globalCtr: {},
      list: [],
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true,
    },
    assetsRank: {
      type: Array,
      required: false,
    },
    translateUniversProps: {
      type: Array,
      required: true,
    },
    translateAssetsProps: {
      type: Array,
      required: true,
    },
    refreshtokenProps: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    globalContraintes: {
      type: Object,
      required: true,
    },
    listType: {
      type: String,
      required: true,
    },
    indexPortfolioProps: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
    enableCondition() {
      return this.list.length && this.list[0].hasOwnProperty('enable');
    },
    colSpanSize() {
      return this.enableCondition ? '6' : '5'
    }
  },
  watch: {
    globalContraintes: function() {
      this.init();
    },
    dataProps: function() {
      this.orderProps();
    },
  },
  methods: {
    translate(n) {
      let idx = this.translateAssetsProps.findIndex((e) => e.optim == n);
      return idx != -1 ? this.translateAssetsProps[idx][this.$i18n.locale] : n;
    },
    translateUnivers(n) {
      let idx = this.translateUniversProps.findIndex((e) => e.fr == n);
      return idx != -1 ? this.translateUniversProps[idx][this.$i18n.locale] : n;
    },
    orderProps() {
      let ctx = this;
      this.dataProps.sort(function(a, b) {
        let idxA = ctx.assetsRank.findIndex((e) => e == a.asset);
        let idxB = ctx.assetsRank.findIndex((e) => e == b.asset);

        return (
          idxA - idxB ||
          b.order[ctx.indexPortfolioProps] - a.order[ctx.indexPortfolioProps]
        );
      });

      this.list = this.dataProps;
    },
    init() {
      this.globalCtr = Object.assign(
        {},
        this.globalCtr,
        this.globalContraintes
      );
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.table__title {
  color: #707070;
  background-color: #e8e8e8;
  font-weight: bold;
  text-align: left;
}
tr > * {
  vertical-align: middle !important;
}
.text_position_start {
  text-align: start !important;
}
.border_right_none {
  border-right-style:hidden;
}
.align_top_important {
  vertical-align: top !important;
}
</style>
