<template>
  <div>
    <div class="projectContainer mb-4">
      <div class="position-relative p-3">
        <div class="text-center img-container">
          <img :src="projectType[project.TypeProjet].img" width="60px" />
        </div>
        <h4 class="text-center title" :style="{ ...cssVars }">
          {{ $t(titleFormat) }}
        </h4>
        <div class="projectInfoContainer" :style="{ ...cssVars }">
          <div class="projectInfo" :style="{ ...cssVars }">
            <p class="projectInfo__title" :style="{ ...cssVars }">
              <span v-if="project.TypeProjet === 'EtudesEnfants'">
                ({{ $t('Annual') }})
              </span>
              {{ $t('OBJECTIVE_SIMULATION') }}
            </p>
            <p class="text-center font-weight-bold mb-0">
              {{ formatNumber(project.MontantCible) }} €
            </p>
          </div>
          <div class="projectInfo" :style="{ ...cssVars }">
            <p class="projectInfo__title" :style="{ ...cssVars }">
              {{ $t('ALLOCATED_CAPITAL') }}
            </p>
            <p class="text-center font-weight-bold mb-0">
              {{ $formatNumberKM(allocationModif) }} €
            </p>
          </div>
          <div class="projectInfo" :style="{ ...cssVars }">
            <p class="projectInfo__title" :style="{ ...cssVars }">
              {{ $t('CONTRIBUTION_PLUS_CAPITAL') }}
            </p>
            <p class="text-center font-weight-bold mb-0">
              {{ $formatNumberKM(allocationTotal) }} €
            </p>
          </div>
          <div class="projectInfo" :style="{ ...cssVars }">
            <p class="projectInfo__title" :style="{ ...cssVars }">
              {{ $t('RISK_LEVEL') }}
            </p>
            <p class="text-center font-weight-bold mb-0">
              {{ roundTwoDigit(riskNote) }}
            </p>
          </div>
          <div class="projectInfo" :style="{ ...cssVars }">
            <p
              class="projectInfo__title"
              :style="{ ...cssVars }"
              name="estate_mensuel"
            >
              {{ $t('EXPECTATION') }}
            </p>
            <p class="text-center font-weight-bold mb-0">
              {{ $formatNumberKM(project.Esperance) }}€
            </p>
          </div>
          <div class="goalProbability" :style="{ ...cssVars }">
            <p
              class="projectInfo__title goalProbability__title"
              :style="{ ...cssVars }"
              name="estate_mensuel"
            >
              {{ $t('PROBABILITY_MEETING_EXCEEDING_GOAL') }}
            </p>
            <DonutPercentage
              :value="formatNumber(project.Proba)"
              :color="projectType[project.TypeProjet].color"
            />
          </div>
          <div
            class="projectInfo"
            :style="{ ...cssVars }"
            v-if="project.TypeProjet == 'InvestissementImmobilier'"
          >
            <p
              class="projectInfo__title"
              :style="{ ...cssVars }"
              name="estate_mensuel"
            >
              {{ $t('MONTHLY_LOAN_REPAYMENT') }}
            </p>
            <p class="text-center font-weight-bold mb-0">{{ rembEmprunts }}</p>
          </div>
        </div>

        <div class="cardInputContainer">
          <div>
            <div class="mt-4 text-center">
              {{ $t('SLIDER_PRIORITY') }} &nbsp;
              <span>{{ Math.abs(roundTwoDigit(mixSlider)) }}</span>
            </div>
            <div class="mt-8 text-center">
              <SliderInput 
                :name="'slid' + project.Name"
                :min="-2"
                :max="2"
                :step="0.1"
                :thumbColor="projectType[project.TypeProjet].color"
                v-model="mixSlider" 
                @handleMouseUp="handlePrioritySlider"/>
              <div class="d-flex justify-content-center">
                <p style="font-size: 12px; margin-right: 60px">
                  {{ $t('MORE_PRIORITY') }},<br />
                  {{$t('LESS_RISK')}}
                </p>
                <p style="font-size: 12px; margin-left: 60px">
                  {{$t('MORE_RISK')}}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="inputGroup">
              <input
                :id="'goal_priority_' + project.Name + project.TypeProjet"
                :name="'goal_priority_' + project.Name + project.TypeProjet"
                :checked="recyclage[project.Name] == 1"
                @change="checkedRecycle()"
                type="checkbox"
              />
              <label
                :for="'goal_priority_' + project.Name + project.TypeProjet"
              >
                {{ $t('RECYCLING_SURPLUSES') }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          d-flex
          flex-column
          justify-content-center
          projectInfoContainer--left
        "
        :style="{ ...cssVars }"
      >
        <ViolinSimulation
          :proba="Math.round(project.Proba)"
          :cible="formatNumber(project['MontantCible'])"
          :cibleInt="project['MontantCible']"
          :annee="project.AnneeCible"
          :name="project.Name"
          :bins="bins"
          :quantfront="quantilesfront"
          :colors="current_colors"
          class="mx-auto"
        />
      </div>
    </div>
  </div>
</template>

<script>

import ViolinSimulation from '@/components/agent/nouveau_compte/ViolinSimulation.vue';
import SliderInput from '@/components/autre/SliderInput.vue';
import DonutPercentage from '@/components/autre/DonutPercentage.vue';

export default {
  name: 'ProjectSimulation',
  components: {
    ViolinSimulation,
    DonutPercentage,
    SliderInput
  },
  props: {
    project: Object,
    obj: Object,
    quantiles: Array,
    recyclage: Object,
    quantilesfront: Array,
    realEstateIsInProjects: Boolean,
    bins: Object,
  },
  watch: {
    project: function (val) {
      (this.allocationModif = val.AllocKInterm), //val.AllocKZero;
        (this.initAlloc = val.AllocKZero);
      this.riskNote = val.Note;
      this.mixSlider = val.Sliders;
      this.allocationTotal = val.TotVersmtsPrjts;
    },
  },
  data() {
    return {
      allocationModif: this.project.AllocKInterm, //this.project.AllocKZero,
      initAlloc: this.project.AllocKZero,
      riskNote: this.project.Note,
      allocationModofiInterval: false,
      mixSlider: this.project.Sliders,
      allocationTotal: this.project.TotVersmtsPrjts,
      projectType: {
        ValorisationTransmission: {
          img: 'img/icons/icon-objectif-transmission.png',
          color: '#8BD6D1',
          imgColor: '#18AEA4',
        },
        InvestissementImmobilier: {
          img: 'img/icons/icon-objectif-immobilier.png',
          color: '#8BB3CC',
          imgColor: '#18699B',
        },
        Autre: {
          img: 'img/icons/icon-objectif-autre.png',
          color: '#FCAEAC',
          imgColor: '#FB5F5B',
        },
        EpargnedePrecaution: {
          img: 'img/icons/icon-objectif-epargne.png',
          color: '#F4A4B3',
          imgColor: '#EB4A69',
        },
        Retraite: {
          img: 'img/icons/icon-objectif-retraite.png',
          color: '#F9D68B',
          imgColor: '#F5AE18',
        },
        EtudesEnfants: {
          img: 'img/icons/icon-objectif-ecole.png',
          color: '#98BF7F',
          imgColor: '#338000',
        },
      },
    };
  },
  computed: {
    maxCapital() {
      return this.project.AllocK * 3;
    },
    rembEmprunts() {
      let remb = null;
      if (this.project.RembEmprunts !== null) {
        remb = this.$formatNumberKM(this.project.RembEmprunts);
        remb = `${remb}€`;
      } else {
        remb = 'Inconnu';
      }
      return remb;
    },
    titleFormat() {
      return this.project.TypeProjet == 'EtudesEnfants'
        ? 'Etudes de ' + this.project.Name
        : this.project.TypeProjet == 'Retraite'
        ? 'Complément de ' + this.project.Name
        : this.project.Name;
    },
    cssVars() {
      return {
        '--goal-color': this.projectType[this.project.TypeProjet].color,
        '--goal-color-img': this.projectType[this.project.TypeProjet].imgColor,
        '--row-count': this.realEstateIsInProjects ? '4' : '3',
      };
    },
    current_colors() {
      return {
        color: this.projectType[this.project.TypeProjet].color,
        imgColor: this.projectType[this.project.TypeProjet].imgColor,
      };
    },
  },
  methods: {
    roundTwoDigit(number) {
      return parseFloat(Math.round(number * 10) / 10).toFixed(1);
    },
    formatNumber(number) {
      return Math.round(number)
        .toString()
        .replace(' ', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    clickAllocationModif(type, projectName) {
      type === 'add'
        ? (this.allocationModif += 1000)
        : (this.allocationModif -= 1000);
      this.slider({ name: projectName, data: this.allocationModif });
    },
    startAllocationModif(type, projectName) {
      const timeInterval = 250;
      const rangeValue = 1000;

      if (!this.allocationModofiInterval) {
        type === 'add'
          ? (this.allocationModofiInterval = setInterval(() => {
              this.allocationModif += rangeValue;
              this.slider({ name: projectName, data: this.allocationModif });
            }, timeInterval))
          : (this.allocationModofiInterval = setInterval(() => {
              this.allocationModif -= rangeValue;
              this.slider({ name: projectName, data: this.allocationModif });
            }, timeInterval));
      }
    },
    stopAllocationModif() {
      clearInterval(this.allocationModofiInterval);
      this.allocationModofiInterval = false;
    },
    slider(val) {
      val.init = this.initAlloc;
      val.name = this.project.Name;
      this.$emit('changed', val);
      this.allocationModif = val.data;
    },
    handlePrioritySlider(val) {
      val.name = this.project.Name;
      this.mixSlider = val.data;
      this.$emit('mixedSlider', val);
    },
    checkedRecycle() {
      let val = {
        name: this.project.Name,
        data: this.recyclage[this.project.Name] == 1 ? null : 1,
      };
      this.$emit('recycled', val);
    },
  },
};
</script>

<style>
@import '../../../assets/css/checkbox-full-btn-v2.css';

.img-container {
  position: absolute;
  top: 0px;
  left: 0px;
}
.title {
  padding: 10px;
  background-color: var(--goal-color);
  color: whitesmoke;
}
.projectContainer {
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: 15px;
  box-shadow: 0px 0px 3px 1px lightgray;
}
.projectInfoContainer {
  margin-top: 40px;
  display: grid;
  grid-template-rows: repeat(var(--row-count), 135px);
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.projectInfoContainer--left {
  border-left: 10px solid var(--goal-color-img);
}
.projectInfo {
  box-shadow: 0px 0px 3px lightgray;
  padding: 4px;
  border-radius: 5px;
}
.projectInfo__title {
  text-align: center;
  background-color: var(--goal-color);
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.goalProbability {
  box-shadow: 0px 0px 3px lightgray;
  grid-row: 2 / span calc(var(--row-count) - 1);
  grid-column: 1 / span 2;
  position: relative;
  padding-top: 50px;
}
.goalProbability__title {
  position: absolute;
  top: 0px;
}
.cardInputContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
</style>
