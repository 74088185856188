<template>
  <div>
    <div v-if="showTitle" class="d-flex justify-content-between">
        <h5 class="align-self-center m-0">
          <img src="../../../../../public/img/icons/planet-earth.png" class="icon" alt="earth and environment" />
          {{ $t('ESG') }}
        </h5>
    </div>
    <hr />
    <div class="card-body">
        <AnimationSlider style="margin-bottom: 50px;"
            :animationJSON="communityAnim"
            placeHolder="SOCIAL"
            :reversed="true"
            :startFrame="61"
            :percentProps="esg.introductionQuestion.itemsScores[1]"
                         viewBox="90 0 300 270"
            @slider="sliderUpdate"/>
        <AnimationSlider :animationJSON="earthAnim"
            placeHolder="ENVIRONMENT"
            :maxFrame="45"
            :percentProps="esg.introductionQuestion.itemsScores[0]"
            @slider="sliderUpdate"/>

        <form action method="post" class="form-horizontal mt-5">
            <Transition>
                <div class="form-row row__container mb-4 pt-4" v-if="environmentIsPopulated">
                    <div v-for="(item, index) in esg.esgGame.itemsKeys" :key="item" class="col-12 input-group mb-4">
                        <span class="input-group-addon">{{$t(item)}} (%)</span>
                        <input
                            type="text"
                            :id="item"
                            :name="item"
                            class="form-control"
                            placeholder
                            v-model="esg.esgGame.itemsWeights[index]"
                            @change="newInput()"
                        />
                    </div>
                </div>
            </Transition>
            <div class="form-row">
                <div class="form-group col">
                    <label for="_pai">Pai</label>
                    <select id="_pai" class="form-control" @change="addItem">
                        <option selected>Choose...</option>
                        <option v-for="item in esgPaiList"
                            :key="item"
                            :value="item"
                        >
                            {{$t(item)}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="pai__container">
                <div class="pai" v-for="(item, index) in esg.pai" :key="item">
                    {{$t(item)}} <i class="fa fa-times ml-2" style="cursor: pointer" @click="removePai(index)"/>
                </div>
            </div>
        </form>
    </div>
  </div>
</template>

<script>
import AnimationSlider from "../../../autre/AnimationSlider.vue"

export default {
    components: {AnimationSlider},
    data() {
        return {
            numberOfEsgPrefToken: 5,
            esgPaiList: [
                "greenhouse_gas_emission",
                "use_of_non_renewable_energy",
                "emissions_to_water",
                "human_rights",
                "social_and_employee_matters"
            ],
            esg: {},
            communityAnim: require('/public/animations/community.json'),
            earthAnim: require('/public/animations/greenify.json'),
            itemsScoresIndexMapping: {
                "ENVIRONMENT": 0,
                "SOCIAL": 1
            },
        }
    },
    props: {
        esgProps: {
            type: Object,
            required: true
        },
        showTitle: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
  watch: {
    esgProps: {
        handler: function() {
            this.esg = this.$_.cloneDeep(this.esgProps)
        },
        immediate: true
    },
  },
  computed: {
    environmentIsPopulated(){
        return this.esg.introductionQuestion.itemsScores[0] > 0
    }
  },
  methods: {
    sliderUpdate(event){
        if(this.esg.introductionQuestion.itemsScores[this.itemsScoresIndexMapping[event.placeHolder]] === event.percent) return
        this.esg.introductionQuestion.itemsScores[this.itemsScoresIndexMapping[event.placeHolder]] = event.percent
        this.newInput()
    },
    addItem(event){
        if(event.target.value === "Choose...") return 

        if(!this.esg.pai.includes(event.target.value)) {
            this.esg.pai.push(event.target.value)
            this.newInput()
        }
    },
    removePai(idx){
        this.esg.pai.splice(idx, 1);
        this.newInput()
    },
    newInput(){
        this.$emit("changeESG", this.esg)
    }
  }
};
</script>

<style scoped>
.icon {
  width: 20px;
  margin: 0 10px 0 0;
  font-size: 14px;
  text-align: center;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
