<template>
  <div style="padding: 10px">
    <div class="d-flex justify-content-between">
      <h5 class="align-self-center m-0">
          <i class="fa fa-list-alt" style="color:#36a9e1;"></i>
          {{$t('HISTORY')}}
      </h5>
    </div>
    <hr />
    <div class="col-10">
      <div>
        <table class="table table-sm">
          <thead>
            <th>Date</th>
            <th>{{$t('CONTRIBUTION_WITHDRAWAL')}}</th>
          </thead>
          <tbody>
            <!-- ligne événement -->
            <tr v-for="(item, index) in reverseItems" :key="index">
              <td>{{dateCreation(item[0])}}</td>
              <td>
                <i class="text-muted fa fa-balance-scale" style="margin-right: 5px;"></i>
                <span
                  v-if="item[1].toString().startsWith('-')"
                  style="color: red"
                >{{formatNumber(item[1])}}</span>
                <span v-else style="color: green">{{formatNumber(item[1])}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      client: (state) => state.client_Portfolio,
    }),

    reverseItems() {
      return this.client.histo.slice().reverse();
    },
  },
  methods: {
    dateCreation(x) {
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      let current = new Date(x);

      return current.toLocaleDateString("fr-FR", options);
    },
    formatNumber(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
  },
};
</script>
<style scoped>
  tr:hover {
    background-color: #F3F1E0;
  }
</style>