var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('NavDash',{staticClass:"text-center",attrs:{"showBtnRevert":false,"showBtnUpdate":false},on:{"clicked":_vm.chooseCompte}}),_c('div',[_c('div',{staticClass:"tab-content",staticStyle:{"padding":"15px","border":"none !important","overflow-y":"auto","max-height":"500px"}},[_c('table',{staticClass:"table table-bordered table-condensed",staticStyle:{"background-color":"white"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center rebalancing-table_size_w"},[_vm._v(" "+_vm._s(_vm.$t('FUNDS_NAME'))+" ")]),_c('th',{staticClass:"text-center rebalancing-table_size_w"},[_vm._v(" "+_vm._s(_vm.$t('ASSETS'))+" ")]),_c('th',{staticClass:"text-center rebalancing-table_size_w"},[_vm._v(" "+_vm._s(_vm.$t('MONEY'))+" ("+_vm._s(_vm.$t('ESTIMATION'))+") ")])])]),('preview_arbitrage' in _vm.portfoliosProps.overall)?_c('tbody',[(_vm.idxCompte == -1)?_vm._l((Object.entries(
                _vm.portfoliosProps.overall.preview_arbitrage
              ).sort(function (a, b) { return Math.abs(b[1].value) - Math.abs(a[1].value); })),function(arbitrage){return (arbitrage[1].value != 0)?_c('tr',[_c('td',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"space mr-2",style:({
                    width: '5px',
                    minWidth: '5px',
                    height: '100%',
                    backgroundColor: arbitrage[1].value > 0 ? 'green' : 'red',
                  })}),_c('div',[_vm._v(_vm._s(arbitrage[1].name))])]),_c('td',{staticStyle:{"align-items":"center","text-align":"center"}},[_vm._v(" "+_vm._s(arbitrage[0])+" ")]),_c('td',{staticStyle:{"align-items":"center","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.formatNumber(arbitrage[1].value))+" ")])]):_vm._e()}):_vm._l((Object.entries(
                _vm.portfoliosProps.individuals[_vm.idxCompte].preview_arbitrage
              ).sort(function (a, b) { return Math.abs(b[1].value) - Math.abs(a[1].value); })),function(arbitrage){return (arbitrage[1].value != 0)?_c('tr',[_c('td',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"space mr-2",style:({
                    width: '5px',
                    height: '100%',
                    backgroundColor: arbitrage[1].value > 0 ? 'green' : 'red',
                  })}),_c('div',[_vm._v(_vm._s(arbitrage[1].name))])]),_c('td',{staticStyle:{"align-items":"center","text-align":"center"}},[_vm._v(" "+_vm._s(arbitrage[0])+" ")]),_c('td',{staticStyle:{"align-items":"center","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.formatNumber(arbitrage[1].value))+" ")])]):_vm._e()})],2):_vm._e(),(!('preview_arbitrage' in _vm.portfoliosProps.overall))?_c('tbody',[(_vm.idxCompte == -1)?_vm._l((Object.entries(
                _vm.portfoliosProps.overall.preview_distribution
              ).sort(function (a, b) { return Math.abs(b[1]) - Math.abs(a[1]); })),function(distrib){return (distrib[1] !== 0)?_c('tr',[_c('td',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"space mr-2",style:({
                    width: '5px',
                    height: '100%',
                    backgroundColor: 'green',
                  })}),_c('div',[_vm._v(_vm._s(distrib[0]))])]),_c('td',{staticStyle:{"align-items":"center","text-align":"center"}},[_vm._v(" "+_vm._s(distrib[0])+" ")]),_c('td',{staticStyle:{"align-items":"center","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.formatNumber( Math.round( _vm.portfoliosProps.overall.bt[ _vm.portfoliosProps.overall.bt.length - 1 ].value * distrib[1] ) ))+" ")])]):_vm._e()}):_vm._l((Object.entries(
                _vm.portfoliosProps.individuals[_vm.idxCompte].preview_distribution
              ).sort(function (a, b) { return Math.abs(b[1]) - Math.abs(a[1]); })),function(distrib){return (distrib[1] !== 0)?_c('tr',[_c('td',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"space mr-2",style:({
                    width: '5px',
                    height: '100%',
                    backgroundColor: 'green',
                  })}),_c('div',[_vm._v(_vm._s(distrib[0]))])]),_c('td',{staticStyle:{"align-items":"center","text-align":"center"}},[_vm._v(" "+_vm._s(distrib[0])+" ")]),_c('td',{staticStyle:{"align-items":"center","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.formatNumber( Math.round( _vm.portfoliosProps.individuals[_vm.idxCompte].bt[ _vm.portfoliosProps.individuals[_vm.idxCompte].bt.length - 1 ].value * distrib[1] ) ))+" ")])]):_vm._e()})],2):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }