<template>
    <footer class="app-footer">
    <span>
      <!-- <a href="http://www.primeradiant.fr">Prime Radianr</a> © 2017 Prime radiant Technologies.-->
    </span>
    <span class="ml-auto fontSpan">Powered by
      <a href="https://www.primeradiant.fr">Primeradiant Technologies © {{ getYear }}</a>
    </span>
  </footer>
</template>
<script>
export default {

  computed: {
    getYear() {
        return new Date().getFullYear()
    }
  }

};
</script>

<style>
.fontSpan{
    font-size: 12px;
}
</style>

