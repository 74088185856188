<template>
  <div class="tab-pane fade in active show">
    <div class="well">
      <label>
        <strong>
          <i class="icon-user"></i>
          {{ $t('CONTACT_INFORMATION')}}
        </strong>
      </label>
      <hr />

      <form
        action
        method="post"
        enctype="multipart/form-data"
        class="form-horizontal"
        style="font-size: 18px;"
      >
        <div class="form-group row">
          <label class="col-md-4 col-form-label">{{ $t('TITLE') }}</label>
          <div class="col-md-8">
            <div class="radio">
              <label for="radio1">
                <input
                  type="radio"
                  id="radio1"
                  name="radios"
                  value="M"
                  v-model="client.sex"
                  :checked="client.sex == 'M'"
                /> M.
              </label>
            </div>
            <div class="radio">
              <label for="radio2">
                <input
                  type="radio"
                  id="radio2"
                  name="radios"
                  value="F"
                  v-model="client.sex"
                  :checked="client.sex == 'F'"
                />
                {{$t('MMS')}}
              </label>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{$t('LAST_NAME')}}</label>
          <div class="col-md-8">
            <input
              type="text"
              id="text-input"
              name="text-input"
              class="form-control"
              placeholder="Nom"
              v-model.lazy="client.lastname"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{ $t('FIRST_NAME')}}</label>
          <div class="col-md-8">
            <input
              type="text"
              id="text-input"
              name="text-input"
              class="form-control"
              placeholder="Prénom"
              v-model.lazy="client.name"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="select">{{$t('NATIONALITY')}}</label>
          <div class="col-md-8">
            <select
              id="select"
              class="form-control"
              v-model="client.info.extra_info.general.country"
            >
              <option value="0">{{$t('CHOOSE')}}…</option>
              <option
                value="1"
                :selected="client.info.extra_info.general.country==1"
              >{{$t('French')}}</option>
              <option
                value="2"
                :selected="client.info.extra_info.general.country==2"
              >{{ $t('BELGIAN')}}</option>
              <option
                value="3"
                :selected="client.info.extra_info.general.country==3"
              >{{$t('SWITZERLAND')}}</option>
            </select>
          </div>
        </div>

        <hr />

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{$t('PHONE')}}</label>
          <div class="col-md-8">
            <input
              type="text"
              id="text-input"
              name="text-input"
              class="form-control"
              v-model.lazy="client.info.extra_info.general.telephone"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="email-input">{{$t("EMAIL")}}</label>
          <div class="col-md-8">
            <input
              type="email"
              id="email-input"
              name="email-input"
              class="form-control"
              v-model.lazy="client.email"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="textarea-input">{{$t('ADDRESS')}}</label>
          <div class="col-md-8">
            <textarea
              id="textarea-input"
              name="textarea-input"
              rows="9"
              class="form-control"
              v-model.lazy="client.info.extra_info.general.address"
            ></textarea>
          </div>
        </div>
        <hr />

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="select">{{ $t('TAXATION')}}</label>
          <div class="col-md-8">
            <select class="form-control" v-model="client.info.extra_info.general.taxation">
              <option value="0">Choisir…</option>
              <option value="1" :selected="client.info.extra_info.general.taxation == 1">IR</option>
              <option value="2" :selected="client.info.extra_info.general.taxation == 2">IS</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="select">{{$t('TAX_RESIDENCE')}}</label>
          <div class="col-md-8">
            <select
              id="select"
              class="form-control"
              v-model="client.info.extra_info.general.fiscal_residency"
            >
              <option value="0">Choisir…</option>
              <option
                value="1"
                :selected="client.info.extra_info.general.fiscal_residency == 1"
              >{{$t('FRANCE')}}</option>
              <option
                value="2"
                :selected="client.info.extra_info.general.fiscal_residency == 2"
              >{{$t('SWITZERLAND')}}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{$t('AGENT')}}</label>
          <div class="col-md-8">
            <input
              type="text"
              id="text-input"
              name="text-input"
              class="form-control"
              placeholder
              v-model.lazy="client.info.extra_info.general.representant"
            />
          </div>
        </div>
      </form>
    </div>

    <div class="text-center">
      <span
        class="btn btn-primary next"
        style="cursor:pointer;"
        @click="btnClick()"
      >{{$t('CONTINUE')}}</span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions(["setClientInfo"]),

    telephone() {
      return this.client.info.extra_info.general.telephone.replace(
        /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
        "$1 $2 $3 $4 $5"
      );
    },
    btnClick() {
      this.$emit("clicked", {
        name: "Coor",
        next: "Situ",
      });
    },
    parseToInt() {
      let myObj;
      for (const item of Object.entries(this.client.info.extra_info.general)) {
        if (
          item[0] == "country" ||
          item[0] == "taxation" ||
          item[0] == "fiscal_residency"
        ) {
          if (typeof item[1] != "number") {
            if (item[1] == "0")
              this.client.info.extra_info.general[item[0]] = 0;
            else
              this.client.info.extra_info.general[item[0]] = parseInt(item[1]);
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      client: (state) => state.client_Info,
    }),
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function (state) {
        return state.client_Info;
      },
      function (val) {
        val.info.extra_info.general.telephone = ctx.telephone();
        ctx.parseToInt();
        ctx.setClientInfo(val);
      },
      {
        deep: true,
      }
    );
    this.client.info.extra_info.general.telephone = ctx.telephone();
  },
};
</script>
