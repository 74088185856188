<template>
  <div>
    <template v-if="client.n_contracts == 1">
      <button
        type="button"
        class="btn btn-sm btn-primary"
        style="
          background-color: #1f97d1;
          margin-bottom: 15px;
          position: relative;
          top: 7.5px;
        "
        @click="btnClick(-1)"
      >
        <i class="fa fa-pie-chart"></i>
        &nbsp; {{ $t(client.individuals[0].name) }}
      </button>
      <button
        @click="revertUpdatePf"
        style="margin-left: 0.5%"
        v-show="showBtnRevert"
        class="btn btn-sm revertBtn"
      >
        <i class="fa fa-undo"></i>
        &nbsp; {{ $t('CANCEL_UPDATE') }}
      </button>
      <button
        @click="previewPf"
        style="margin-left: 0.5%"
        v-show="showBtnProposal"
        class="btn btn-sm revertBtn"
      >
        <i class="fa fa-eye"></i>
        &nbsp; {{ $t('VIEW_PROPOSAL') }}
      </button>
    </template>
    <template v-else>
      <button
        type="button"
        :class="{
          'btn btn-sm btn-primary mybtnactive': isClicked == -1,
          'btn btn-sm btn-primary': isClicked != -1,
        }"
        style="margin-bottom: 15px; position: relative; top: 7.5px"
        @click="btnClick(-1, 'consolidatedAccount')"
      >
        <i class="fa fa-pie-chart"></i>
        &nbsp; {{ $t('CONSOLIDATED_ACCOUNT') }}
      </button>
      <!-- Compte PEA / cpte titre.... -->
      <button
        v-for="(pf, index) in client.individuals"
        type="button"
        :class="{
          'btn btn-sm btn-primary mybtnactive': isClicked == index,
          'btn btn-sm btn-primary': isClicked != index,
        }"
        style="
          margin-left: 0.5%;
          margin-bottom: 15px;
          position: relative;
          top: 7.5px;
        "
        :key="index+'_'+generateUuid"
        @click="btnClick(index, pf.name)"
      >
        <i class="fa fa-pie-chart"></i>
        &nbsp; {{ $t(pf.name) }}
      </button>
      <button
        style="margin-left: 0.5%"
        @click="revertUpdatePf"
        v-show="showBtnRevert"
        class="btn btn-sm revertBtn"
      >
        <i class="fa fa-undo"></i>
        &nbsp; {{ $t('CANCEL_UPDATE') }}
      </button>
      <button
        style="margin-left: 0.5%"
        @click="previewPf"
        v-show="showBtnProposal"
        class="btn btn-sm revertBtn"
      >
        <i class="fa fa-eye"></i>
        &nbsp; {{ $t('VIEW_PROPOSAL') }}
      </button>
    </template>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import uuidMixin from '@/mixins/uuid'

export default {
  mixins: [uuidMixin],
  data() {
    return {
      isClicked: -1,
    };
  },
  props: {
    showBtnRevert: {
      type: Boolean,
      required: true,
    },
    showBtnProposal: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState({
      client: (state) => state.client_Portfolio,
    }),
  },
  methods: {
    btnClick(idx, name) {
      this.$emit('clicked', idx, name);
      this.isClicked = idx;
    },
    revertUpdatePf() {
      this.$emit('revert');
    },
    previewPf() {
      this.$emit('previewPf', true);
    },
  },
};
</script>

<style>
.mybtnactive {
  background-color: #1f97d1;
}
.revertBtn {
  background-color: #82d435;
  border-color: #82d435;
  color: black;
}
</style>
