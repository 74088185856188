<template>
    <div class="svg-item" :style="{height}">
      <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
        <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
        <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5"></circle>
        <circle class="donut-segment donut-segment-2" :style="{...cssVars}" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5" :stroke-dasharray="`${value} ${100-value}`" stroke-dashoffset="25"></circle>
        <g class="donut-text donut-text-1" :style="{...cssVars}">
          <text y="50%" transform="translate(0, 2)">
            <tspan x="50%" text-anchor="middle" class="donut-percent" :style="{...cssVars}">{{value}}%</tspan>   
          </text>
          <text y="60%" transform="translate(0, 2)">
            <label>{{$t(text)}}</label>
            <tspan v-if="text" x="50%" text-anchor="middle" class="donut-data">{{$t(text)}}</tspan>   
          </text>
        </g>
      </svg>
    </div>
</template>
<script>

export default {
    name: 'DonutPercentage',
    props: {
        text: {
            type: String, 
            required: false
        },
        value: {
            type: [Number, String],
            required: true
        },
        height: {
            type: String,
            required: false,
            default: "100%"
        },
        color: {
            type: String, 
            required: false,
            default: "#8BD6D1"
        }
    },
    computed: {
        cssVars() {
            return {
                '--color': this.color,
                '--value': this.value,
                '--value-from-hundred': 100 - this.value
            }
        }
    }
}
</script>
<style>
    .svg-item {
        text-align: center;
        font-size: 16px;
        margin: 0 auto;
        animation: donutfade 1s;
    }
    .donut-ring {
        stroke: #EBEBEB;
    }
    .donut-segment {
        transform-origin: center;
        stroke: #FF6200;
    }
    .donut-segment-2 {
        stroke: var(--color);
        animation: donut1 3s;
    }     
     @keyframes donutfade {
      /* this applies to the whole svg item wrapper */
        0% {
            opacity: .2;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes donutfadelong {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes donut1 {
        0% {
            stroke-dasharray: 0, 100;
        }
        100% {
            stroke-dasharray: var(--value), var(--value-from-hundred);
        }
    }
    .donut-percent {
        animation: donutfadelong 1s;
    }
    .donut-data {
        font-size: 0.12em;
        line-height: 1;
        transform: translateY(0.5em);
        text-align: center;
        text-anchor: middle;
        color:#666;
        fill: #666;
        animation: donutfadelong 1s;
    }
    .donut-text {
        font-family: Arial, Helvetica, sans-serif;
        fill: #FF6200;
    }
    .donut-text-1 {
        fill: var(--color);
    }
    .donut-label {
        font-size: 0.28em;
        font-weight: 700;
        line-height: 1;
        fill: #000;
        transform: translateY(0.25em);
    }
    .donut-percent {
        font-size: 0.5em;
        line-height: 1;
        transform: translateY(0.5em);
        font-weight: bold;
    }
</style>