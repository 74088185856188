<template>
  <div class="tab-pane active fade show">
    <notifications
      classe="n-light"
      group="foo"
      position="top center"
      :max="3"
      :width="400"
    />
    <modalimmo
      @clicked="hideModal"
      @saved="savedModal"
      :dataProps="objectifsProps.immo.data"
      v-if="isShow.immo"
    />
    <modalretraite
      @clicked="hideModal"
      @saved="savedModal"
      :dataProps="objectifsProps.retraite"
      v-if="isShow.retraite"
    />
    <modalautre
      @clicked="hideModal"
      @saved="savedModal"
      :dataProps="objectifsProps.autre"
      v-if="isShow.autre"
    />
    <modalvalo
      @clicked="hideModal"
      @saved="savedModal"
      :dataProps="objectifsProps.transmi"
      v-if="isShow.transmi"
    />
    <modalschool
      @clicked="hideModal"
      @saved="savedModal"
      :dataProps="objectifsProps.school.data"
      v-if="isShow.school"
    />
    <modalepargne
      @clicked="hideModal"
      @saved="savedModal"
      :dataProps="objectifsProps.precaution"
      v-if="isShow.precaution"
    />
    <div class="well">
      <form
        action
        method="post"
        enctype="multipart/form-data"
        class="form-horizontal"
        style="font-size: 18px;"
      >
        <div class="row" style="margin-top: 30px;">
          <div class="col-1"></div>
          <div class="col-12">
            <form class="form-horizontal well" role="form">
              <div class="row">
                <div
                  class="col-2"
                  :class="[isCheck.precaution ? 'chkObj styleObj' : 'styleObj']"
                >
                  <strong>
                    {{ $t('PRECAUTIONARY_SAVINGS_FIRST_PART') }}
                    <br />
                    {{ $t('PRECAUTIONARY_SAVINGS_SECOND_PART') }}
                  </strong>
                  <div class="forCheck">
                    <img
                      src="/img/icons/icon-objectif-epargne.png"
                      :class="{
                        'img-responsive img-radio styleImg': !isCheck.precaution,
                        'img-responsive img-radio styleImg imgOpacity':
                          isCheck.precaution,
                      }"
                    />
                    <button
                      id="btnchkPreco"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsCheck('precaution')"
                    >
                      <span v-if="isCheck.precaution">{{
                        $t('UNSELECT')
                      }}</span>
                      <span v-else>{{ $t('SELECT') }}</span>
                    </button>
                    <button
                      v-if="isCheck.precaution"
                      style="margin-top:2px;"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsClick('precaution')"
                    >
                      {{ $t('EDIT') }}
                    </button>
                    <span v-if="isCheck.precaution">
                      {{ $t('PRIORITY') }}:
                      <input
                        name="chkprecaution"
                        style="margin-top:5%;width:50px"
                        type="checkbox"
                        v-model="objectifsProps.precaution.prio"
                      />
                    </span>
                  </div>
                </div>
                <div
                  class="col-2"
                  :class="[isCheck.transmi ? 'chkObj styleObj' : 'styleObj']"
                >
                  <strong>
                    {{ $t('PROMOTION_TRANSMISSION_FIRST_PART') }}
                    <br />
                    {{ $t('PROMOTION_TRANSMISSION_SECOND_PART') }}
                  </strong>
                  <div class="forCheck">
                    <img
                      src="/img/icons/icon-objectif-transmission.png"
                      :class="{
                        'img-responsive img-radio styleImg': !isCheck.transmi,
                        'img-responsive img-radio styleImg imgOpacity':
                          isCheck.transmi,
                      }"
                    />
                    <button
                      id="btnchkValo"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsCheck('transmi')"
                    >
                      <span v-if="isCheck.transmi">{{ $t('UNSELECT') }}</span>
                      <span v-else>{{ $t('SELECT') }}</span>
                    </button>
                    <button
                      v-if="isCheck.transmi"
                      style="margin-top:2px;"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsClick('transmi')"
                    >
                      {{ $t('EDIT') }}
                    </button>
                    <span v-if="isCheck.transmi">
                      {{ $t('PRIORITY') }}:
                      <input
                        name="chktransmi"
                        style="margin-top:5%;width:50px"
                        type="checkbox"
                        v-model="objectifsProps.transmi.prio"
                      />
                    </span>
                  </div>
                </div>
                <div
                  class="col-2"
                  :class="{
                    styleObj: !isCheck.immo,
                    'chkObj styleObj': isCheck.immo,
                  }"
                >
                  <strong>
                    {{ $t('PROPERTY_INVESTMENT_FIRST_PART') }}
                    <br />
                    {{ $t('PROPERTY_INVESTMENT_SECOND_PART') }}
                  </strong>
                  <div class="forCheck">
                    <img
                      src="/img/icons/icon-objectif-immobilier.png"
                      :class="{
                        'img-responsive img-radio styleImg': !isCheck.immo,
                        'img-responsive img-radio styleImg imgOpacity':
                          isCheck.immo,
                      }"
                    />
                    <button
                      id="btnchkImmo"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsCheck('immo')"
                    >
                      <span v-if="isCheck.immo">{{ $t('UNSELECT') }}</span>
                      <span v-else>{{ $t('SELECT') }}</span>
                    </button>
                    <button
                      v-if="isCheck.immo"
                      style="margin-top:2px;"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsClick('immo')"
                    >
                      {{ $t('EDIT') }}
                    </button>
                    <span v-if="isCheck.immo">
                      {{ $t('PRIORITY') }} :
                      <input
                        name="chkimmo"
                        style="margin-top:5%;width:50px"
                        type="checkbox"
                        v-model="objectifsProps.immo.prio"
                      />
                    </span>
                  </div>
                </div>
                <div
                  class="col-2"
                  :class="{
                    styleObj: !isCheck.retraite,
                    'chkObj styleObj': isCheck.retraite,
                  }"
                >
                  <strong>
                    {{ $t('RETIREMENT_PREPARATION_FIRST_PART') }}
                    <br />
                    <br v-if="$i18n.locale == 'en'" />
                    <span v-else>{{
                      $t('RETIREMENT_PREPARATION_SECOND_PART')
                    }}</span>
                  </strong>
                  <div class="forCheck">
                    <img
                      src="/img/icons/icon-objectif-retraite.png"
                      :class="{
                        'img-responsive img-radio styleImg': !isCheck.retraite,
                        'img-responsive img-radio styleImg imgOpacity':
                          isCheck.retraite,
                      }"
                    />
                    <button
                      id="btnchkRetraite"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsCheck('retraite')"
                    >
                      <span v-if="isCheck.retraite">{{ $t('UNSELECT') }}</span>
                      <span v-else>{{ $t('SELECT') }}</span>
                    </button>
                    <button
                      v-if="isCheck.retraite"
                      style="margin-top:2px;"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsClick('retraite')"
                    >
                      {{ $t('EDIT') }}
                    </button>
                    <span v-if="isCheck.retraite">
                      {{ $t('PRIORITY') }} :
                      <input
                        name="chkretraite"
                        style="margin-top:5%;width:50px"
                        type="checkbox"
                        v-model="objectifsProps.retraite.prio"
                      />
                    </span>
                  </div>
                </div>
                <div
                  class="col-2"
                  :class="{
                    styleObj: !isCheck.school,
                    'chkObj styleObj': isCheck.school,
                  }"
                >
                  <strong>
                    {{ $t('CHILDEN_STUDIES_FIRST_PART') }}
                    <br />
                    {{ $t('CHILDEN_STUDIES_SECOND_PART') }}
                  </strong>
                  <div class="forCheck">
                    <img
                      src="/img/icons/icon-objectif-ecole.png"
                      :class="{
                        'img-responsive img-radio styleImg': !isCheck.school,
                        'img-responsive img-radio styleImg imgOpacity':
                          isCheck.school,
                      }"
                    />
                    <button
                      id="btnchkSchool"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsCheck('school')"
                    >
                      <span v-if="isCheck.school">{{ $t('UNSELECT') }}</span>
                      <span v-else>{{ $t('SELECT') }}</span>
                    </button>
                    <button
                      v-if="isCheck.school"
                      style="margin-top:2px;"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsClick('school')"
                    >
                      {{ $t('EDIT') }}
                    </button>
                    <span v-if="isCheck.school">
                      {{ $t('PRIORITY') }} :
                      <input
                        name="chkschool"
                        style="margin-top:5%;width:50px"
                        type="checkbox"
                        v-model="objectifsProps.school.prio"
                      />
                    </span>
                  </div>
                </div>
                <div
                  class="col-2"
                  :class="[isCheck.autre ? 'chkObj styleObj' : 'styleObj']"
                >
                  <strong>
                    {{ $t('OTHER') }}
                    <br />
                    {{ $t('project') }}
                  </strong>
                  <div class="forCheck">
                    <img
                      src="/img/icons/icon-objectif-autre.png"
                      :class="{
                        'img-responsive img-radio styleImg': !isCheck.autre,
                        'img-responsive img-radio styleImg imgOpacity':
                          isCheck.autre,
                      }"
                    />
                    <button
                      id="btnchkAutre"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsCheck('autre')"
                    >
                      <span v-if="isCheck.autre">{{ $t('UNSELECT') }}</span>
                      <span v-else>{{ $t('SELECT') }}</span>
                    </button>
                    <button
                      v-if="isCheck.autre"
                      style="margin-top:2px;"
                      type="button"
                      class="btn btn-primary btn-radio"
                      @click="btnObjectifsClick('autre')"
                    >
                      {{ $t('EDIT') }}
                    </button>
                    <span v-if="isCheck.autre">
                      {{ $t('PRIORITY') }} :
                      <input
                        name="chkautre"
                        style="margin-top:5%;width:50px"
                        type="checkbox"
                        v-model="objectifsProps.autre.prio"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </form>
    </div>
    <hr />
    <!----
    <div class="text-center">
      <span
        v-if="isCheck.immo || isCheck.retraite || isCheck.school|| isCheck.autre || isCheck.transmi || isCheck.precaution "
        class="btn btn-primary"
        style="cursor:pointer;"
        @click="btnClick()"
      >{{$t('SAVE')}}</span>
      <span v-else>{{$t('PLEASE_SELECT_LEAST_ONE_GOAL')}}</span>
    </div> ---->
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import Notifications from 'vue-notification';
Vue.use(Notifications);

import modalImmo from '@/components/agent/nouveau_compte/modal_immo.vue';
import modalRetraite from '@/components/agent/nouveau_compte/modal_retraite.vue';
import modalAutre from '@/components/agent/nouveau_compte/modal_autre.vue';
import modalSchool from '@/components/agent/nouveau_compte/modal_school.vue';
import modalEpargne from '@/components/agent/nouveau_compte/modal_epargne.vue';
import modalValo from '@/components/agent/nouveau_compte/modal_autreBis.vue';

Vue.component('modalvalo', modalValo);
Vue.component('modalepargne', modalEpargne);
Vue.component('modalschool', modalSchool);
Vue.component('modalimmo', modalImmo);
Vue.component('modalretraite', modalRetraite);
Vue.component('modalautre', modalAutre);

export default {
  data() {
    return {
      isShow: {
        immo: false,
        retraite: false,
        autre: false,
        school: false,
        precaution: false,
        transmi: false,
      },
      isCheck: {
        immo: false,
        retraite: false,
        transmi: false,
        autre: false,
        school: false,
        precaution: false,
      },
    };
  },
  props: {
    objectifsProps: {
      type: Object,
      required: false,
    },
  },
  methods: {
    btnObjectifsClick(val) {
      this.isShow[val] = true;
    },
    btnObjectifsCheck(val) {
      this.isCheck[val] = this.isCheck[val] ? false : true;
      this.objectifsProps[val].isCheck = this.isCheck[val];
      this.isShow[val] = this.isCheck[val] ? true : false;

      if (!this.isShow[val]) {
        this.$emit('save', true);
      }
    },
    btnClick() {
      this.$emit('save', true);
    },
    hideModal(val) {
      this.isShow[val] = false;
    },
    savedModal(val) {
      if (val.name == 'school' || val.name == 'immo') {
        this.objectifsProps[val.name]['data'] = val.data;
      } else {
        if (!('createdAt' in val.data)) {
          val.data['createdAt'] = new Date().toISOString();
        }
        if (!this.objectifsProps[val.name].isCheck) {
          //this.objectifsProps["oldDateProjects"] = new Date().toISOString();
        }
        this.objectifsProps[val.name] = {
          ...this.objectifsProps[val.name],
          ...val.data,
        };
      }
      this.$emit('save', true);
      let title = this.$t('GOALS');
      let group = 'foo';
      let type = '';
      let text = this.$t('OBJECTIF_UPDATED');
      this.$notify({
        group,
        title,
        text,
        type,
      });
    },
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function(state) {
        return state.new_Compte;
      },
      function(val) {
        ctx.setNewCompte(val);
      },
      {
        deep: true,
      }
    );

    let arr = ['precaution', 'transmi', 'immo', 'retraite', 'school', 'autre'];
    for (let index = 0; index < arr.length; index++) {
      if (!(arr[index] in this.objectifsProps)) {
        this.objectifsProps[arr[index]] = {
          isCheck: false,
          prio: false,
          data: [],
        };
      }
      if (
        this.objectifsProps[arr[index]].isCheck ||
        this.objectifsProps[arr[index]].isCheck == 'true'
      )
        this.isCheck[arr[index]] = this.objectifsProps[arr[index]].isCheck;
    }
  },
};
</script>

<style>
.styleImg {
  width: 90px;
  margin-bottom: 10px;
}

.imgOpacity {
  opacity: 1;
}

.chkObj {
  background-color: #e6f8ff;
  border: 1px #000 solid;

  border-radius: 15px;
}

.styleObj {
  padding: 40px 10px;
  text-align: center;
}
</style>
