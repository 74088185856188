<template>
<div style="max-height: 500px; overflow-y:auto;">
  <table class="table table-bordered bg-white">
    <thead>
      <tr>
        <th colspan="2">{{$t('ASSET_CLASSES')}}</th>
        <th class="numeric">{{$t('MIN')}}</th>
	      <th class="numeric">{{$t('MAX')}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(a,n) in dataProps" :key="a.name + '___' + n">
        <td class="border_right_none" v-if="!a.forced">
            <i style="cursor:pointer;" class="fa fa-times" @click="remove(n)"/>
        </td>
        <td :colspan="colSpanSize(a)" :class="[!a.forced ? 'text_position_start' : '']">      
	          {{translate(a.name)}}
	      </td>
        <td>
          <input type="text" class="form-control text-center" 
                @change="handleMin(n,$event)" :value="a.min"
          />
        </td>
	      <td>
          <input type="text" class="form-control text-center" 
                @change="handleMax(n,$event)"
                :value="a.max"
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
export default {
    data() {
	return {
	    translated: [],
	    universtranslated: [],
	    groupeAssets: [],
	    isShow: {},
	};
    },
    props: {
	dataProps: {
	    type: Array,
	    required: true,
	},
	translateUniversProps: {
	    type: Array,
	    required: true,
	},
	translateAssetsProps: {
	    type: Array,
	    required: true,
	}
    },
    watch: {
    dataProps: function () {
      this.getGroupePf();
    },
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    })
  },
  methods: {
    translate(n) {
      let idx = this.translateAssetsProps.findIndex((e) => e.optim == n);
      return idx != -1 ? this.translateAssetsProps[idx][this.$i18n.locale] : n;
    },
    translateUnivers(n) {
      let idx = this.translateUniversProps.findIndex((e) => e.fr == n);
      return idx != -1 ? this.translateUniversProps[idx][this.$i18n.locale] : n;
    },
    async getGroupePf() {
      let data = await this.$http.get(
        this.baseUrl +
          "users/dashboard/portfolios/portfolios/" +
          localStorage.getItem("priradCompany")
      );
      this.groupeAssets = data.data.resultat[0].contents;
      let tmp = {};
      for (const iterator of Object.entries(this.groupeAssets)) {
        tmp[iterator[0]] = false;
      }
      this.isShow = tmp;
    },
    showAssets(e, i) {
      this.isShow[e] = true;
      this.$emit("isShow", true);
    },
    hideAssets(e, i) {
      this.isShow[e] = false;
      if (!this.findValue(this.isShow, true)) this.$emit("isShow", false);
    },
    nametokey(g) {
      return g.name.replace(/\s+/g, "");
    },
    findValue(o, value) {
      for (var prop in o) {
        if (o.hasOwnProperty(prop) && o[prop] === value) {
          return true;
        }
      }
      return false;
    },
      remove(index){
	  this.$emit("remove", index);
      },
      handleMin(index,e){
	  this.$emit("edit",{index:index,min:e.target.value});
      },
      handleMax(index,e){
	  let ctx = this;
	  this.$emit("edit",{index:index,max:e.target.value});
      },
      colSpanSize(a) {
      return a.forced ? '2' : '1'
    }
  },
  created() {
    this.getGroupePf();
  },
};
</script>
<style scoped>
tr > * {
  vertical-align: middle !important;
}
.text_position_start {
  text-align: start !important;
}
.border_right_none {
  border-right-style:hidden;
  vertical-align: top !important;
}
</style>
