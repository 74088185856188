<template>
  <div>
    <NavDash
      class="text-center"
      @clicked="chooseCompte"
      :showBtnRevert="false"
      :showBtnUpdate="false"
    />
    <div>
      <div
        class="tab-content"
        style="
          padding: 15px;
          border: none !important;
          overflow-y: auto;
          max-height: 500px;
        "
      >
        <table
          class="table table-bordered table-condensed"
          style="background-color: white"
        >
          <thead>
            <tr>
              <th class="text-center rebalancing-table_size_w">
                {{ $t('FUNDS_NAME') }}
              </th>
              <th class="text-center rebalancing-table_size_w">
                {{ $t('ASSETS') }}
              </th>
              <th class="text-center rebalancing-table_size_w">
                {{ $t('MONEY') }} ({{$t('ESTIMATION')}})
              </th>
            </tr>
          </thead>
          <tbody v-if="'preview_arbitrage' in portfoliosProps.overall">
            <template v-if="idxCompte == -1">
              <tr
                v-for="arbitrage in Object.entries(
                  portfoliosProps.overall.preview_arbitrage
                ).sort((a, b) => Math.abs(b[1].value) - Math.abs(a[1].value))"
                v-if="arbitrage[1].value != 0"
              >
                <td style="display: flex; align-items: center">
                  <div
                    :style="{
                      width: '5px',
                      minWidth: '5px',
                      height: '100%',
                      backgroundColor: arbitrage[1].value > 0 ? 'green' : 'red',
                    }"
                    class="space mr-2"
                  >
                    &nbsp;
                  </div>
                  <div>{{ arbitrage[1].name }}</div>
                </td>
                <td style="align-items: center; text-align: center">
                  {{ arbitrage[0] }}
                </td>
                <td style="align-items: center; text-align: center">
                  {{ formatNumber(arbitrage[1].value) }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr
                v-for="arbitrage in Object.entries(
                  portfoliosProps.individuals[idxCompte].preview_arbitrage
                ).sort((a, b) => Math.abs(b[1].value) - Math.abs(a[1].value))"
                v-if="arbitrage[1].value != 0"
              >
                <td style="display: flex; align-items: center">
                  <div
                    :style="{
                      width: '5px',
                      height: '100%',
                      backgroundColor: arbitrage[1].value > 0 ? 'green' : 'red',
                    }"
                    class="space mr-2"
                  >
                    &nbsp;
                  </div>
                  <div>{{ arbitrage[1].name }}</div>
                </td>
                <td style="align-items: center; text-align: center">
                  {{ arbitrage[0] }}
                </td>
                <td style="align-items: center; text-align: center">
                  {{ formatNumber(arbitrage[1].value) }}
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-if="!('preview_arbitrage' in portfoliosProps.overall)">
            <template v-if="idxCompte == -1">
              <tr
                v-for="distrib in Object.entries(
                  portfoliosProps.overall.preview_distribution
                ).sort((a, b) => Math.abs(b[1]) - Math.abs(a[1]))"
                v-if="distrib[1] !== 0"
              >
                <td style="display: flex; align-items: center">
                  <div
                    :style="{
                      width: '5px',
                      height: '100%',
                      backgroundColor: 'green',
                    }"
                    class="space mr-2"
                  >
                    &nbsp;
                  </div>
                  <div>{{ distrib[0] }}</div>
                </td>
                <td style="align-items: center; text-align: center">
                  {{ distrib[0] }}
                </td>
                <td style="align-items: center; text-align: center">
                  {{
                    formatNumber(
                      Math.round(
                        portfoliosProps.overall.bt[
                          portfoliosProps.overall.bt.length - 1
                        ].value * distrib[1]
                      )
                    )
                  }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr
                v-for="distrib in Object.entries(
                  portfoliosProps.individuals[idxCompte].preview_distribution
                ).sort((a, b) => Math.abs(b[1]) - Math.abs(a[1]))"
                v-if="distrib[1] !== 0"
              >
                <td style="display: flex; align-items: center">
                  <div
                    :style="{
                      width: '5px',
                      height: '100%',
                      backgroundColor: 'green',
                    }"
                    class="space mr-2"
                  >
                    &nbsp;
                  </div>
                  <div>{{ distrib[0] }}</div>
                </td>
                <td style="align-items: center; text-align: center">
                  {{ distrib[0] }}
                </td>
                <td style="align-items: center; text-align: center">
                  {{
                    formatNumber(
                      Math.round(
                        portfoliosProps.individuals[idxCompte].bt[
                          portfoliosProps.individuals[idxCompte].bt.length - 1
                        ].value * distrib[1]
                      )
                    )
                  }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import NavDash from '@/components/agent/dashboard/dash/nav.vue';

export default {
  components: {
    NavDash
  },
  data() {
    return {
      idxCompte: -1,
    };
  },
  props: {
    portfoliosProps: {
      type: Object,
      required: true,
      default: {},
    },
  },
  methods: {
    chooseCompte(val) {
      this.idxCompte = val;
    },
    formatNumber(x) {
      let parts = x.toString();
      parts = parts.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts;
    },
  },
};
</script>
<style scoped>
.space::before {
  content: '\200B';
}
.rebalancing-table_size_w{
  width: 300px;
}
</style>
