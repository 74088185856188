<template>
  <div>
    <h4>{{$t('MARKET_CONVICTIONS')}}</h4>
    <div class="row" style="margin-top: 25px;">
      <div class="col-sm-12 col-md-12">
        <div class="card text-black text-center" style="border: none;">
          <div class="card-body">
            <blockquote class="card-bodyquote">
              <p>
                <span style="font-size:12.5px;">
                  {{$t('MARKET_CONVICTION_IMPACT_MODEL_PORTFOLIOS_BUILD_CLIENT_ALLOCATION')}}
                  <br />
                  {{$t('RISK_PROFILE_CHOICE_SPECIFIC_FUND_CATEGORIES')}}
                </span>
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="col-6">
          <h5>
            <i class="fa fa-university" style="color:#20c997;margin-bottom: 20px;"></i>
            {{$t('CONVICTIONS')}}
            <span style="color:#20c997;">{{$t('ACTIVE')}}</span>
          </h5>
        </div>
        <div class="col-9">
          <div class="card card-accent-secondary">
            <div class="card-body">
              <div class="card-title text-center h6 mt-3">
                <img
                  :src="lstLogos[companyInArray]"
                  style="max-width: -webkit-fill-available;width:75%;margin-top:-10px;margin-bottom: 15px;"
                />
                <h6>{{lstCompany[companyInArray]}}</h6>
              </div>
            </div>
            <div class="card-body py-0 px-4 b-t-1">
              <div class="row b-b-1" style="font-size: 12px; padding-top: 5px;padding-bottom: 5px;">
                <div class="col-4">
                  <strong>Date :</strong>
                </div>
                <div class="col-6">12/05/2022</div>
                <div class="col-2"></div>
              </div>
              <div class="row b-b-1" style="font-size: 12px; padding-top: 5px;padding-bottom: 5px;">
                <div class="col-4" style="padding-top: 5px;">
                  <strong>{{$t('MANAGER')}} :</strong>
                </div>
                <div class="col-8" style="text-align: left;margin-left: -10px;">
                  <button
                    type="button"
                    class="btn btn-sm btn-link"
                    disabled="disabled"
                  >{{lstCompany[companyInArray]}}</button>
                </div>
              </div>

              <div class="row">
                <ul class="nav nav-tabs" role="tablist" style="font-size: 12.5px;">
                  <li class="nav-item">
                    <span
                      @click="changeTab(0)"
                      style="cursor:pointer;"
                      :class="{'nav-link active':conv_cadre == 0,'nav-link': conv_cadre == 1 }"
                      aria-controls="profile"
                    >
                      <i class="icon-pie-chart"></i>
                      {{$t('CONVICTIONS')}}
                    </span>
                  </li>
                  <li class="nav-item">
                    <span
                      @click="changeTab(1)"
                      style="cursor:pointer;"
                      :class="{'nav-link active':conv_cadre == 1,'nav-link': conv_cadre == 0 }"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="profile"
                    >
                      <i class="icon-pencil"></i>
                      {{$t('GENERAL_FRAMEWORK')}}
                    </span>
                  </li>
                </ul>
                <div class="tab-content col-12" style="padding:0;">
                  <convictions_conv v-if="conv_cadre == 0" />
                  <cadre_conv v-if="conv_cadre == 1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="col-6">
          <h5>
            <i class="fa fa-university" style="color:#9da0a8;margin-bottom: 20px;"></i>
            {{$t('CONVICTIONS')}}
            <span style="color:#9da0a8;">{{$t("PAST")}}</span>
          </h5>
        </div>
        <div class="col-9">
          <div class="card card-accent-secondary">
            <div class="card-body">
              <div class="card-title text-center h6 mt-3">
                <img
                  :src="lstLogos[companyInArray]"
                  style="max-width: -webkit-fill-available;width:75%;margin-top:-10px;margin-bottom: 15px;"
                />
                <h6>{{lstCompany[companyInArray]}}</h6>
              </div>
            </div>
            <div class="card-body py-0 px-4 b-t-1">
              <div class="row b-b-1" style="font-size: 12px; padding-top: 5px;padding-bottom: 5px;">
                <div class="col-4" style="padding-top: 5px;">
                  <strong>{{$t('MANAGER')}} :</strong>
                </div>
                <div class="col-8" style="text-align: left;margin-left: -10px;">
                  <button
                    type="button"
                    class="btn btn-sm btn-link"
                    disabled="disabled"
                  >{{lstCompany[companyInArray]}}</button>
                </div>
              </div>
              <div class="tab-content col-12" style="padding:0;">
                <table
                  class="table table-bordered table-hover table-condensed"
                  style="font-size: 12px;text-align: center;margin-top: 15px;width: 90%;margin-left: 5%;"
                >
                  <thead>
                    <tr>
                      <th>{{$t('DATE')}}</th>
                      <th>{{$t('CLASSES')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>23/10/2017</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>04/10/2017</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>08/09/2017</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>07/08/2017</td>
                      <td>4</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

import Convictions_conv from "@/components/agent/convictions/convictions.vue";
import Cadre_conv from "@/components/agent/convictions/cadre_general.vue";

Vue.component("cadre_conv", Cadre_conv);
Vue.component("convictions_conv", Convictions_conv);

export default {
  data() {
    return {
      lstCompany: [
        "La Française",
        "OFI AM",
        "Primonial",
        "Pictet",
        "Generali",
        "Financière Edmond de Rothschild",
        "Sogecap",
        "Carmignac",
        "LCL",
        "ODDO",
      ],
      lstLogos: [
        "/img/logos/frLogos.png",
        "/img/logos/ofiLogos.jpg",
        "/img/logos/primoLogos.jpg",
        "/img/logos/pictetLogos.png",
        "/img/logos/geneLogos.png",
        "/img/logos/edrLogos.jpg",
        "/img/logos/socgenLogos.jpg",
        "/img/logos/carmignacLogos.jpg",
        "/img/logos/lclLogos.jpg",
        "/img/logos/oddoLogos.jpg",
      ],
	conv_cadre: 0,
	//for generali
      companyInArray: 4,
    };
  },
  computed: {
    ...mapState({
      breadLink: (state) => state.breadLink,
      baseUrl: (state) => state.baseUrl,
    }),
  },
  created() {
    this.checkLog();
  },
  mounted() {
    this.setBreadLink(["Prime Radiant", "CRM", "Convictions"]);
  },
  methods: {
    ...mapActions(["setBreadLink", "setLogged"]),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({ isLog: false, role: 0 });
        await this.$http.get(this.baseUrl +"auth/logout");
        localStorage.removeItem("priradToken");
        localStorage.removeItem("priradRole");
        localStorage.removeItem("priradCompany");
        this.$router.push("/login");
      } else {
        let role = this.$getRole();
        if (role == 10) {
            let token = window.localStorage.getItem("priradToken");

          let client = await this.$http.get("/api/users/dashboard/mine", {
            headers: {
              Authorization: token,
            },
          });

          for (const [index, item] of Object.entries(this.lstCompany)) {
            if (item == client.data.resultat.company) {
              this.companyInArray = parseInt(index);
              break;
            }
          }

        } else {
          this.setLogged({ isLog: false, role: 0 });
          await this.$http.get(this.baseUrl +"auth/logout");
          localStorage.removeItem("priradToken");
          localStorage.removeItem("priradRole");
          localStorage.removeItem("priradCompany");
          this.$router.push("/login");
        }
      }
    },
    changeTab(val) {
      this.conv_cadre = val;
    },
  },
};
</script>
