<template>
  <div>
    <notifications
      classe="n-light"
      group="foo"
      position="top center"
      :max="3"
      :width="400"
    />
    <transition name="fade">
      <Spinner v-show="showSpinner" />
    </transition>
    <transition name="fade">
      <PopUp
        v-show="toastMsg"
        :message="message"
        :question="question"
        @answer="previewPf"
        @closePopUp="previewPf"
      />
    </transition>
    <h4 style="margin-bottom: 15px" id="hnameCli">
      {{ client.name + ' ' + client.lastname }}
    </h4>
    <div class="card">
      <headerDash />
      <div v-if="showAgentProposal" style="padding: 50px;">
        <div class="d-flex justify-content-between">
          <h4>{{$t('UPDATE_AVAILABLE')}}</h4>
          <div>
            <button style="margin-right: 10px;" class="btn btn-sm" @click="cancelProposal">{{$t('CANCEL_')}}</button>
            <button @click="updatePf" class=" btn btn-sm revertBtn">
              <i class="fa fa-check" />
              &nbsp;{{$t('ACCEPT_PROPOSAL')}}
            </button>
          </div>
        </div>
        <div>
          <p style="margin-bottom: 5px; text-decoration: underline double">{{$t('MANAGER_COMMENT')}}</p>
          <div class="comment">
            <p v-if="checkForOptimComment">{{basepf.optimization_comment}}</p>
            <p v-else>{{$t('NO_MANAGER_COMMENT')}}</p>
          </div>
        </div>
        <nav class="nav-container">
          <ul class="nav-list-container">
            <li class="nav-switch" :class="{'nav-switch_focus': switchNavigation==='comparaison'}" @click="switchNavigation='comparaison'">
              {{$t('COMPARAISON')}}
            </li>
            <li class="nav-switch" :class="{'nav-switch_focus': switchNavigation==='rebalancing'}" @click="switchNavigation='rebalancing'">
              {{$t('ARBITRAGE')}}
            </li>
            <li class="nav-switch" :class="{ 'nav-switch_focus': switchNavigation === 'preferencesComparaison' }" @click="switchNavigation = 'preferencesComparaison'">
              {{ $t('PREFERENCES') }}
            </li>
          </ul>
        </nav>
        <Comparaison v-show="switchNavigation==='comparaison'" :portfoliosProps="portfolios" :basepf="basepf"/>
        <Arbitrage v-show="switchNavigation==='rebalancing'" :nav="nav" :portfoliosProps="portfolios" />
        <PreferencesComparaison v-show="switchNavigation === 'preferencesComparaison'" :nav="nav" :portfoliosProps="portfolios" :basepf="basepf" />
      </div>
      <div v-else class="email-app mb-6" style="min-height: 450px;">
        <nav style="font-size: 16px;">
          <ul class="nav">
            <li class="nav-item pointer" v-for="(item, idx) in navigation" @click="navigationIdx = idx" v-show="filterCompagny(item.firm)">
                <span class="nav-link myNav" :class="{ 'btn-primary text-white': idx === navigationIdx }">
                  <i :class="[item.icon]"></i>
                  {{ $t(item.name) }}
                </span>  
            </li>
          </ul>
        </nav>
        <main class="inbox">
          <div class="toolbar">
            <dashboard
              v-if="navigation[navigationIdx].id === 'dash'"
              @revert="revertPf"
              @previewPf="previewPf"
              :showBtnRevert="showBtnRevert"
              :showBtnProposal="showBtnProposal"
              :portfoliosProps="portfolios"
            />
            <objectifs
              v-else-if="navigation[navigationIdx].id === 'goals'"
              :objectifsProps="client.info.extra_info.objectifs"
              :apportsProps="createApportPropsObj()"
              :currentProps="client.current"
              @saveObjs="saveObjectifs"
              :dateCreationProps="client.created"
            />
            <historique v-else-if="navigation[navigationIdx].id === 'histo'" />
            <compliance v-else-if="navigation[navigationIdx].id === 'compliance'" />
            <KycGenerali v-else-if="navigation[navigationIdx].id === 'kycGenerali'" :newClient="false" @saveKYCGenerali="saveKYCGenerali" />
            <Preferences
              v-else-if="navigation[navigationIdx].id === 'pref'"
              @savePreferences="savePreferences"
              :showButtonSaveProps="true"
            />
            <ESG v-else-if="navigation[navigationIdx].id === 'esg'" @saveESG="saveESG" @message="showMessage" :saved="savedESG"/>
            <!----- RESUME COMPONENT WILL BE HERE  ---->

              <div v-else-if="navigation[navigationIdx].id === 'summary'" style="padding: 15px;">
                <div style="padding: 15px;">
                  <button class="nav-switch" :class="{'nav-switch_focus': switchNavigation==='comparaison'}" @click="switchNavigation='comparaison'">
                    {{$t('COMPARAISON')}}
                  </button>
                  <button class="nav-switch" :class="{'nav-switch_focus': switchNavigation==='rebalancing'}" @click="switchNavigation='rebalancing'">
                    {{$t('ARBITRAGE')}}
                  </button>
                </div>
                <Comparaison v-show="switchNavigation==='comparaison'" :portfoliosProps="portfolios" :basepf="basepf" :hide_proposal="true" />
                <Arbitrage v-show="switchNavigation==='rebalancing'" :portfoliosProps="portfolios" />
              </div>
            <!----- END RESUME COMPONENT ---->
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Notifications from 'vue-notification';
import { mapActions, mapState } from 'vuex';

import emptyPreFillData from "@/data/v2_emptyNewAccount.json"

import HeaderDash from '@/components/agent/dashboard/header.vue';
import NavDash from '@/components/agent/dashboard/nav.vue';
import Dashboard from '@/components/agent/dashboard/dashboard.vue';
import Objectifs from '@/components/agent/dashboard/objectifs.vue';
import Historique from '@/components/agent/dashboard/historique.vue';
import Compliance from '@/components/agent/dashboard/compliance.vue';
import PopUp from '@/components/autre/popUp.vue';
import Spinner from '../components/autre/spinner';
import Comparaison from '@/components/agent/dashboard/resume/comparaison.vue';
import Arbitrage from '@/components/agent/dashboard/resume/arbitrage.vue';
import Preferences from '@/components/agent/dashboard/resume/preferences.vue';
import ESG from '@/components/agent/dashboard/resume/esg.vue'
import PreferencesComparaison from '@/components/agent/dashboard/resume/preferencesComparaison.vue';
import KycGenerali from "@/components/agent/nouveau_compte/kycGenerali.vue"

Vue.use(Notifications);

Vue.component('compliance', Compliance);
Vue.component('objectifs', Objectifs);
Vue.component('historique', Historique);
Vue.component('dashboard', Dashboard);
Vue.component('headerDash', HeaderDash);
Vue.component('navDash', NavDash);

export default {
  data() {
    return {
      navigationIdx: 0,
      navigation: [
        { id: "dash", icon: "icon-grid", name: "DASHBOARD", firm: ["LCL", "La Française"] },
        { id: "goals", icon: "fa fa-crosshairs", name: "GOALS", firm: ["LCL", "La Française"] },
        { id: "pref", icon: "fa fa-asterisk", name: "PREFERENCES", firm: ["LCL", "La Française"] },
        { id: "esg", icon: "fa fa-globe", name: "ESG", firm: ["LCL", "La Française"] },
        { id: "compliance", icon: "fa fa-check-circle", name: "COMPLIANCE", firm: ["La Française"] },
        { id: "kycGenerali", icon: "fa fa-check-circle", name: "COMPLIANCE", firm: ["LCL"] },
        { id: "histo", icon: "fa fa-calendar-check-o", name: "HISTORY", firm: ["LCL", "La Française"] },
        { id: "summary", icon: "fa fa-list-ol", name: "SUMMARY", firm: ["LCL", "La Française"] },
      ],
      portfolios: {},
      basepf: {},
      nav: 'Dash',
      lgObjectifs: 0,
      toastMsg: false,
      message: this.$i18n.t('VIEW_NEW_WALLET_VERSION'),
      question: true,
      showSpinner: false,
      showBtnRevert: false,
      showBtnProposal: false,
      showAgentProposal: false,
      switchNavigation: 'comparaison',
      savedESG: ""
    };
  },
  components: {
    PopUp,
    Spinner,
    Comparaison,
    Arbitrage,
    Preferences,
    ESG,
    PreferencesComparaison,
    KycGenerali
},
  methods: {
    ...mapActions([
      'setBreadLink',
      'setClientInfo',
      'setClientPortfolio',
      'setLogged',
    ]),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({ isLog: false, role: 0 });
        await this.$http.get(this.baseUrl + 'auth/logout');
        localStorage.removeItem('priradToken');
        localStorage.removeItem('priradRole');
        localStorage.removeItem('priradCompany');
        this.$router.push('/login');
      } else {
        let role = this.$getRole();
        if (role == 10) {
          this.initData()
        } else {
          this.setLogged({ isLog: false, role: 0 });
          await this.$http.get(this.baseUrl + 'auth/logout');
          localStorage.removeItem('priradToken');
          localStorage.removeItem('priradRole');
          localStorage.removeItem('priradCompany');
          this.$router.push('/login');
        }
      }
    },
    createApportPropsObj(){
      let VersmtPeriodik = null
      if(this.client.info.extra_info.apports.ver_progra) {
        VersmtPeriodik = this.formatInt(
          this.client.info.extra_info.apports['ver' + this.client.info.extra_info.ver_progra]
        )
      }else{
        VersmtPeriodik = this.client.info.extra_info.apports.VersmtPeriodik
      }
      let obj = {
        year: this.client.info.extra_info.apports.duree ? this.client.info.extra_info.apports.duree : this.client.info.extra_info.apports.duration,
        initial: this.client.info.extra_info.apports.initial,
        VersmtPeriodik,
        FreqVersmtPeriodik:this.client.info.extra_info.apports.ver_progra ? this.client.info.extra_info.apports.ver_progra : this.client.info.extra_info.apports.FreqVersmtPeriodik
      }
      return obj
    },
    async initData(){
      let token = window.localStorage.getItem('priradToken');
          let client = await this.$http.get(
            this.baseUrl + 'users/dashboard/' + this.$route.params.id,
            {
              headers: {
                Authorization: token,
              },
            }
          );

          if ('objectifs' in client.data.resultat.info.extra_info) {
            this.lgObjectifs = Object.keys(
              client.data.resultat.info.extra_info.objectifs
            ).length;
          } else {
            client.data.resultat.info.extra_info['objectifs'] = {};
            client.data.resultat.info.extra_info['apports'] = emptyPreFillData.apports
          }

          if (!('preferences' in client.data.resultat)) {
            client.data.resultat['preferences'] = {};
          }
          if (!('esg' in client.data.resultat)) 
            client.data.resultat['esg'] = emptyPreFillData.esg

          this.setClientInfo(client.data.resultat);
          this.setClientPortfolio(client.data.portfolio);
          this.portfolios = Object.assign({}, this.portfolios, client.data.portfolio)
          await this.checkForPfUpdate(token)
    },
    async checkForPfUpdate() {
      let token = window.localStorage.getItem('priradToken');
      const updateUser = await this.$http.get(
            this.baseUrl +
              'users/checkUpdatePortfolios/' +
              this.$route.params.id,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          if(updateUser.data){
            this.toastMsg = true;
            this.message = this.$i18n.t('VIEW_NEW_WALLET_VERSION');
            this.question = true;
          }else this.checkOldDistributionExist();
    },
    clickNav(val) {
      if(val !== this.nav)
        this.nav = val;
    },
    saveKYCGenerali(kyc, apports){
      let client = this.$_.cloneDeep(this.client)
      client.KycGenerali = kyc
      client.info.extra_info.apports = apports
      client.updatePortfolio = true;
      this.setClientInfo(client);
      this.showBtnProposal = true;
      this.showBtnRevert = false;
      this.saveObjectifs("KYC_PREF_SAVED");
    },
    saveESG(esg){
      let client = this.$_.cloneDeep(this.client)
      client.esg = esg
      client.updatePortfolio = true;
      this.setClientInfo(client);
      this.showBtnProposal = true;
      this.showBtnRevert = false;
      this.saveObjectifs("ESG_PREF_SAVED");
    },
    async previewPf(yesNo) {
      this.toastMsg = false;

      if (yesNo) {
        this.nav = "Dash"
        this.showSpinner = true;
        let token = window.localStorage.getItem('priradToken');
        let resUpdate = await this.$http.get(
          `${this.baseUrl}users/viewProposal/${this.$route.params.id}`,
          {
            headers: {
              Authorization: token,
              company: window.localStorage.getItem("priradCompany")
            },
          }
        );
        if (resUpdate.data.statusCode === 200) {
          let newPf = resUpdate.data.pf.value
          this.basepf = resUpdate.data.basepf
          this.setClientPortfolio(newPf);
          this.portfolios = Object.assign({}, this.portfolios, newPf)
          this.showSpinner = false;
          this.showAgentProposal = true;
        } else {
          this.showSpinner = false;
          this.toastMsg = true;
          this.message = this.$i18n.t('AN_ERROR_HAS_OCCURRED');
          this.question = false;
        }
      } else {
        this.showBtnProposal = true;
      }
    },
    cancelProposal() {
      this.showAgentProposal = false;
      this.showBtnProposal = true;
    },
    async updatePf() {
      this.showSpinner = true;
        let token = window.localStorage.getItem('priradToken');
        // TO UPDATE PORTFOLIO
        let resUpdate = await this.$http.get(
          `${this.baseUrl}users/updatePortfolio/${this.$route.params.id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (resUpdate.data.statusCode === 200) {
          //Update the data on the screen
          this.showAgentProposal = false;
          this.checkLog();
          this.showBtnProposal = false;
          this.showSpinner = false;
          this.showBtnRevert = true;
        } else {
          this.showSpinner = false;
          this.toastMsg = true;
          this.message = this.$i18n.t('AN_ERROR_HAS_OCCURRED');
          this.question = false;
        }
    },
    async revertPf() {
      this.showSpinner = true;
      let token = window.localStorage.getItem('priradToken');
      let revert = await this.$http.get(
        this.baseUrl + 'users/revertUpdate/' + this.$route.params.id,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (revert.data.status === 200) {
        setTimeout(() => {
          this.checkLog();
          this.showSpinner = false;
          this.showBtnRevert = false;

          let title = this.$t('CANCELLATION');
          let group = 'foo';
          let type = '';
          let text = this.$t('CHANGE_HAVE_BEEN_CANCELED');
          this.$notify({
            group,
            title,
            text,
            type,
          });
        }, 3000);
      } else {
        this.showSpinner = false;
        this.toastMsg = true;
        this.message = $t('ERROR_OCCURRED_TRY_AGAIN');
        this.question = false;
      }
    },
    async checkOldDistributionExist() {
      let token = window.localStorage.getItem('priradToken');
      let check = await this.$http.get(
        this.baseUrl +
          'users/checkOldDistributionExist/' +
          this.$route.params.id,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (check.data.status === 200) {
        check.data.checked ? (this.showBtnRevert = true) : '';
      }
    },
    savePreferences(pref) {
      let client = this.client;
      client['preferences'] = pref;
      client['updatePortfolio'] = true;
      this.setClientInfo(client);
      this.showBtnProposal = true;
      this.showBtnRevert = false;
      
      this.saveObjectifs();

    },
    saveViews(views) {
      let client = this.client;
      client['views'] = views;
      client['updatePortfolio'] = true;
      this.setClientInfo(client);
      this.showBtnProposal = true;
      this.showBtnRevert = false;
    },
    showMessage(msg){
        this.toastMsg = true;
        this.message = this.$i18n.t(msg);
        this.question = false;
    },
    async saveObjectifs(text = "VIEWS_SAVED") {
      try {
        await this.$http.post(
          this.baseUrl + 'users/dashboard/update',
          this.client
        )

        this.$notify({
          group: 'foo',
          title: this.$t('SAVE'),
          text: this.$t(text),
          type: '',
        });

        this.toastMsg = true;
        this.message = this.$i18n.t('VIEW_NEW_WALLET_VERSION');
        this.question = true;

      }catch(err){
        this.toastMsg = true;
        this.message = this.$i18n.t('AN_ERROR_HAS_OCCURRED');
        this.question = false;
      }
    },
    filterCompagny(firm) {
      return firm.includes(window.localStorage.getItem("priradCompany"))
    }
  },
  computed: {
    ...mapState({
      breadLink: (state) => state.breadLink,
      baseUrl: (state) => state.baseUrl,
      token: (state) => state.token,
      client: (state) => state.client_Info,
      client_portfolios: (state) => state.client_Portfolio,
    }),

    checkForOptimComment: function() {
      return this.basepf.optimization_comment !== undefined && this.basepf.optimization_comment.length > 0
    }
  },
  async mounted() {
    this.setBreadLink([
      'Prime Radiant',
      'CRM',
      'CLIENTS',
      this.$route.params.id,
    ]);
  },
  beforeMount() {
    this.checkLog();
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.revertBtn {
  background-color: '#82d435';
  border-color: '#82d435';
  color: 'black';
}
.comment {
  padding: 15px;
  background-color: #F1F1F1;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.nav-container {
  margin: 40px 0px;
  box-shadow: 0px 0px 2px 4px #dfe1e6;
}
.nav-list-container {
  padding-left: 0;
  display: flex;
  margin-bottom: 0px;
  gap: 10px;
}
.nav-switch {
  cursor: pointer;
  padding: 20px 15px;
}
.nav-switch_focus{
  background-color: #F1F1F1;
}
</style>
