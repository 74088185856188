<script>
import { Pie } from "@/assets/js/graph/BaseChart.js";

export default {
  extends: Pie,
  data() {
    return {
      datacollection: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            hoverBackgroundColor: [],
            data: []
          }
        ]
      },
      options: {
        onClick: this.graphClickEvent,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        }
      }
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: false
    }
  },
  watch: {
    dataProps: function(value) {
      this.destroyIt();
      this.fillData();
      this.renderChart(this.datacollection, this.options);
    }
  },
  methods: {
    fillData() {
      this.datacollection.labels = [];
      this.datacollection.datasets[0].data = [];
      for (let index = 0; index < this.dataProps.length; index++) {
        this.datacollection.labels.push(this.dataProps[index].name);
        this.datacollection.datasets[0].data.push(
          Math.round(this.dataProps[index]["total"] * 100)
        );
        this.datacollection.datasets[0].backgroundColor.push(
          this.dataProps[index].color
        );
        this.datacollection.datasets[0].hoverBackgroundColor.push(
          this.dataProps[index].color
        );
      }
    },
    graphClickEvent(event, array) {
      let i = 0;
      for (let index = 0; index < this.dataProps.length; index++) {
        if (this.dataProps[index].name == array[0]["_view"].label) {
          i = index;
          break;
        }
      }

      let name = [];
      let value = [];

      for (let u in this.dataProps[i].data) {
        for (let v in this.dataProps[i].data[u]) {
          name.push(v);
          value.push(Math.round(this.dataProps[i].data[u][v] * 100));
        }
      }

      this.$emit("clicked", [name, value]);
    }
  },
  mounted() {
    this.fillData();
    this.renderChart(this.datacollection, this.options);
  }
};
</script>