<template>
  <div>
    <h4 style="text-align:center;">{{ $t('MARKET_VIEWS') }}</h4>
    <table
      class="table table-bordered table-condensed mx-auto"
      style="background-color:white; max-width: 500px;"
    >
      <thead>
        <tr>
          <th style="text-align:center;">{{ $t('ASSET_CLASSES') }}</th>
          <th
            v-show="findValue(isShow, true)"
            style="text-align:center;"
            class="numeric"
          >
            {{ $t('GRADE') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(g, name, index) in groupAssets">
          <tr :style="{ fontWeight: 'bold' }" :key="name + '-' + Math.random()">
            <td style="display:flex; align-items: center; cursor: pointer" 
                  v-on="
                    !isShow[name]
                      ? { click: () => showAssets(name, index) }
                      : { click: () => hideAssets(name, index) }">
              <div
                :style="{
                  width: '5px',
                  alignSelf: 'stretch',
                  backgroundColor: g.color,
                }"
                class="mr-2"
              >
                &nbsp;
              </div>
              <div style="width: 100%; text-align: center">
                {{ translateUnivers(name) }}
              </div>
              <div>                 
                <i :class="[!isShow[name] ? 'fa fa-caret-down' : 'fa fa-caret-up']"></i>
              </div>
            </td>
          </tr>
          <tr
            v-show="isShow[name]"
            v-for="(a, n, i) in dataProps"
            v-if="n in g.assets"
            :key="name + 'assets' + i"
          >
            <td style="text-align:center;">{{ translate(n) }}</td>
            <td style="text-align:center">
              <i
                class="icon-minus"
                @mousedown="onClick(a, n, -1)"
                @mouseup="stopInterval()"
              ></i>
              {{ a }}
              <i
                class="icon-plus"
                @mousedown="onClick(a, n, 1)"
                @mouseup="stopInterval()"
              ></i>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      groupAssets: [],
      isShow: {},
      interval: '',
      stillDown: false,
      hashSelected: ""
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true,
    },
    translateUniversProps: {
      type: Array,
      required: true,
    },
    translateAssetsProps: {
      type: Array,
      required: true,
    },
  },
  watch: {
    dataProps: function() {
      this.getGroupePf();
    },
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },
  methods: {
    translate(n) {
      let idx = this.translateAssetsProps.findIndex((e) => e.optim == n);
      return idx != -1 ? this.translateAssetsProps[idx][this.$i18n.locale] : n;
    },
    translateUnivers(n) {
      let idx = this.translateUniversProps.findIndex((e) => e.fr == n);
      return idx != -1 ? this.translateUniversProps[idx][this.$i18n.locale] : n;
    },
    async getGroupePf() {
      let data = await this.$http.get(
        this.baseUrl +
          'users/dashboard/portfolios/portfolios/' +
          localStorage.getItem('priradCompany')
      );
      this.groupAssets = data.data.resultat[0].contents;
      let tmp = {};
      for (const iterator of Object.entries(this.groupAssets)) {
        tmp[iterator[0]] = false;
      }
      this.isShow = tmp;
    },
    showAssets(e, i) {
      this.isShow[e] = true;
      this.$emit('isShow', true);
    },
    hideAssets(e, i) {
      this.isShow[e] = false;
      if (!this.findValue(this.isShow, true)) this.$emit('isShow', false);
    },
    onClick(value, name, direction) {
      clearInterval(this.interval);
      let limits = [-2, 2]
      let hashSelected = this.hashSelected = JSON.stringify([value, name, direction, Date.now()])
      this.stillDown = true
      value = Math.max(Math.min(parseInt(value)+direction, limits[1]), limits[0]);
      this.$emit('clicked', {value, name});
      setTimeout(()=>{
        if (this.stillDown && hashSelected===this.hashSelected){
          this.interval = setInterval(()=> {
            value = Math.max(Math.min(value+direction, limits[1]), limits[0]);
            this.$emit('clicked', {value, name});
          }, 100);
        }
      }, 500)
    },
    stopInterval() {
      this.stillDown = false
      this.hashSelected = ""
      clearInterval(this.interval);
    },
    nametokey(g) {
      return g.name.replace(/\s+/g, '');
    },
    findValue(o, value) {
      for (var prop in o) {
        if (o.hasOwnProperty(prop) && o[prop] === value) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    this.getGroupePf();
  },
};
</script>
