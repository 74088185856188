<template>
  <div class="row" :style="styleProps">
    <div class="evalRiskBar evalRiskB">
      <div class="bar-titles" style="font-size: 16px;">
        <div class="right" style="margin-bottom: 5px;">
          {{ $t('LESS_SECURE') }}
          <br />
          {{ $t('MORE_DYNAMIC') }}
        </div>
        <div class="left" style="margin-bottom: 5px;">
          {{ $t('SAFER') }}
          <br />
          {{ $t('LESS_DYNAMIC') }}
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="bar">
        <div class="area area_upper" :style="styleAreaUpper" id="divUpperRiskDesign"></div>
        <div class="area area_lower" :style="styleAreaLower" id="divLowerRiskDesign"></div>
        <i class="fa fa-briefcase fa-2x"></i>
      </div>
      <div class="datas" style="font-size: 16px;">
        <div
          class="data data_upper"
          :style="styleDataUpper"
          id="divUpperRisk"
        >{{(Math.round(frameProps.max * 10) / 10)}}</div>
        <div
          class="data data_lower"
          :style="styleDataLower"
          id="divLowerRisk"
        >{{(Math.round(frameProps.min * 10) / 10)}}</div>
        <div class="data data_exposition">
          <div class="triangle"></div>
          <div class="score" id="divRisk">{{ Math.round(riskProps * 10) / 10 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Max: 0,
      Lower: 0,
    };
  },
  props: {
    frameProps: {
      type: Object,
      required: true,
    },
    riskProps: {
      type: Number,
      required: true,
    },
    styleProps: {
      type: String,
      required: false,
      default: "margin-top:5%; margin-right: auto; margin-left: auto",
    },
  },
  watch: {
    frameProps: function (value) {
      this.Max = ((5 - value.max) / 4) * 100;
      this.Lower = ((value.min - 1) / 4) * 100;
    },
  },
  computed: {
    styleAreaUpper: function () {
      return "width:" + this.Max + "%";
    },
    styleAreaLower: function () {
      return "width:" + this.Lower + "%";
    },
    styleDataUpper: function () {
      return "width:calc(" + this.Max + "% + 15px)";
    },
    styleDataLower: function () {
      return "width:calc(" + this.Lower + "% + 15px)";
    },
  },
  mounted() {
    this.Max = ((5 - this.frameProps.max) / 4) * 100;
    this.Lower = ((this.frameProps.min - 1) / 4) * 100;
  },
};
</script>

<style scoped>
.evalRiskB {
  max-width: 550px;
  margin-right: auto;
  margin-left: auto;
  min-width: 300px;
}
</style>
