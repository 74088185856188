<template>
  <div>
    <!-- stats clients 1 -->
    <i class="icon-people iMargin"></i>
    {{$t('CUSTOMER_STATISTICS')}}
    <div class="row" style="margin-top: 15px;">
      <div class="col-sm-4 col-lg-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="icon-people bg-primary p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-primary mb-0 pt-3">{{nbClientsProps}}</div>
            <div
              class="text-muted text-uppercase font-weight-bold"
              style="font-size: 11px;margin-top: 5px;"
            >{{$t('CLIENTS')}}</div>
          </div>
        </div>
      </div>
      <!--/.col-->
      <div class="col-sm-4 col-lg-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="icon-user-following bg-success p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-primary mb-0 pt-3">125 (87%)</div>
            <div
              class="text-muted text-uppercase font-weight-bold"
              style="font-size: 11px;margin-top: 5px;"
            >{{$t('COMPLIANT')}}</div>
          </div>
        </div>
      </div>
      <!--/.col-->

      <div class="col-sm-4 col-lg-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-eur bg-warning p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-primary mb-0 pt-3">71 934 282 €</div>
            <div
              class="text-muted text-uppercase font-weight-bold"
              style="font-size: 11px;margin-top: 5px;"
            >{{$t("MANAGED_OUTSTANDINGS")}}</div>
          </div>
        </div>
      </div>

      <!--/.col-->

      <!--/.col-->

      <div class="col-sm-4 col-lg-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-percent bg-info p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-primary mb-0 pt-3">1 253 123 €</div>
            <div
              class="text-muted text-uppercase font-weight-bold"
              data-toggle="tooltip"
              data-placement="top"
              title="% moyen de l'encours géré par rapport au patrimoine total déclaré"
              style="font-size: 11px;margin-top: 5px;"
            >{{$t("AVERAGE_OUTSTANDING")}}</div>
          </div>
        </div>
      </div>
      <!--/.col-->

      <div class="col-sm-4 col-lg-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-bell bg-danger p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-danger mb-0 pt-3">15</div>
            <div
              class="text-muted text-uppercase font-weight-bold"
              style="font-size: 11px;margin-top: 5px;"
            >{{$t('COMPLIANCE_ALERTS')}}</div>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-lg-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="icon-pie-chart bg-warning p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-primary mb-0 pt-3">55%</div>
            <div
              class="text-muted text-uppercase font-weight-bold"
              style="font-size: 11px;margin-top: 5px;"
            >{{$t('PART_U_C')}}</div>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-lg-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="icon-briefcase bg-primary p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-primary mb-0 pt-3">153</div>
            <div
              class="text-muted text-uppercase font-weight-bold"
              style="font-size: 11px;margin-top: 5px;"
            >{{$t('ACCOUNTS')}}</div>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-lg-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-line-chart bg-primary p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-primary mb-0 pt-3">2.15%</div>
            <div
              class="text-muted text-uppercase font-weight-bold"
              style="font-size: 11px;margin-top: 5px;"
            >{{$t('AVERAGE_PERF')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    nbClientsProps: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style>
.iMargin {
  margin-right: 0.5rem;
}
</style>
