<template>
  <div class="container-fluid">
    <h4 style="margin-bottom: 15px;">{{$t('DASHBOARD')}}</h4>
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <i class="icon-user"></i>
          {{$t('USER_SHEET')}}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <div class="col-6 infoFont">
                <strong>{{$('USERNAME')}}</strong>
                <p>{{user.username}}</p>
                <strong>{{$t('CREATION_DATE')}}</strong>
                <p>{{$t('CREATED_THE')}} {{createdAt}}</p>
              </div>
            </div>
            <div class="col-8 row">
              <div class="col-sm-6 col-lg-4">
                <div>
                  <div class="clearfix">
                    <h6 class="h6 text-primary mt-2 mb-0" style="padding-top: 10px;">Token</h6>
                    <div class="input-group">
                      <span @click="renewToken()" style="cursor:pointer;" class="input-group-addon">
                        <i class="fa fa-refresh"></i>
                      </span>
                      <input
                        type="text"
                        id="txtToken"
                        name="txtToken"
                        class="form-control"
                        :value="user.token"
                        readonly
                      />
                      <span @click="copyToken()" style="cursor:pointer;" class="input-group-addon">
                        <i class="fa fa-files-o"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4" style="margin-left:15%;">
                <div>
                  <div class="clearfix">
                    <h6
                      class="h6 text-primary mt-2 mb-0"
                      style="padding-top: 10px;visibility:hidden;"
                    >Token</h6>
                    <div class="input-group" v-show="!changePw">
                      <button class="btn btn-primary" @click="newPassword()">{{$t('NEW_PASSWORD')}}</button>
                    </div>

                    <div class="input-group" v-show="changePw">
                      <input
                        type="password"
                        id="txtMdp"
                        name="txtMdp"
                        class="form-control"
                        v-model="newPwd"
                      />
                      <span @click="changePwd()" style="cursor:pointer;" class="input-group-addon">
                        <i class="fa fa-floppy-o"></i>
                      </span>
                      <span
                        @click="stopChangePwd()"
                        style="cursor:pointer;"
                        class="input-group-addon"
                      >
                        <i class="fa fa-ban"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <i class="icon-user"></i>
          {{$t('AVAILABLE_FUNCTIONS')}}
        </div>
        <div class="card-body">
          <div class="row" v-if="functions.length > 0">
            <div class="col-2" style="border-right: 1px solid #e4e6eb;text-align:center;">
              <ul>
                <li v-for="(func,index) in functions">
                  <span
                    :class="{'get':func.type=='GET','post':func.type=='POST','patch':func.type=='PATCH','delete':func.type=='DELETE'}"
                    style="float:left;"
                  >{{func.type}}</span>
                  <span
                    class="nameFunction"
                    style="margin-left:5%;cursor:pointer;"
                    @click="chooseFunc(index)"
                  >{{func.nameToShow}}</span>
                </li>
              </ul>
            </div>

            <div class="col-5" style="text-align:center;border-right: 1px solid #e4e6eb;">
              <strong>
                <span
                  :class="{'get':functions[funcChoosen].type=='GET','post':functions[funcChoosen].type=='POST','patch':functions[funcChoosen].type=='PATCH','delete':functions[funcChoosen].type=='DELETE'}"
                  style="float:left;"
                >{{functions[funcChoosen].type}}</span>
                <span style="margin-left:5%;">{{getUrl+functions[funcChoosen].name}}</span>
              </strong>
              <hr />
              <h5>{{$t('DESCRIPTION_SECOND')}}</h5>
              <pre style="text-align:left;">{{functions[funcChoosen].description}}</pre>
              <hr />
              <h5>{{$t('HEADERS')}}</h5>
              <table
                class="table table-striped table-condensed table-bordered datatable tableStyle"
              >
                <tbody>
                  <tr
                    v-for="head in functions[funcChoosen].request_parameters"
                    v-if="head.location =='header'"
                  >
                    <td>
                      <strong>{{head.name}}</strong>
                    </td>
                    <td style="text-align:center;">{{head.example}}</td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div v-if="functions[funcChoosen].type=='POST'">
                <h5>{{$t('BODY')}}</h5>
                <pre
                  v-for="data in functions[funcChoosen].request_parameters"
                  v-if="data.location =='body'"
                  style="text-align:left;background-color:#e4e6eb;"
                >{{data.example}}</pre>
                <hr />
              </div>

              <h5>{{$t('RESPONSE')}}</h5>
              <table
                class="table table-striped table-condensed table-bordered datatable tableStyle"
              >
                <tbody>
                  <tr v-for="res in functions[funcChoosen].responses">
                    <td>
                      <strong>{{res.type}}</strong>
                    </td>
                    <td style="text-align:center;">{{res.name}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-5" style="text-align:center;">
              <h5 style="text-align:center;">
                <strong>{{$t('EXAMPLES')}}</strong>
              </h5>
              <table
                class="table table-striped table-condensed table-bordered datatable tableStyle"
              >
                <tbody>
                  <tr>
                    <td>{{getUrl + functions[funcChoosen].name}}</td>
                  </tr>
                  <template v-for="ex in functions[funcChoosen].examples">
                    <tr>
                      <td>
                        Input:
                        <br />
                        <pre style="background-color:#e4e6eb;text-align:left;">{{ex.input}}</pre>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        Output:
                        <br />
                        <pre style="background-color:#e4e6eb;text-align:left;">{{ex.output}}</pre>
                      </td>
                    </tr>
                    <hr />
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <span v-if="functions.length < 1">
            <h3>
              {{$t('NO_FUNCTION_AVAILABLE')}}
              <br />
              {{$t('PLEASE_CONTACT_US_BY_EMAIL')}}:
              <a
                :href="'mailto:contact@primeradiant.fr'"
              >contact@primeradiant.fr</a>
            </h3>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      user: {},
      functions: [],
      funcChoosen: 0,
      changePw: false,
      newPwd: "",
    };
  },
  computed: {
    ...mapState({
      breadLink: (state) => state.breadLink,
      baseUrl: (state) => state.baseUrl,
    }),
    createdAt: function () {
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      let current = new Date(this.user.createdAt);

      return current.toLocaleDateString("fr-FR", options);
    },
    getUrl() {
      let location = window.location.href;
      location = location.replace("/users", "");
      return location + this.baseUrl + "pr/functions/call/";
    },
  },
  methods: {
    ...mapActions(["setBreadLink", "setLogged"]),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({ isLog: false, role: 0 });
        await this.$http.get(this.baseUrl +"auth/logout");
        localStorage.removeItem("priradApiToken");
        localStorage.removeItem("priradApiRole");
        this.$router.push("/login");
      } else {
        let role = this.$getRole();
        if (role != 45) {
          this.setLogged({ isLog: false, role: 0 });
          await this.$http.get(this.baseUrl +"auth/logout");
          localStorage.removeItem("priradApiToken");
          localStorage.removeItem("priradApiRole");
          this.$router.push("/login");
        } else {
          this.getInfoUser();
        }
      }
    },
    async getInfoUser() {
      let data = await this.$http.get(
        this.baseUrl + "pr/users/get/" + this.$getCookies("priradApiId"),
        {
          headers: {
            Authorization: localStorage.getItem("priradApiToken"),
          },
        }
      );
      this.user = data.data.resultat;
      if (this.user.available_functions.length > 0) this.getFunctions();
    },
    async getFunctions() {
      let ctx = this;
      let data = await this.$http.get(
        this.baseUrl + "pr/users/functions/" + this.$getCookies("priradApiId"),
        {
          headers: {
            Authorization: ctx.user.token,
          },
        }
      );
      this.functions = data.data.resultat;
    },
    async renewToken() {
      let ctx = this;
      let data = await this.$http.get(
        this.baseUrl + "pr/users/renew/" + this.$getCookies("priradApiId"),
        {
          headers: {
            Authorization: ctx.user.token,
          },
        }
      );
      localStorage.setItem("priradApiToken", data.data.resultat);
      this.user["token"] = data.data.resultat;
    },
    newPassword() {
      this.changePw = true;
    },
    async changePwd() {
      let ctx = this;
      await this.$http.patch(
        this.baseUrl + "pr/users/update/pwd",
        {
          id: ctx.user.id,
          password: ctx.newPwd,
        },
        {
          headers: {
            Authorization: ctx.user.token,
          },
        }
      );
      this.changePw = false;
    },
    chooseFunc(index) {
      this.funcChoosen = index;
    },
    copyToken() {
      let copyText = document.getElementById("txtToken");
      copyText.select();
      document.execCommand("copy");
    },
    stopChangePwd() {
      this.changePw = false;
    },
  },
  mounted() {
    this.setBreadLink(["Prime Radiant", "API", "DASHBOARD"]);
    this.checkLog();
  },
};
</script>

<style>
ul {
  list-style-type: none;
}

.get {
  color: green;
}

.post {
  color: orange;
}

.patch {
  color: grey;
}

.delete {
  color: red;
}

.nameFunction:hover {
  text-decoration: underline;
}
</style>