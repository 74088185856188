<template>
  <div class="parentContainer">
    <div style="flex: 1; align-self: center">
      <div class="inputLabel">
        <span v-if="placeHolder">{{ $t(placeHolder) }}</span>
        <span>{{ showPercentage }}</span>
      </div>
      <SliderInput
        :name="'animationSlider_' + placeHolder"         
        :min="minRange"
        :max="maxRange"
        v-model="slider"
        :step="step"/>
    </div>
    <div
      class="ml-4 animationContainer"
      :id="'animationContainer_' + placeHolder"
    ></div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Lottie from 'lottie-web';
import SliderInput from '@/components/autre/SliderInput.vue';

export default {
  name: 'AnimationSlider',
  components: {
    SliderInput
  },
  data() {
    return {
      percent: 0,
      slider: 0,
      step: 1,
      maxRange: 100,
      minRange: 0,
      lottieAnimation: null,
    };
  },
  props: {
    animationJSON: {
      required: true,
    },
    placeHolder: {
      required: false,
    },
    reversed: {
      required: false,
      default: false,
    },
    maxFrame: {
      required: false,
    },
    startFrame: {
      required: false,
    },
    percentProps: {
      required: false,
    },
    viewBox: {
      required: false,
    },
  },
  watch: {
    slider: function () {
      let tmp =
        this.reversed * (this.maxRange - this.slider) +
        !this.reversed * this.slider;
      this.percent = Math.round((this.slider * 100) / this.maxRange) / 100;
      this.lottieAnimation.goToAndStop(Math.round(tmp), true);

      this.$emit('slider', {
        placeHolder: this.placeHolder,
        slider: this.slider,
        percent: this.percent,
      });
    },
    percentProps: function () {
      this.checkPercentProps();
    },
  },
  computed: {
    ...mapState({
      data: (state) => state.new_Compte,
    }),
    showPercentage() {
      return `${Math.round(this.percent * 100)}%`;
    },
  },
  methods: {
    init() {
      const name_id = 'animationContainer_' + this.placeHolder;
      let animationContainer = document.getElementById(name_id);
      this.lottieAnimation = Lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: this.animationJSON,
        rendererSettings: { viewBox: this.viewBox },
      });
      if (this.viewBox)
        this.$el
          .querySelector('#' + name_id + ' > svg')
          .setAttribute('viewBox', this.viewBox);

      if (this.startFrame)
        this.lottieAnimation.goToAndStop(this.startFrame, true);
      this.maxRange = this.maxFrame
        ? this.maxFrame
        : this.lottieAnimation.getDuration(true);
      this.checkPercentProps();
    },
    checkPercentProps() {
      if (this.percentProps === this.percent) return;
      this.slider = Math.round(
        (this.maxRange * (this.percentProps * 100)) / 100
      );
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.inputLabel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.parentContainer {
  position: relative;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.animationContainer {
  width: 130px;
  height: 130px;

}
</style>
