<template>
  <div id="app" class="app">
    <header_component />
    <div class="app-body">
      <sidebar />
      <main class="main">
        <breadcrumb />
        <div class="container-fluid">
          <div class="animated fadeIn">
            <router-view />
          </div>
        </div>
      </main>
    </div>
    <footer_component />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

import Header from "@/layouts/header.vue";
import Sidebar from "@/layouts/sidebar.vue";
import Footer from "@/layouts/footer.vue";
import Bread from "@/layouts/breadcrumb.vue";

Vue.component("breadcrumb", Bread);
Vue.component("footer_component", Footer);
Vue.component("sidebar", Sidebar);
Vue.component("header_component", Header);

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(["setBaseUrl", "setLogged"]),
  },
  created() {
    this.setBaseUrl("/api/");
  },
};
</script>


<style>
</style>
