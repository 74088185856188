
<template>
<div>
  <div>
    <input type="text" v-model="nameCombi" :placeholder="$t('NEW_COMBINAISON')" />
    <br />
    <span @click="addNewCombi()">{{$t('ADD_NEW_COMBINAISON')}} </span>
    <span style="display:none">{{refreshtoken}}</span>
  </div>

    <table  class="table table-bordered table-condensed"
      style="background-color:white; max-width: 500px">
      <thead>
	<tr>
	  <th>{{$t('CONSTRAINTS')}}</th>
	  <th>{{$t('AMOUNT')}}</th>
	  <th>{{$t('MIN_OR_MAX')}}</th>
	</tr>
      </thead>
      <tbody>
	<template  v-for="combi in Object.entries(dataProps)">
	  <tr style="font-Weight:bold;">
	    <td>
	      <input type="radio" :value="combi[0]" name="checkCombi" @change="checkCombi(combi[0])" :id="combi[0]" :checked="checked == combi[0]" />
	      <i
                 style="cursor:pointer; float:left;"
		:class="{'icon-minus':show[combi[0]],'icon-plus':!show[combi[0]]}"
		@click="showCombi(combi[0])" />
	      {{combi[1].name}}
	    </td>
	    <td><input type="text" :value="amount[combi[0]]" @input="changeAmount(combi[0],$event)" /></td>
	    <td>{{$t('MIN')}}<input name="rdoMinMax" :checked="combi[1].min" value="min" @change="changeMinMax(combi[0],'min')" type="radio"/>{{$t('MAX')}}<input name="rdoMinMax" value="max" :checked="combi[1].max" @change="changeMinMax(combi[0],'max')" type="radio"/></td>
	  </tr>
	    <tr v-for="asset in combi[1].assets" v-show="show[combi[0]]">
	      <td colspan="3"><i class="icon-minus" style="cursor:pointer; margin-right:10px;"
		@click="remove(asset)" />{{asset}}</td>
	  </tr>
	</template>
      </tbody>
    </table>
  </div>
</template>
<script>
import Vue from "vue";
export default{
    data(){
	return{
	    show:{
	    },
	    checked: 0,
	    amount:{},
	    nameCombi:""
	}
    },
    
    props:{
	dataProps:{
	    type: Object,
	    required:true
	},
	refreshtoken: {
	    type: String,
	    required:false
	}
    },
    watch:{
	refreshtoken:function(){ 
	    let tmp = {};
	    for(let item of Object.keys(this.dataProps)){
		tmp[item] = false;
		if(item in this.show && this.show[item]){
		    tmp[item] = true
		}
	    }
	    this.show = tmp;
	}
    },
    methods:{
	showCombi(name){
	    this.show[name] = this.show[name] ? false : true;
	},
	checkCombi(index){
	    this.checked = index;
	    this.$emit("checked",index);
	},
	remove(name){
	    let idx = this.dataProps[this.checked].assets.findIndex(item => item == name);
	    this.$emit("remove",idx);
	},
	changeAmount(combi,e){
	    this.$emit("changeamountcombi",{combi:combi,amount:e.target.value})
	},
	addNewCombi(){
	    this.$emit("addgroup",this.nameCombi);
	},
	changeMinMax(groupekey,value){
	    this.$emit('changeminmax',{group:groupekey,value:value});
	}
    }, 
    mounted(){
	let amount = {}
	for(let item of Object.entries(this.dataProps)){
	    amount[item[0]] = item[1].amount;
	}

	this.amount = amount; 
    }
}
</script>
