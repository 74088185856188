<template>
  <div class="popup">
    <div class="popup-layout">
      <div class="popup-header">
        <button class="btn" style="background-color: white; margin-right: 5px" @click="showCart = !showCart">
          <i v-if="!showCart" class="fa fa-shopping-cart" style="position:relative;"><span class="cartCount">{{selectedList.length}}</span></i>
          <i v-else class="fa fa-list"></i>
        </button>
        <button class="btn btn-overlay" @click="$emit('save', selectedList)">
          {{ $t('SAVE') }}
        </button>
        <div class="popup_close">
          <i class="fa fa-times fa-2x" @click="$emit('close', true)" />
        </div>
      </div>
      <h4>{{$t('ADD_ASSETS_TO_CART')}}</h4>
      <div v-if="!showCart" class="uniList">
        <div v-for="(uniV, uniK) in portfolioContent" :key="uniK">
          <div class="univer-content" @click="displayUniver(uniK)">
            <div class="univer-theme" :style="{ backgroundColor: uniV.color }">
              &nbsp;
            </div>
            <span>{{ translateUnivers(uniK) }}</span>
            <i
              :class="[univerShow[uniK] ? 'icon-minus' : 'icon-plus']"
              style="margin-left: auto"
            />
          </div>
          <div v-if="univerShow[uniK]" class="asset-container">
            <div v-for="(assetV, assetK) in isInAssetShow(uniV.assets)" :key="assetK">
              <div
                class="asset"
              >
                {{ translate(assetK) }}
                <label
                  class="check"
                >
                  <input
                    type="checkbox"
                    class="checkbox"
                    :checked="selectedList.indexOf(assetK) !== -1"
                    v-on:input="addToCart(assetK)"
                  />
                  <div class="box"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="uniList">
        <div v-if="!selectedList.length">
          {{ $t('NO_ASSET_SELECTED') }}
        </div>
        <div class="asset-container" style="padding: 0 0 0 15px !important">
          <div v-for="(asset, index) in selectedList" :key="asset" class="asset">
            {{ translate(asset) }}
            <button class="btn" @click="removeFromSelectList(index)">
              <i class="fa fa-minus" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedList: [],
      univerShow: {},
      showCart: false
    };
  },
  props: {
    portfolioContent: {
      type: Object,
      required: true,
    },
    translatedAssets: {
      type: Array,
      required: true,
    },
    translatedUnivers: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
    assetShow: {
      type: Array,
      required: true,
    },
  },
  methods: {
    translate(n) {
      let idx = this.translatedAssets.findIndex((e) => e.optim == n);
      return idx != -1 ? this.translatedAssets[idx][this.$i18n.locale] : n;
    },
    translateUnivers(n) {
      let idx = this.translatedUnivers.findIndex((e) => e.fr == n);
      return idx != -1 ? this.translatedUnivers[idx][this.$i18n.locale] : n;
    },
    displayUniver(uniK) {
      if (!this.univerShow.hasOwnProperty(uniK)) {
        this.univerShow = Object.assign({}, this.univerShow, { [uniK]: false });
      }
      for (const universK of Object.keys(this.univerShow)) {
        if (universK != uniK) this.univerShow[universK] = false;
      }
      this.univerShow[uniK] = !this.univerShow[uniK];
    },
    addToCart(asset){
      let idxOf = this.selectedList.indexOf(asset) 
      idxOf === -1 ? this.selectedList.push(asset) : this.removeFromSelectList(idxOf)
    }, 
    removeFromSelectList(index) {
      this.selectedList.splice(index, 1)
    },
    isInAssetShow(assetList) {
      const asArray = Object.entries(assetList);
      const filtered = asArray.filter(([key, value]) => this.assetShow.indexOf(key) === -1);
      const obj = Object.fromEntries(filtered);

      return obj
    }
  }
}
</script>
<style scoped>
.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  background-color: rgba(240, 240, 240, 0.7);
}
.popup-layout {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f1f1f1;
  width: 80%;
  height: 80%;
  box-shadow: 0 0 4px gray;
  border-radius: 3px;
  padding: 10px;
  overflow-y: auto;
}
.popup-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.popup_close {
  width: max-content;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.univer-theme {
  display: inline-block;
  width: 5px;
  align-self: stretch;
  margin-right: 0.5rem;
}
.univer-content {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: snow;
  cursor: pointer;
}
.uniList {
  padding: 20px 40px;
  margin-bottom: 20px;
}
.uniList > div:not(:last-child) {
  margin-bottom: 15px;
}
.asset {
  padding: 10px;
  background-color: snow;
  width: max-content;
}
.asset-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  padding: 15px 15px 0 15px;
}
.btn {
  border: none;
  background-color: #fdd5b1;
  color: #707070;
  display: inline-block;
}
.btn-overlay {
  background-color: rgb(31, 151, 209);;
  color: white;
  font-weight: bold;
}
.cartCount {
  position: absolute;
  top: 13px;
  font-size: 10px;
  right: -10px;
}
</style>
