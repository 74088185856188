<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper modalContainer">
        <div class="modal-dialog modalContainer">
          <div class="modal-content p-4 modalContent">
            <div class="text-center imgContainer">
              <img :src="data.value.img" width="100px"/>
            </div>
            <h4 class="text-center title" :style="{ ...cssVars}">
              {{ $t(data.value.title) }}
            </h4>
            <i class="fa fa-times button__close" @click="hideModal()"/>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

export default {
  name: "GoalModal",
  props: {
    data:{
        type: Object,
        require: true,
    }
  },
  computed:{
    cssVars() {
        return {
            '--goal-color': this.data.value.color
        }
    },
  },
  methods: {
    hideModal() {
      this.$emit('clicked', this.data.key);
    },
  }
};
</script>
<style>
.imgContainer {
  position: absolute;
  top: -5px;
  left: -32px;
}
.title {
  padding: 10px;
  background-color: var(--goal-color);
  color: whitesmoke;
}
.modalContent{
  border: none !important;
}
.button__close{
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 5;
  cursor: pointer;
}
.modalContainer {
  min-width: 50% !important;
  width: fit-content !important;
}
</style>
