<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">

                            <button type="button" class="close" @click="hideModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="card-body">
                                    {{$t(dataProps)}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    };
  },
  props:{
      dataProps:{
          type:String,
          required:true
      }
  },
  methods: {
    hideModal() {
      this.$emit("clicked");
    },
  },
};
</script>
<style>

</style>
