<template>
  <div class="sidebar">
    <form class="sidebar-form">
      <div class="form-group p-2 mb-0"></div>
    </form>
    <!-- button nouveau projet -->
    <router-link
      v-if="data.isLog && data.role == 10"
      :to="'/new'"
      :class="['btn', 'btn-primary', 'aButtonMargin', aButtonFullDisplay ? 'aButton' : 'aButtonClose']"
    >
      <i v-if="!aButtonFullDisplay" class="fa fa-user-plus" />
      <span v-if="aButtonFullDisplay">{{$t("NEW_ACCOUNT") }}</span>
    </router-link>

    <!-- end dropdown nouveau -->
    <nav class="sidebar-nav">
      <ul class="nav" v-if="data.isLog && data.role == 10">
        <li class="divider"></li>

        <li class="divider"></li>
        <li class="nav-item">
          <router-link class="nav-link d-flex" :to="'/clients'">
            <span><i class="icon-people"></i></span>
            <span>{{$t("CLIENTS") }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link d-flex" to="/convictions">
            <span><i class="fa fa-university"></i></span>
            <span>{{$t("MARKET_CONVICTIONS") }}</span>
          </router-link>
        </li>
      </ul>
      <ul class="nav" v-if="data.isLog && data.role == 20 && isGerantPath">
        <li class="divider"></li>

        <li class="divider"></li>
        <li class="nav-item">
          <router-link class="nav-link d-flex" :to="'/references'">
            <span><i class="icon-pie-chart"></i></span>
            {{$t("REFERENCE") }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link d-flex" to="/optimisation">
            <span><i class="icon-speedometer"></i></span>
            {{$t("OPTIMIZATION") }}
          </router-link>
        </li>
       <!-- <li class="nav-item">
          <router-link class="nav-link" to="/contraintes">
            <i class="icon-settings"></i>
            {{$t("CONSTRAINTS") }}
          </router-link>
        </li> -->
      </ul>
      <ul class="nav" v-if="data.isLog && data.role ==45">
        <li class="divider"></li>

        <li class="divider"></li>
      </ul>
      <ul class="nav" v-if="data.isLog && data.role == 60">
        <li class="divider"></li>

        <li class="divider"></li>
      </ul>
    </nav>
    <button
      class="sidebar-minimizer brand-minimizer"
      type="button"
      v-on:click="aButtonFullDisplay = !aButtonFullDisplay"
    ></button>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      role: 0,
      aButtonFullDisplay: true,
      path: "", 
    };
  },
  watch: {
    $route(to, from){
      this.path = to.path
    }
  },
  computed: {
    ...mapState({
      data: (state) => state.isLogged,
    }),
    isGerantPath: function() {
      return (this.path == '/references' || this.path == '/optimisation')
    }
  },
  methods: {
    ...mapActions(["setLogged"]),
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function (state) {
        return state.isLogged;
      },
      function (val) {
        ctx.setLogged(val);
      },
      {
        deep: true,
      }
    );
    this.path = this.$route.path
  },
};
</script>

<style>
.aButtonMargin {
  margin-top: 5px;
  margin-bottom: 10px;
}
.aButton {
  margin-left: 8px;
  width: 92%;
}
.aButtonClose {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
}
</style>
