<template>
    <input 
        type="text" 
        :id="_id" 
        :name="_name" 
        v-model="displayValue" 
        @blur="isFocus = false" 
        @focus="isFocus = true" 
        :readonly="readOnly" 
        :placeholder="placeHolder"
    />
</template>
<script>
/**** !IMPORTANT, if project change vue version to vue 3 there will be breaking change ****/
export default {
    data() {
        return {
            isFocus: false
        }
    },
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        _id: {
            type: String,
            required: false
        },
        _name: {
            type: String,
            required: false
        }, 
        // Needs to be a regex
        formatInsideInput: {
            type: RegExp, 
            required: true
        },
        // Needs to be a regex
        formatOutsideInput: {
            type: RegExp, 
            required: true
        },
        insideNewvalue: {
            type: String, 
            required: true
        },
        outsideNewvalue: {
            type: String, 
            required: true
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false 
        },
        placeHolder: {
            type: String,
            required: false,
            default: ''
        }

    },
    computed: {
        displayValue: {
            get: function() {
                if(!this.value) return
                if(typeof(this.value) === "number") this.value = String(this.value)
		        return this.value.replace(this.formatOutsideInput, this.outsideNewvalue);
            },
            set: function(modifiedValue) {
                let newValue = modifiedValue.replace(this.formatInsideInput,  this.insideNewvalue)
                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it
                this.$emit('input', newValue)
            }
        }
    }
}
</script>
