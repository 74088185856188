<template>
  <div v-if="readyToMount">
    <div id="investmentRegion">
      <div style="text-align:center">
        <strong>{{ $t('INVESTMENT') }}</strong>
      </div>
      <br />
      <div>
        <div>
          <p style="font-size:15px" class="alert alert-info text-center">
              {{ $t('CUMULATIVE_PAYMENTS') }} {{getCumulativePayments}}
          </p>
          <p style="font-size: 15px" class="alert alert-info text-center">
              {{ $t('MEDIAN_RESULT') }}
              <!--{{$formatNumberKM((graph.current.post[graph.current.post.length - 1]['l0.5'] / 100) * 100)}}€-->
              {{$formatNumberKM((graph.response.scenarios[2]/100) *100)}}
          </p>
          <!-- résumé scénario -->
          <strong>{{ $t('SCENARIOS') }}</strong>

          <table width="100%" style="display: table; font-size: 14px;">
            <tbody>
              <tr class style="display: table-row;">
                <td>
                  <span class="refcolor" id="AMUpSpan"></span>
                  {{ $t('OPTIMISTIC_SCENARIO') }}
                  <span id="upSpan">({{ $t('probability') }} 15</span>
                  %){{ $t('TWO_POINTS_ALONE') }}
                </td>
                <td class="text-right">
                  <span name="l3" class><!--{{
                    $formatNumberKM(
                      graph.current.post[graph.current.post.length - 1]['l3']
                    )
                  }}-->
                    {{$formatNumberKM(graph.response.scenarios[3])}}
                  </span
                  >€ -
                  <span name="l4" class><!--{{
                    $formatNumberKM(
                      graph.current.post[graph.current.post.length - 1]['l4']
                    )
                  }}-->

                    {{$formatNumberKM(graph.response.scenarios[4])}}
                  </span
                  >€
                </td>
              </tr>
              <tr class style="display: table-row;">
                <td>
                  <span class="refcolor" id="AMMidSpan"></span>
                  {{ $t('MEDIAN_SCENARIO') }}
                  <span id="middleSpan">({{ $t('probability') }} 60</span>
                  %){{ $t('TWO_POINTS_ALONE') }}
                </td>
                <td class="text-right">
                  <span name="l2" class><!--{{
                    $formatNumberKM(
                      graph.current.post[graph.current.post.length - 1]['l2']
                    )
                  }}-->
                                    {{$formatNumberKM(graph.response.scenarios[2])}}

                  </span
                  >€ -
                  <span name="l3" class><!--{{
                    $formatNumberKM(
                      graph.current.post[graph.current.post.length - 1]['l3']
                    )
                  }}-->
                   {{$formatNumberKM(graph.response.scenarios[3])}}

                  </span
                  >€
                </td>
              </tr>
              <tr class style="display: table-row;">
                <td>
                  <span class="refcolor" id="AMLowSpan"></span>
                  {{ $t('PESSIMISTIC_SCENARIO') }}
                  <span id="lowSpan">({{ $t('probability') }} 15</span>
                  %){{ $t('TWO_POINTS_ALONE') }}
                </td>
                <td class="text-right">
                  <span name="l1" class><!--{{
                    $formatNumberKM(
                      graph.current.post[graph.current.post.length - 1]['l1']
                    )
                  }}-->
                    {{$formatNumberKM(graph.response.scenarios[1])}}

                  </span
                  >€ -
                  <span name="l2" class><!--{{
                    $formatNumberKM(
                      graph.current.post[graph.current.post.length - 1]['l2']
                    )
                  }}-->
                         {{$formatNumberKM(graph.response.scenarios[2])}}

                  </span
                  >€
                </td>
              </tr>
            </tbody>
          </table>
          <!-- end résumé scénario -->
          <br />

          <p style="font-size:15px" class="alert alert-info text-center">
              {{ $t('RISK_LEVEL') }}
              <span>{{
                Math.round((graph.response.NoteTotale + Number.EPSILON) * 10) /
                  10
              }}</span>
          </p>
          <p style="font-size:15px" class="alert alert-info text-center">
              {{ $t('MAXIMUM_LOSS') }}
              <span
                >{{
                  Math.round(
                    (graph.response.PerteMax90[0] + Number.EPSILON) * 100
                  )
                }}%</span
              >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      dataObjectifs: 0,
      year: 0,
      isShow: {
        inv: true,
        ret: false,
      },
      targetProb: 0,
      targetAmountRet: 0,
      showPlus: false,
      graph: {},
    };
  },
  props: {
    graphProps: {
      type: Object,
      require: true,
    },
  },
    watch: {
    graphProps: {
      handler: function(val) {
        this.graph = this.graphProps;
        this.year = val.current_year;
        let projs = this.graphProps.response.Projets
        let note = 0;
        for (let item of Object.entries(projs)) {
          if (item[1].Note > note) {
            note = item[1].Note;
          }
        }

        this.setNewNoteVisu(
          Math.round((this.graph.response.NoteTotale + Number.EPSILON) * 10) /
            10
        );
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      data: (state) => state.new_Compte,
    }),
    dataObjectifsFormat: {
      get: function() {
        return this.formatInt(this.dataObjectifs);
      },
      set: function(val) {
        this.dataObjectifs = val.toString().replace(' ', '');
      },
    },
    readyToMount(){
      return this.graph['fdata'] !== undefined
    },
    getCumulativePayments(){
      return `${this.formatInt(this.graph.fdata.cashin[this.graph.fdata.cashin.length - 1].value)}€`
    }
  },
  methods: {
    ...mapActions(['setSimulation', 'setNewNoteVisu']),
    formatInt(x) {
      return Math.round(x)
        .toString()
        .replace(' ', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    formatDelta(x) {
      if (x === undefined) return 0;
      return Math.round(x)
        .toString()
        .replace(' ', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    refresh() {
      this.$emit('refreshed');
    },
  },
  created() {
    let ctx = this;

    this.$store.watch(
      function(state) {
        return state.new_Compte;
      },
      function(val) {
        //ctx.setNewCompte(val);
      },
      {
        deep: true,
      }
    );
  },
};
</script>

<style>
.refcolor {
  opacity: 1;
  width: 10px;
  height: 10px;
  display: inline-block;
  border: 1px solid #222;
  vertical-align: middle;
  margin-right: 5px;
}

#AMUpSpan {
  background: #0099cc;
}

#AMMidSpan {
  background: #c3efec;
}

#AMLowSpan {
  background: #8c8c8c;
}
</style>
