<template>
    <div>
        <div class="form background containerNewPf" style="margin: 15px auto;">
            <form
                style="width: 50%; margin-left:10px"
                id="app"
                @submit="submitForm"
            >
                <p v-if="formErr.length">
                    <b>{{$t('PLEASE_CORRECT_ERRORS')}}</b>
                    <ul>
                        <li v-for="error in formErr" :key="error">{{ error }}</li>
                    </ul>
                </p>
                <p class="form__group field">
                    <input
                    id="pfName"
                    v-model="pfName"
                    type="text"
                    name="pfName"
                    class="form__field"
                    :placeholder="$t('NAME')"
                    >
                    <label class="form__label" for="pfName">{{$t('NAME')}}</label>
                </p>
                <p class="form__group field">
                    <input
                    id="pfNote"
                    v-model="pfNote"
                    type="number"
                    name="pfNote"
                    class="form__field"
                    min="0"
                    placeholder="Note"
                    >
                    <label class="form__label" for="pfNote">Note</label>
                </p>
                <p>
                    <input class="btn" type="submit" value="Submit">
                </p>
            </form>
            <div style="width: 50%; margin-left:10px; text-align: center">
                <p style="font-weight: bold" >{{$t('SUMMARY')}}</p>
                <ul style="list-style: none; padding: 0">
                    <li style="padding: 5px" v-for="item in orderedPortfolio" :key="idx">
                        {{basePf.portfolio_names[item.index]}} - {{basePf.portfolio_notes[item.index]}}
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="showFillPf" class="containerNewPf">
            <AddAssetsPopUp 
                v-if="assetFundPopupShow"
                :portfolioContent="basePf.contents"
                :assetShow="assetShow"
                :index="index"
                :translatedAssets="translated" 
                :translatedUnivers="universtranslated"
                @save="popupSave"
                @close="assetFundPopupShow = false"
            />
           
            <div class="form" style="justify-content: space-between;">
                <div class="donut background">
                    <DonutChart
                        :dataProps="newPf"
                        :widthProps="500"
                        :heightProps="300"
                        :alreadyDone="true"
                        :indexProps="index"
	                    :onlyBaseProps="true"
	                    :translateAssetsProps="translated" 
                        :translateUniversProps="universtranslated"
                        :refreshtoken="refreshtoken"
                    />
                    <Volatility style="margin-top: 30px;" :portfolioContent="newPf" :index="index" :refreshtoken="refreshVolToken" />
                </div>
                <div class="background" style="width: 60%;">
                     <button class="btn" style="margin-left: auto; margin-bottom: 20px;" @click="assetFundPopupShow = true">
                        <i class="fa fa-plus" />
                    </button>
                    <div class="fundTable__container">
                        <UpdatedAssetFundTable 
                            :portfolioContent="newPf"
                            :weightAvailable="weightAvailable"
                            :assetShow="assetShow"
                            @weightUpd="weightUpd"
                            @setAssetToZero="setAssetToZero"
                            :index="index"
                            :translatedAssets="translated" 
                            :translatedUnivers="universtranslated"
	                        @refresh="refreshVol"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="form containerNewPf" style="justify-content: flex-end;">
            <button v-if="showFillPf" class="btn" style="display: inline-block ! important" @click="save(true)">
                {{$t('SAVE')}}
            </button>
            <button class="btn__back" @click="save(false)">
                {{$t('BACK')}}
            </button>
        </div>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    
    import DonutChart from "@/components/autre/donutchart.vue";
    import AddAssetsPopUp from "@/components/gerant/assets/addAssetsPopUp.vue"
    import UpdatedAssetFundTable from "@/components/gerant/assets/updateAssetFundTable.vue"
    import Volatility from "@/components/gerant/assets/volatility.vue"

    export default {
        components: {
            AddAssetsPopUp,
            UpdatedAssetFundTable,
            DonutChart,
            Volatility,
        },
        data() {
            return {
                pfName: null, 
                pfNote: null,
                formErr: [],
                showFillPf: false,
                universtranslated: [],
                translated: [],
                basePf: {},
                newPf: {},
                assetShow: {},
                assetFundPopupShow: false,
                weightAvailable: 0,
                index: null,
                refreshtoken: '',
                refreshVolToken: '',
                popup: {
                    show: false,
                    question: true,
                    message: this.$i18n.t('CHANGE_WILL_BE_LOST'),
                },
            }
        },
	props:{
	    portfolioProps:{
		type:Object,
		required: true
	    }
	},
        computed: {
	        ...mapState({
	            baseUrl: (state) => state.baseUrl,
	        }),
	    orderedPortfolio(){
		if(!("portfolio_notes" in this.basePf))
		    return [];

	  let arr = [];
	  let idx =  0;
	  for(let item of this.basePf.portfolio_notes){
	      let tmp = {index: idx, note:item}
	      arr.push(tmp);
	      idx++;
	  }

	  return arr.sort((a,b) => a.note - b.note);
      }
        },
        methods: {
            async init()  {
		        this.basePf = JSON.parse(JSON.stringify(this.portfolioProps));
		        this.newPf = this.basePf.contents;
            },
            async getTranslatedUnivers() {
                let univers_translated = await this.$http.get(
                    this.baseUrl + 'univers/translated'
                );
                this.universtranslated = univers_translated.data.resultat;
            },
            async getTranslatedAssets() {
                let assets_translated = await this.$http.get(
                    this.baseUrl + 'assets/translated'
                );
                this.translated = assets_translated.data.resultat;
            },
            addToBasePf() {
                let notes = this.basePf.portfolio_notes
                let idx = notes.length; //notes.findIndex(el => el > this.pfNote)
               
                this.index = idx //=== -1 ? notes.length : idx    
                this.basePf.portfolio_notes.splice(this.index, 0, this.pfNote)
                this.basePf.portfolio_names.splice(this.index, 0, this.pfName)

                // Going to add the new index for this pf and calculate the total of each univers
                for (const [uniK, uniV] of Object.entries(this.newPf)) {
                    let total = Array(this.basePf.portfolio_notes.length).fill(0);
                    
                    for (const [assetK, assetV] of Object.entries(uniV.assets)) {
                        this.newPf[uniK].assets[assetK].base_weights.splice(this.index, 0, 0)
                        this.newPf[uniK].assets[assetK].optimized_weights.splice(this.index, 0, 0)
                        this.newPf[uniK].assets[assetK].adjusted_weights.splice(this.index, 0, 0)
                        
                        assetV.base_weights.map((el, idx) => {
                            total[idx] += el
                        })

                        for (const [fundK, fundV] of Object.entries(assetV.funds)) {
                            let key = this.newPf[uniK].assets[assetK].funds[fundK].stock ? 'stockOrder' : 'order'
                            this.newPf[uniK].assets[assetK].funds[fundK][key].splice(this.index,0,this.newPf[uniK].assets[assetK].funds[fundK][key][0])
                        }
                    }
                    this.newPf[uniK]['total'] = total
                }

                this.showFillPf = true
            },
            checkForm(){
                this.formErr = []
                if (!this.pfName) {
                    this.formErr.push(this.$i18n.t("NAME_REQUIRED"));
                }
                if (!this.pfNote) {
                    this.formErr.push(this.$i18n.t("NOTE_REQUIRED"));
                }
                if (this.basePf.portfolio_notes.includes(this.pfNote)) {
                    this.formErr.push(this.$i18n.t("NOTE_ALREADY_EXIST"));
                }
            },
            submitForm(e) {
                this.pfNote = Number(this.pfNote)

                if(this.showFillPf){
                    this.basePf.portfolio_names.splice(this.index,1,this.pfName);
		    this.basePf.portfolio_notes.splice(this.index,1,this.pfNote);			
                } else { 
                    this.checkForm()
                    if(!this.formErr.length) {this.addToBasePf()}
                }
                e.preventDefault();
            },
            popupSave(list) {
                for (const assetK of list) {
                    this.assetShow[assetK] = true
                }
                this.assetShow = Object.assign({}, this.assetShow, this.assetShow)
                this.assetFundPopupShow = false 
            },
            setAssetToZero(uniK, assetK, index){
                this.assetShow[assetK] = false
                this.weightAvailable -= this.newPf[uniK].assets[assetK].base_weights[index]*100
                this.newPf[uniK].total[index] -= this.newPf[uniK].assets[assetK].base_weights[index]
                this.newPf[uniK].assets[assetK].base_weights[index] = 0;
                this.refreshtoken = Date.now();
            },
            weightUpd(uniK, assetK, weight, operator, index, weiAvailable) {
                let oldBaseWeight = this.newPf[uniK].assets[assetK].base_weights[index]
                let oldTotal = this.newPf[uniK].total[index]

                this.weightAvailable = weiAvailable
                this.newPf[uniK].assets[assetK].base_weights[index] = weight
                this.newPf[uniK].total[index] = operator === '+' ? oldTotal + (weight - oldBaseWeight) : oldTotal - (oldBaseWeight - weight)  
                this.refreshtoken = Date.now();          
            },
            save(yesNo){
                if(!yesNo){
                    this.$emit('back')
                }else {
		    if(this.weightAvailable >= 99.99){
			this.$emit('save', this.basePf);
		    }
                }
            },
            refreshVol() {
                this.refreshVolToken = Date.now();
            },
            preventNav(e) {
                if (!this.showFillPf) return;
                e.preventDefault();
                // Custom message has been removed from browser
                return '';
            },
            errorMsgWeightUsed() {
                this.$notify({
                    group: 'foo',
                    title: this.$t('ERROR'),
                    text: this.$t('PORTFOLIO_DOES_NOT_ADD_UP'),
                    type: '',
                });
            }
        },
        beforeMount() {
            window.onbeforeunload = this.preventNav;
            this.getTranslatedUnivers();
            this.getTranslatedAssets();
        },
        mounted(){
            this.init()
        },
        beforeDestroy() {
            // Don't forget to clean up event listeners
            window.onbeforeunload = null;
        }
    }
</script>
<style scoped>
.btn {
        border: none;
        background-color: #E8E8E8;
        color: #707070;
        display: block;
        cursor: pointer;
    }
.containerNewPf {
    max-width: 1200px;
    margin: 15px auto;
}
.background {
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    height: min-content;
}

.donut {
    width: 600px;
    margin-right: 15px;
}
.fundTable__container {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    margin: auto 0px;
    max-height: 500px;
    overflow-y: auto;
}
.form {
    display: flex;
}
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #707070;
  padding: 7px 0;
  background: transparent;
  transition: all 0.2s;
}
.form__field::placeholder{
    color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}

.form__field:focus {
  
  padding-bottom: 6px;  
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, black,#707070);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: #707070;
}

/* reset input */
.form__field:required .form__field:invalid{
  box-shadow:none;
}
.btn__back {
    background-color: inherit;
    border: none;
    font-size: 15px;
    color: #707070;
}
</style>
