<template>
    <div class="customSelect" :tabindex="tabindex" @blur="isOpen = false" >
        <div class="customSelect_btn" :style="overrideSelectBtn" @click="isOpen = !isOpen">
            <span>{{$t(selectedItem) || selectedItem}}</span>

            <i class="fa fa-sort-down arrow" style="margin-left: 10px;" :class="{reverseArrow: isOpen}"></i>
        </div>
        <transition name="slide-fade">
            <ul class="dropdown" v-show="isOpen" :style="popUpMaxHeight ? {maxHeight: `${popUpMaxHeight}px`, overflow: 'scroll'} : {}">
                <li v-show="showOptionPlaceholder" @click="select(optionPlaceholder, -1)" >
                    {{ optionPlaceholder }}
                </li>
                <li v-for="(item, idx) in list" 
                    :key="`selec${item}-${idx}`"
                    v-show="idx !== selectedIdx"
                    @click="select(item, idx)"
                >
                    {{$t(item) || item}}
                </li>
            </ul>
        </transition>
    </div>
</template>
<script>
    export default ({
        data() {
            return {
                isOpen: false,
                selectedItem: "",
                selectedIdx: null
            }
        },
        props: {
            index: {
                type: Number,
                required: true
            },
            optionPlaceholder: {
                type: String, 
                required: false
            },
            list: {
                type: Array, 
                required: true
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0,
            },
            popUpMaxHeight: {
                type: Number,
                required: false
            },
            /*
                Adding style to the select btn, need to use camelcase, example:
                "{backgroundColor: 'inherit', color: 'white'}"
            */
            overrideSelectBtn: {
                type: Object,
                required: false,
                default: () => ({})
            }
        },
        computed: {
            showOptionPlaceholder: function() {
                return this.optionPlaceholder !== undefined && this.selectedIdx !== -1
            }
        },
        watch: {
            index: function(){
                this.selectedItem = this.list[this.index]
                this.selectedIdx = this.index
            }
        },
        methods: {
            select(item, idx) {
                this.selectedItem = item
                this.selectedIdx = idx
                this.isOpen = false
                this.$emit('change', idx)
            }
        },
        beforeMount() {
            if(this.index === -1 && this.optionPlaceholder !== undefined) {
                this.selectedItem = this.optionPlaceholder
                this.selectedIdx = this.index
            } else if(this.index !== -1 && this.index > -1) {
                this.selectedItem = this.list[this.index]
                this.selectedIdx = this.index
            } else {
                this.selectedItem = this.list[0]
                this.selectedIdx = 0
                this.$emit('change', 0)
            }
        },
    })
</script>
<style scoped>
.customSelect {
    position: relative;
    height: 50px;
}
.customSelect_btn {
    background-color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0 20px 0 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown{
    list-style: none;
    position: absolute;
    z-index: 5;
    background-color: white;
    width: 105%;
    padding: 20px;
    margin-top: 15px;
    box-shadow: 0px 0px 5px gray;
}
.dropdown > li {
    padding: 10px;
}
.dropdown:after {
    position: absolute;
    content: '';
    left: 20px;
    top: -10px;
    box-shadow: 2px 2px 5px -2px gray;
    width: 20px;
    height: 20px;
    background-color: white;
    transform: rotate(225deg);
}
.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
li:hover {
    background-color: rgb(245, 240, 255);
}
.arrow {
    transform: rotateZ(0deg) translateY(0px);
	transition-duration: 0.3s;
	transition-timing-function: cubic-bezier(.59,1.39,.37,1.01);
}
.reverseArrow {
    transform: rotateZ(180deg) translateY(-2px);
}
</style>