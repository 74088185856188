<template>
  <div>
    <p style="display:none">{{ hidden }}</p>
    <table
      class="table table-bordered table-condensed"
      style="background-color:white; max-width: 500px"
    >
      <thead>
        <tr>
          <th style="text-align:center;">{{ $t('ASSET_CLASSES') }}</th>
          <th style="text-align:center;" class="numeric">
            {{ $t('PERCENT') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(g, name, index) in groupeAssets">
          <tr :style="{ fontWeight: 'bold' }" :key="name + '-' + Math.random()">
            <td style="display:flex; align-items: center;">
              <div
                :style="{
                  width: '5px',
                  alignSelf: 'stretch',
                  backgroundColor: g.color,
                }"
                class="mr-2"
              >
                &nbsp;
              </div>
              <div>
                <i
                  v-if="name != 'Fonds Eur et Monetaire'"
                  style="cursor:pointer; float:left;"
                  :class="{
                    'icon-minus': isShow[name],
                    'icon-plus': !isShow[g],
                  }"
                  v-on="
                    !isShow[name]
                      ? { click: () => showAssets(name, index) }
                      : { click: () => hideAssets(name, index) }
                  "
                />
              </div>
              <div style="width: 100%; text-align: center">
                {{ translateUnivers(name) }}
              </div>
            </td>
            <td>{{ Math.round(g.total[indexProps] * 100) }}</td>
          </tr>
          <tr
            v-show="isShow[name]"
            v-for="(a, n, i) in groupeAssets[name].assets"
            v-if="n in g.assets"
            :key="name + 'assets' + i"
          >
            <td style="text-align:center;">{{ translate(n) }}</td>
            <td style="text-align:center">
              <i
                class="icon-minus"
                style="float:left;cursor:pointer;"
                @mousedown="minusClick(a.base_weights[indexProps], n, name)"
                @mouseup="stopTimeout()"
              ></i>
              {{ Math.round(a.base_weights[indexProps] * 100) }}
              <i
                class="icon-plus"
                style="float:right;cursor:pointer;"
                @mousedown="plusClick(a.base_weights[indexProps], n, name)"
                @mouseup="stopTimeout()"
              ></i>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      groupeAssets: {},
      isShow: {},
      hidden: 0,
      timeout: '',
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true,
    },
    indexProps: {
      type: Number,
      required: false,
    },
    refreshtoken: {
      type: String,
      required: false,
    },
    translateUniversProps: {
      type: Array,
      required: true,
    },
    translateAssetsProps: {
      type: Array,
      required: true,
    },
  },
  watch: {
    dataProps: function() {
      if (Object.keys(this.groupeAssets).length == 0) {
        this.getGroupePf();
      }
    },
    locale: function(value) {
      this.geGroupePf();
    },
    indexProps: function(value) {
      this.getGroupePf();
    },
    refreshtoken: function() {
      this.getGroupePf();
    },
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },
  methods: {
    translate(n) {
      let idx = this.translateAssetsProps.findIndex((e) => e.optim == n);
      return idx != -1 ? this.translateAssetsProps[idx][this.$i18n.locale] : n;
    },
    translateUnivers(n) {
      let idx = this.translateUniversProps.findIndex((e) => e.fr == n);
      return idx != -1 ? this.translateUniversProps[idx][this.$i18n.locale] : n;
    },
    async getGroupePf() {
      this.groupeAssets = this.dataProps;
    },
    getShows() {
      let tmp = {};
      for (const iterator of Object.entries(this.groupeAssets)) {
        tmp[iterator[0]] = false;
      }
      this.isShow = tmp;
    },
    showAssets(e, i) {
      this.isShow[e] = true;
      this.$emit('isShow', true);
    },
    hideAssets(e, i) {
      this.isShow[e] = false;
      if (!this.findValue(this.isShow, true)) this.$emit('isShow', false);
    },
    nametokey(g) {
      return g.name.replace(/\s+/g, '');
    },
    findValue(o, value) {
      for (var prop in o) {
        if (o.hasOwnProperty(prop) && o[prop] === value) {
          return true;
        }
      }
      return false;
    },
    plusClick(value, name, group) {
      let ctx = this;
      let val = parseInt(value * 100);
      let valTreso = parseInt(
        this.groupeAssets['Fonds Eur et Monetaire'].assets['Trésorerie Euro'][
          'base_weights'
        ][this.indexProps] * 100
      );

      this.timeout = setInterval(function() {
        if (val < 100 && valTreso > 0) {
          val += 1;
          valTreso -= 1;

          ctx.groupeAssets[group].assets[name]['base_weights'][ctx.indexProps] =
            val / 100;
          ctx.groupeAssets[group].total[ctx.indexProps] += 1 / 100;

          ctx.groupeAssets['Fonds Eur et Monetaire'].assets['Trésorerie Euro'][
            'base_weights'
          ][ctx.indexProps] = valTreso / 100;
          ctx.groupeAssets['Fonds Eur et Monetaire'].total[ctx.indexProps] -=
            1 / 100;

          //for rerender
          ctx.hidden += 1;

          ctx.$emit('clicked', {
            val: val / 100,
            name: name,
            treso: valTreso / 100,
            index: ctx.indexProps,
            group: group,
            total: ctx.groupeAssets[group].total[ctx.indexProps],
            totalTreso:
              ctx.groupeAssets['Fonds Eur et Monetaire'].total[ctx.indexProps],
          });
        }
      }, 100);
    },
    minusClick(value, name, group) {
      let ctx = this;
      let val = parseInt(value * 100);
      let valTreso = parseInt(
        this.groupeAssets['Fonds Eur et Monetaire'].assets['Trésorerie Euro'][
          'base_weights'
        ][this.indexProps] * 100
      );

      this.timeout = setInterval(function() {
        if (val > 0 && valTreso < 100) {
          val -= 1;
          valTreso += 1;

          ctx.groupeAssets[group].assets[name]['base_weights'][ctx.indexProps] =
            val / 100;
          ctx.groupeAssets[group].total[ctx.indexProps] -= 1 / 100;

          ctx.groupeAssets['Fonds Eur et Monetaire'].assets['Trésorerie Euro'][
            'base_weights'
          ][ctx.indexProps] = valTreso / 100;
          ctx.groupeAssets['Fonds Eur et Monetaire'].total[ctx.indexProps] +=
            1 / 100;

          //for rerender
          ctx.hidden += 1;

          ctx.$emit('clicked', {
            val: val / 100,
            name: name,
            treso: valTreso / 100,
            index: ctx.indexProps,
            group: group,
            total: ctx.groupeAssets[group].total[ctx.indexProps],
            totalTreso:
              ctx.groupeAssets['Fonds Eur et Monetaire'].total[ctx.indexProps],
          });
        }
      }, 100);
    },
    stopTimeout() {
      clearInterval(this.timeout);
    },
  },
  created() {
    this.getGroupePf();
    this.getShows();
  },
};
</script>
