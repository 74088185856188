<template>
  <div>
    <table
      class="table table-bordered table-condensed"
      style="background-color:white; max-width: 500px"
    >
      <thead>
        <tr>
          <th class="text-center">{{ $t('ASSET_CLASSES') }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(g, name, index) in groupeAssets">
          <tr class="fw-bold" :key="name + '-' + Math.random()">
            <td class="d-flex align-items-center" style="cursor: pointer;"
              v-on="
                    !isShow[name]
                      ? { click: () => showAssets(name, index) }
                      : { click: () => hideAssets(name, index) }"
            >
              <div
                :style="{
                  width: '5px',
                  alignSelf: 'stretch',
                  backgroundColor: g.color,
                }"
                class="mr-2"
              >
                &nbsp;
              </div>
              <div class="w-100 text-center">
                {{ translateUnivers(name) }}
              </div>
              <div>                 
                <i :class="[!isShow[name] ? 'fa fa-caret-down' : 'fa fa-caret-up']"></i>
              </div>
            </td>
          </tr>
          <tr
            v-show="isShow[name]"
            v-for="(a, n, i) in groupeAssets[name].assets"
            v-if="n in g.assets"
            :key="name + 'assets' + i"
          >
            <td style="text-align:center;" :style="{backgroundColor: selectedAsset == n ? '#f3f1e0' : ''}">
              <span>{{ translate(n) }}</span>
              <div style="float:right">
                <button :aria-label="$t('ADD_CONSTRAINT')" @click="addContr(n)" class="noDefaultBtnStyle infobulle">
                  <i  :class="[isInConstraint(n) ? 'fa fa-lock' : 'fa fa-unlock']" :style="{color: isInConstraint(n) ? '#ff4040' : '#40c540'}"></i>
                </button>
                <button :aria-label="$t('VIEW_ASSET_DETAILS')" @click="getFunds(name, n)" class="noDefaultBtnStyle infobulle">
                  <i class="fa fa-list-ul"></i>
                </button>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      groupeAssets: [],
      isShow: {},
      timeout: '',
      selectedAsset: '',
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true,
    },
    translateUniversProps: {
      type: Array,
      required: true,
    },
    translateAssetsProps: {
      type: Array,
      required: true,
    },
    assetsContraintes: {
      type: Array,
      default: [],
      required: false
    }
  },
  watch: {
    dataProps: function() {
      this.getGroupePf();
    },
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },
  methods: {
    translate(n) {
      let idx = this.translateAssetsProps.findIndex((e) => e.optim == n);
      return idx != -1 ? this.translateAssetsProps[idx][this.$i18n.locale] : n;
    },
    translateUnivers(n) {
      let idx = this.translateUniversProps.findIndex((e) => e.fr == n);
      return idx != -1 ? this.translateUniversProps[idx][this.$i18n.locale] : n;
    },
    async getGroupePf() {
      let data = await this.$http.get(
        this.baseUrl +
          'users/dashboard/portfolios/portfolios/' +
          localStorage.getItem('priradCompany')
      );
      this.groupeAssets = data.data.resultat[0].contents;
      let tmp = {};
      for (const iterator of Object.entries(this.groupeAssets)) {
        tmp[iterator[0]] = false;
      }
      this.isShow = tmp;
    },
    showAssets(e, i) {
      this.isShow[e] = true;
      this.$emit('isShow', true);
    },
    hideAssets(e, i) {
      this.isShow[e] = false;
      if (!this.findValue(this.isShow, true)) {
        this.selectedAsset = '';
        this.$emit('isShow', false);
      }
    },
    nametokey(g) {
      return g.name.replace(/\s+/g, '');
    },
    findValue(o, value) {
      for (var prop in o) {
        if (o.hasOwnProperty(prop) && o[prop] === value) {
          return true;
        }
      }
      return false;
    },
    getFunds(group, name) {
      this.selectedAsset = name;
      this.$emit('clicked', {
        group: group,
        asset: name,
      });
    },
    addContr(name) {
      this.$emit('add', {
        asset: name,
      });
    },
    isInConstraint(name) {
      if(this.assetsContraintes.length) {
        const exist = this.assetsContraintes.find(el => {
          return el.name == name
        })

        return exist 
      }
    }
  },
  created() {
    this.getGroupePf();
  },
};
</script>
<style scoped>
.infobulle {
  position: relative;
  cursor: pointer;
}

.infobulle:hover::after,
.infobulle:focus::after {
  content: attr(aria-label);
  position: absolute;
  top: -2.4em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  white-space: nowrap;
  background-color: #e8e8e8;
  padding: 5px 10px;
  border-radius: 10px;
}
[aria-label]:hover:before,
[aria-label]:focus:before {
  content: '▼';
  position: absolute;
  top: -1em;
  left: 50%;
  transform: translateX(-50%); /* on centre horizontalement  */
  font-size: 10px;
  color: #707070;
}
[aria-label]:focus {
  outline: none;
}
.noDefaultBtnStyle {
  background-color: inherit;
  border: none;
  margin-right: 10px;
}
</style>