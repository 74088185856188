<template>
  <div>
    <table class="table table-bordered table-condensed table_container center">
      <thead>
        <tr>
          <th>{{ $t('ASSET_CLASSES') }}</th>
          <th>{{ weightAvailable }}/100</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(uniV, uniK) in portfolioContent">
          <tr class="univer" :key="uniK">
            <td class="univer-container" @click="displayUniver(uniK)">
              <div
                class="univer-theme"
                :style="{ backgroundColor: uniV.color }"
              >
                &nbsp;
              </div>
              <div class="univer-name">{{ translateUnivers(uniK) }}</div>
               <div>                 
                <i :class="[!univerShow[uniK] ? 'fa fa-caret-down' : 'fa fa-caret-up']"></i>
              </div>
            </td>
            <td>{{ Math.round(uniV.total[index] * 100) }}</td>
          </tr>
          <template v-if="univerShow[uniK]">
            <tr
              v-for="(assetV, assetK, idx) in uniV.assets"
              :key="assetK + idx"
            >
              <template v-if="isInAssetShow(assetK)">
                <td class="btnContainer d-flex">
                  <div>
                    <button
                      style="line-height:1;"
                      class="btn btn-sm"
                      @click="setAssetToZero(uniK, assetK)"
                    >
                      <i class="fa fa-minus" />
                    </button>
                  </div>

                  <div class="mx-auto">
                    {{ translate(assetK) }}
                  </div>
                </td>
                <td>
                  <i
                    class="icon-minus"
                    @mousedown="weightUpdate(uniK, assetK, assetV, '-')"
                    @mouseup="stopTimeout()"
                    @mouseleave="stopTimeout()"
                    @touchstart="weightUpdate(uniK, assetK, assetV, '-')"
                    @touchend="stopTimeout()"
                    @touchcancel="stopTimeout()"
                    :style="{
                      cursor:
                        Math.round(assetV.base_weights[index] * 100) > 0
                          ? 'pointer'
                          : null,
                    }"
                  />
                  {{ Math.round(assetV.base_weights[index] * 100) }}
                  <i
                    class="icon-plus"
                    @mousedown="weightUpdate(uniK, assetK, assetV, '+')"
                    @mouseup="stopTimeout()"
                    @mouseleave="stopTimeout()"
                    @touchstart="weightUpdate(uniK, assetK, assetV, '+')"
                    @touchend="stopTimeout()"
                    @touchcancel="stopTimeout()"
                    :style="{
                      cursor: weightAvailable < 100 ? 'pointer' : null,
                    }"
                  />
                </td>
              </template>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      univerShow: {},
      timeout: false,
    };
  },
  props: {
    portfolioContent: {
      type: Object,
      required: true,
    },
    weightAvailable: {
      type: Number,
      required: true,
    },
    translatedAssets: {
      type: Array,
      required: true,
    },
    translatedUnivers: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    assetShow: {
      type: Array,
      required: true,
    },
  },
  methods: {
    translate(n) {
      let idx = this.translatedAssets.findIndex((e) => e.optim == n);
      return idx != -1 ? this.translatedAssets[idx][this.$i18n.locale] : n;
    },
    translateUnivers(n) {
      let idx = this.translatedUnivers.findIndex((e) => e.fr == n);
      return idx != -1 ? this.translatedUnivers[idx][this.$i18n.locale] : n;
    },
    displayUniver(uniK) {
      if (!this.univerShow.hasOwnProperty(uniK)) {
        this.univerShow = Object.assign({}, this.univerShow, { [uniK]: false });
      }
      this.univerShow[uniK] = !this.univerShow[uniK];
    },
    weightUpdate(uniK, assetK, assetV, operator) {
      let weight = parseInt(assetV.base_weights[this.index] * 100);
      let weiAvailable = this.weightAvailable;

      this.timeout = setInterval(() => {
        if (operator === '-') {
          if (weight == 0) return;

          weiAvailable -= 1;
          weight -= 1;
        } else {
          if (weiAvailable == 100 || weight >= 100) return;

          weiAvailable += 1;
          weight += 1;
        }
        this.$emit(
          'weightUpd',
          uniK,
          assetK,
          weight / 100,
          operator,
          this.index,
          weiAvailable
        );
      }, 100);
    },
    stopTimeout() {
      if (this.timeout != false) {
        this.$emit('refresh');
      }
      clearInterval(this.timeout);
      this.timeout = false;
    },
    setAssetToZero(uniK, assetK) {
      this.$emit('setAssetToZero', uniK, assetK, this.index);
    },
    isInAssetShow(value) {
      const exist = this.assetShow.findIndex(el => el === value)
      return exist === -1 ? false : true 
    }
  }
};
</script>
<style scoped>
.table_container {
  max-width: 500px;
  text-align: center;
}
.univer {
  font-weight: bold;
}
.univer-theme {
  display: inline-block;
  width: 5px;
  align-self: stretch;
  margin-right: 0.5rem;
}
.univer-name {
  width: 100%;
}
.univer-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn {
  border: none;
  background-color: #fdd5b1;
  color: #707070;
}
.btnContainer .btn {
  opacity: 0;
  display: inline-block;
}
.btnContainer:hover .btn {
  display: inline-block; /* On :hover of div show button */
  opacity: 1;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
</style>
