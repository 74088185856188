<template>
    <fieldset>
        <label :for="name" />
        <input type="range"
            class="custom-slider"
            :style="{ ...cssVars}" 
            :id="name"
            :name="name"
            :min="min" 
            :max="max" 
            :step="step"
            :value="value"
            @input="$emit('input', Number($event.target.value))" 
            @mouseup="handleMouseUp()"/>
    </fieldset>
</template>

<script>
export default {
    name:"SliderInput",
    props:{
        name:{
            type: String,
            required: true
        },
        max: {
            type: Number,
            required: false,
            default: 100
        },
        min: {
            type: Number,
            required: false,
            default: 0
        },
        value: {
            type: [Number, String],
            required: true,
        },
        step: {
            type: Number,
            required: false,
            default: 1
        },
        thumbColor: {
            type: String, 
            required: false,
            default: "#337ab7"
        }
    },
    computed: {
        cssVars() {
            return {
                '--thumb-color': this.thumbColor
            }
        }
    },
    methods:{
        handleMouseUp(){
            this.$emit("handleMouseUp", { name: this.name, data: this.value })
        }
    }
}
</script>

<style scoped>
.custom-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: white;
  outline: none;
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px 1px lightgrey;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--thumb-color);
  border:none;
  cursor: pointer;
  border-radius: 50%;
}

.custom-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--thumb-color);
  cursor: pointer;
  border-radius: 50%;
  border: none;
}
</style>