<template>
    <div>
        <div class="d-flex justify-content-between">
            <h5 class="align-self-center m-0">
                <img src="../../../../../public/img/icons/planet-earth.png" class="icon" alt="earth and environment" />
                {{ $t('ESG') }}
            </h5>
            <div v-if="showESGForm">
                <button
                    v-if="clientHasESGResult()"
                    type="button"
                    class="btn btn-primary mr-2"
                    @click="showESGForm = false"
                >
                    {{ $t("VIEW_ESG_GRAPH") }}
                </button>
                <button
                    type="button"
                    class="btn btn-primary mr-2"
                    @click="preFillData()"
                >
                    {{ $t("DEFAULT_SETTINGS") }}
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="saveESG()"
                >
                    {{ $t('SAVE') }}
                </button>
            </div>
            <div v-else>
                <button
                    v-if="clientHasESGResult()"
                    type="button"
                    class="btn btn-primary mr-2"
                    @click="showESGForm = true"
                >
                    {{ $t("VIEW_ESG_FORM") }}
                </button>
            </div>
        </div>
        <hr v-if="!showESGForm">
        <ESGForm v-if="showESGForm" :esgProps="esg" :showTitle="false" @changeESG="setClientESG"/>
        <ESGGraph v-else :esgRes="client.esgResult" />
    </div>

</template>
<script>
    import { mapActions, mapState } from 'vuex';

    import preFillData from "@/data/v2_defaultNewAccount.json"

    import ESGGraph from '@/components/agent/dashboard/resume/esgGraph.vue'
    import ESGForm from '@/components/agent/dashboard/resume/esgForm.vue'

    export default {
        data(){
            return {
                esg: {},
                showESGForm: true,
            }
        },
        components: {
            ESGGraph,
            ESGForm 
        },
        computed: {
            ...mapState({
                baseUrl: (state) => state.baseUrl,
                client: (state) => state.client_Info,
                clientPF: (state) => state.client_Portfolio
            })
        },
        methods: {
            ...mapActions([
                'setClientInfo'
            ]),
            init(){
                this.esg = this.$_.cloneDeep(this.client.esg)
                if(this.clientHasESGResult())
                    this.showESGForm = false
            },
            clientHasESGResult(){
                if(!this.client.hasOwnProperty("esgResult")) return false
                else if(!Object.keys(this.client.esgResult).length) return false

                return true
            },
            setClientESG(data){
                this.esg = data
            },
            preFillData(){
                this.setClientESG(preFillData.esg)
            },
            getSum(prev, curr) {
                return prev + Number(curr);
            },
            calcNumberOfTokenUsed(){
                return this.esg.introductionQuestion.itemsScores.reduce(this.getSum, 0)
            },
            calcESGGameWeights(){
                return this.esg.esgGame.itemsWeights.reduce(this.getSum, 0)
            },
            saveESG(){
                if(this.calcESGGameWeights() !== 100) {
                    this.$emit("message", "WEIGHT_DISTRIBUTION_ERROR")
                    return
                } 
                this.$emit("saveESG", this.esg)
            }
        },
        beforeMount(){
            this.init()
        }
    }
</script>