<template>
  <div class="lds-dual-ring">
    <p>{{$t('LOADING')}}</p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.lds-dual-ring {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: #36a9e1;
  border-radius: 5px;
  padding: 20px;
  color: white;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-dual-ring:after {
  content: " ";
  display: inline-block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>