<template>
  <div>
    <table
      class="table table-bordered table-condensed text-center bg-white mx-auto"
      style="font-size: 15px;"
    >
      <thead>
        <tr style="background: #f6f4f3">
          <th class="text-center">{{ $t('FUNDS') }}</th>
          <th class="numeric text-center">
            {{ $t('PERCENT') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(group, name) in dataProps">
          <tr :key="name + '-' + Math.random()">
            <td class="d-flex align-items-center" style="cursor: pointer;" @click="isShow[name] = !isShow[name]">
                <div
                  :style="{
                    width: '5px',
                    alignSelf: 'stretch',
                    backgroundColor: group.color,
                  }"
                  class="mr-2"
                >
                  &nbsp;
                </div>
                <div class="w-100 text-center" style="font-weight: bold">
                  {{ translateUnivers(name) }}
                </div>
                <div>                 
                  <i :class="[!isShow[name] ? 'fa fa-caret-down' : 'fa fa-caret-up']"></i>
                </div>
            </td>
            <td>{{ Math.round(group.total[indexProps] * 100) }}</td>
          </tr>
          <template
            v-for="(asset, nameAsset) in dataProps[name].assets"
          >
            <tr
              v-show="isShow[name]"
              v-for="fund in asset.funds"
              :key="fund.ISIN_current"
            >
              <td
                class="tooltipfund"
                style="text-align: center; position: relative"
              >
                <span class="tooltiptext">{{ translate(nameAsset) }}</span>
                {{ fund.Name }}
              </td>
              <td class="text-center">
                <i
                  class="icon-minus user-select-none"
                  style="cursor: pointer"
                  @mousedown="weightUpdate(name, nameAsset, fund, -1)"
                  @mouseup="stopInterval"
                  @mouseout="stopInterval()"
                />
                {{ Math.round(fund.weight * 100) }}
                <i
                  class="icon-plus"
                  style="cursor: pointer"
                  @mousedown="weightUpdate(name, nameAsset, fund, 1)"
                  @mouseup="stopInterval"
                  @mouseout="stopInterval()"
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>
(
<script>
import Vue from 'vue';

export default {
  data() {
    return {
      isShow: {},
      interval: '',
      stillDown: false,
      hashSelected: null
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true,
    },
    translateAssetsProps: {
      type: Array,
      required: true,
    },
    translateUniversProps: {
      type: Array,
      required: true,
    },
    indexProps: {
      type: Number,
      required: false,
    },
    overrideShowProps: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    dataProps: function() {
      this.getShows();
    },
  },
  methods: {
    translateUnivers(n) {
      let idx = this.translateUniversProps.findIndex((e) => e.fr == n);
      return idx != -1 ? this.translateUniversProps[idx][this.$i18n.locale] : n;
    },
    translate(n) {
      let idx = this.translateAssetsProps.findIndex((e) => e.optim == n);
      return idx != -1 ? this.translateAssetsProps[idx][this.$i18n.locale] : n;
    },
    findValue(o, value) {
      for (var prop in o) {
        if (o.hasOwnProperty(prop) && o[prop] === value) {
          return true;
        }
      }
      return false;
    },
    getShows() {
      let tmp = {};
      for (const iterator of Object.entries(this.dataProps)) {
        tmp[iterator[0]] = false;
      }
      this.isShow = tmp;
    },
    weightUpdate(univers, assetName, fund, value) {
      clearInterval(this.interval)
      this.stillDown = true
      let hashSelected = this.hashSelected = Date.now()
      
      let fundIdx = this.dataProps[univers].assets[assetName].funds.findIndex(
        e => e.ISIN === fund.ISIN
      );

      this.$emit('event-fund-edition', {
        index: this.indexProps,
        assetName,
        univers,
        fundIdx,
        fundName: fund.Name,
        value
      })

      setTimeout(() => {
        if(this.stillDown && hashSelected === this.hashSelected){
          this.interval = setInterval(() => {

            this.$emit('event-fund-edition', {
              index: this.indexProps,
              assetName,
              univers,
              fundIdx,
              fundName: fund.Name,
              value
            })
            }, 100);
          }
      }, 500)
    },
    stopInterval() {      
      this.stillDown = false
      this.hashSelected = null
      clearInterval(this.interval);
    },
  },
  beforeMount() {
    this.getShows();
  },
  beforeDestroy() {
    this.stopInterval()
  },
};
</script>

<style scoped>
.tooltipfund .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #0672b9;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltipfund:hover .tooltiptext {
  visibility: visible;
}
</style>
