<template>
  <div>
    <h3 style="text-align: center">{{ $t('CONSTRAINTS') }}</h3>
    <br />
    <div class="col-12 row">
      <div class="col-4" style="text-align: center">
        <h4>{{ $t('LIST_CONSTRAINTS') }}</h4>
        <br />
        <span
          style="cursor: pointer; text-decoration: underline"
          @click="addContraintes()"
          >{{ $t('ADD_NEW_CONSTRAINT') }}</span
        >
        <br />
        <ul>
          <li v-for="(contr, index) in groupeContraintes">
            <i
              class="icon-minus"
              style="cursor: pointer; float: left"
              @click="removeContraintes(index)"
            ></i>

            <span style="cursor: pointer" @click="seeContraintes(index)">{{
              contr.name
            }}</span>
          </li>
        </ul>
      </div>

      <div v-if="!isNew" class="col-4" style="text-align: center">
        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{
            $t('NAME_CONSTRAINT')
          }}</label>

          <div class="col-md-8 input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Nom"
              v-model="groupeContraintes[indexToShow].name"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{
            $t('MINIMUM')
          }}</label>
          <div class="col-md-8 input-group">
            <span class="input-group-addon">
              <i class="fa fa-percent"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="0"
              v-model="groupeContraintes[indexToShow].min"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{
            $t('MAXIMUM')
          }}</label>
          <div class="col-md-8 input-group">
            <span class="input-group-addon">
              <i class="fa fa-percent"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="10"
              v-model="groupeContraintes[indexToShow].max"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{
            $t('MIN_NO_ZERO_WEIGHT')
          }}</label>
          <div class="col-md-8 input-group">
            <span class="input-group-addon">
              <i class="fa fa-percent"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="1"
              v-model="groupeContraintes[indexToShow].minNonZeroWeight"
            />
          </div>
        </div>
        <ul>
          <li
            v-for="ass in $_.orderBy(
              groupeContraintes[indexToShow].assets,
              [],
              ['asc']
            )"
          >
            <i
              class="icon-minus"
              style="cursor: pointer; float: left"
              @click="removeAssets(ass)"
            ></i>
            {{ ass }}
          </li>
        </ul>
      </div>
      <div v-else class="col-4" style="text-align: center">
        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{
            $t('NAME_CONSTRAINT')
          }}</label>

          <div class="col-md-8 input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Nom"
              v-model="newContraintes.name"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{
            $t('MINIMUM')
          }}</label>
          <div class="col-md-8 input-group">
            <span class="input-group-addon">
              <i class="fa fa-percent"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="0"
              v-model="newContraintes.min"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{
            $t('MAXIMUM')
          }}</label>
          <div class="col-md-8 input-group">
            <span class="input-group-addon">
              <i class="fa fa-percent"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="10"
              v-model="newContraintes.max"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="text-input">{{
            $t('MIN_NO_ZERO_WEIGHT')
          }}</label>
          <div class="col-md-8 input-group">
            <span class="input-group-addon">
              <i class="fa fa-percent"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="1"
              v-model="newContraintes.minNonZeroWeight"
            />
          </div>
        </div>
        <ul>
          <li v-for="ass in $_.orderBy(newContraintes.assets, [], ['asc'])">
            <i
              class="icon-minus"
              style="cursor: pointer; float: left"
              @click="removeAssets(ass)"
            ></i>
            {{ ass }}
          </li>
        </ul>
      </div>
      <div class="col-4" style="text-align: center">
        <h4>{{ $t('LIST_ASSET_CLASSES') }}</h4>
        <allAssetsContraintes @clicked="addAssets" />
      </div>
    </div>
    <div class="col-12" style="text-align: center">
      <button
        v-if="isNew"
        class="btn btn-primary btn-sm myNavInfo"
        @click="saveNewContraintes()"
      >
        {{ $t('Add') }}
      </button>
      <button
        v-else
        class="btn btn-primary btn-sm myNavInfo"
        @click="saveChangeContraintes()"
      >
        {{ $t('SAVE') }}
      </button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import AllAssetsContraintes from '@/components/gerant/assets/allAssetsContraintes.vue';
Vue.component('allAssetsContraintes', AllAssetsContraintes);

export default {
  data() {
    return {
      groupeContraintes: [
        {
          assets: [
            'Trésorerie Euro',
            'Actions France - SMids',
            'Actions Internationales',
          ],
          min: 0,
          max: 10,
          minNonZeroWeight: 5,
          name: 'Groupe 1',
        },
        {
          assets: ['Actions UK', 'Actions Espagne', 'Actions Emergents'],
          min: 0,
          max: 10,
          minNonZeroWeight: 1,
          name: 'Groupe 2',
        },
      ],
      indexToShow: 0,
      newContraintes: {
        assets: [],
        min: 0,
        max: 10,
        minNonZeroWeight: 1,
        name: '',
      },
      isNew: false,
    };
  },
  methods: {
    ...mapActions(['setBreadLink', 'setLogged']),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({
          isLog: false,
          role: 0,
        });

        await this.$http.get(this.baseUrl + 'auth/logout');

        localStorage.removeItem('priradToken');
        localStorage.removeItem('priradRole');
        localStorage.removeItem('priradCompany');
        this.$router.push('/login');
      } else {
        let role = this.$getRole();
        if (role == 20) {
          /* DO SOMETHING */
        } else {
          this.setLogged({
            isLog: false,
            role: 0,
          });

          await this.$http.get(this.baseUrl + 'auth/logout');

          localStorage.removeItem('priradToken');
          localStorage.removeItem('priradRole');
          localStorage.removeItem('priradCompany');
          this.$router.push('/login');
        }
      }
    },
    removeContraintes(i) {
      this.groupeContraintes.splice(i, 1);
      this.indexToShow = 0;
    },
    seeContraintes(i) {
      this.indexToShow = i;
      this.isNew = false;
    },
    addContraintes() {
      this.isNew = true;
    },
    saveNewContraintes() {
      this.groupeContraintes.push(this.newContraintes);
      this.isNew = false;
      this.indexToShow = this.groupeContraintes.length - 1;
    },
    addAssets(value) {
      let ctx = this;
      let exist = false;
      if (this.isNew) {
        for (const iterator of ctx.newContraintes.assets) {
          if (value == iterator) {
            exist = true;
            break;
          }
        }
        if (!exist) this.newContraintes.assets.push(value);
      } else {
        for (const iterator of ctx.groupeContraintes[ctx.indexToShow].assets) {
          if (value == iterator) {
            exist = true;
            break;
          }
        }
        if (!exist) this.groupeContraintes[ctx.indexToShow].assets.push(value);
      }
    },
    removeAssets(n) {
      if (!this.isNew) {
        let index = this.groupeContraintes[this.indexToShow].assets.indexOf(n);
        this.groupeContraintes[this.indexToShow].assets.splice(index, 1);
      } else {
        let index = this.newContraintes.assets.indexOf(n);
        this.newContraintes.assets.splice(index, 1);
      }
    },
    saveChangeContraintes() {
      /* SAUVEGARDER LES CHANGEMENTS DANS LES CONTRAINTES
              use : this.indexToShow
              */
    },
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },
  mounted() {
    this.checkLog();
    this.setBreadLink(['Prime Radiant', 'MANAGER', 'CONSTRAINTS']);
  },
};
</script>

<style>
ul {
  list-style-type: none;
}
</style>
