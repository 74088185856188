<template>
  <div class="tab-pane active">
    <div class="modal-body">
      <div>
        <div class="navbar-inner">
          <ul class="nav nav-pills" style="margin-bottom: 15px;">
            <li>
              <span @click="clickLink('Coor')" :class="Coor">
                <i class="fa fa-address-card-o"></i>
                {{$t('CONTACT_INFORMATION')}}
              </span>
            </li>
            <li>
              <span @click="clickLink('Situ')" :class="Situ">
                <i class="fa fa-users"></i>
                {{$t('SITUATION')}}
              </span>
            </li>
            <li>
              <span @click="clickLink('Pat')" :class="Pat">
                <i class="fa fa-eur"></i>
                {{$t('PATRIMONY')}}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="progress">
        <div
          class="progress-bar progress-bar-success"
          role="progressbar"
          :style="styleEtape"
        >{{ $t('STEP_OUT_OF', {etape, etape_total: 3}) }}</div>
      </div>
    </div>
    <div class="tab-content">
      <coordonnees v-if="etape== 1" @clicked="continuerEtape" />
      <situation v-if="etape== 2" @clicked="continuerEtape" />
      <patrimoine v-if="etape== 3" />
    </div>
  </div>
</template>
<script>
import Vue from "vue";

import Coordonnees from "@/components/agent/dashboard/compliance/info/coordonnees.vue";
import Situation from "@/components/agent/dashboard/compliance/info/situation.vue";
import Patrimoine from "@/components/agent/dashboard/compliance/info/patrimoine.vue";

Vue.component("patrimoine", Patrimoine);
Vue.component("situation", Situation);
Vue.component("coordonnees", Coordonnees);

export default {
  data() {
    return {
      Coor: "btn btn-primary btn-sm myNavInfo active",
      Situ: "btn btn-primary btn-sm myNavInfo",
      Pat: "btn btn-primary btn-sm myNavInfo",
      etape: 1,
      styleEtape: "width: 33%;",
    };
  },
  methods: {
    clickLink(val) {
      this.changeEtape(val);
    },
    continuerEtape(val) {
      if (val.next != "") this.changeEtape(val.next);
    },
    changeEtape(val) {
      let styleNo = "btn btn-primary btn-sm myNavInfo";
      let style = "btn btn-primary btn-sm myNavInfo active";

      let arr = ["Coor", "Situ", "Pat"];
      let arrWidth = ["width: 33%;", "width: 66%;", "width: 100%;"];
      let i = 0;
      for (const item of arr) {
        if (item != val) this[item] = styleNo;
        else if (item == val) {
          this[item] = style;
          this.etape = i + 1;
          this.styleEtape = arrWidth[i];
        }
        i++;
      }
    },
  },
};
</script>

<style>
.myNavInfo {
  cursor: pointer;
  margin-right: 5px;
}
</style>