<template>
  <div class="tab-pane fade in active show">
    <div class="well">
      <label>
        <strong>
          <i class="fa fa-eur"></i>
          {{$t('PATRIMONY')}}
        </strong>
      </label>
      <hr />
    </div>
    <form
      action
      method="post"
      enctype="multipart/form-data"
      class="form-horizontal"
      style="font-size: 18px;"
    >
      <div class="form-group row">
        <label class="col-md-6 col-form-label" for="select">{{$t('ANNUAL_INCOME_EUR')}}</label>
        <div class="col-md-6">
          <input
            type="text"
            class="form-control"
            name="txtRevenuBrut"
            id="txtRevenuBrut"
            v-model.lazy="client.info.extra_info.assets.annual_revenue"
          />
        </div>
      </div>
      <div class="form-group row">

        <label class="col-md-6 col-form-label" for="select">{{$t('IS_YOUR_INCOME')}}</label>

        <div class="col-md-6">
          <select
            id="selectRevenus"
            class="form-control"
            v-model="client.info.extra_info.assets.revenue_type"
          >
            <option value="0">{{$t('CHOOSE')}}</option>
            <option
              value="1"
              :selected="client.info.extra_info.assets.revenue_type==1"
            >{{$t('STABLE')}}</option>
            <option
              value="2"
              :selected="client.info.extra_info.assets.revenue_type==2"

            >{{$t('VARIABLE')}}</option>

            <option
              value="3"
              :selected="client.info.extra_info.assets.revenue_type==3"
            >{{$t('VERY_VARIABLE')}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-6 col-form-label" for="select">{{$t('HOW_SEE_BALANCE_EVOLVING')}}</label>
        <div class="col-md-6">
          <select
            id="selectRevenus"
            class="form-control"
            v-model="client.info.extra_info.assets.revenue_type"
          >
            <option value>{{$t('CHOOSE')}}</option>
            <option
              value="0"
              :selected="client.info.extra_info.assets.evolution_5_years==0"
            >{{$t('UNCHANGED')}}</option>
            <option
              value="1"
              :selected="client.info.extra_info.assets.evolution_5_years==1"
            >{{$t('IMPROVEMENT')}}</option>
            <option
              value="-1"
              :selected="client.info.extra_info.assets.evolution_5_years==-1"
            >{{$t('DETERIORATION')}}</option>
          </select>
        </div>
      </div>
      <hr />
      <div class="form-group row">
        <label class="col-md-6 col-form-label" for="text-input">{{$t('ASSESSMENT_RAW_ASSETS_EUR')}}</label>
        <div class="col-md-6">
          <input
            type="text"
            id="txtPatrimoine"
            name="txtPatrimoine"
            class="form-control"
            placeholder="€"
            v-model.lazy="totAssets"
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-md-6 col-form-label"
          for="text-input"
        >{{$t('REGULAR_MONTHLY_CHARGES_EUR')}}</label>
        <div class="col-md-6">
          <input
            type="text"
            id="txtRegul"
            name="txtRegul"
            class="form-control"
            placeholder="€"
            v-model.lazy="client.info.extra_info.assets.monthly_expenses"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-6 col-form-label" for="text-input">{{$t('OVERALL_AMOUNT_LOANS_EUR')}}</label>
        <div class="col-md-6">
          <input
            type="text"
            id="txtRestant"
            name="txtRestant"
            class="form-control"
            placeholder="€"
            v-model.lazy="client.info.extra_info.assets.total_loan"
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions(["setClientInfo"]),
    formatInput(num, space) {
      space = typeof space == "undefined" ? " " : space;
      if (typeof num == "string") {
        num = num.replace(/[^0-9]/g, "");
      }
      if (num == "" && typeof num == "string") return "";
      num = parseInt(num);
      var n = num.toString(),
        p = n.indexOf(".");
      return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, function ($0, i) {
        return p < 0 || i < p ? $0 + space : $0;
      });
    },
    allInput() {
      this.client.info.extra_info.assets.total_loan = this.formatInput(
        this.client.info.extra_info.assets.total_loan
      );
      this.client.info.extra_info.assets.monthly_expenses = this.formatInput(
        this.client.info.extra_info.assets.monthly_expenses
      );
      /* this.client.info.extra_info.assets.total_assets = this.formatInput(
        this.client.info.extra_info.assets.total_assets
      );*/
      this.client.info.extra_info.assets.annual_revenue = this.formatInput(
        this.client.info.extra_info.assets.annual_revenue
      );
    },
    parseToInt() {
      for (const item of Object.entries(this.client.info.extra_info.assets)) {
        if (item[0] == "revenue_type") {
          if (typeof item[1] != "number") {
            if (item[1] == "0") this.client.info.extra_info.assets[item[0]] = 0;
            else
              this.client.info.extra_info.assets[item[0]] = parseInt(item[1]);
          }
        }
      }
    },
    formatInt(x) {
      return Math.round(x)
        .toString()
        .replace(" ", "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  computed: {
    ...mapState({
      client: (state) => state.client_Info,
    }),
    totAssets: {
      get: function () {
        if (isNaN(this.client.info.extra_info.assets.total_assets))
          return this.client.info.extra_info.assets.total_assets;
        else
          return this.formatInt(
            this.client.info.extra_info.assets.total_assets
          );
      },
      set: function (val) {
        this.client.info.extra_info.assets.total_assets = val.replace(" ", "");
      },
    },
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function (state) {
        return state.client_Info;
      },
      function (val) {
        ctx.allInput();
        ctx.parseToInt();
        ctx.setClientInfo(val);
      },
      {
        deep: true,
      }
    );
    this.allInput();
  },
};
</script>