<template>
  <div class="tab-pane active fade show">
    <div class="well">
      <label>
        <strong>
          <i class="fa fa-lock"></i>
          <span v-if="!showContraintes">{{
            $t('LIQUIDITY_CONSTRAINTS_PRECAUTIONARY_SAVINGS')
          }}</span>
          <span v-else>{{
            $t('LIQUIDITY_CONSTRAINTS_PRECAUTIONARY_SAVINGS_SLIDE_2')
          }}</span>
        </strong>
      </label>
      <hr />

      <form
        v-if="!showContraintes"
        class="form-horizontal col-6"
        style="
          font-size: 18px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        "
      >
        <div class="card">
          <div class="card-header">
            <strong>
              <i class="fa fa-unlock-alt" style="margin-right: 10px"></i>
              {{ $t('POTENTIAL_WITHDRAWALS') }}
            </strong>
          </div>
          <div class="card-body">
            <form action method="post" class="form-horizontal">
              <div class="form-group row">
                <label class="col-md-4 col-form-label" for="text-input">{{
                  $t('LESS_THAN_YEARS', { number_year: 2 })
                }}</label>
                <div class="col-md-8 input-group">
                  <span class="input-group-addon">
                    <i class="fa fa-eur"></i>
                  </span>
                  <BaseInputFormatted 
                    _id="txtdeux"
                    _name="txtdeux"
                    class="form-control"
                    v-model="newAccount.contr.contr1"
                    :formatOutsideInput="formatOutsideInput"
                    :formatInsideInput="formatInsideInput" 
                    :insideNewvalue="insideNewvalue"
                    :outsideNewvalue="outsideNewvalue"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </form>
      <ClientPref
        v-if="showContraintes"
	:isFromNewAcc="true"
        @changePreferences="changePreferences"
      />
    </div>
    <div class="text-center mt-4">
      <span
        class="btn btn-primary"
        style="cursor: pointer"
        @click="[!showContraintes ? btnContr() : btnNext()]"
        >{{ $t('CONTINUE') }}</span
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ClientPref from '@/components/agent/dashboard/resume/preferences.vue';
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'

export default {
  data() {
    return {
      showContraintes: false,
      formatInsideInput: /[^\d\.]/g,
      formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      insideNewvalue: '',
      outsideNewvalue: '$1 '
    };
  },
  components: {
    ClientPref,
    BaseInputFormatted
  },
  watch: {
    'newAccount.contr.contr1': function(){
      this.newAccount.contr.createdAt = new Date().toISOString();
    }
      
  },
  computed: {
    ...mapState({
      newAccount: (state) => state.new_Compte,
    })
  },
  methods: {
    ...mapActions(['setNewCompte', 'setNewAccountContraintes']),
    btnContr() {
      this.showContraintes = true;
    },
    changePreferences(pref) {
      this.newAccount.contraintes = pref;
    },
    btnNext() {
      // this.$emit('clicked', {
      //   name: 'Contr',
      //   next: 'Obj',
      // });
      this.$emit("next")
    },
    resetShow() {
      this.showContraintes = false;
    },
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function (state) {
        return state.new_Compte;
      },
      function (val) {
        ctx.setNewCompte(val);
      },
      {
        deep: true,
      }
    );
  },
};
</script>
