<template>
<div>
  <div class="btn_container">
			  <button class="btn" @click="hideContr">
			  	<i class="fa fa-arrow-circle-left" style="margin-right: 5px;"></i>
			  	{{ $t('BACK') }}
			  </button>

			  <button class="btn text-white" style="background-color:#1f97d1;" @click="showGroupContr">
			  	{{$t('GROUP')}}
			  	<i class="fa fa-arrow-circle-right" style="margin-left: 5px;"></i>
			  </button>
		</div>
  <div
    class="row chart-wrapper"
    style="text-align: center; justify-content: center"
  >
    
    <div class="col-10 col-xl-5">
      <h4 class="title">{{ $t('CONSTRAINTS') }}</h4>
      <AssetsContraintes
        :dataProps="assetsContraintes"
        @remove="removeContr"
        @edit="editContr"
        :translateAssetsProps="translateAssetsProps"
        :translateUniversProps="translateUniversProps"
      />
      <FundsContraintes
        :dataProps="lstStockContr"
        :assetsRank="assetsRank"
        :indexPortfolioProps="indexPortfolioProps"
        :translateAssetsProps="translateAssetsProps"
        :translateUniversProps="translateUniversProps"
        title="STOCKS"
        listType="direct"
        :globalContraintes="globalContraintes.direct"
        @handleGlobal="handleGlobal"
        @emit-deleteConstraint="deleteConstraint"
      />
      <FundsContraintes
        :dataProps="lstFundContr"
        :assetsRank="assetsRank"
        :indexPortfolioProps="indexPortfolioProps"
        :translateAssetsProps="translateAssetsProps"
        :translateUniversProps="translateUniversProps"
        title="FUNDS"
        listType="fund"
        :globalContraintes="globalContraintes.fund"
        @handleGlobal="handleGlobal"
        @emit-deleteConstraint="deleteConstraint"
      />
    </div>
    <div class="col-10 col-xl-4">
      <h4 class="title">{{ $t('CLASSES') }}</h4>
      <AssetsFunds
        :dataProps="assetsTreeProps"
        :assetsContraintes="assetsContraintes"
        @clicked="getLstFunds"
        @isShow="fundDisplayHandler"
        @add="addContr"
        :translateAssetsProps="translateAssetsProps"
        :translateUniversProps="translateUniversProps"
      />
    </div>
    <div class="col-10 col-xl-3">
      <h4 class="title">{{ $t('ASSETS_DETAILS') }}</h4>
      <div v-if="!displayedFunds.length" class="bg-white" style="margin-top: 0.5rem; padding: 50px;">
        {{ $t('NO_ASSET_SELECTED') }}
      </div>
      <div v-else>
        <div class="d-flex">
          <span
            :class="['choiceCSS', { active: choice === 'funds' }]"
            @click="choice = 'funds'"
            >{{ $t('FUNDS') }}</span
          >
          <span
            :class="['choiceCSS', { active: choice === 'stocks' }]"
            @click="choice = 'stocks'"
            >{{ $t('STOCKS') }}</span
          >
        </div>
        <DraggableFunds
          v-if="choice === 'funds'"
          :list="displayedFunds"
          :contraintes="fundsContraintes"
          :selected="fundSelected"
          :index="indexPortfolioProps"
          @edit="editMaxMinFunds"
          @selected="clickFund"
          @change="orderChange"
        />
        <DraggableFunds
          v-else
          :list="displayedStocks"
          :contraintes="stocksContraintes"
          :selected="fundSelected"
          :index="indexPortfolioProps"
          @edit="editMaxMinFunds"
          @selected="clickFund"
          @change="orderChange"
          @enable="checkEnable"
        />
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import Vue from 'vue';

import AssetsContraintes from '@/components/gerant/assets/assetscontraintes.vue';
import FundsContraintes from '@/components/gerant/assets/fundsContraintes.vue';
import AssetsFunds from '@/components/gerant/assets/assetsfunds.vue';
import DraggableFunds from '@/components/gerant/assets/draggableFunds.vue';

export default {
  components: {
    AssetsContraintes,
    FundsContraintes,
    AssetsFunds,
    DraggableFunds,
  },
  data() {
    return {
      displayedFunds: [],
      assetsContraintes: [],
      fundsContraintes: {},
      fundSelected: '',
      stocksContraintes: {},
      displayedStocks: [],
      groupPath: '',
      assetPath: '',
      choice: 'funds',
      globalContraintes: {},
      assetsRank: [],
    };
  },
  props: {
    assetsTreeProps: {
      type: Object,
      required: true,
      default: {},
    },
    existingContraintesProps: {
      type: Object,
      required: true,
      default: {},
    },
    translateAssetsProps: {
      type: Object,
      requried: true,
    },
    translateUniversProps: {
      type: Object,
      requried: true,
    },
    indexPortfolioProps: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    lstFundContr() {
      return this.formatList(this.fundsContraintes);
    },
    lstStockContr() {
      return this.formatList(this.stocksContraintes);
    },
  },
  methods: {
    orderChange(list) {
      let orderKey = this.choice === 'funds' ? 'order' : 'stockOrder';
      let fundList =
        this.assetsTreeProps[this.groupPath].assets[this.assetPath].funds;

      this.hasToBeSaved = true;

      if (list.length) {
        // To reset the order preference within each fund of the asset
        if (list[0].hasOwnProperty(orderKey)) {
          list.map(
            (fund, idx) =>
              (fund[orderKey][this.indexPortfolioProps] = list.length - idx)
          );
          for (const item in list) {
            for (const [idx, fund] of Object.entries(fundList)) {
              if (fund.ISIN == item.ISIN) {
                fundList[idx][orderKey] = item[orderKey];
                break;
              }
            }
          }

          this.$emit('changeOrder', {
            list: fundList,
            group: this.groupPath,
            asset: this.assetPath,
            choice: this.choice,
          });

          if (this.choice == 'funds') {
            this.fundsContraintes = Object.assign(
              {},
              this.fundsContraintes,
              this.fundsContraintes
            );
          } else {
            this.stocksContraintes = Object.assign(
              {},
              this.stocksContraintes,
              this.stocksContraintes
            );
          }
        }
      }
    },
    formatList(list) {
      let lst = [];
      for (let item of Object.entries(list)) {
        if (item[1].min != '' || item[1].max != '' || item[1].enable) {
          lst.push(item[1]);
        }
      }
      return lst;
    },
    checkEnable(e, isin) {
      this.stocksContraintes[isin]['enable'] = e.target.checked;
      this.$emit('needSave');
      this.saveContraintes();
    },
    getLstFunds(data) {
      let tmpFundConstraint = {};
      let tmpStockConstraint = {};
      let tmpListFund = [];
      let tmpListStock = [];

      this.groupPath = data.group;
      this.assetPath = data.asset;

      for (let item of this.assetsTreeProps[data.group].assets[data.asset]
        .funds) {
        if (!item.stock) {
          if (!(item.ISIN in this.fundsContraintes)) {
            tmpFundConstraint[item.ISIN] = {
              min: '',
              max: '',
              name: item.Name,
              asset: data.asset,
              order: item.order,
            };
          }
          tmpListFund.push(item);
        } else {
          if (!(item.ISIN in this.stocksContraintes)) {
            tmpStockConstraint[item.ISIN] = {
              min: '',
              max: '',
              name: item.Name,
              asset: data.asset,
              enable: false,
              order: item.stockOrder,
            };
          }
          tmpListStock.push(item);
        }
      }
      this.fundsContraintes = Object.assign(
        {},
        this.fundsContraintes,
        tmpFundConstraint
      );
      this.displayedFunds = tmpListFund.sort(
        (a, b) =>
          b.order[this.indexPortfolioProps] - a.order[this.indexPortfolioProps]
      );
      this.stocksContraintes = Object.assign(
        {},
        this.stocksContraintes,
        tmpStockConstraint
      );
      this.displayedStocks = tmpListStock.sort(
        (a, b) =>
          b.stockOrder[this.indexPortfolioProps] -
          a.stockOrder[this.indexPortfolioProps]
      );
    },
    addContr(data) {
      let idx = this.assetsContraintes.findIndex((e) => e.name == data.asset);
      if (idx == -1) {
        this.assetsContraintes.push({
          name: data.asset,
          min: 0,
          max: 100,
          forced: false,
        });
      }else{
        if(this.assetsContraintes[idx].name !== "Trésorerie Euro"){
          this.assetsContraintes.splice(idx, 1);
        }
      }
        this.$emit('needSave');
        this.saveContraintes();
    },
    fundDisplayHandler(emit) {
      if (!emit) {
        this.fundSelected = '';
        this.displayedFunds = [];
        this.displayedStocks = [];
      }
    },
    editContr(data) {
      if ('min' in data) {
        this.assetsContraintes[data.index].min = data.min;
      }
      if ('max' in data) {
        this.assetsContraintes[data.index].max = data.max;
      }
      this.$emit('needSave')
      this.saveContraintes();
    },
    handleGlobal(maxMin, listType, event) {
      this.globalContraintes[listType][maxMin] = event.target.value;
      this.$emit('needSave');
      this.saveContraintes();
    },
    removeContr(index) {
      this.assetsContraintes.splice(index, 1);
      this.$emit('needSave');
      this.saveContraintes();
    },
    editMaxMinFunds(e, isin, maxMin) {
      if (this.choice === 'funds') {
        this.fundsContraintes[isin][maxMin] = e.target.value;
      } else {
        this.stocksContraintes[isin][maxMin] = e.target.value;
      }

      this.$emit('needSave');
      this.saveContraintes();
    },
    clickFund(element) {
      this.fundSelected = element.ISIN;
    },
    initContraintes() {
      if (Object.keys(this.existingContraintesProps).length > 0) {
        this.assetsContraintes = Object.assign(
          [],
          this.assetsContraintes,
          this.existingContraintesProps.single
        );
        this.fundsContraintes = Object.assign(
          {},
          this.fundsContraintes,
          this.existingContraintesProps.funds.single
        );
        this.stocksContraintes = Object.assign(
          {},
          this.stocksContraintes,
          this.existingContraintesProps.funds.direct
        );
        this.globalContraintes = Object.assign(
          {},
          this.globalContraintes,
          this.existingContraintesProps.funds.global
        );
      } else {
        this.assetsContraintes = [
          { name: 'Trésorerie Euro', min: 0, max: 100, forced: true },
        ];
        this.fundsContraintes = {};
        this.stocksContraintes = {};
        this.globalContraintes = {
          fund: { min: 0, max: 100 },
          direct: { min: 0, max: 100 },
        };
        this.$emit('needSave');
        this.saveContraintes();
      }
    },
    showGroupContr() {
      this.$emit('showGroup');
    },
    hideContr() {
      this.$emit('hideContr');
    },
    saveContraintes() {
      let contr = {
        combi:
          Object.keys(this.existingContraintesProps).length > 0
            ? this.existingContraintesProps.combi
            : [],
        single: this.assetsContraintes,
        funds: {
          combi: [],
          single: this.fundsContraintes,
          direct: this.stocksContraintes,
          global: this.globalContraintes,
        },
      };

      this.$emit('editContr', contr);
    },
    assetOrder() {
      let assetsRank = [];
      for (const univerV of Object.values(this.assetsTreeProps)) {
        for (const assetK of Object.keys(univerV.assets)) {
          assetsRank.push(assetK);
        }
      }

      this.assetsRank = Object.assign([], this.assetsRank, assetsRank);
    },
    deleteConstraint(asset, listType) {

      let list = listType === 'fund' ? this.lstFundContr : this.lstStockContr

      let idx = list.findIndex((e) => e.name == asset.name);

      if(idx !== -1){
        list = list.splice(idx, 1)

        if(listType === 'fund') {
          this.lstFundContr = list

          for (const [isin, constraint] of Object.entries(this.fundsContraintes)) {
            if(constraint.name === asset.name){
              this.fundsContraintes[isin].min = ""
              this.fundsContraintes[isin].max = ""
            }
          }
        } else {
          this.lstStockContr = list

          for (const [isin, constraint] of Object.entries(this.stocksContraintes)) {
            if(constraint.name === asset.name){
              this.stocksContraintes[isin].min = ""
              this.stocksContraintes[isin].max = ""
              this.stocksContraintes[isin].enable = false
            }
          }
        }
      }

    }
  },
  mounted() {
    this.initContraintes();
    this.assetOrder();
  },
};
</script>
<style scoped>
.choiceCSS {
  display: inline-block;
  width: 50%;
  padding: 10px;
  cursor: pointer;
}
.active {
  color: #707070;
  background-color: #e8e8e8;
  font-weight: bold;
}
.btn{
	border: none;
	width: fit-content;
	padding: 10px 20px;
}
.btn_container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}
.title {
  padding: 10px;
  background-color: lightslategray;
  color: white;
}
</style>
