<template>
  <div class="tab-pane active fade show">
    <div class="well">
      <label>
        <strong>
          <i class="fa fa-user"></i>
          {{$t('CLIENT')}}
        </strong>
      </label>
      <hr />
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
          <div class="card">
            <div class="card-header">
              <strong>
                <i class="fa fa-user" style="margin-right: 10px;"></i>
                {{$t('ASSIGN_ACCOUNT')}}
              </strong>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group radio" v-if="data.isNew">
                    <label for="radioNewClient">
                      <input
                        type="radio"
                        id="radioNewClient"
                        name="radioClient1"
                        value="0"
                        v-model="isChecked"
                        :checked="isChecked==0"
                      />
                      {{$t('NEW_CUSTOMER')}}
                    </label>
                  </div>
                  <div v-if="isChecked==0">
                    <label for="txtName" class="labelFormCenter">
                      {{$t('LAST_NAME')}}
                      <input
                        type="text"
                        id="txtName"
                        v-model.lazy="data.client.name"
                        class="form-control"
                      />
                    </label>
                    <label for="txtPrenom" class="labelFormCenter">
                      {{$t('FIRST_NAME')}}
                      <input
                        type="text"
                        id="txtPrenom"
                        v-model.lazy="data.client.prenom"
                        class="form-control"
                      />
                    </label>
                    <label for="txtPhone" class="labelFormCenter">
                      {{$t('PHONE')}}
                      <BaseInputFormatted 
                          _id="txtPhone"
                          class="form-control"
                          v-model="data.client.phone"
                          :formatOutsideInput="formatOutsideInput"
                          :formatInsideInput="formatInsideInput" 
                          :insideNewvalue="insideNewvalue"
                          :outsideNewvalue="outsideNewvalue"
                        />
                    </label>
                    <label for="txtMail" class="labelFormCenter">
                      {{$t('EMAIL')}}
                      <input
                        id="txtMail"
                        type="text"
                        v-model.lazy="data.client.mail"
                        class="form-control"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="text-center">
      <span
        class="btn btn-primary next"
        style="cursor:pointer;"
        @click="btnClick()"
      >{{$t('CONTINUE')}}</span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'

export default {
  components: {
    BaseInputFormatted
  },
  data() {
    return {
      isChecked: 0,
      formatInsideInput: /\s+/g,
      formatOutsideInput: /(.{2})/g,
      insideNewvalue: '',
      outsideNewvalue: '$1 '
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.new_Compte,
    }),
  },
  methods: {
    ...mapActions(["setNewCompte"]),
    btnClick() {
      this.$emit("next")
    },
  }
};
</script>

<style>
.labelFormCenter {
  text-align: center;
}
</style>