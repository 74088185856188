<template>
  <div class="tab-pane fade in active show">
    <div class="well">
      <label>
        <strong>
          <i class="fa fa-users"></i>
          {{$t('FAMILY_SITUATION')}}
        </strong>
      </label>
      <hr />
      <form
        action
        method="post"
        enctype="multipart/form-data"
        class="form-horizontal"
        style="font-size: 18px;"
      >
        <div class="form-group row">
          <label class="col-md-4 col-form-label" for="select">{{$t('MATRIMONIAL_REGIME')}}</label>
          <div class="col-md-8">
            <select
              id="select"
              class="form-control"
              v-model="client.info.extra_info.situation.marital_status"
            >
              <option value="0">{{$t('CHOOSE')}}</option>
              <option
                value="1"
                :selected="client.info.extra_info.situation.marital_status == 1"
              >{{$t('CIVIL_PARTNER')}}</option>
              <option
                value="2"
                :selected="client.info.extra_info.situation.marital_status == 2"
              >{{$t('WEDDING')}}</option>
              <option
                value="3"
                :selected="client.info.extra_info.situation.marital_status == 3"
              >{{$t('COHABITATION')}}</option>
              <option
                value="4"
                :selected="client.info.extra_info.situation.marital_status == 4"
              >{{$t('SINGLE')}}</option>
            </select>
          </div>
        </div>
        <hr />

        <div class="row">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>{{$t('FIRST_NAME')}}</th>
                <th>{{$t('DATE_BIRTH')}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(child,index) in client.info.extra_info.situation.children" :key="index">
                <td scope="row">{{index}}</td>
                <td>{{child.name}}</td>
                <td>{{birth(child.birthday)}}</td>
                <td>
                  <span class="glyphicon glyphicon-minus-sign retireEnfant" aria-hidden="true"></span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row" style="margin-top: -15px">
            <div class="col-4">
              <input
                :placeholder="$t('CHILD_NAME')"
                id="ajoutEnfantNom"
                class="form-control"
                v-model="nomChild"
              />
            </div>
            <div class="col-4">
              <input class="form-control" type="date" v-model="birthdayChild" :id="idProps" />
            </div>
            <div class="col-4">
              <span
                @click="addChild()"
                class="btn btn-primary btn-sm"
                style="cursor:pointer;"
              >{{$t('ADD_CHILD')}}</span>
            </div>
          </div>
        </div>
      </form>
      <hr />
      <label>
        <strong>
          <i class="fa fa-black-tie"></i>
          {{$t('PROFESSIONAL_SITUATION')}}
        </strong>
      </label>
      <hr />
      <div>
        <form
          action
          method="post"
          enctype="multipart/form-data"
          class="form-horizontal"
          style="font-size: 18px;"
        >
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select">{{$t('ACTIVITY')}}</label>
            <div class="col-md-8">
              <select
                id="select"
                class="form-control"
                v-model="client.info.extra_info.situation.activity"
              >
                <option value="0">{{$t('CHOOSE')}}</option>
                <option
                  value="1"
                  :selected="client.info.extra_info.situation.activity==1"
                >{{$t('LIBERAL')}}</option>
                <option
                  value="2"
                  :selected="client.info.extra_info.situation.activity==2"
                >{{$t('EMPLOYEE')}}</option>
                <option
                  value="3"
                  :selected="client.info.extra_info.situation.activity==3"
                >{{$t('INDEPENDENT')}}</option>
                <option
                  value="4"
                  :selected="client.info.extra_info.situation.activity==4"
                >{{$t('RETIREMENT')}}</option>
                <option
                  value="5"
                  :selected="client.info.extra_info.situation.activity==5"
                >{{$t('OTHER')}}</option>
              </select>
            </div>
          </div>

          <hr />
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select">{{$t('GOING_RETITEMENT')}}</label>
            <div class="input-group col-md-8">
              <span class="input-group-addon">
                <i class="fa fa-calendar"><span style="margin-left:10px">{{$t('YEARS')}}</span></i>
              </span>
              <input
                type="text"
                class="form-control"
                id="date"
                v-model="client.info.extra_info.situation.retirement_year"
              />
            </div>
            <div class="col-md-4"></div>
            <small
              class="col-md-8 text-muted"
            >{{$t('ESTIMATED_YEAR')}}</small>
          </div>
          <hr />
        </form>
      </div>
    </div>
    <div class="text-center">
      <span
        class="btn btn-primary next"
        style="cursor:pointer;"
        @click="btnClick()"
      >{{$t('CONTINUE')}}</span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      nomChild: "",
      birthdayChild: "",
    };
  },
  methods: {
    ...mapActions(["setClientInfo"]),
    btnClick() {
      this.$emit("clicked", {
        name: "Situ",
        next: "Pat",
      });
    },
    birth(x) {
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      let current = new Date(x);

      return current.toLocaleDateString("fr-FR", options);
    },
    addChild() {
      let ctx = this;
      this.client.info.extra_info.situation.children.push({
        birthday: ctx.birthdayChild,
        name: ctx.nomChild,
      });
      this.client.info.extra_info.situation.n_children++;
    },
    parseToInt() {
      let myObj;
      for (const item of Object.entries(
        this.client.info.extra_info.situation
      )) {
        if (item[0] == "activity" || item[0] == "marital_status") {
          if (typeof item[1] != "number") {
            if (item[1] == "0")
              this.client.info.extra_info.situation[item[0]] = 0;
            else
              this.client.info.extra_info.situation[item[0]] = parseInt(
                item[1]
              );
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      client: (state) => state.client_Info,
    }),
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function (state) {
        return state.client_Info;
      },
      function (val) {
        ctx.parseToInt();
        ctx.setClientInfo(val);
      },
      {
        deep: true,
      }
    );
  },
};
</script>