<template>
  <div class="tab-pane fade in active show">
    <div class="well">
      <label>
        <strong>
          <i class="fa fa-users"></i>
          {{$t('RISK_TOLERANCE')}}
        </strong>
      </label>
      <hr />
      <div>
        <form
          action
          method="post"
          enctype="multipart/form-data"
          class="form-horizontal"
          style="font-size: 12.5px;"
        >
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('WHAT_WORD_ASSOCIATE_WITH_TERM_RISK')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="Riskradio11">
                  <input
                    type="radio"
                    id="Riskradio11"
                    name="radios1"
                    v-model="client.info.KYC.risk.q1"
                    :checked="client.info.KYC.risk.q1 == 2"
                    value="2"
                  />
                  {{$t('THRILL')}}
                </label>
              </div>
              <div class="radio">
                <label for="Riskradio12">
                  <input
                    type="radio"
                    id="Riskradio12"
                    name="radios1"
                    v-model="client.info.KYC.risk.q1"
                    :checked="client.info.KYC.risk.q1 == 1"
                    value="1"
                  />
                  {{$t('OPPORTUNITY')}}
                </label>
              </div>
              <div class="radio">
                <label for="Riskradios13">
                  <input
                    type="radio"
                    id="Riskradios13"
                    name="radios1"
                    v-model="client.info.KYC.risk.q1"
                    :checked="client.info.KYC.risk.q1 == -1"
                    value="-1"
                  />
                  {{$t('UNCERTAINTY')}}
                </label>
              </div>
              <div class="radio">
                <label for="Riskradio14">
                  <input
                    type="radio"
                    id="Riskradio14"
                    name="radios1"
                    v-model="client.info.KYC.risk.q1"
                    :checked="client.info.KYC.risk.q1 == -2"
                    value="-2"
                  />
                  {{$t('LOSS')}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('WIN_MONEY_CHOOSE_OPTION')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="Riskradio21">
                  <input
                    type="radio"
                    id="Riskradio21"
                    name="radios2"
                    v-model="client.info.KYC.risk.q2"
                    :checked="client.info.KYC.risk.q1 == -1"
                    value="-1"
                  />
                  {{$t('PAY_WITH_CERTAINTY')}}
                </label>
              </div>
              <div class="radio">
                <label for="Riskradio22">
                  <input
                    type="radio"
                    id="Riskradio22"
                    name="radios2"
                    v-model="client.info.KYC.risk.q2"
                    :checked="client.info.KYC.risk.q2 == 1"
                    value="1"
                  />
                  {{$t('ONE_OUT_TWO_CHANCE_RECEIVING')}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('RECEIVED_MONEY_INHERITANCE_CHOOSE_OPTION')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="Riskradio31">
                  <input
                    type="radio"
                    id="Riskradio31"
                    name="radios3"
                    v-model="client.info.KYC.risk.q3"
                    :checked="client.info.KYC.risk.q3 == -1"
                    value="-1"
                  />
                  {{$t('PAY_500_CERTAINTY')}}
                </label>
              </div>
              <div class="radio">
                <label for="Riskradio32">
                  <input
                    type="radio"
                    id="Riskradio32"
                    name="radios3"
                    v-model="client.info.KYC.risk.q3"
                    :checked="client.info.KYC.risk.q3 == 1"
                    value="1"
                  />
                  {{$t('ONE_OUT_TWO_OF_PAYING_TAX_OR_PAYING_NOTHING')}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('MARKET_FLUCTUATES_SIGNIFICANTLY_INVESTEMENT_LOSES_WHAT_DO_YOU_DO')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="radiosFluctue_1">
                  <input
                    type="radio"
                    id="radiosFluctue_1"
                    name="radiosFluctue"
                    v-model="client.info.KYC.risk.q4"
                    :checked="client.info.KYC.risk.q4 == -2"
                    value="-1.5"
                  />

                  {{$t('SELL_EVERYTHING')}}

                </label>
              </div>
              <div class="radio">
                <label for="radiosFluctue_2">
                  <input
                    type="radio"
                    id="radiosFluctue_2"
                    name="radiosFluctue"
                    v-model="client.info.KYC.risk.q4"
                    :checked="client.info.KYC.risk.q4 == -1"
                    value="-0.5"
                  />
                  {{$t('SELL_A_PART')}}
                </label>
              </div>
              <div class="radio">
                <label for="radiosFluctue_3">
                  <input
                    type="radio"
                    id="radiosFluctue_3"
                    name="radiosFluctue"
                    v-model="client.info.KYC.risk.q4"
                    :checked="client.info.KYC.risk.q4 == 1"
                    value="0.5"
                  />
                  {{$t('KEEP_EVERYTHING')}}
                </label>
              </div>

              <div class="radio">
                <label for="radiosFluctue_4">
                  <input
                    type="radio"
                    id="radiosFluctue_4"
                    name="radiosFluctue"
                    v-model="client.info.KYC.risk.q4"
                    :checked="client.info.KYC.risk.q4 == 2"
                    value="1.5"
                  />
                  {{$t('BUY_MORE')}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('YOUR_SHARE_CAPITAL_LOSS_AFTER_ONE_YEAR')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="Riskradio51">
                  <input
                    type="radio"
                    id="Riskradio51"
                    name="radios5"
                    v-model="client.info.KYC.risk.q5"
                    :checked="client.info.KYC.risk.q5 == 1"
                    value="1"
                  />
                  {{$t('READY_TO_GIVE_UP_LITTLE_SECURITY_FOR_MORE_YIELD')}}
                </label>
              </div>
              <div class="radio">
                <label for="Riskradio52">
                  <input
                    type="radio"
                    id="Riskradio52"
                    name="radios5"
                    v-model="client.info.KYC.risk.q5"
                    :checked="client.info.KYC.risk.q5 == 0"
                    value="0"
                  />
                  {{$t("RENT_IS_MORE_IMPORTANT")}}
                  
                </label>
              </div>
              <div class="radio">
                <label for="Riskradio53">
                  <input
                    type="radio"
                    id="Riskradio53"
                    name="radios5"
                    v-model="client.info.KYC.risk.q5"
                    :checked="client.info.KYC.risk.q5 == -1"
                    value="-1"
                  />
                  {{$t('BITTERNESS_DIFFICULT_ACCEPT_SITUATION')}}
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="text-center">
        <span
          class="btn btn-primary next"
          style="cursor:pointer;"
          @click="btnClick()"
        >{{$t('CONTINUE')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions(["setClientInfo"]),
    btnClick() {
      this.$emit("clicked", {
        name: "Risk",
        next: "Obj",
      });
    },
    parseToInt() {
      let myObj;
      for (const item of Object.entries(this.client.info.KYC.risk)) {
        if (typeof item[1] != "number") {
          if (item[1] == "0") this.client.info.KYC.risk[item[0]] = 0;
          else this.client.info.KYC.risk[item[0]] = parseInt(item[1]);
        }
      }
    },
  },
  computed: {
    ...mapState({
      client: (state) => state.client_Info,
    }),
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function (state) {
        return state.client_Info;
      },
      function (val) {
        ctx.parseToInt();
        ctx.setClientInfo(val);
      },
      {
        deep: true,
      }
    );
    if (this.$_.isEmpty(this.client.info.KYC.risk)) {
      this.client.info.KYC.risk = {};
    }
  },
};
</script>
