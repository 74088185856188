<template>
  <div class="tab-pane active fade show">
    <div class="well">
      <label>
        <strong>
          <i class="fa fa-list-ol"></i>
          {{$t('SUMMARY_OF_YOUR_PROJECT')}}
        </strong>
      </label>
      <hr />
      <form
        action
        method="post"
        enctype="multipart/form-data"
        class="form-horizontal"
        style="font-size: 18px;"
      >
        <div class="row" style="justify-content: center">
          <div class="col-7">
            <div class="card">
              <div class="card-header">
                <strong>
                  <i class="fa fa-cog" style="margin-right: 10px;"></i>
                  {{$t('YOUR_SETTINGS')}}
                </strong>
              </div>
              <div class="card-body">
                <form action>
                  <table class="table table-bordered table-hover table-condensed">
                    <thead>
                      <tr>
                        <th>{{$t('YOUR_SAVINGS')}}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{$t('INITIAL_CONTRIBUTION')}}</td>
                        <td id="tdApportInit">{{formatForDisplay(data.apports.initial)}}</td>
                      </tr>
                      <tr>
                        <td>{{$t('ANTICIPATED_CONTRIBUTIONS_ANNUAL')}}</td>
                        <td id="tdApportMen">{{formatForDisplay(data.apports.VersmtPeriodik)}}</td>
                        <!-- <td id="tdApportMen">{{ formatForDisplay(data.infos.account.estimatedAnnualContribution) }}</td> -->
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered table-hover table-condensed">
                    <thead>
                      <tr>
                        <th>{{$t('CONSTRAINTS')}}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{$t('WITHDRAWALS_WITHIN_YEARS', {year: 2})}}</td>
                        <td id="tdRetrait02">{{formatForDisplay(data.contr.contr1)}} €</td>
                      </tr>
                      <tr>
                        <td>{{$t('WITHDRAWALS_BETWEEN_YEARS', {begining_year: 2, ending_year: 5})}}</td>
                        <td id="tdRetrait25">{{formatForDisplay(data.contr.contr2)}} €</td>
                      </tr>
                      <tr>
                        <td>{{$t('WITHDRAWALS_BETWEEN_YEARS', {begining_year: 5, ending_year: 10})}}</td>
                        <td id="tdRetrait510">{{formatForDisplay(data.contr.contr3)}} €</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered table-hover table-condensed">
                    <thead>
                      <tr>
                        <th>{{$t('GOALS')}}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody id="tbodyPrecau" v-if="data.objectifs.precaution.isCheck">
                      <tr>
                        <td>
                          <i class="fa fa-university"></i>
                          <strong>{{$t('PRECAUTIONARY_SAVINGS')}}</strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>{{$t('AMOUNT')}}</td>
                        <td
                          id="tdMonPrecau"
                          v-if="data.objectifs.precaution.amount"
                        >{{data.objectifs.precaution.amount}} €</td>
                        <td id="tdMonPrecau" v-else>{{$t('NOT_SPECIFIED')}}</td>
                      </tr>
                    </tbody>
                    <tbody id="tbodyTransmi" v-if="data.objectifs.transmi.isCheck">
                      <tr>
                        <td>
                          <i class="fa fa-balance-scale"></i>
                          <strong>
                            {{$t('PROMOTION_TRANSMISSION')}}
                            <span
                              v-if="data.objectifs.transmi.name"
                            >- {{data.objectifs.transmi.name}}</span>
                          </strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>{{$t('AMOUNT')}}</td>
                        <td
                          id="tdMonTransmi"
                          v-if="data.objectifs.transmi.amount"
                        >{{formatForDisplay(data.objectifs.transmi.amount)}} €</td>
                        <td id="tdMonTransmi" v-else>{{$t('NOT_SPECIFIED')}}</td>
                      </tr>
                    </tbody>
                    <tbody
                      v-if="data.objectifs.immo.isCheck"
                      v-for="(item,index) in data.objectifs.immo.data"
                      :id="'tbodyImmo_'+index"
                      :key="index"
                    >
                      <tr>
                        <td>
                          <i class="fa fa-home"></i>
                          <strong>{{$t('PROPERTY_INVESTMENT')}} - {{item.name}}</strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>{{$t('AMOUNT')}}</td>
                        <td :id="'tdMonImmoRes_'+index">{{formatForDisplay(item.value)}} €</td>
                      </tr>
                    </tbody>
                    <tbody id="tbodyRetraite" v-if="data.objectifs.retraite.isCheck">
                      <tr>
                        <td>
                          <i class="fa fa-home"></i>
                          <strong>{{$t('RETIREMENT')}}</strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>{{$t('AMOUNT')}}</td>
                        <td id="tdMonRetraiteRes">{{formatForDisplay(data.objectifs.retraite.rent)}} €</td>
                      </tr>
                    </tbody>
                    <tbody id="tbodyRetraite" v-if="data.objectifs.school.isCheck">
                      <tr>
                        <td>
                          <i class="fa fa-graduation-cap"></i>
                          <strong>{{$t('CHILDEN_STUDIES')}}</strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>{{$t('AMOUNT')}}</td>
                        <td id="tdMonRetraiteRes">{{formatForDisplay(data.objectifs.school.data[0].expenses)}} €</td>
                      </tr>
                    </tbody>
                    <tbody id="tbodyRetraite" v-if="data.objectifs.autre.isCheck">
                      <tr>
                        <td>
                          <i class="fa fa-heart"></i>
                          <strong>
                            {{$t('OTHER_PROJECT')}}
                            <span
                              v-if="data.objectifs.autre.name"
                            >- {{data.objectifs.autre.name}}</span>
                          </strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>{{$t('AMOUNT')}}</td>
                        <td id="tdMonRetraiteRes">{{formatForDisplay(data.objectifs.autre.amount)}} €</td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <hr />
    <div class="text-center">
      <span
        class="btn btn-primary next"
        style="cursor:pointer;"
        @click="btnClick()"
      >{{$t('CONTINUE')}}</span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      data: (state) => state.new_Compte,
    })
  },
  methods: {
    btnClick() {
      // next: "KYC",
      // this.$emit("clicked", {
      //   name: "Res",
      //   next: "Visu",
      // });
      this.$emit("next")
    },
    formatForDisplay(value){
      value.replace(/[^\d\.]/g, '')
      return value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1 ')
    }
  },
};
</script>