<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">

                            <button type="button" class="close" @click="hideModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="card-body">
                                        <p style="text-align:center;" v-for="(value,index) in dataProps[0]" :key="index" v-if="dataProps[1][index]>0" >{{value}}: {{dataProps[1][index]}} % </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true
    }
  },
  methods: {
    hideModal() {
      this.$emit("clicked");
    }
  }
};
</script>
<style>

</style>