<template>
  <div>
    <div id="ExpectedGraph1"></div>
    <div v-if="isShowProps" class="row adv_option">
      <div class="col-6" style="float:left;">
        <h4>{{$t('PRIIPS')}}</h4>
        <ul class="pagination" id="PRIIPS">
          <li :class="{'':data.priips != 1, 'active':data.priips == 1}">
            <a href="#">1</a>
          </li>
          <li :class="{'':data.priips != 2, 'active':data.priips == 2}">
            <a href="#">2</a>
          </li>
          <li :class="{'':data.priips != 3, 'active':data.priips == 3}">
            <a href="#">3</a>
          </li>
          <li :class="{'':data.priips != 4, 'active':data.priips == 4}">
            <a href="#">4</a>
          </li>
          <li :class="{'':data.priips != 5, 'active':data.priips == 5}">
            <a href="#">5</a>
          </li>
          <li :class="{'':data.priips != 6, 'active':data.priips == 6}">
            <a href="#">6</a>
          </li>
          <li :class="{'':data.priips != 7, 'active':data.priips == 7}">
            <a href="#">7</a>
          </li>
        </ul>
      </div>
      <div class="col-6" style="float:right;">
        <h4>{{$t('SRRI')}}</h4>
        <ul class="pagination" id="SRRI">
          <li :class="{'':data.srri != 1, 'active':data.srri == 1}">
            <a href="#">1</a>
          </li>
          <li :class="{'':data.srri != 2, 'active':data.srri == 2}">
            <a href="#">2</a>
          </li>
          <li :class="{'':data.srri != 3, 'active':data.srri == 3}">
            <a href="#">3</a>
          </li>
          <li :class="{'':data.srri != 4, 'active':data.srri == 4}">
            <a href="#">4</a>
          </li>
          <li :class="{'':data.srri != 5, 'active':data.srri == 5}">
            <a href="#">5</a>
          </li>
          <li :class="{'':data.srri != 6, 'active':data.srri == 6}">
            <a href="#">6</a>
          </li>
          <li :class="{'':data.srri != 7, 'active':data.srri == 7}">
            <a href="#">7</a>
          </li>
        </ul>
      </div>
      <div class="row adv_option">
        <div class="col-12" style="margin-top: 15px;">
          <p class="alert alert-warning">
            {{$t('YOU_HAVE')}}
            <b>
              <span id="accProbMore">{{data.riskacc.more}}</span>%
            </b>
            {{$t('PROBABILITY_HAVING_LEAST_CUMULATIVE_PAYMENTS')}}.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExpectationsGraph from "@/assets/js/graph/edited_graph_builder.js";
//import ExpectationsGraph from "@/assets/js/graph/graph_builder.js";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      myGraph: {},
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.simulation,
    }),
      locale() {
	    return this.$i18n.locale;
	},
  },
  methods: {
    ...mapActions(["setSimulation"]),
  },
  props: {
    dataProps: {
      type: Object,
      required: true,
    },
    isShowProps: {
      type: Boolean,
      required: true,
      default: false,
    },
    neededRefresh: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    neededRefresh: function (val) {
      if (val == true) {
        let ctx = this;
        this.myGraph.ComputeData(this.dataProps, function (stats) {
          if ("error" in stats) {
            alert("Projet trop ambitieux ou données incomplètes");
            ctx.$emit("ambitious");
          }
          ctx.setSimulation(stats);
          ctx.$emit("clicked", ctx.myGraph);
        });
        this.$emit("done");
      }
    },
      locale: function(){
	  let file = this.$i18n.locale === "en" ? "/js/en-EN.json" : "/js/fr-FR.json";
	  this.myGraph.setLocales(file)
      }
  },
  mounted() {
    let ctx = this;
    const filePath =
      this.$i18n.locale === "en" ? "/js/en-EN.json" : "/js/fr-FR.json";
    this.myGraph = new ExpectationsGraph("#ExpectedGraph1", filePath);
    this.myGraph.ComputeData(this.dataProps, function (stats) {
      if ("error" in stats) {
        alert("Projet trop ambitieux ou données incomplètes");
        ctx.$emit("ambitious");
      }
      ctx.setSimulation(stats);
      ctx.$emit("clicked", ctx.myGraph);
    });
  },
};
</script>
