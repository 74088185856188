<template>
  <div>
    <div id="sequence"></div>
    <div :id="'donutchart_' + guid" style="position: relative">
      <div id="explanation" style="visibility: hidden">
        <span id="percentage"></span>
        <br />
        <span id="nameAssets"></span>
      </div>
    </div>
    <div v-if="showThirdLayoutBtn" class="d-flex checkbox-full-btn" style="margin-top: 20px">
      <input
        :id="'thirdLayout_' + guid"
        type="checkbox"
        v-model="showThirdLayout"
      />
      <label :for="'thirdLayout_' + guid" style="margin-bottom: 0">
        {{ $t('SHOW_FUNDS') }}
      </label>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import * as d3 from 'd3';
export default {
  data() {
    return {
      guid: '',
      groupeAssets: [],
      radius: 0,
      totalSize: 0,
      vis: '',
      arc: '',
      partition: '',
      b: {
        w: 175,
        h: 30,
        s: 3,
        t: 10,
      },
      showThirdLayout: false,
      textmultiplier: 9,
    };
  },
  props: {
    dataProps: {
      type: Object,
      required: true,
    },
    heightProps: {
      type: Number,
      required: false,
      default: 600,
    },
    widthProps: {
      type: Number,
      required: false,
      default: 750,
    },
    indexProps: {
      type: Number,
      required: false,
    },
    refreshtoken: {
      type: String,
      required: false,
    },
    onlyBaseProps: {
      type: Boolean,
      required: false,
      default: false,
    },
    translateAssetsProps: {
      type: Array,
      required: true,
    },
    translateUniversProps: {
      type: Array,
      required: true,
    },
    heightProps: {
      type: Number,
      required: false,
      default: 600,
    },
    showThirdLayoutBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    forceShowThirdLayout: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),

    locale() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    this.showThirdLayout = this.forceShowThirdLayout;
    this.guid = this.guidGenerator();
    this.$nextTick(function () {
      if (Object.keys(this.dataProps) != 0) {
        this.initChart();
        this.initializeBreadcrumbTrail();
      }
    });
  },
  watch: {
    forceShowThirdLayout: function() {
      this.showThirdLayout = this.forceShowThirdLayout
    },
    showThirdLayout: function () {
      this.$emit('checkedFunds', this.showThirdLayout);
      this.initChart();
    },
    dataProps: function (value) {
      this.initChart();
    },
    locale: function (value) {
      this.initChart();
    },
    refreshtoken: function () {
      this.initChart();
    },
    translateUniversProps: function () {
      this.initChart();
    },
    translateAssetsProps: function () {
      this.initChart();
    },
  },
  methods: {
    guidGenerator() {
      let S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return (
        S4() +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        S4() +
        S4()
      );
    },
    createEmptyChild(total) {
      let obj = {
        name: this.$i18n.t('EMPTY'),
        type: 0,
        color: '#E8E8E8',
        children: [
          {
            name: this.$i18n.t('EMPTY'),
            size: 1 - total,
            type: 1,
            color: '#E8E8E8',
          },
        ],
      };
      return obj;
    },
    formatDonut() {
      let ctx = this;
      let myArr = [];
      let total = null;
      for (const iterator of Object.entries(ctx.dataProps)) {
        let myObj = {};
        let idxu = this.translateUniversProps.findIndex(
          (e) => e.fr == iterator[0]
        );
        myObj['name'] =
          idxu != -1
            ? this.translateUniversProps[idxu][this.$i18n.locale]
            : iterator[0];
        myObj['type'] = 0;
        myObj['color'] = iterator[1].color;
        let miniArr = [];
        if (iterator[1].total) {
          total += iterator[1].total[this.indexProps];
        }
        for (const it of Object.entries(iterator[1].assets)) {
          let miniArrObj = {};
          let size = 0;
          if (it[1].adjusted_weights != null && !ctx.onlyBaseProps) {
            size = it[1].adjusted_weights[ctx.indexProps];
          } else if (it[1].base_weights != null) {
            size = it[1].base_weights[ctx.indexProps];
          } else {
            size = it[1].weight;
          }
          if (ctx.showThirdLayout) {
            let funds = [];
            for (const fund of it[1].funds) {
              funds.push({
                name: fund.Name,
                size: fund.weight,
                color: ctx.increase_brightness(iterator[1].color, 70),
              });
            }
            miniArrObj['children'] = funds;
          }
          let idx = this.translateAssetsProps.findIndex(
            (e) => e.optim == it[0]
          );

          miniArrObj['name'] =
            idx != -1
              ? this.translateAssetsProps[idx][this.$i18n.locale]
              : it[0];
          miniArrObj['size'] = size;
          miniArrObj['type'] = 1;
          miniArrObj['color'] = ctx.increase_brightness(iterator[1].color, 50);
          miniArr.push(miniArrObj);
        }

        myObj['children'] = miniArr;
        myArr.push(myObj);
      }

      if (total !== null) {
        myArr.push(this.createEmptyChild(total));
      }

      return {
        name: 'portfolio',
        children: myArr,
      };
    },

    initChart() {
      let doc = this.$el.querySelector('#donutchart_' + this.guid);

      this.$el.querySelector(
        '#donutchart_' + this.guid
      ).innerHTML = `<div id="explanation" style="visibility: hidden;">

        <span id="percentage"></span>
        <br />
        <span id="nameAssets"></span>
  		</div>
		  `;

      let json = this.formatDonut();
      let ctx = this;
      ctx.radius = Math.min(ctx.widthProps, ctx.heightProps) / 2;

      this.vis = d3
        .select(ctx.$el.querySelector('#donutchart_' + ctx.guid))
        .append('svg:svg')
        .attr('display', 'block')
        .attr('margin', 'auto')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', '0 0 ' + ctx.widthProps + ' ' + ctx.heightProps)
        .append('svg:g')
        .attr('id', 'container')
        .attr(
          'transform',
          'translate(' + ctx.widthProps / 2 + ',' + ctx.heightProps / 2 + ')'
        );

      this.partition = d3
        .partition()
        .size([2 * Math.PI, ctx.radius * ctx.radius]);

      this.arc = d3
        .arc()
        .startAngle(function (d) {
          return d.x0;
        })
        .endAngle(function (d) {
          return d.x1;
        })
        .innerRadius(function (d) {
          return Math.sqrt(d.y0);
        })
        .outerRadius(function (d) {
          return Math.sqrt(d.y1);
        });

      this.createVisualisation(json);
    },
    createVisualisation(json) {
      let ctx = this;

      // Basic setup of page elements.
      //this.initializeBreadcrumbTrail();

      // Bounding circle underneath the sunburst, to make it easier to detect
      // when the mouse leaves the parent g.
      ctx.vis.append('svg:circle').attr('r', ctx.radius).style('opacity', 0);

      // Turn the data into a d3 hierarchy and calculate the sums.
      let root = d3.hierarchy(json).sum(function (d) {
        let size = d.size;
        if ('children' in d) {
          size = 0;
        }
        return size;
      });
      /*.sort(function (a, b) {
		    return b.value - a.value;
		})*/ // For efficiency, filter nodes to keep only those large enough to see.
      let nodes = ctx
        .partition(root)
        .descendants()
        .filter(function (d) {
          return d.x1 - d.x0 > 0.005; // 0.005 radians = 0.29 degrees
        });

      let path = ctx.vis
        .selectAll('path')
        .data(nodes)
        .enter()
        .append('path')
        .attr('display', function (d) {
          return d.depth ? null : 'none';
        })
        .attr('d', ctx.arc)
        .attr('fill-rule', 'evenodd')
        .style('fill', function (d) {
          return d.data.color;
        })
        .style('opacity', 1)
        .style('stroke', '#fff')
        .on('mouseover', ctx.mouseover);

      // Add the mouseleave handler to the bounding circle.
      d3.select(ctx.$el.querySelector('#container')).on(
        'mouseleave',
        ctx.mouseleave
      );

      // Get total size of the tree = value of root node from partition.
      ctx.totalSize = path.datum().value;
    },
    mouseover(d) {
      let ctx = this;
      let name = d.data.name;
      let percentage = ((100 * d.value) / ctx.totalSize).toPrecision(3);
      percentage = Math.round(percentage);
      let percentageString = percentage + '%';
      if (percentage < 0.1) {
        percentageString = '< 0.1%';
      }

      d3.select(ctx.$el.querySelector('#percentage'))
        .text(percentageString)
        .style('color', 'black')
        .style('word-break', 'unset');
      d3.select(ctx.$el.querySelector('#nameAssets')).text(name);
      let textsize, explanationwidth;
      if (this.showThirdLayout) {
        let num = this.widthProps >= 700 ? 19 : 17;

        textsize = Math.min(num, num - name.length / 8);
        explanationwidth = 100;
      } else {
        textsize = Math.min(18, 18 - name.length / 5);
        explanationwidth = 140;
      }

      d3.select(ctx.$el.querySelector('#nameAssets'))
        .style('font-size', textsize + 'pt')
        .style('color', 'black');
      d3.select(ctx.$el.querySelector('#explanation'))
        .style('visibility', '')
        .style('width', explanationwidth + 'px');

      let sequenceArray = d.ancestors().reverse();
      sequenceArray.shift(); // remove root node from the array
      this.updateBreadcrumbs(sequenceArray, percentageString);

      // Fade all the segments.
      ctx.vis.selectAll('path').style('opacity', 0.3);

      // Then highlight only those that are an ancestor of the current segment.
      ctx.vis
        .selectAll('path')
        .filter(function (node) {
          return sequenceArray.indexOf(node) >= 0;
        })
        .style('opacity', 1);
    },
    mouseleave(d) {
      let ctx = this;
      // Hide the breadcrumb trail
      d3.select(ctx.$el.querySelector('#trail')).style('visibility', 'hidden');
      d3.select(ctx.$el.querySelector('#explanation')).style(
        'visibility',
        'hidden'
      );

      // Deactivate all segments during transition.
      // d3.selectAll("path").on("mouseover", null);

      // Transition each segment to full opacity and then reactivate it.
      ctx.vis.selectAll('path').style('opacity', 1);
    },
    initializeBreadcrumbTrail() {
      let ctx = this;
      // Add the svg area.
      var trail = d3
        .select(ctx.$el.querySelector('#sequence'))
        .append('svg:svg')
        .attr('viewBox', '0 0 ' + ctx.widthProps + ' ' + 50)
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('display', 'block')
        .attr('id', 'trail')
        .attr('font-size', 10);
      // Add the label at the end, for the percentage.
      trail.append('svg:text').attr('id', 'endlabel').style('fill', '#000');
    },
    updateBreadcrumbs(nodeArray, percentageString) {
      let ctx = this;

      // Data join; key function combines name and depth (= position in sequence).
      var trail = d3
        .select(ctx.$el.querySelector('#trail'))
        .selectAll('g')
        .data(nodeArray, function (d) {
          return d.data.name + d.depth;
        });

      // Remove exiting nodes.
      trail.exit().remove();

      // Add breadcrumb and label for entering nodes.
      var entering = trail.enter().append('svg:g');

      entering
        .append('svg:polygon')
        .attr('points', ctx.breadcrumbPoints)
        .style('fill', function (d) {
          return d.data.color;
        });

      entering
        .append('svg:text')
        /* .attr("x", (ctx.b.w + ctx.b.t) / 2) */
        .attr('x', (d) => {
          let width = d.data.name.length * this.textmultiplier;
          return (width + ctx.b.t) / 2;
        })
        /* (ctx.b.w + ctx.b.t) / 2 */
        .attr('y', ctx.b.h / 2)
        .attr('dy', '0.35em')
        .attr('text-anchor', 'middle')
        .text(function (d) {
          return d.data.name;
        })
        .attr('font-size', '11pt');

      // Merge enter and update selections; set position for all nodes.
      function getsize(d, l) {
        l.push(d.data.name.length);
        if (d.parent != null) {
          return getsize(d.parent, l);
        }
        return l;
      }

      entering.merge(trail).attr('transform', function (d, i) {
        let l = getsize(d, []);

        let width = l
          .slice(1, -1)
          .reduce((a, b) => a + b * ctx.textmultiplier + ctx.b.s, 0);
        return 'translate(' + width + ', 0)';
      });

      // Make the breadcrumb trail visible, if it's hidden.
      d3.select(ctx.$el.querySelector('#trail')).style('visibility', '');
    },
    breadcrumbPoints(d, i) {
      let ctx = this;
      var points = [];
      let width = d.data.name.length * this.textmultiplier;
      points.push('0,0');
      points.push(width + ',0');
      points.push(width + ctx.b.t + ',' + ctx.b.h / 2);
      points.push(width + ',' + ctx.b.h);
      points.push('0,' + ctx.b.h);
      if (i > 0) {
        // Leftmost breadcrumb; don't include 6th vertex.
        points.push(ctx.b.t + ',' + ctx.b.h / 2);
      }
      return points.join(' ');
    },
    increase_brightness(hex, percent) {
      // strip the leading # if it's there
      hex = hex.replace(/^\s*#|\s*$/g, '');

      // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
      if (hex.length == 3) {
        hex = hex.replace(/(.)/g, '$1$1');
      }

      var r = parseInt(hex.substr(0, 2), 16),
        g = parseInt(hex.substr(2, 2), 16),
        b = parseInt(hex.substr(4, 2), 16);

      return (
        '#' +
        (0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
          .toString(16)
          .substr(1) +
        (0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
          .toString(16)
          .substr(1) +
        (0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
          .toString(16)
          .substr(1)
      );
    },
  },
};
</script>

<style>
#donutchart {
  position: relative;
}

#donutchart path {
  stroke: #fff;
}

#sequence {
  height: 70px;
}

#sequence text,
#legend text {
  font-weight: 600;
  fill: black;
}

#explanation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 140px;
  text-align: center;
  color: #666;
}

#percentage {
  font-size: 20pt;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #666;
}

#nameAssets {
  font-size: 14pt;
  font-family: 'Open Sans', sans-serif;
  color: #666;
  line-height: 1;
  display: block;
}
</style>
