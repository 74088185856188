<template>
  <div>
    <div class="card-header">
      <i class="icon-user"></i>
      {{ $t('CUSTOMER_CARD') }}
      <router-link :to="'/clients'" class="btn btn-primary btn-sm float-right">
        <i class="fa fa-caret-left"></i>
        <i class="icon-people"></i>
        {{ $t('LIST_CLIENT') }}
      </router-link>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <div class="col-6 infoFont">
            <strong>{{ name }}</strong>
            <p>{{ birth }}</p>
            {{ $t('SITUATION_' + situation) }} -
            <span v-if="client.info.extra_info.situation.n_children != 0"
              >{{ client.info.extra_info.situation.n_children }}
              {{ $t('CHILDREN') }}</span
            >
            <span v-else>{{ $t('NO_CHILDREN') }}</span>
            <br />
            <br />
            {{ client.address }}
            <br />
            {{ telephone }}
            <br />
            <a :href="'mailto:' + client.email">{{ client.email }}</a>
            <br />
            <br />
          </div>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-sm-6 col-lg-4">
              <div class>
                <div class="clearfix">
                  <i class="fa fa-eur bg-info p-3 font-xl mr-3 float-left"></i>
                  <h6
                    class="h6 text-primary mt-2 mb-0"
                    style="padding-top: 10px"
                  >
                    {{ patrimoine }} €
                  </h6>
                  <div
                    class="text-muted text-uppercase font-weight-bold"
                    style="margin-top: 2px; font-size: 11px"
                  >
                    {{ $t('FINANCIAL_NET') }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg-4">
              <div class>
                <div class="clearfix">
                  <i
                    class="fa fa-briefcase bg-primary p-3 font-xl mr-3 float-left"
                  ></i>
                  <div
                    class="h6 text-primary mt-2 mb-0"
                    style="padding-top: 10px"
                  >
                    {{ gestion }} € ({{ percentGestion }}%)
                  </div>
                  <div
                    class="text-muted text-uppercase font-weight-bold"
                    style="margin-top: 2px; font-size: 11px"
                  >
                    {{ $t('UNDER_MANAGEMENT') }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg-4">
              <div class>
                <div class="clearfix">
                  <i
                    class="fa fa-bar-chart p-3 font-xl mr-3 float-left"
                    style="background-color: #89c444; color: white"
                  ></i>
                  <div
                    class="h6 text-primary mt-2 mb-0"
                    style="padding-top: 10px"
                  >
                    {{ rendement }} %
                  </div>
                  <div
                    class="text-muted text-uppercase font-weight-bold"
                    style="margin-top: 2px; font-size: 11px"
                  >
                    {{ $t('AVERAGE_YIELD') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {};
  },

  computed: {
    ...mapState({
      client: (state) => state.client_Info,
      portfolio: (state) => state.client_Portfolio,
    }),
    name: function () {
      return this.client.name + ' ' + this.client.lastname;
    },
    rendement: function () {
      //need to change that

      return '2.5';
    },
    situation: function () {
      return this.client.info.extra_info.situation.marital_status;

      /* let sit = "";
      if (this.client.info.extra_info.situation.marital_status == 1)
        sit = "Pacsé";
      else if (this.client.info.extra_info.situation.marital_status == 2)
        sit = "Marié";
      else if (this.client.info.extra_info.situation.marital_status == 3)
        sit = "Concubiné";
      else if (this.client.info.extra_info.situation.marital_status == 4)
        sit = "Célibataire";

      return sit; */
    },
    birth: function () {
      let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      let current = new Date(this.client.birthday);

      return current.toLocaleDateString('fr-FR', options);
    },
    telephone: function () {
      return this.client.info.extra_info.general.telephone.replace(
        /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
        '$1 $2 $3 $4 $5'
      );
    },
    percentGestion: function () {
      let lastLength = this.portfolio.overall.bt.length;
      let tot = Math.round(this.portfolio.overall.bt[lastLength - 1].value);

      let calc = tot / this.client.info.extra_info.assets.total_assets;
      let perc = calc * 100;
      return Math.round(perc);
    },
    patrimoine: function () {
      var parts = Math.round(this.client.info.extra_info.assets.total_assets)
        .toString()
        .split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join('.');
    },
    gestion: function () {
      let lastLength = this.portfolio.overall.bt.length;
      let tot = Math.round(this.portfolio.overall.bt[lastLength - 1].value);

      //just for the demo
      tot = Math.round(this.client.current);

      var parts = tot.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join('.');
    },
  },
};
</script>

<style>
.infoFont {
  font-size: 18px;
  text-align: left;
  margin-left: 30px;
}
</style>
