<template>
<div  class="col-4" style="text-align:center;">
  <ul>
    <template v-for="(value,name,index) in assets">
      <li style="font-weight: bold;">
        <i
          style="cursor:pointer; float:left;"
          :class="{'icon-minus':isShow[name],'icon-plus':!isShow[name]}"
          v-on="!isShow[name] ? { click: () => showAssets(name,index) } : {click:()=> hideAssets(name,index)}"
          ></i>
        {{translateUnivers(name)}}
      </li>
      <template v-for="(val,n) in value.assets"
        v-if="isShow[name]">      
	<li>
	  <span @click="typeProps == 'asset'? selectAsset(n): null"
        :style="{cursor: typeProps == 'asset'?'pointer': '', color: selectedAsset == n ? 'red': ''}">{{translate(n)}}</span> <i @click="getFunds(name,n)" style="cursor:pointer; float:right;" class="icon-arrow-right-circle" />
	<li v-if="selectedAsset == n && 'funds' in val" v-for="fund in val.funds" :style="{cursor: typeProps == 'fund'?'pointer': '', color:'blue', marginTop:'5px', marginBottom:'5px', fontSize:'smaller'}" @click="typeProps == 'fund'?selectFund(fund,n): null">{{fund.Name}}</li>
      </li>
      </template>
    </template>
  </ul>
</div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
export default {
    data() {
	return {
	    isShow: {},
	    assets: [],
	    selectedAsset: ''
	};
    },
    props:{
	translateUniversProps: {
	    type: Array,
	    required: true,
	},
	translateAssetsProps: {
	    type: Array,
	    required: true,
	},
	typeProps: {
	    type: String,
	    required: true
	}
    },
    computed: {
	...mapState({
	    baseUrl: (state) => state.baseUrl,
	})},
    methods: {
	showAssets(e, i) {
	    this.isShow[e] = true;
	},
	hideAssets(e, i) {
	    this.isShow[e] = false;
	},
	selectAsset(n) {
	    this.$emit("clicked", n);
	},
	translate(n) {
	    let idx = this.translateAssetsProps.findIndex((e) => e.optim == n);
	    return idx != -1 ? this.translateAssetsProps[idx][this.$i18n.locale] : n;
	},
	translateUnivers(n) {
	    let idx = this.translateUniversProps.findIndex((e) => e.fr == n);
	    return idx != -1 ? this.translateUniversProps[idx][this.$i18n.locale] : n;
	},
	async getGroupPf(){
	    let data = await this.$http.get(
		this.baseUrl + 
		    "users/dashboard/portfolios/portfolios/" +
		    localStorage.getItem("priradCompany"),
		{
		    headers: {
			Authorization: localStorage.getItem("priradToken")
		    }
		}
	    );
	    let tmp = {};
	    for (const iterator of Object.entries(data.data.resultat[0].contents)) {
		tmp[iterator[0]] = false;
	    }
	    this.isShow = tmp;
	    this.assets = data.data.resultat[0].contents;	    
	},
	getFunds(group,name){
	    this.selectedAsset = name;
	},
	selectFund(fund,asset){
	    let element = {
		isin: fund.ISIN,
		name: fund.Name,
		asset:asset
	    }
	     this.$emit("fundClicked", element);
	}
    },
    async mounted() {
	this.getGroupPf();
  }
};
</script>

<style>
ul {
  list-style-type: none;
}
</style>
