<template>
<div class="col-4" style="text-align:center;">
  <h4>{{$t('LIST_CONSTRAINTS')}}</h4>
  <br />
  <span
    style="cursor:pointer;text-decoration:underline;"
    @click="addContraintes()"
    >{{$t('ADD_NEW_CONSTRAINT')}}</span>
  <br />
  <ul style="text-align: left">
    <li v-for="(contr,index) in groupesProps">
      <i
        class="fa fa-times"
        style="cursor:pointer; margin-left: 5px;"
        @click="removeContraintes(index)"
        ></i>
      
      <span style="cursor:pointer;" @click="seeContraintes(index)">{{contr.name}}</span>
    </li>
  </ul>
</div>
</template>


<script>

import Vue from "vue";
export default {
    data(){
	return {
	}
    },
    props:{
	groupesProps:{
	    type: Array,
	    required:true
	}
    },
    methods:{
	addContraintes(){
	    this.$emit("addgroupcombi",true);
	},
	seeContraintes(index){
	    this.$emit("selectgroupcombi",index);
	},
	removeContraintes(index){
	    this.$emit('removegroupcombi',index);
	}
    }
}

</script>

<style>
ul {
  list-style-type: none;
}
</style>
