import { mapActions, mapState } from 'vuex';

export default {
  data(){
    return {
      kycObj: {}
    }
  },
  computed: {
    ...mapState({
      newAccount: (state) => state.new_Compte,
      risk: (state) => state.risk,
      baseUrl: (state) => state.baseUrl,
    })
  },
  methods:{
    ...mapActions(['setNewCompte', 'setRisk']),
    createData: function() {
      let precautionary_savings = {
        amount: 0,
        savings_account: 0,
      };
      if (this.newAccount.kyc.suit.saveCheck == 1)
        precautionary_savings.savings_account = this.parseInt(
          this.newAccount.kyc.suit.precoSaving
        );

      if (this.newAccount.kyc.suit.amountCheck == 1)
        precautionary_savings.amount = this.parseInt(
          this.newAccount.kyc.suit.precoAmount
        );

      if (this.patMonthLoan == '') this.patMonthLoan == 0;
      if (this.patTermLoan == '') this.patTermLoan == 0;

      this.kycObj = {
        investment_experience: {
          monetary: this.parseInt(this.newAccount.kyc.experience.monetary),
          q1: this.parseInt(this.newAccount.kyc.experience.q1),
          q2: this.parseInt(this.newAccount.kyc.experience.q2),
        },
        financial_assets: this.parseInt(this.newAccount.kyc.suit.patrimoine),
        house_loan: {
          monthly: this.parseInt(this.newAccount.kyc.suit.patMonthLoan),
          term: this.parseInt(this.newAccount.kyc.suit.patTermLoan),
        },
        annual_revenue: this.parseInt(this.newAccount.kyc.suit.revenuTot),
        monthly_expenses: this.parseInt(this.newAccount.kyc.suit.monthlyExpenses),
        precautionary_savings: precautionary_savings,
        risk_tolerance: {
          q1: this.parseInt(this.newAccount.kyc.risk.fluctuation),
        },
        investment_objectives: {
          q1: this.parseInt(this.newAccount.kyc.objectifs.preo),
          q2: this.parseInt(this.newAccount.kyc.objectifs.choicePref),
        },
        evolution_5_years: this.parseInt(this.newAccount.kyc.suit.evolution_5_years),
        revenue_type: this.parseInt(this.newAccount.kyc.suit.revenue_type),
      };
    },
    parseInt: function(x) {
      return Math.round(x.toString().replace(/\s/g, ''));
    },
    callValidation: async function() {
      let ctx = this;
      let data = await this.$http.post(
        this.baseUrl + 'cunit/getData',
        ctx.kycObj,
        {
          headers: {
            function: 'suitability_demo',
          },
        }
      );
      this.setRisk(data.data);
    },
    note2Vol: async function(){
      let newAccount = this.$_.cloneDeep(this.newAccount)
      if (this.risk){
        let call = await this.$http.post(
          this.baseUrl + "cunit/note2Vol",
          {
            note: Number(this.risk.proposed_risk)
          }
        )
        newAccount.kyc_vol = call.data.vol
        this.setNewCompte(newAccount)
      }
    }
  }
}