<script>
import { Bar } from "@/assets/js/graph/BaseChart.js";
export default {
  extends: Bar,
  data() {
    return {
      data: {
        labels: [],
        datasets: [
          {
            label: this.titleProps,
            backgroundColor: "#f87979",
            data: []
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              display: false
            }
          ]
        }
      }
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true
    },
    titleProps: {
      type: String,
      required: true
    }
  },
  watch: {
    dataProps: function(value) {
      this.destroyIt();
      this.fillData();
      this.renderChart(this.data, this.options);
      //this.passWidthHeight(895, 447);
    }
  },
  methods: {
    fillData() {
      let ctx = this;
      this.data = {
        labels: [],
        datasets: [
          {
            label: ctx.titleProps,
            backgroundColor: "#36a9e1",
            data: []
          }
        ]
      };

      for (const iterator of Object.entries(this.dataProps)) {
        this.data.labels.push(iterator[0]);
        this.data.datasets[0].data.push(Math.round(iterator[1] * 100));
      }
    }
  },
  mounted() {
    this.fillData();
    this.passWidthHeight(895, 447);
    this.renderChart(this.data, this.options);
  }
};
</script>
