<template>
<div style="margin-top: 80px;">
  <Spinner v-show="showSpinner" />
  <div class="d-flex mb-5">
    <button class="btn btn_graph" @click="showGraph = !showGraph" >
				<span v-if="showGraph">
          {{$t('HIDE_GRAPH')}}
        </span>
        <span v-else>
          {{$t('SHOW_GRAPH')}}
        </span>
				<i class="icon-pie-chart" style="margin-left: 5px;"></i>
		</button>
    <div class="checkbox-full-btn">
      <input  id="showFunds" 
              name="showFunds" 
              type="checkbox" 
              :checked="showFunds" 
              @change="handleFundShow"
              :disabled="canSavePf"
      />
      <label for="showFunds">{{ $t('SHOW_FUNDS') }}</label>
    </div>
  </div>
  <div class="optimisedContainer">
    <div v-for="item in pfDetailsProps" :key="item.pfName" style="flex: 1">
      <h4 class="text-center" style="background-color: #E8E8E8; padding: 10px;">
            {{ $t(item.pfName) }} -
            {{ item.pfNotes }}
      </h4>
      <PieChartDonut
        v-show="showGraph"
        :dataProps="structuredPf[item.index]"
        style="min-width: 500px; margin-bottom: 20px"
        :widthProps="500"
        :heightProps="300"
        :indexProps="item.index"
        :translateAssetsProps="assetsTranslatedProps"
        :translateUniversProps="universTranslatedProps"
        :forceShowThirdLayout="showFunds"
        :refreshtoken="refreshDonut"
      />

      <Volatility
        v-show="showGraph"
        :portfolioContent="structuredPf[item.index]"
        :index="item.index"
      />
      <div class="position-relative">
        <p class="alert alert-warning text-center warnPosition" v-show="weightAvailable[item.index] != 100 ? true : false" >
          {{ $t('PORTFOLIO_DOES_NOT_ADD_UP') }} <br />
          <span style="font-weight: bold;">{{ weightAvailable[item.index] }}/100</span>
        </p>

        <AdjustementsAssets
          v-show="!showFunds"
          :groupeProps="groupePfProps"
          :translateAssetsProps="assetsTranslatedProps"
          :translateUniversProps="universTranslatedProps"
          :optimisationProps="portfolios[item.index]"
          :indexProps="item.index"
          @event-asset-edition="assetEdition"
        />

        <FundsEdition
          v-show="showFunds"
          :indexProps="item.index"
          :translateAssetsProps="assetsTranslatedProps"
          :translateUniversProps="universTranslatedProps"
          :dataProps="structuredPf[item.index]"
          @event-fund-edition="handleFundEdit"
        />
      </div>
    </div>
  </div>
    <textarea
        v-if="!commentaireShow"
        class="form-control mt-5 mb-3 mx-auto col-6"
        rows="10"
        v-model="comment"
      ></textarea>

    <div class="col-12 text-center mb-3">
      <button class="btn btn-primary myNavInfo" style=" background-color:#e8e8e8; color: black;" @click="commentaireShow = !commentaireShow">
        <span v-if="commentaireShow">{{ $t('LEAVE_COMMENT') }}</span>
        <span v-else>{{ $t('HIDE_COMMENT') }}</span>
      </button>
      <button
        class="btn btn-primary myNavInfo"
        @click="formatForSaving()"
        :disabled="canSavePf"
      >
        {{ $t('SAVE') }}
        <i class="fa fa-save" style="margin-left: 5px;"></i>
      </button>
    </div>
</div>

</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import PieChartDonut from '@/components/autre/donutchart.vue';
import FundsEdition from '@/components/gerant/assets/fundsEdition.vue';
import AdjustementsAssets from '@/components/gerant/assets/adjustementsAssets.vue';
import Volatility from '@/components/gerant/assets/volatility.vue';
import Spinner from '@/components/autre/spinner.vue';

export default {
  data() {
    return {
      showFunds: false,
      portfolios: [],
      fundsDistribution: [],
      fundsDistribution_optimized: [],
      structuredPf: [],
      weightAvailable: [],
      assetsEditionFlag: [],
      fundsEditionFlag: 0,
      commentaireShow: false,
      comment: "",
      showGraph: false,
      showSpinner: false,
      refreshDonut: null
    };
  },
  components: {
    PieChartDonut,
    FundsEdition,
    Volatility,
    AdjustementsAssets,
    Spinner
  },
  props: {
    groupePfProps: {
      type: Object,
      required: true,
    },
    optimisationProps: {
      type: Object,
      required: true,
    },
    fundsProps: {
      type: Object,
      required: true,
    },
    universTranslatedProps: {
      type: Array,
      required: true,
    },
    assetsTranslatedProps: {
      type: Array,
      required: true,
    },
    pfDetailsProps: {
      type: Array,
      required: true,
    },
  },
  watch: {
    optimisationProps: {
      immediate: true,
      handler() {
        this.initStates();
      }
    }
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
    canSavePf() {
      //need to revert for disabled button
      for (let item of this.weightAvailable) {
        if (item != 100) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    async formatForSaving() {
      let ctx = this;
      this.showSpinner = true
      await this.checkForFundUpdate()

      try {
        for (let [index, pf] of this.portfolios.entries()) {
          let tmp = {};
          for (let asset of Object.entries(pf[0])) {
            tmp[asset[0]] = [pf[0][asset[0]], pf[1][asset[0]], pf[2][asset[0]]];
          }

          await this.$http.post(
            this.baseUrl + 'users/dashboard/portfolios/savePortfolio', {
              company: localStorage.getItem('priradCompany'),
              portfolios: tmp,
              index,
              optimized_distribution: ctx.fundsDistribution_optimized[index],
              adjusted_distribution:  ctx.fundsDistribution[index],
              comment: this.comment
            }
          )
        }
        this.showSpinner = false
        this.$emit('savingPortfolios', true)
      }catch(err) {
        this.$emit('savingPortfolios', false)
      }
    },
    formatFunds() {
      this.fundsDistribution = Object.assign(
        [],
        this.fundsDistribution,
        this.fundsProps
      );

      this.fundsDistribution_optimized = JSON.parse(
        JSON.stringify(this.fundsDistribution)
      );
    },
    async initStates() {
      this.weightAvailable = new Array(this.optimisationProps.length).fill(100);
      this.structuredPf = new Array(this.optimisationProps.length).fill(
        {}
      );
      this.assetsEditionFlag = new Array(this.optimisationProps.length).fill(
        false
      );
      this.portfolios = Object.assign([], this.portfolios, this.optimisationProps);

      this.formatFunds();

      for (const item of this.pfDetailsProps) {
        this.structurePf(item.index)
      }
    },
    structurePf(index) {
      
        let group = JSON.parse(JSON.stringify(this.groupePfProps));
        
        for (let univers of Object.entries(group)) {
          if(!group[univers[0]].hasOwnProperty('total')){
            group[univers[0]]['total'] = new Array(this.optimisationProps.length).fill(0);
          }
          let tot = 0;
          for (let asset of Object.entries(univers[1].assets)) {
            asset[1]['weight'] = 0;
            asset[1].adjusted_weights = null;
            asset[1].base_weights = null;
            asset[1].optimized_weights = null;

            if (asset[0] in this.portfolios[index][2]) {
              asset[1]['weight'] = this.portfolios[index][2][asset[0]];
              asset[1].base_weights = new Array(this.optimisationProps[0].length).fill(0);
              asset[1].base_weights[index] = this.portfolios[index][2][asset[0]]
            }
            tot += asset[1]['weight'];
            for (let fund of asset[1].funds) {
              fund['weight'] =
                fund.ISIN in this.fundsDistribution[index]
                  ? this.fundsDistribution[index][fund.ISIN]
                  : 0;
            }
          }
          group[univers[0]]['total'][index] = tot;
        }
  
        this.$set(this.structuredPf, index, group)
    },
    noWeightAvailable(index, value) {
      return (this.weightAvailable[index] + value) > 100 || (this.weightAvailable[index] + value) < 0
    },
    assetEdition({index, assetName, value}) {
      this.assetsEditionFlag.splice(index, 1, true)
      let noAseetWeightAvailable = (Math.round(this.portfolios[index][2][assetName]*100) + value) > 100 || (Math.round(this.portfolios[index][2][assetName]*100) + value) < 0

      if(this.noWeightAvailable(index, value) || noAseetWeightAvailable){
        return;
      }
      this.$set(this.weightAvailable, index, this.weightAvailable[index] += value)


      let pfs = this.portfolios[index];
      pfs[2][assetName] += value/100;
      this.portfolios[index] = Object.assign({}, this.portfolios[index], pfs)
      this.structurePf(index)
      this.refreshDonut = String(Date.now())
    },
    async getFundsDistribution(index) {
      let data = await this.$http.post(this.baseUrl + 'cunit/adjusted/funds', {
        company: localStorage.getItem('priradCompany'),
        distribution: this.portfolios[index][2],
        index
      });
      this.fundsDistribution.splice(index, 1, data.data.fundsDistribution);
    },
    async checkForFundUpdate() {
        for (const [index, flag] of this.assetsEditionFlag.entries()) {
          if(flag){
            await this.getFundsDistribution(index)
            this.structurePf(index)
            this.assetsEditionFlag.splice(index, 1, false)
          }
        }
    },
    async handleFundShow(){
      
      if(!this.showFunds){
        this.showSpinner = true
        await this.checkForFundUpdate()
        this.showSpinner = false
      }
      this.showFunds = !this.showFunds
    },
    handleFundEdit({index, assetName, univers, fundIdx, fundName, value}) {
      
      let fund = this.structuredPf[index][univers].assets[assetName].funds[fundIdx]

      let noFundWeightAvailable = (Math.round(fund.weight*100) + value) > 100 || (Math.round(fund.weight*100) + value) < 0
      
      if(this.noWeightAvailable(index, value) || noFundWeightAvailable) {
        return;
      }

      // this.$set for reactivity in array
      this.$set(this.weightAvailable, index, this.weightAvailable[index] += value)

      let tmpPfForPie = this.structuredPf[index]
      tmpPfForPie[univers].assets[assetName].funds[fundIdx].weight += value/100
      tmpPfForPie[univers].total[index] += value/100
      this.$set(this.structuredPf, index, tmpPfForPie)

      let pfs = this.portfolios;
      pfs[index][2][assetName] += value/100;
      this.portfolios = Object.assign([], this.portfolios, pfs);

      this.fundsDistribution[index][fundName] += value
      this.fundsEditionFlag = Date.now();
      this.refreshDonut = String(Date.now())
    }
  }
};
</script>

<style scoped>
.btn{
	border: none;
	width: fit-content;
	padding: 10px 20px;
}
.btn:focus{
  text-decoration: none;
  box-shadow: none;
}
.btn_graph{
  background-color:#1f97d1; 
  color: #fff;
  margin-left: auto;
  margin-right: 10px;
}
.optimisedContainer {
  display: flex; 
  column-gap: 20px;
  overflow-x: auto;
}
.optimisedContainer::-webkit-scrollbar {
    display: none;
}
.checkbox-full-btn > input:disabled ~ label{
  opacity: 0.45;
}
.warnPosition {
  position: absolute;
  z-index: 10;
  font-size: 15px;
}
</style>
