<template>
  <div>
    <table
      class="table table-bordered table-condensed bg-white"
      style="font-size: 15px;"
    >
      <thead>
        <tr class="text-center" style="background: #f6f4f3">
          <th>{{ $t('ASSET_CLASSES') }}</th>
          <th>{{ $t('REFERENCE') }}</th>
          <th>{{ $t('OPTIMIZED') }}</th>
          <th>{{ $t('ADJUSTED') }}</th>
          <th>{{ $t('CURRENT') }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(group, groupName, index) in groupeProps">
          <tr :key="groupName + '_' + index" class="text-center">
            <td class="d-flex align-items-center" style="cursor: pointer;"
              v-on=" !isShow[groupName] ? { click: () => showAssets(groupName) } : { click: () => hideAssets(groupName) }"
            >
              <div
                :style="{
                  width: '5px',
                  alignSelf: 'stretch',
                  backgroundColor: group.color,
                }"
                class="mr-2"
              >
                &nbsp;
              </div>
              <div class="w-100 text-center" style="font-weight: bold">
                {{ translateUnivers(groupName) }}
              </div>
              <div>                 
                <i :class="[!isShow[groupName] ? 'fa fa-caret-down' : 'fa fa-caret-up']"></i>
              </div>
            </td>
            <td>
              {{ totalOfUnivers(groupName, 0) }}
            </td>
            <td>
              {{ totalOfUnivers(groupName, 1) }}
            </td>
            <td>
              {{ totalOfUnivers(groupName, 2) }}
            </td>
            <td>
              {{ totalOfCurrentUnivers(groupName, indexProps) }}
            </td>
          </tr>

          <tr
            class="text-center"
            v-show="isShow[groupName]"
            v-for="(asset, assetName, index) in filterOpti(optimisationProps[0], group)"
            :key="assetName+index"
          >
            <td>
              {{ translate(assetName) }}
            </td>
            <td>
              {{ Math.round(optimisationProps[0][assetName] * 100) }}
            </td>
            <td>
              {{ Math.round(optimisationProps[1][assetName] * 100) }}
            </td>
            <td>
              <i
                class="icon-minus"
                style="float: left; cursor: pointer"
                @mousedown="updateWeight(assetName, -1)"
                @mouseup="stopInterval()"
                @mouseout="stopInterval()"
              />

              {{ Math.round(optimisationProps[2][assetName] * 100) }}

              <i
                class="icon-plus"
                style="float: right; cursor: pointer"
                @mousedown="updateWeight(assetName, 1)"
                @mouseup="stopInterval()"
                @mouseout="stopInterval()"
              />
            </td>
            <td>
              {{ Math.round(groupeProps[groupName].assets[assetName].adjusted_weights[indexProps] * 100)}}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: {},
      interval: '',
      stillDown: false,
      hashSelected: null
    };
  },
  props: {
    groupeProps: {
      type: Object,
      required: true,
    },
    optimisationProps: {
      type: Array,
      required: true,
    },
    translateAssetsProps: {
      type: Array,
      required: true,
    },
    translateUniversProps: {
      type: Array,
      required: true,
    },
    indexProps: {
      type: Number,
      required: true,
    },
  },
  methods: {
    initState() {
      for (let item of Object.keys(this.groupeProps)) {
        this.isShow[item] = false;
      }
    },
    hideAssets(groupName) {
      this.isShow = Object.assign({}, this.isShow, { [groupName]: false });
    },
    showAssets(groupName) {
      this.isShow = Object.assign({}, this.isShow, { [groupName]: true });
    },
    translate(n) {
      let idx = this.translateAssetsProps.findIndex((e) => e.optim == n);
      return idx != -1 ? this.translateAssetsProps[idx][this.$i18n.locale] : n;
    },
    translateUnivers(n) {
      let idx = this.translateUniversProps.findIndex((e) => e.fr == n);
      return idx != -1 ? this.translateUniversProps[idx][this.$i18n.locale] : n;
    },
    totalOfUnivers(groupName, index) {
      if (this.optimisationProps[index] === undefined) {
        return 0;
      }
      let total = 0;
      for (let asset of Object.entries(this.groupeProps[groupName].assets)) {
        total +=
          asset[0] in this.optimisationProps[index]
            ? this.optimisationProps[index][asset[0]]
            : 0;
      }
      return Math.round(total * 100);
    },
    totalOfCurrentUnivers(groupName, index) {
      let total = 0;
      for (let assetV of Object.values(this.groupeProps[groupName].assets)) {
        total += assetV.adjusted_weights[index]
      }
      return Math.round(total * 100);
    },
    updateWeight(assetName, value) {
      clearInterval(this.interval)
      this.stillDown = true
      let hashSelected = this.hashSelected = Date.now()

      this.$emit('event-asset-edition', {index: this.indexProps, assetName, value})

      setTimeout(() => {
        if(this.stillDown && hashSelected === this.hashSelected){
          this.interval = setInterval(() => {
            this.$emit('event-asset-edition', {index: this.indexProps, assetName, value})
          }, 100)
        }
      }, 500)
    },
    stopInterval() {
      this.stillDown = false
      this.hashSelected = null
      clearInterval(this.interval);
    },
    filterOpti(data, group) {
      const dataArr = Object.entries(data)
      const filtered = dataArr.filter(([key, _]) => key in group.assets)
      return  Object.fromEntries(filtered)
    }
  },
  mounted() {
    this.initState()
  },
  beforeDestroy() {
    this.stopInterval()
  },
};
</script>