<template>
  <div class="tab-pane active">
    <div class="modal-body">
      <div>
        <div class="navbar-inner">
          <ul class="nav nav-pills" style="margin-bottom: 15px;">
            <li>
              <span @click="clickLink('Exp')" :class="Exp">
                <i class="fa fa-address-card-o"></i>
                {{$t('EXPERIENCE')}}
              </span>
            </li>
            <li>
              <span @click="clickLink('Risk')" :class="Risk">
                <i class="fa fa-users"></i>
                {{$t('RISK_TOLERANCE')}}
              </span>
            </li>
            <li>
              <span @click="clickLink('Obj')" :class="Obj">
                <i class="fa fa-eur"></i>
                {{$t('GOALS')}}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="progress">
        <div
          class="progress-bar progress-bar-success"
          role="progressbar"
          :style="styleEtape"
        >{{ $t('STEP_OUT_OF', {etape, etape_total: 3}) }}</div>
      </div>
    </div>
    <div class="tab-content">
      <experience v-if="etape==1" @clicked="continuerEtape" />
      <risk v-if="etape == 2" @clicked="continuerEtape" />
      <objectifsKYC v-if="etape==3" />
    </div>
  </div>
</template>
<script>
import Vue from "vue";

import Experience from "@/components/agent/dashboard/compliance/kyc/experience.vue";
import Risk from "@/components/agent/dashboard/compliance/kyc/risk.vue";
import ObjectifsKyc from "@/components/agent/dashboard/compliance/kyc/objectifs.vue";

Vue.component("experience", Experience);
Vue.component("risk", Risk);
Vue.component("objectifsKYC", ObjectifsKyc);
export default {
  data() {
    return {
      Exp: "btn btn-primary btn-sm myNavInfo active",
      Risk: "btn btn-primary btn-sm myNavInfo",
      Obj: "btn btn-primary btn-sm myNavInfo",
      etape: 1,
      styleEtape: "width: 33%;",
    };
  },
  methods: {
    clickLink(val) {
      this.changeEtape(val);
    },
    continuerEtape(val) {
      if (val.next != "") this.changeEtape(val.next);
    },
    changeEtape(val) {
      let styleNo = "btn btn-primary btn-sm myNavInfo";
      let style = "btn btn-primary btn-sm myNavInfo active";

      let arr = ["Exp", "Risk", "Obj"];
      let arrWidth = [
        "width: 33%;",
        "width: 66%;",
        "width: 100%;"
      ];
      let i = 0;
      for (const item of arr) {
        if (item != val) this[item] = styleNo;
        else if (item == val) {
          this[item] = style;
          this.etape = i + 1;
          this.styleEtape = arrWidth[i];
        }
        i++;
      }
    },
  },
};
</script>