<template>
<div class="col-4" style="text-align:center;">
  <div v-if="Object.keys(dataProps).length == 0">
    {{$t("ADD_NEW_CONTR_GROUP")}} 
  </div>
  <div v-else>
    
    <div class="form-group row">
      
      <label class="col-md-4 col-form-label" for="text-input">{{$t('NAME_CONSTRAINT')}}</label>
      
      <div class="col-md-8 input-group">
	<input
          type="text"
          class="form-control"
          placeholder="Nom"
          v-model="dataProps.name"
          />
      </div>
    </div>

    <div class="form-group row">
    
    <label class="col-md-4 col-form-label" for="text-input">{{$t('TYPE_CONSTRAINT')}}</label>
    
    <div class="col-md-8 input-group">
      <div class="col-md-4 row">
      <label for="type1">{{$t('ASSET_CLASSES')}}</label>
      <input
        type="radio"
	id="type1"
        class="form-control"
        v-model="dataProps.type"
	value="asset"
	name="rdoType"
	:checked="dataProps.type == 'asset'"
        />
      </div>
      <div class="col-md-6 row"></div>
      <div class="col-md-4 row">
      <label for="type2">{{$t('FUNDS')}}</label>
      <input
        type="radio"
	id="type2"
        class="form-control"
        v-model="dataProps.type"
	value="fund"
	name="rdoType"
	:checked="dataProps.type == 'fund'"
        />
      </div>
    </div>
  </div>
  
  
  <div class="form-group row">
    <label class="col-md-4 col-form-label" for="text-input">{{$t('MINIMUM')}}</label>
    <div class="col-md-8 input-group">
      <span class="input-group-addon">
        <i class="fa fa-percent"></i>
      </span>
      <input
        type="text"
        class="form-control"
        placeholder="0"
        v-model="dataProps.min"
        />
    </div>
  </div>
  
  <div class="form-group row">
    <label class="col-md-4 col-form-label" for="text-input">{{$t('MAXIMUM')}}</label>
    <div class="col-md-8 input-group">
      <span class="input-group-addon">
        <i class="fa fa-percent"></i>
      </span>
      <input
        type="text"
        class="form-control"
        placeholder="10"
        v-model="dataProps.max"
        />
    </div>
  </div>
  <ul class="list-assets">
    <li v-if="dataProps.type =='asset'" v-for="(ass) in $_.orderBy(dataProps.assets,[],['asc']) " style="text-align: left">
      <i class="fa fa-times" style="margin-right: 5px; cursor: pointer" @click="removeAssets(ass)"></i>
      <span>{{translate(ass)}}</span>
    </li>

    <li v-if="dataProps.type=='fund'" v-for="(ass) in $_.orderBy(dataProps.assets,[],['asc']) " style="text-align: left">
      <i class="fa fa-times" style="margin-right: 5px; cursor: pointer" @click="removeFunds(ass)"></i>
      <span>{{ass.name}}</span>
    </li>

    
  </ul>
  </div>
</div>
</template>

<script>

import Vue from "vue";
import { mapActions, mapState } from "vuex";
export default {
    data(){
	return{
	    translated: []
	}
    },
    computed: {
	...mapState({
	    baseUrl: (state) => state.baseUrl,
	})},
  props:{
	  dataProps:{
	    type: Object,
	    required:false
	},
	refreshtoken: {
	    type: String,
	    required:false
	}
    },
    watch: {
	refreshtoken: function(){
	    this.getGroupePf();
	}
    },
    methods:{
	removeAssets(asset){
	    let assetIdx = this.dataProps.assets.indexOf(asset)
	    this.$emit("removeassetInGroupCombi", assetIdx);
	},
	removeFunds(fund){
	    let fundIdx = this.dataProps.assets.findIndex(item => item.isin == fund.isin);
	    this.$emit("removeassetInGroupCombi", fundIdx);
	},
  	translate(n) {
	    let idx = this.translated.findIndex((e) => e.optim == n);
	    return idx != -1 ? this.translated[idx][this.$i18n.locale] : n;
	  },
	  async getTranslated() {
	    let assets_translated = await this.$http.get(
		    this.baseUrl + "assets/translated"
	    );
	    this.translated = assets_translated.data.resultat;
	  }
  },
  mounted(){
	  this.getTranslated()
  }
}
</script>
<style scoped>
.list-assets {
    padding-left: 10px;
}
.list-assets > li:not(:last-child){
    margin-bottom: 5px;
}
</style>
