<template>
  <form class="mt-4 goalForm">
    <div class="mt-4 d-flex">
      <span class="labelSpan">
        {{ $t('RETIREMENT_HORIZON') }}
      </span>
      <input
        type="text"
        id="txtRetireModalRetraite"
        name="txtRetireModalRetraite"
        class="inputTxt"
        v-model.lazy="data.duree"
      />
    </div>
    <div class="mt-4 d-flex">
      <span class="labelSpan">
        {{ $t('DESIRED_MONTHLY_PENSION') }}
      </span>
      <BaseInputFormatted
        _id="txtAmountModalRetraite"
        _name="txtAmountModalRetraite"
        class="inputTxt"
        v-model="data.rent"
        :formatOutsideInput="formatOutsideInput"
        :formatInsideInput="formatInsideInput"
        :insideNewvalue="insideNewvalue"
        :outsideNewvalue="outsideNewvalue"
      />
    </div>
    <div class="button__save--container">
      <button type="button" class="button__save" :style="{...cssVars}" @click="save()">
        {{ $t('SAVE') }}
      </button>
    </div>
  </form>
</template>
<script>
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'

export default {
  components: {
    BaseInputFormatted,
  },
  name: "ModalRetraite",
  data() {
    return {
      data: {},
      formatInsideInput: /[^\d\.]/g,
      formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      insideNewvalue: '',
      outsideNewvalue: '$1 ',
    };
  },
  props: {
    dataProps: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true
    }
  },
  computed:{
    cssVars() {
      return {
        '--goal-color': this.color
      }
    },
  },
  methods: {
    formatData() {
      let k = Object.keys(this.data);
      for (let index = 0; index < Object.keys(this.data).length; index++) {
        if (typeof this.data[k[index]] === "boolean") {
          continue;
        }
	  if(k[index] != "createdAt"){
	      this.data[k[index]] = this.data[k[index]]
		  .toString()
		  .replace(" ", "")
		  .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	  }
      }
    },
    save() {
	//this.formatData();
      this.$emit("saved", {
        name: "retraite",
        data: this.data,
      });
      this.$emit("clicked", "retraite");
    },
  },
  mounted() {
    let ctx = this;
    this.data = JSON.parse(JSON.stringify(ctx.dataProps));
  },
};
</script>
<style>
  @import '../../../assets/css/goal-modal.css';
</style>
