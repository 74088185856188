<template>
  <draggable
    v-model="tmpList"
    group="funds"
    @start="drag = true"
    @end="drag = false"
    @change="$emit('change', tmpList)"
  >
    <div v-if="!tmpList.length" class="bg-white" style="margin-top: 0.5rem; padding: 50px;">
        {{ $t('LIST_IS_EMPTY') }}
    </div>
    <div
      v-else
      class="fundCard"
      v-for="element in tmpList"
      :key="element.ISIN"
      @click="$emit('selected', element)"
    >
      <span v-if="checkConstraint(element)" >                  
        <i  class="fa fa-lock" style="color: #ff4040"></i>
      </span>
      {{ element.Name }}
      <div v-if="selected == element.ISIN" class="d-flex justify-content-center" style="margin-top: 5px">
        <label
          for="txtPrenom"
          class="labelFormCenter"
          style="margin-right: 15px"
        >
          {{ $t('MIN_NOT_UPPERCASE') }}
          <input
            type="text"
            id="txtIsinMin"
            class="input"
            v-bind:value="contraintes[element.ISIN].min"
            v-on:input="$emit('edit', $event, element.ISIN, 'min')"
          />
        </label>
        <label for="txtPrenom" style="margin-right: 15px">
          {{ $t('MAX_NOT_UPPERCASE') }}
          <input
            type="text"
            id="txtIsinMax"
            class="input"
            v-bind:value="contraintes[element.ISIN].max"
            v-on:input="$emit('edit', $event, element.ISIN, 'max')"
          />
        </label>
        <label
          v-if="element.stock"
          :for="'check' + element.ISIN"
          class="check"
        >
          Active
          <input
            type="checkbox"
            class="checkbox"
            :id="'check' + element.ISIN"
            v-model="contraintes[element.ISIN].enable"
            v-on:input="$emit('enable', $event, element.ISIN)"
          />
          <div class="box"></div>
        </label>
      </div>
    </div>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable';

export default {
  components: { draggable },
  data() {
    return {
      tmpList: [],
    };
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    contraintes: {
      type: Object,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  watch: {
    list: function () {
      this.init();
    },
  },
  methods: {
    init() {
      this.tmpList = Object.assign([], {}, this.list);

      if (this.tmpList.length) {
        let orderKey = this.tmpList[0].stock ? 'stockOrder' : 'order';
        if (this.tmpList[0].hasOwnProperty(orderKey)) {
          this.tmpList.sort(
            (a, b) => a[orderKey][this.index] < b[orderKey][this.index]
          );
        }
      }
    },
    checkConstraint(element) {
      return element.ISIN in this.contraintes && (this.contraintes[element.ISIN].min != '' || this.contraintes[element.ISIN].max != '' || this.contraintes[element.ISIN].enable)
    }
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.fundCard {
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  box-sizing: border-box;
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05),
    0 0.5rem 1.125rem rba(75, 0, 0, 0.05);
  background: white;
  margin-top: 5px;
  cursor: pointer;
  padding: 1rem;
}
.input {
  display: block;
  width: 50px;
  text-align: center;
}
</style>
