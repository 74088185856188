<template>
  <form class="mt-4 goalForm">
    <div class="d-flex">
      <div class="form__group field w-100 mr-3">
        <input 
          type="text" 
          class="form__field"
          id="input_ADD_CHILD" 
          :placeholder="$t('ADD_CHILD')"
          v-model="newChild" 
          :style="{...cssVars}"/>
        <label 
          for="input_ADD_CHILD" 
          class="form__label"
          :style="{...cssVars}">
            {{$t('ADD_CHILD')}}
        </label>
      </div>
      <button
        type="button"
        class="btn btn-primary myNavInfo next"
        @click="saveChild()"
      >
        {{ $t('ADD') }}
        <i class="fa fa-plus"/>
      </button>
    </div>
    <div v-if="data.length > 0" class="mt-4">
      <div class="d-flex">
        <CustomSelect
          :list="getChildsName"
          :index="indexChild"
          @change="indexChild = $event"
          style="flex:1; box-shadow: 0px 0px 3px lightgray;"
        />
        <button 
          type="button"
          class="btn btn-outline-danger myNavInfo next ml-3"
          @click="deleteChild()">
          {{ $t('DELETE') }}
          <i class="fa fa-trash-o"/>
        </button>
      </div>
    </div>
    <div v-if="data.length > 0">
      <div class="mt-4 d-flex">
        <span class="labelSpan">
          {{ $t('STUDIES_STARTED_YEARS') }}
        </span>
        <input
          type="text"
          class="inputTxt"
          v-model="data[indexChild]['year']"
        />
      </div>
      <div class="mt-4 d-flex">
        <span class="labelSpan">
          {{ $t('DURATION_STUDIES_YEARS') }}
        </span>
        <input
          type="text"
          class="inputTxt"
          v-model="data[indexChild]['duration']"
        />
      </div>
      <div class="mt-4 d-flex">
        <span class="labelSpan">
          {{ $t('ANNUAL_EXPECTED_COST') }}
        </span>
        <BaseInputFormatted
          class="inputTxt"
          v-model="data[indexChild]['expenses']"
          :formatOutsideInput="formatOutsideInput"
          :formatInsideInput="formatInsideInput"
          :insideNewvalue="insideNewvalue"
          :outsideNewvalue="outsideNewvalue"
        />
      </div>
    </div>
    <div class="button__save--container">
      <button type="button" class="button__save" :style="{...cssVars}" @click="save()">
        {{ $t('SAVE') }}
      </button>
    </div>
  </form>
</template>
<script>
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'
import CustomSelect from '@/components/autre/customSelect.vue';

export default {
  name: "ModalSchool",
  components: {
    BaseInputFormatted,
    CustomSelect
  },
  data() {
    return {
      data: [],
      indexChild: 0,
      newChild: "",
      formatInsideInput: /[^\d\.]/g,
      formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      insideNewvalue: '',
      outsideNewvalue: '$1 '
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: true
    }
  },
  computed:{
    getChildsName(){
      let tmp = []
      for (const child of this.data) {
        tmp.push(child.name)
      }
      return tmp
    },
    cssVars() {
      return {
        '--goal-color': this.color
      }
    },
  },
  methods: {
    save() {
      this.$emit("saved", {
        name: "school",
        data: this.data,
      });
      this.$emit("clicked", "school");
    },
    comboClick() {
      let lst = document.getElementById("comboChild");
      let str = lst.options[lst.selectedIndex].value;
      this.indexChild = str;
    },
    saveChild() {
      this.data.push({
          name: this.newChild,
	  createdAt: new Date().toISOString()
      });
      this.newChild = "";
      this.indexChild = this.data.length == 1 ? 0 : this.indexChild + 1;
    },
    deleteChild() {
      this.data.splice(this.indexChild, 1);
      this.indexChild = 0;
    },
  },
  mounted() {
    let ctx = this;
    this.data = JSON.parse(JSON.stringify(ctx.dataProps));
  },
};
</script>
<style>
  @import '../../../assets/css/goal-modal.css';
  .form__field:focus{
    border-bottom: 2px solid var(--goal-color);
  }
  .form__field:focus ~ .form__label{
    color: var(--goal-color);
  }
</style>