<template>
  <form class="mt-4 goalForm">
    <div class="form__group field w-100 mr-3">
      <input 
        type="text" 
        class="form__field"
        id="input_NAME_OF_THE_PROJECT" 
        :placeholder="$t('NAME_OF_THE_PROJECT')"
        v-model="data.name" 
        :style="{...cssVars}"/>
      <label 
        for="input_NAME_OF_THE_PROJECT" 
        class="form__label" 
        :style="{...cssVars}">
          {{$t('NAME_OF_THE_PROJECT')}}
      </label>
    </div>
    <div class="mt-4 d-flex">
      <span class="labelSpan">
        {{ $t('AMOUNT') }}
      </span>
      <BaseInputFormatted
        _id="txtModalObjectif"
        _name="txtModalObjectif"
        class="inputTxt"
        v-model="data.amount"
        :formatOutsideInput="formatOutsideInput"
        :formatInsideInput="formatInsideInput"
        :insideNewvalue="insideNewvalue"
        :outsideNewvalue="outsideNewvalue"
      />
    </div>
    <div class="mt-4 d-flex">
      <span class="labelSpan">
        {{ $t('HORIZON_YEARS') }}
      </span>
      <input
        type="text"
        id="txtModalObjectifDuree"
        name="txtModalObjectifDuree"
        class="inputTxt"
        v-model.lazy="data.duree"
      />
    </div>
    <div class="button__save--container">
      <button type="button" class="button__save" :style="{...cssVars}" @click="save()">
        {{ $t('SAVE') }}
      </button>
    </div>
  </form>
</template>
<script>
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'

export default {
  name: "ModalValo",
  components: {
    BaseInputFormatted,
  },
  data() {
	  return {
	    data: {},
      formatInsideInput: /[^\d\.]/g,
      formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      insideNewvalue: '',
      outsideNewvalue: '$1 ',
	  };
  },
  props: {
	  dataProps: {
	    type: Object,
	    required: true,
	  },
    color: {
      type: String,
      required: true
    }
  },
  computed:{
    cssVars() {
      return {
        '--goal-color': this.color
      }
    }
  },
  methods: {
	  formatData() {
	    let k = Object.keys(this.data);
	    for (let index = 0; index < Object.keys(this.data).length; index++) {
		    if (typeof this.data[k[index]] === "boolean") {
		      continue;
		    }
		
		    if(k[index] != "name" && k[index] != "createdAt"){
		      this.data[k[index]] = this.data[k[index]]
			      .toString()
			      .replace(" ", "")
			      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		    }
      }
    },
    save() {
      //this.formatData();
      this.$emit("saved", {
        name: "transmi",
        data: this.data,
      });
      this.$emit("clicked", "transmi");
    },
  },
  mounted() {
    let ctx = this;
    this.data = JSON.parse(JSON.stringify(ctx.dataProps));
  },
};
</script>
<style>
  @import '../../../assets/css/goal-modal.css';

  .form__field:focus{
    border-bottom: 2px solid var(--goal-color);
  }
  .form__field:focus ~ .form__label{
    color: var(--goal-color);
  }
</style>
