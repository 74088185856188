<template>
  <form class="mt-4 goalForm">
    <div class="d-flex">
      <div class="form__group field w-100 mr-3">
        <input 
          type="text" 
          class="form__field"
          id="input_ADD_REAL_ESTATE_PROJECT" 
          :placeholder="$t('ADD_REAL_ESTATE_PROJECT')"
          v-model="newChild" 
          :style="{...cssVars}"/>
        <label 
          for="input_ADD_REAL_ESTATE_PROJECT" 
          class="form__label"
          :style="{...cssVars}">
            {{$t('ADD_REAL_ESTATE_PROJECT')}}
        </label>
      </div>
      <button
        type="button"
        class="btn btn-primary myNavInfo next"
        @click="saveChild()"
      >
        {{ $t('ADD') }}
        <i class="fa fa-plus"/>
      </button>
    </div>
    <div v-if="data.length > 0" class="mt-4">
      <div class="d-flex">
        <CustomSelect
          :list="getRealEstateProjects"
          :index="indexChild"
          @change="indexChild = $event"
          style="flex:1; box-shadow: 0px 0px 3px lightgray;"
        />
        <button 
          type="button"
          class="btn btn-outline-danger myNavInfo next ml-3"
          @click="deleteChild()">
          {{ $t('DELETE') }}
          <i class="fa fa-trash-o"/>
        </button>
      </div>
      <div class="mt-4 d-flex">
          <span class="labelSpan">
            {{ $t('PROPERTY_VALUE') }}
          </span>
          <BaseInputFormatted
            _id="txtHouseModalImmo"
            _name="txtHouseModalImmo"
            class="inputTxt"
            v-model="data[indexChild].value"
            :formatOutsideInput="formatOutsideInput"
            :formatInsideInput="formatInsideInput"
            :insideNewvalue="insideNewvalue"
            :outsideNewvalue="outsideNewvalue"
          />
      </div>
      <div class="mt-4 d-flex">
        <span class="labelSpan">
          {{ $t('PURCHASE_HORIZON') }}
        </span>
        <input
          type="text"
          id="txtStartModalImmo"
          name="txtStartModalImmo"
          class="inputTxt"
          v-model.lazy="data[indexChild].date"
        />
      </div>
      <div class="mt-4 p-2 border">
        <div class="d-flex">
          <span class="labelSpan">
            {{ $t('BORROWED') }}
          </span>
          <CustomSelect
            :list="borrowed"
            :index="getBorrowedIdx"
            :popUpMaxHeight="300"
            :optionPlaceholder="''"
            @change="setBorrowed($event)"
            class="inputTxt"
          />
        </div>
        <div class="mt-4 d-flex">
          <span class="labelSpan">
            {{ $t('TERM_OF_LOAN') }}
          </span>
          <CustomSelect
            :list="termOfLoan"
            :index="getTermOfLoanIdx"
            :optionPlaceholder="''"
            @change="setTermOfLoan($event)"
            class="inputTxt"
          />
        </div>
        <div class="d-flex justify-content-between mt-4">
          <span class="labelSpan">
            {{ $t('THE_SALE_PROPERTY') }}
          </span>
          <div class="d-flex">
            <input 
              class="customRadio"
              type="radio" 
              name="saleProperty" 
              id="radio_yes" 
              value="true"
              v-model="data[indexChild].vente"
              :checked="data[indexChild].vente == true">
            <label for="radio_yes" class="option radio_yes mb-0" :style="{...cssVars}">
              <div class="dot"></div>
              <span>{{ $t('YES') }}</span>
            </label>
            <input 
              class="customRadio"
              type="radio" 
              name="saleProperty" 
              id="radio_no"
              value="false"
              v-model="data[indexChild].vente"
              :checked="data[indexChild].vente == false">
            <label for="radio_no" class="option radio_no mb-0" :style="{...cssVars}">
              <div class="dot"></div>
              <span>{{ $t('NO') }}</span>
            </label>
          </div>
        </div>
        <div class="mt-4 d-flex" v-if="data[indexChild].vente === 'true'">
          <span class="labelSpan">
            {{ $t('EXPECTED_VALUE') }}
          </span>
          <input
            type="text"
            id="txtHouseModalImmo"
            name="txtHouseModalImmo"
            class="inputTxt"
            v-model.lazy="data[indexChild].venteAmount"
          />
        </div>
      </div>
    </div>
    <div class="button__save--container">
      <button type="button" class="button__save" :style="{...cssVars}" @click="save()">
        {{ $t('SAVE') }}
      </button>
    </div>
  </form>
</template>
<script>
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'
import CustomSelect from '@/components/autre/customSelect.vue';

export default {
  name: "ModalImmo",
  components: {
    BaseInputFormatted,
    CustomSelect
  },
  data() {
    return {
      data: [],
      indexChild: 0,
      newChild: '',
      formatInsideInput: /[^\d\.]/g,
      formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      insideNewvalue: '',
      outsideNewvalue: '$1 ',
      borrowed: ["0%","10%","20%","30%","40%","50%","60%","70%","80%","90%"],
      termOfLoan: [5, 10, 15, 20, 25]
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    getRealEstateProjects: function(){
      let tmp = []
      for (const project of this.data) {
        tmp.push(project.name)
      }
      return tmp
    },
    getBorrowedIdx: function(){
      if(!this.data[this.indexChild].percent) return -1

      let borrowed = `${this.data[this.indexChild].percent*100}%`
      let idx = this.borrowed.findIndex(el => el === borrowed)

      if(idx === -1) return 0

      return idx
    },
    getTermOfLoanIdx: function(){
      if(!this.data[this.indexChild].duree) return -1

      return this.termOfLoan.findIndex(el => el === this.data[this.indexChild].duree)
    },
    cssVars() {
        return {
            '--goal-color': this.color
        }
    },
  },
  methods: {
    save() {
      this.$emit('saved', {
        name: 'immo',
        data: this.data,
      });
      this.$emit('clicked', 'immo');
    },
    saveChild() {
      if(!this.newChild.length) return
      this.data.push({
        name: this.newChild,
        createdAt: new Date().toISOString(),
      });
      this.newChild = '';
      this.indexChild = this.data.length == 1 ? 0 : this.indexChild + 1;
    },
    deleteChild() {
      this.data.splice(this.indexChild, 1);
      this.indexChild = 0;
    },
    setBorrowed(e){
      let percent = Number(this.borrowed[e].replace("%", ""))
      this.data[this.indexChild].percent = percent/100
    },
    setTermOfLoan(e){
      this.data[this.indexChild].duree = this.termOfLoan[e]
    }
  },

  mounted() {
    let ctx = this;
    this.data = JSON.parse(JSON.stringify(ctx.dataProps));
  },
};
</script>
<style>
  @import '../../../assets/css/goal-modal.css';
  .form__field:focus{
    border-bottom: 2px solid var(--goal-color);
  }
  .form__field:focus ~ .form__label{
    color: var(--goal-color);
  }
</style>
