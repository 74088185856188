<template>
  <div class="d-flex justify-content-center">
    <table
      class="table table-bordered table-condensed bg-white text-center"
      style="max-width: 500px;"
    >
      <thead>
        <tr>
          <th class="text-center">{{ $t('FUNDS') }}</th>
          <th
            v-show="overrideShowProps ? true : findValue(isShow, true)"
            class="numeric text-center"
          >
            {{ $t('PERCENT') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(group, name, index) in groupeAssets">
          <tr style="font-weight: bold" :key="name + '-' + Math.random()">
            <td class="d-flex align-items-center" style="cursor: pointer" 
              v-on="!isShow[name] ? { click: () => showAssets(name, index) } : { click: () => hideAssets(name, index) }"
            >
              <div
                class="mr-2 align-self-stretch"
                :style="{ width: '5px', backgroundColor: group.color}"
              >
                &nbsp;
              </div>
              <div class="w-100 text-center">
                {{ translateUnivers(name) }}
              </div>
              <div>                 
                <i :class="[!isShow[name] ? 'fa fa-caret-down' : 'fa fa-caret-up']"></i>
              </div>
            </td>
            <td>{{ Math.round(group.total[indexProps] * 100) }}</td>
          </tr>
          <template
            v-for="(asset, nameAsset, idxAsset) in groupeAssets[name].assets"
          >
            <tr
              v-show="isShow[name]"
              v-for="(fund, idxFund) in asset.funds"
              :key="fund.ISIN_current"
              v-if="fund.weight > 0"
            >
              <td
                class="tooltipfund"
                style="text-align: center; position: relative"
              >
                <span class="tooltiptext">{{ translate(nameAsset) }}</span>
                {{ fund.Name }}
              </td>
              <td style="text-align: center">
                {{ Math.round(fund.weight * 100) }}
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    return {
      groupeAssets: {},
      isShow: {},
    };
  },
  props: {
    dataProps: {
      type: Object,
      required: true,
    },
    indexProps: {
      type: Number,
      required: false,
    },
    overrideShowProps: {
      type: Boolean,
      required: false,
      default: false,
    },
    translateUniversProps: {
      type: Array,
      required: true,
    },
    translateAssetsProps: {
      type: Array,
      required: true,
    }
  },
  watch: {
    dataProps: function() {
      this.getGroupePf();
    },
    locale: function(value) {
      this.geGroupePf();
    },
  },
  methods: {
    getGroupePf() {
      this.groupeAssets = this.dataProps;
    },
    translate(n) {
      let idx = this.translateAssetsProps.findIndex((e) => e.optim == n);
      return idx != -1 ? this.translateAssetsProps[idx][this.$i18n.locale] : n;
    },
    translateUnivers(n) {
      let idx = this.translateUniversProps.findIndex((e) => e.fr == n);
      return idx != -1 ? this.translateUniversProps[idx][this.$i18n.locale] : n;
    },
    showAssets(e, i) {
      this.isShow[e] = true;
    },
    hideAssets(e, i) {
      this.isShow[e] = false;
      //if (!this.findValue(this.isShow, true)) this.$emit("isShow", false);
    },
    nametokey(g) {
      return g.name.replace(/\s+/g, '');
    },
    findValue(o, value) {
      for (var prop in o) {
        if (o.hasOwnProperty(prop) && o[prop] === value) {
          return true;
        }
      }
      return false;
    },
    getShows() {
      let tmp = {};
      for (const iterator of Object.entries(this.groupeAssets)) {
        tmp[iterator[0]] = false;
      }
      this.isShow = tmp;
    },
  },
  created() {
    this.getGroupePf();
    this.getShows();
  },
};
</script>

<style scoped>
.tooltipfund .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #0672b9;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltipfund:hover .tooltiptext {
  visibility: visible;
}
</style>
