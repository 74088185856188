<template>
  <div class="tab-pane fade in active show">
    <div class="well">
      <label style="margin-bottom: 25px;">
        <strong>
          <i class="fa fa-eur"></i>
          {{$t('INVESTOR_EXPERIENCE')}}
        </strong>
      </label>
      <div>
        <table class="table" id style="font-size: 12.5px;">
          <tbody>
            <tr class="headTable">
              <th></th>
              <th class="text-center">{{$t('NONE')}}</th>
              <th class="text-center">{{$t('KNOWLEDGE')}}</th>
              <th class="text-center">{{$t('EXPERIENCE')}}</th>
              <th></th>
            </tr>
            <tr class="rowTable" id="30">
              <td>{{$t('MONEY_MARKET_PRODUCT_ASSOCIATED_UCITS')}}</td>
              <td class="text-center">
                <input
                  type="radio"
                  id="PMOpcvm_1"
                  value="-1"
                  v-model="client.info.KYC.experience.q1"
                  :checked="client.info.KYC.experience.q1 == -1"
                  name="PMOpcvm"
                />
              </td>
              <td class="text-center">
                <input
                  type="radio"
                  id="PMOpcvm_2"
                  value="0"
                  v-model="client.info.KYC.experience.q1"
                  :checked="client.info.KYC.experience.q1 == 0"
                  name="PMOpcvm"
                />
              </td>
              <td class="text-center">
                <input
                  type="radio"
                  id="PMOpcvm_3"
                  value="1"
                  v-model="client.info.KYC.experience.q1"
                  :checked="client.info.KYC.experience.q1 == 1"
                  name="PMOpcvm"
                />
              </td>
            </tr>
            <tr class="rowTable" id="32">
              <td>{{$t('BONDS_ASSOCIATED_UCITS')}}</td>
              <td class="text-center">
                <input
                  type="radio"
                  id="ObligationsOpcvm_1"
                  value="-1"
                  v-model="client.info.KYC.experience.q2"
                  :checked="client.info.KYC.experience.q2 == -1"
                  name="ObligationsOpcvm"
                  required
                />
              </td>
              <td class="text-center">
                <input
                  type="radio"
                  id="ObligationsOpcvm_2"
                  value="0"
                  v-model="client.info.KYC.experience.q2"
                  :checked="client.info.KYC.experience.q2 == 0"
                  name="ObligationsOpcvm"
                  required
                />
              </td>
              <td class="text-center">
                <input
                  type="radio"
                  id="ObligationsOpcvm_3"
                  value="1"
                  v-model="client.info.KYC.experience.q2"
                  :checked="client.info.KYC.experience.q2 == 1"
                  name="ObligationsOpcvm"
                  required
                />
              </td>
            </tr>
            <tr class="rowTable" id="34">
              <td>{{$t('SHARES_UCI')}}</td>

              <td class="text-center">
                <input
                  type="radio"
                  id="ActionOpc_1"
                  value="-1"
                  v-model="client.info.KYC.experience.q3"
                  :checked="client.info.KYC.experience.q3 == -1"
                  name="ActionOpc"
                  required
                />
              </td>
              <td class="text-center">
                <input
                  type="radio"
                  id="ActionOpc_2"
                  value="0"
                  v-model="client.info.KYC.experience.q3"
                  :checked="client.info.KYC.experience.q3 == 0"
                  name="ActionOpc"
                  required
                />
              </td>
              <td class="text-center">
                <input
                  type="radio"
                  id="ActionOpc_3"
                  value="1"
                  v-model="client.info.KYC.experience.q3"
                  :checked="client.info.KYC.experience.q3 == 1"
                  name="ActionOpc"
                  required
                />
              </td>
            </tr>
            <tr class="rowTable" id="35">
              <td>{{$t('STRUCTURED_PRODUCT')}}</td>
              <td class="text-center">
                <input
                  type="radio"
                  id="ProduitStructure_1"
                  value="-1"
                  v-model="client.info.KYC.experience.q4"
                  :checked="client.info.KYC.experience.q4 == -1"
                  name="ProduitStructure"
                  required
                />
              </td>
              <td class="text-center">
                <input
                  type="radio"
                  id="ProduitStructure_2"
                  value="0"
                  v-model="client.info.KYC.experience.q4"
                  :checked="client.info.KYC.experience.q4 == 0"
                  name="ProduitStructure"
                  required
                />
              </td>
              <td class="text-center">
                <input
                  type="radio"
                  id="ProduitStructure_3"
                  value="1"
                  v-model="client.info.KYC.experience.q4"
                  :checked="client.info.KYC.experience.q4 == 1"
                  name="ProduitStructure"
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>

        <hr />

        <form
          action
          method="post"
          enctype="multipart/form-data"
          class="form-horizontal"
          style="font-size: 12.5px;"
        >
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('VALUE_STOCK_CAN_DROP_ZERO')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="Expradio51">
                  <input
                    type="radio"
                    id="Expradio51"
                    name="radios5"
                    v-model="client.info.KYC.experience.q5"
                    :checked="client.info.KYC.experience.q5 == -1"
                    value="-1"
                  />
                  {{$t('TRUE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio52">
                  <input
                    type="radio"
                    id="Expradio52"
                    name="radios5"
                    v-model="client.info.KYC.experience.q5"
                    :checked="client.info.KYC.experience.q5 == 0"
                    value="0"
                  />
                  {{$t('FALSE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio53">
                  <input
                    type="radio"
                    id="Expradio53"
                    name="radios5"
                    v-model="client.info.KYC.experience.q5"
                    :checked="client.info.KYC.experience.q5 == 1"
                    value="1"
                  />
                  {{$t('DO_NOT_KNOW')}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('LISTED_SHARE_CAN_QWICKLY_SOLD_ON_STOCK_EXCHANGE')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="Expradio61">
                  <input
                    type="radio"
                    id="Expradio61"
                    name="radios6"
                    v-model="client.info.KYC.experience.q6"
                    :checked="client.info.KYC.experience.q6 == -1"
                    value="-1"
                  />
                  {{$t('TRUE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio62">
                  <input
                    type="radio"
                    id="Expradio62"
                    name="radios6"
                    v-model="client.info.KYC.experience.q6"
                    :checked="client.info.KYC.experience.q6 == 0"
                    value="0"
                  />
                  {{$t('FALSE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio63">
                  <input
                    type="radio"
                    id="Expradio63"
                    name="radios6"
                    v-model="client.info.KYC.experience.q6"
                    :checked="client.info.KYC.experience.q6 == 1"
                    value="1"
                  />
                  {{$t('DO_NOT_KNOW')}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('WHEN_INVEST_BOND_COMPANY_ACQUIRE_SHARE_COMPANY')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="Expradio71">
                  <input
                    type="radio"
                    id="Expradio71"
                    name="radios7"
                    v-model="client.info.KYC.experience.q7"
                    :checked="client.info.KYC.experience.q7 == 1"
                    value="-1"
                  />
                  {{$t('TRUE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio72">
                  <input
                    type="radio"
                    id="Expradio72"
                    name="radios7"
                    v-model="client.info.KYC.experience.q7"
                    :checked="client.info.KYC.experience.q7 == 1"
                    value="0"
                  />
                  {{$t('FALSE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio73">
                  <input
                    type="radio"
                    id="Expradio73"
                    name="radios7"
                    v-model="client.info.KYC.experience.q7"
                    :checked="client.info.KYC.experience.q7 == 1"
                    value="1"
                  />
                  {{$t('DO_NOT_KNOW')}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('UCITS_EXPERIENCE_SIGNIFICANT_DECLINES')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="radiosOPCVMLow_1">
                  <input
                    type="radio"
                    id="radiosOPCVMLow_1"
                    name="radiosOPCVMLow"
                    v-model="client.info.KYC.experience.q8"
                    :checked="client.info.KYC.experience.q8 == 1"
                    value="1"
                  />
                  {{$t('TRUE')}}
                </label>
              </div>
              <div class="radio">
                <label for="radiosOPCVMLow_2">
                  <input
                    type="radio"
                    id="radiosOPCVMLow_2"
                    name="radiosOPCVMLow"
                    v-model="client.info.KYC.experience.q8"
                    :checked="client.info.KYC.experience.q8 == -1"
                    value="-1"
                  />
                  {{$t('FALSE')}}
                </label>
              </div>
              <div class="radio">
                <label for="radiosOPCVMLow_3">
                  <input
                    type="radio"
                    id="radiosOPCVMLow_3"
                    name="radiosOPCVMLow"
                    v-model="client.info.KYC.experience.q8"
                    :checked="client.info.KYC.experience.q8 == 0"
                    value="0"
                  />
                  {{$t('DO_NOT_KNOW')}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('UCITS_INVESTED_EQUITIES_MORE_RISKY_UCITS_INVESTED_BOND')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="radiosOPCVMRiksy_1">
                  <input
                    type="radio"
                    id="radiosOPCVMRiksy_1"
                    name="radiosOPCVMRiksy"
                    v-model="client.info.KYC.experience.q9"
                    :checked="client.info.KYC.experience.q9 == 1"
                    value="1"
                  />
                  {{$t('TRUE')}}
                </label>
              </div>
              <div class="radio">
                <label for="radiosOPCVMRiksy_2">
                  <input
                    type="radio"
                    id="radiosOPCVMRiksy_2"
                    name="radiosOPCVMRiksy"
                    v-model="client.info.KYC.experience.q9"
                    :checked="client.info.KYC.experience.q9 == -1"
                    value="-1"
                  />
                  {{$t('FALSE')}}
                </label>
              </div>
              <div class="radio">
                <label for="radiosOPCVMRiksy_3">
                  <input
                    type="radio"
                    id="radiosOPCVMRiksy_3"
                    name="radiosOPCVMRiksy"
                    v-model="client.info.KYC.experience.q9"
                    :checked="client.info.KYC.experience.q9 == 0"
                    value="0"
                  />
                  {{$t('DO_NOT_KNOW')}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('STRUCTURED_PRODUCTS_ALWAYS_TOTAL_GUARANTEE_INVESTED_CAPITAL_END_INVESTMENT')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="Expradio101">
                  <input
                    type="radio"
                    id="Expradio101"
                    name="radios10"
                    v-model="client.info.KYC.experience.q10"
                    :checked="client.info.KYC.experience.q10 ==-1"
                    value="-1"
                  />
                  {{$t('TRUE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio102">
                  <input
                    type="radio"
                    id="Expradio102"
                    name="radios10"
                    v-model="client.info.KYC.experience.q10"
                    :checked="client.info.KYC.experience.q10 == 1"
                    value="1"
                  />
                  {{$t('FALSE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio103">
                  <input
                    type="radio"
                    id="Expradio103"
                    name="radios10"
                    v-model="client.info.KYC.experience.q10"
                    :checked="client.info.KYC.experience.q10 == 0"
                    value="0"
                  />
                  {{$t('DO_NOT_KNOW')}}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('POSSIBLE_LOSE_MONEY_WITH_SCPI')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="Expradio111">
                  <input
                    type="radio"
                    id="Expradio111"
                    name="radios11"
                    v-model="client.info.KYC.experience.q11"
                    :checked="client.info.KYC.experience.q11 == 1"
                    value="1"
                  />
                  {{$t('TRUE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio112">
                  <input
                    type="radio"
                    id="Expradio112"
                    name="radios11"
                    v-model="client.info.KYC.experience.q11"
                    :checked="client.info.KYC.experience.q11 == -1"
                    value="-1"
                  />
                  {{$t('FALSE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio113">
                  <input
                    type="radio"
                    id="Expradio113"
                    name="radios11"
                    v-model="client.info.KYC.experience.q11"
                    :checked="client.info.KYC.experience.q11 == 0"
                    value="0"
                  />
                  {{$t('DO_NOT_KNOW')}}
                </label>
              </div>
            </div>
          </div>
          <hr />

          <div class="form-group row">
            <label class="col-md-6 col-form-label">
              <strong>{{$t('VALUE_UNLISTED_SECURITY_NEVER_GO_DOWN')}}</strong>
            </label>
            <div class="col-md-6">
              <div class="radio">
                <label for="Expradio121">
                  <input
                    type="radio"
                    id="Expradio121"
                    name="radios12"
                    v-model="client.info.KYC.experience.q11"
                    :checked="client.info.KYC.experience.q12 == -1"
                    value="-1"
                  />
                  {{$t('TRUE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio122">
                  <input
                    type="radio"
                    id="Expradio122"
                    name="radios12"
                    v-model="client.info.KYC.experience.q11"
                    :checked="client.info.KYC.experience.q12 == 1"
                    value="1"
                  />
                  {{$t('FALSE')}}
                </label>
              </div>
              <div class="radio">
                <label for="Expradio123">
                  <input
                    type="radio"
                    id="Expradio123"
                    name="radios12"
                    v-model="client.info.KYC.experience.q11"
                    :checked="client.info.KYC.experience.q12 == 0"
                    value="0"
                  />
                  {{$t('DO_NOT_KNOW')}}
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="text-center">
        <span
          class="btn btn-primary next"
          style="cursor:pointer;"
          @click="btnClick()"
        >{{$t('CONTINUE')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions(["setClientInfo"]),
    btnClick() {
      this.$emit("clicked", {
        name: "Exp",
        next: "Risk",
      });
    },
    parseToInt() {
      let myObj;
      for (const item of Object.entries(this.client.info.KYC.experience)) {
        if (typeof item[1] != "number") {
          if (item[1] == "0") this.client.info.KYC.experience[item[0]] = 0;
          else this.client.info.KYC.experience[item[0]] = parseInt(item[1]);
        }
      }
    },
  },
  computed: {
    ...mapState({
      client: (state) => state.client_Info,
    }),
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function (state) {
        return state.client_Info;
      },
      function (val) {
        ctx.parseToInt();
        ctx.setClientInfo(val);
      },
      {
        deep: true,
      }
    );
    if (this.$_.isEmpty(this.client.info.KYC.experience)) {
      this.client.info.KYC.experience = {};
    }
  },
};
</script>