<template>
  <div class="container-fluid">
    <h4 style="margin-bottom: 15px;">{{$t('DASHBOARD')}}</h4>
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <i class="icon-user"></i>
          {{$t('Users')}}
        </div>
        <div class="card-body">
          <button class="btn btn-primary" @click="createUser()">
            <span v-if="!formUser">{{$t('ADD_USER')}}</span>
            <span v-else>{{$t('RETUNR_TO_THE_LIST_USERS')}}</span>
          </button>

          <button
            v-if="formUser && Object.keys(copyUser).length > 0"
            class="btn btn-primary"
            @click="editPwd()"
          >
            <span v-if="!formPwd.form">{{$t('NEW_PASSWORD')}}</span>
            <span v-if="formPwd.form">{{$t('RETURN_TO_MODIFICATION')}}</span>
          </button>

          <button v-if="formUser" class="btn btn-primary" @click="editParams()">
            <span v-if="!formParams.form">{{$t('EDIT_FUNCTION_PARAMETERS')}}</span>
            <span v-if="formParams.form">{{$t('HIDE_FUNCTION_PARAMETERS')}}</span>
          </button>

          <table
            v-if="!formUser"
            class="table table-striped table-condensed table-bordered datatable tableStyle"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>{{$t('USERNAME')}}</th>
                <th>Token</th>
                <th>{{$t('FUNCTIONS')}}</th>
                <th>{{$t('CREATION_DATE')}}</th>
                <th>{{$t('ACTIONS')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(u,index) in users">
                <td>{{u.id}}</td>
                <td>{{u.username}}</td>
                <td>
                  <input type="text" class="form-control" :value="u.token" readonly />
                </td>
                <td>{{u.available_functions}}</td>
                <td>{{u.createdAt}}</td>
                <td>
                  <i
                    @click="editUserForm(index)"
                    style="cursor:pointer;"
                    class="fa fa-pencil-square-o"
                  ></i> |
                  <i @click="deleteUser(index)" style="cursor:pointer;" class="fa fa-trash-o"></i>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="form-group"
            style=" margin-top:2%;"
            v-if="formUser && Object.keys(copyUser).length == 0"
          >
            <br />
            <div class="row" style="text-align:center;">
              <div class="col-4">
                <label>
                  {{$t('USERNAME')}}
                  <input
                    type="text"
                    name
                    id
                    v-model="newUser.username"
                    class="form-control"
                  />
                </label>
              </div>
              <div class="col-4">
                <label>
                  {{$t('PASSWORD')}}
                  <input
                    type="password"
                    name
                    id
                    v-model="newUser.password"
                    class="form-control"
                  />
                </label>
              </div>
              <div class="col-4">
                <label for>
                  {{$t('FUNCTIONS')}}
                  <ul style="height:150px; overflow-y:auto;">
                    <li v-for="(f,index) in functions">
                      {{f.nameToShow}}
                      <input type="checkbox" @change="checkAddFunction(index)" />
                    </li>
                  </ul>
                </label>
              </div>
            </div>
            <div v-if="formParams.form">
              <div v-if="newUser.available_functions.length > 0" class="row">
                <div class="col-2">
                  {{$t('LIST_FUNCTIONS')}}
                  <ul style="height:150px; overflow-y:auto;">
                    <li
                      v-for="(f,index) in formParams.params"
                      @click="selectedFuncForParam(index)"
                      style="cursor:pointer;"
                    >{{f.function_name}}</li>
                  </ul>
                </div>
                <div class="col-10 row">
                  <div class="col-3 row">
                    {{$t('LIST_PARAMETER_NAMES')}}
                    <span
                      style="cursor:pointer;"
                      @click="addNameParams()"
                    >+</span>
                    <br />
                    <ul style="height:150px; overflow-y:auto;">
                      <li
                        v-for="(f,index) in formParams.params[formParams.index].params"
                        style="cursor:pointer;"
                      >
                        <input type="text" v-model="f.name" v-if="formParams.indexPf == index" />
                        <span @click="changeIndexPf(index)" v-else>{{f.name}}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-5 row">
                    {{$t('LIST_FUNCTION_PARAMETERS')}}
                    <span
                      @click="addParamsUser()"
                      style="cursor:pointer;"
                    >+</span>
                    <div
                      class="form-group row"
                      v-for="f in formParams.params[formParams.index].params[formParams.indexPf].params "
                    >
                      <div class="col-3 row">
                        {{$t('LAST_NAME')}}
                        <br />
                        <input type="text" v-model="f.key" />
                      </div>
                      <div class="col-3 row">
                        {{$t('VALUE')}}
                        <br />
                        <input type="text" v-model="f.value" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="newUser.available_functions.length == 0"
                style="text-align:center;"
              >{{$t('PLEASE_SELECT_FUNCTION')}}</div>
            </div>

            <button class="btn btn-primary row" @click="doNewUser()">{{$t('SAVE')}}</button>
          </div>
          <div
            class="form-group"
            style="margin-top:2%;"
            v-if="formUser && Object.keys(copyUser).length > 0"
          >
            <br />
            <div class="row" style="text-align:center;" v-if="!formPwd.form">
              <div class="col-4">
                <label>
                  {{$t('USERNAME')}}
                  <input
                    type="text"
                    name
                    id
                    v-model="copyUser.username"
                    class="form-control"
                  />
                </label>
              </div>
              <div class="col-4">
                <label for>
                  <div class="input-group">
                    <span @click="renewToken()" style="cursor:pointer;" class="input-group-addon">
                      <i class="fa fa-refresh"></i>
                    </span>
                    <input
                      type="text"
                      id="txtToken"
                      name="txtToken"
                      class="form-control"
                      :value="copyUser.token"
                      readonly
                    />
                    <span @click="copyToken()" style="cursor:pointer;" class="input-group-addon">
                      <i class="fa fa-files-o"></i>
                    </span>
                  </div>
                </label>
              </div>
              <div class="col-4">
                <label for>
                  {{$t('FUNCTIONS')}}
                  <ul style="height:150px; overflow-y:auto;">
                    <li v-for="(f,index) in functions">
                      {{f.nameToShow}}
                      <input
                        type="checkbox"
                        @change="checkAddFunctionEdit(index)"
                        :checked="copyUser.available_functions.includes(f.id)"
                      />
                    </li>
                  </ul>
                </label>
              </div>
            </div>
            <div v-if="formPwd.form">
              <label>
                {{$t('PASSWORD')}}
                <input
                  type="password"
                  name
                  id
                  v-model="formPwd.newPwd"
                  class="form-control"
                />
              </label>
            </div>
            <div v-if="formParams.form">
              <div v-if="copyUser.available_functions.length > 0" class="row">
                <div v-if="formParams.form">
                  <div v-if="copyUser.available_functions.length > 0" class="row">
                    <div class="col-2">
                      {{$t('LIST_FUNCTIONS')}}
                      <ul style="height:150px; overflow-y:auto;">
                        <li
                          v-for="(f,index) in formParams.params"
                          @click="selectedFuncForParam(index)"
                          style="cursor:pointer;"
                        >{{f.function_name}}</li>
                      </ul>
                    </div>
                    <div class="col-10 row">
                      <div class="col-3 row">
                        {{$t('LIST_PARAMETER_NAMES')}}
                        <span
                          style="cursor:pointer;"
                          @click="addNameParams()"
                        >+</span>
                        <br />
                        <ul style="height:150px; overflow-y:auto;">
                          <li
                            v-for="(f,index) in formParams.params[formParams.index].params"
                            style="cursor:pointer;"
                          >
                            <input type="text" v-model="f.name" v-if="formParams.indexPf == index" />
                            <span @click="changeIndexPf(index)" v-else>{{f.name}}</span>
                          </li>
                        </ul>
                      </div>
                      <div class="col-5 row">
                        {{$t('LIST_FUNCTION_PARAMETERS')}}
                        <span
                          @click="addParamsUser()"
                          style="cursor:pointer;"
                        >+</span>
                        <div
                          class="form-group row"
                          v-for="f in formParams.params[formParams.index].params[formParams.indexPf].params "
                        >
                          <div class="col-3 row">
                            {{$t('LAST_NAME')}}
                            <br />
                            <input type="text" v-model="f.key" />
                          </div>
                          <div class="col-3 row">
                            {{$t('VALUE')}}
                            <br />
                            <input type="text" v-model="f.value" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="copyUser.available_functions.length == 0"
                    style="text-align:center;"
                  >{{$t('PLEASE_SELECT_FUNCTION')}}</div>
                </div>
              </div>
              <div
                v-if="copyUser.available_functions.length == 0"
                style="text-align:center;"
              >{{$t('PLEASE_SELECT_FUNCTION')}}</div>
            </div>
            <button class="btn btn-primary row" @click="doEditUser()">{{$t('SAVE')}}</button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <i class="icon-user"></i>
          {{$t('FUNCTIONS')}}
        </div>
        <div class="card-body">
          <button class="btn btn-primary" @click="createFunc()">
            <span v-if="!formFunc">{{$t('ADD_FUNCTION')}}</span>
            <span v-else>{{$t('RETURN_FUNCTION_LIST')}}</span>
          </button>
          <table
            v-if="!formFunc"
            class="table table-striped table-condensed table-bordered datatable tableStyle"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>{{$t('LAST_NAME')}}</th>
                <th>{{$t('DISPLAY_NAME')}}</th>
                <th>{{$t('URL')}}</th>
                <th>{{$t('TYPE')}}</th>
                <th>{{$t('DESCRIPTION')}}</th>
                <th>{{$t('ACTIONS')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(f,index) in functions">
                <td>{{f.id}}</td>
                <td>{{f.name}}</td>
                <td>{{f.nameToShow}}</td>
                <td>{{f.url}}</td>
                <td>{{f.type}}</td>
                <td>{{f.description}}</td>
                <td>
                  <i
                    @click="editFuncForm(index)"
                    style="cursor:pointer;"
                    class="fa fa-pencil-square-o"
                  ></i> |
                  <i @click="deleteFunc(index)" style="cursor:pointer;" class="fa fa-trash-o"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="formFunc && Object.keys(copyFunc).length == 0">
            <br />
            <div class="form-group row" style="text-align:center;">
              <div class="col-4">
                <label for>
                  {{$t('INTERNAL_NAME')}}
                  <input
                    type="text"
                    class="form-control"
                    v-model="newFunc.name"
                  />
                </label>
              </div>
              <div class="col-4">
                <label for>
                  {{$t('DISPLAY_NAME')}}
                  <input
                    type="text"
                    class="form-control"
                    v-model="newFunc.nameToShow"
                  />
                </label>
              </div>
              <div class="col-4">
                <label for>
                  {{$t('URL')}}
                  <input type="text" class="form-control" v-model="newFunc.url" />
                </label>
              </div>
            </div>
            <div class="form-group row" style="text-align:center;">
              <div class="col-4">
                <label for>
                  {{$t('DESCRIPTION')}}
                  <textarea
                    name
                    id
                    cols="30"
                    rows="10"
                    class="form-control"
                    v-model="newFunc.description"
                  ></textarea>
                </label>
              </div>
              <div class="col-4">
                <label for>
                  {{$t('TYPE')}}
                  <br />Get
                  <input
                    type="radio"
                    value="GET"
                    name="rdoTypeFunc"
                    id="rdoTypeFunc1"
                    v-model="newFunc.type"
                  /> Post
                  <input
                    type="radio"
                    value="POST"
                    name="rdoTypeFunc"
                    id="rdoTypeFunc2"
                    v-model="newFunc.type"
                  /> Patch
                  <input
                    type="radio"
                    value="PATCH"
                    name="rdoTypeFunc"
                    id="rdoTypeFunc3"
                    v-model="newFunc.type"
                  /> Delete
                  <input
                    type="radio"
                    value="DELETE"
                    name="rdoTypeFunc"
                    id="rdoTypeFunc4"
                    v-model="newFunc.type"
                  />
                </label>
              </div>
            </div>
            <hr />
            <div class="form-group" style="text-align:center;">
              {{$t('REQUEST_PARAMETERS')}}
              <span
                @click="addNewReqparamNewFunc()"
                style="cursor:pointer;"
              >+</span>
              <br />

              <div class="col-12" v-for="req in newFunc.request_parameters">
                <label for>
                  {{$t('LAST_NAME')}}
                  <input
                    type="text"
                    name
                    id
                    class="form-control"
                    v-model="req.name"
                  />
                </label>
                <label for>
                  {{$t('LOCATION')}}
                  <input
                    type="text"
                    name
                    id
                    class="form-control"
                    v-model="req.location"
                  />
                </label>

                <label for>
                  {{$t('TYPE')}}
                  <input type="text" name id class="form-control" v-model="req.type" />
                </label>
                <label for>
                  {{$t('example')}}
                  <textarea
                    name
                    id
                    cols="30"
                    rows="10"
                    class="form-control"
                    v-model="req.example"
                  ></textarea>
                </label>
              </div>
            </div>
            <hr />
            <div class="form-group" style="text-align:center;">
              {{$t('RESPONSES')}}
              <span @click="addNewResNewFunc()" style="cursor:pointer;">+</span>
              <br />

              <div class="col-12" v-for="res in newFunc.responses">
                <label for>
                  {{$t('LAST_NAME')}}
                  <input
                    type="text"
                    name
                    id
                    class="form-control"
                    v-model="res.name"
                  />
                </label>
                <label for>
                  {{$t('TYPE')}}
                  <input type="text" name id class="form-control" v-model="res.type" />
                </label>
              </div>
            </div>
            <hr />
            <div class="form-group" style="text-align:center;">
              {{$t('EXAMPLES')}}
              <span @click="addNewExNewFunc()" style="cursor:pointer;">+</span>
              <br />

              <div class="col-12" v-for="ex in newFunc.examples">
                <label for>
                  Input
                  <textarea
                    name
                    id
                    cols="30"
                    rows="10"
                    class="form-control"
                    v-model="ex.input"
                  ></textarea>
                </label>
                <label for>
                  Output
                  <textarea
                    name
                    id
                    cols="30"
                    rows="10"
                    class="form-control"
                    v-model="ex.output"
                  ></textarea>
                </label>
              </div>
            </div>
            <button class="btn btn-primary row" @click="doNewFunc()">Sauvegarder</button>
          </div>
          <div v-if="formFunc && Object.keys(copyFunc).length > 0">
            <br />
            <div class="form-group row" style="text-align:center;">
              <div class="col-4">
                <label for>
                  {{$t('INTERNAL_NAME')}}
                  <input
                    type="text"
                    class="form-control"
                    v-model="copyFunc.name"
                  />
                </label>
              </div>
              <div class="col-4">
                <label for>
                  {{$t('DISPLAY_NAME')}}
                  <input
                    type="text"
                    class="form-control"
                    v-model="copyFunc.nameToShow"
                  />
                </label>
              </div>
              <div class="col-4">
                <label for>
                  {{$t('URL')}}
                  <input type="text" class="form-control" v-model="copyFunc.url" />
                </label>
              </div>
            </div>
            <div class="form-group row" style="text-align:center;">
              <div class="col-4">
                <label for>
                  {{$t('DESCRIPTION')}}
                  <textarea
                    name
                    id
                    cols="30"
                    rows="10"
                    class="form-control"
                    v-model="copyFunc.description"
                  ></textarea>
                </label>
              </div>
              <div class="col-4">
                <label for>
                  {{$t('TYPE')}}
                  <br />Get
                  <input
                    type="radio"
                    value="GET"
                    name="rdoTypeFunc"
                    id="rdoTypeFunc1"
                    v-model="copyFunc.type"
                  /> Post
                  <input
                    type="radio"
                    value="POST"
                    name="rdoTypeFunc"
                    id="rdoTypeFunc2"
                    v-model="copyFunc.type"
                  /> Patch
                  <input
                    type="radio"
                    value="PATCH"
                    name="rdoTypeFunc"
                    id="rdoTypeFunc3"
                    v-model="copyFunc.type"
                  />
                  Delete
                  <input
                    type="radio"
                    value="DELETE"
                    name="rdoTypeFunc"
                    id="rdoTypeFunc4"
                    v-model="copyFunc.type"
                  />
                </label>
              </div>
            </div>
            <hr />
            <div class="form-group" style="text-align:center;">
              {{$t('REQUEST_PARAMETERS')}}
              <span
                @click="addNewReqparamCopyFunc()"
                style="cursor:pointer;"
              >+</span>
              <br />

              <div class="col-12" v-for="req in copyFunc.request_parameters">
                <label for>
                  {{$t('LAST_NAME')}}
                  <input
                    type="text"
                    name
                    id
                    class="form-control"
                    v-model="req.name"
                  />
                </label>
                <label for>
                  {{$t('LOCATION')}}
                  <input
                    type="text"
                    name
                    id
                    class="form-control"
                    v-model="req.location"
                  />
                </label>

                <label for>
                  {{$t('TYPE')}}
                  <input type="text" name id class="form-control" v-model="req.type" />
                </label>
                <label for>
                  {{$t('example')}}
                  <textarea
                    name
                    id
                    cols="30"
                    rows="10"
                    class="form-control"
                    v-model="req.example"
                  ></textarea>
                </label>
              </div>
            </div>
            <hr />
            <div class="form-group" style="text-align:center;">
              {{$t('RESPONSES')}}
              <span @click="addNewResCopyFunc()" style="cursor:pointer;">+</span>
              <br />

              <div class="col-12" v-for="res in copyFunc.responses">
                <label for>
                  {{$t('LAST_NAME')}}
                  <input
                    type="text"
                    name
                    id
                    class="form-control"
                    v-model="res.name"
                  />
                </label>
                <label for>
                  {{$t('TYPE')}}
                  <input type="text" name id class="form-control" v-model="res.type" />
                </label>
              </div>
            </div>
            <hr />
            <div class="form-group" style="text-align:center;">
              {{$t('EXAMPLES')}}
              <span @click="addNewExCopyFunc()" style="cursor:pointer;">+</span>
              <br />

              <div class="col-12" v-for="ex in copyFunc.examples">
                <label for>
                  Input
                  <textarea
                    name
                    id
                    cols="30"
                    rows="10"
                    class="form-control"
                    v-model="ex.input"
                  ></textarea>
                </label>
                <label for>
                  Output
                  <textarea
                    name
                    id
                    cols="30"
                    rows="10"
                    class="form-control"
                    v-model="ex.output"
                  ></textarea>
                </label>
              </div>
            </div>
            <button class="btn btn-primary row" @click="doEditFunction()">{{$t('SAVE')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      functions: [],
      users: [],
      formUser: false,
      formFunc: false,
      formPwd: {
        form: false,
        newPwd: "",
      },
      formParams: {
        form: false,
        params: [],
        index: 0,
        indexPf: 0,
      },
      newUser: {
        username: "",
        password: "",
        available_functions: [],
        paramsCunit: [],
      },
      newFunc: {
        name: "",
        nameToShow: "",
        url: "",
        description: "",
        type: "",
        request_parameters: [
          {
            type: "",
            example: "",
            name: "",
            location: "",
          },
        ],
        responses: [
          {
            type: "",
            name: "",
          },
        ],
        examples: [
          {
            input: "",
            output: "",
          },
        ],
      },
      copyUser: {},
      copyFunc: {},
    };
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    })},
  methods: {
    ...mapActions(["setBreadLink", "setLogged"]),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({
          isLog: false,
          role: 0,
        });
        await this.$http.get(this.baseUrl +"auth/logout");
        localStorage.removeItem("priradApiToken");
        localStorage.removeItem("priradApiRole");
        this.$router.push("/login");
      } else {
        let role = this.$getRole();
        if (role != 60) {
          this.setLogged({
            isLog: false,
            role: 0,
          });
          await this.$http.get(this.baseUrl +"auth/logout");
          localStorage.removeItem("priradApiToken");
          localStorage.removeItem("priradApiRole");
          this.$router.push("/login");
        } else {
          this.getUsers();
          this.getFunctions();
        }
      }
    },
    async getUsers() {
      let data = await this.$http.get(this.baseUrl + "pr/users/getAll", {
        headers: {
          Authorization: localStorage.getItem("priradApiToken"),
        },
      });
      this.users = data.data.resultat;
    },
    async getFunctions() {
      let data = await this.$http.get(this.baseUrl + "pr/functions/getAll", {
        headers: {
          Authorization: localStorage.getItem("priradApiToken"),
        },
      });
      this.functions = data.data.resultat;
    },
    async editUserForm(index) {
      this.copyUser = JSON.parse(JSON.stringify(this.users[index]));
      this.formParams.params = JSON.parse(
        JSON.stringify(this.users[index].paramsCunit)
      );
      this.formUser = true;
    },
    async editFuncForm(index) {
      this.copyFunc = JSON.parse(JSON.stringify(this.functions[index]));

      this.formFunc = true;
    },
    async deleteFunc(index) {
      await this.$http.delete(
        this.baseUrl + "pr/functions/delete/" + this.functions[index].id,
        {
          headers: {
            Authorization: localStorage.getItem("priradApiToken"),
          },
        }
      );
      this.functions.splice(index, 1);
    },
    async deleteUser(index) {
      await this.$http.delete(this.baseUrl + "pr/users/delete/" + this.users[index].id, {
        headers: {
          Authorization: localStorage.getItem("priradApiToken"),
        },
      });
      this.users.splice(index, 1);
    },
    createFunc() {
      this.formFunc = this.formFunc ? false : true;
      if (!this.formFunc) {
        this.copyFunc = {};
      }
    },
    createUser() {
      this.formUser = this.formUser ? false : true;
      if (!this.formUser) {
        this.copyUser = {};
        this.formPwd.form = false;
        this.formPwd.newPwd = "";
        this.formParams.index = 0;
        this.formParams.params = [];
        this.formParams.form = false;
      }
    },
    async doNewUser() {
      this.newUser.paramsCunit = this.formParams.params;
      let user = await this.$http.post(this.baseUrl + "pr/users/create", this.newUser, {
        headers: {
          Authorization: localStorage.getItem("priradApiToken"),
        },
      });
      this.users.push(user.data.resultat[0]);
      this.newUser = {};
      this.formParams.index = 0;
      this.formParams.params = [];
      this.formParams.form = false;
      this.formUser = false;
    },
    async doNewFunc() {
      let func = await this.$http.post(
        this.baseUrl + "pr/functions/create",
        this.newFunc,
        {
          headers: {
            Authorization: localStorage.getItem("priradApiToken"),
          },
        }
      );
      this.functions.push(func.data.resultat);
      this.newFunc = {};
      this.formFunc = false;
    },
    async doEditFunction() {
      await this.$http.patch(this.baseUrl + "pr/functions/update", this.copyFunc, {
        headers: {
          Authorization: localStorage.getItem("priradApiToken"),
        },
      });

      for (let index = 0; index < this.functions.length; index++) {
        if (this.functions[index].id == this.copyFunc.id) {
          this.functions[index] = JSON.parse(JSON.stringify(this.copyFunc));
          break;
        }
      }

      this.copyFunc = {};
      this.formFunc = false;
    },
    addNewReqparamNewFunc() {
      this.newFunc.request_parameters.push({
        type: "",
        example: "",
        name: "",
        location: "",
      });
    },
    addNewResNewFunc() {
      this.newFunc.responses.push({
        type: "",
        name: "",
      });
    },
    addNewExNewFunc() {
      this.newFunc.examples.push({
        input: "",
        output: "",
      });
    },
    addNewReqparamCopyFunc() {
      this.copyFunc.request_parameters.push({
        type: "",
        example: "",
        name: "",
        location: "",
      });
    },
    addNewResCopyFunc() {
      this.copyFunc.responses.push({
        type: "",
        name: "",
      });
    },
    addNewExCopyFunc() {
      this.copyFunc.examples.push({
        input: "",
        output: "",
      });
    },
    checkAddFunction(index) {
      if (this.newUser.available_functions.includes(this.functions[index].id)) {
        let i = this.newUser.available_functions.indexOf(
          this.functions[index].id
        );
        this.newUser.available_functions.splice(i, 1);
        this.formParams.params.splice(i, 1);
      } else {
        this.newUser.available_functions.push(this.functions[index].id);
        this.formParams.params.push({
          function_id: this.functions[index].id,
          function_name: this.functions[index].nameToShow,
          params: [
            {
              name: "",
              params: [
                {
                  key: "",
                  value: "",
                },
              ],
            },
          ],
        });
      }
    },
    checkAddFunctionEdit(index) {
      if (
        this.copyUser.available_functions.includes(this.functions[index].id)
      ) {
        let i = this.copyUser.available_functions.indexOf(
          this.functions[index].id
        );
        this.copyUser.available_functions.splice(i, 1);
        this.formParams.params.splice(i, 1);
      } else {
        this.copyUser.available_functions.push(this.functions[index].id);
        this.formParams.params.push({
          function_id: this.functions[index].id,
          function_name: this.functions[index].nameToShow,
          params: [
            {
              name: "",
              params: [
                {
                  key: "",
                  value: "",
                },
              ],
            },
          ],
        });
      }
    },
    async doEditUser() {
      if (!this.formPwd.form) {
        await this.$http.patch(
          this.baseUrl + "pr/users/update",
          {
            id: this.copyUser.id,
            username: this.copyUser.username,
            available_functions: this.copyUser.available_functions,
            paramsCunit: this.formParams.params,
          },
          {
            headers: {
              Authorization: localStorage.getItem("priradApiToken"),
            },
          }
        );

        this.copyUser["paramsCunit"] = this.formParams.params;
        for (let index = 0; index < this.users.length; index++) {
          if (this.users[index].id == this.copyUser.id) {
            this.users[index] = JSON.parse(JSON.stringify(this.copyUser));
            break;
          }
        }

        this.copyUser = {};
        this.formParams.index = 0;
        this.formParams.params = [];
        this.formParams.form = false;
        this.formUser = false;
      } else {
        let ctx = this;
        await this.$http.patch(
          this.baseUrl + "pr/users/update/pwd",
          {
            id: ctx.copyUser.id,
            password: ctx.formPwd.newPwd,
          },
          {
            headers: {
              Authorization: localStorage.getItem("priradApiToken"),
            },
          }
        );
      }

      this.formPwd.form = false;
      this.formPwd.newPwd = "";
    },
    async renewToken() {
      let ctx = this;
      let data = await this.$http.get(
        this.baseUrl + "pr/users/renew/" + this.copyUser.id,
        {
          headers: {
            Authorization: localStorage.getItem("priradApiToken"),
          },
        }
      );

      this.copyUser.token = data.data.resultat;
    },
    async copyToken() {
      let copyText = document.getElementById("txtToken");
      copyText.select();
      document.execCommand("copy");
    },
    editPwd() {
      this.formPwd.form = this.formPwd.form ? false : true;
    },
    editParams() {
      this.formParams.form = this.formParams.form ? false : true;
    },
    selectedFuncForParam(index) {
      this.formParams.index = index;
    },
    addParamsUser() {
      this.formParams.params[this.formParams.index].params[
        this.formParams.indexPf
      ].params.push({
        key: "",
        value: "",
      });
    },
    addNameParams() {
      this.formParams.params[this.formParams.index].params.push({
        name: "",
        params: [
          {
            key: "",
            value: "",
          },
        ],
      });
      this.formParams.indexPf += 1;
    },
    changeIndexPf(index) {
      this.formParams.indexPf = index;
    },
  },
  created() {
    this.setBreadLink(["Prime Radiant", "API", "ADMIN"]);
    this.checkLog();
  },
};
</script>

<style>
ul {
  list-style-type: none;
}
</style>