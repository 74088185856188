<template>
  <div class="popUpStyle" :class="[`${type}`, !question ? 'popUpStyleNoQuestion' : '']">
    <i class="fa fa-times closePopUp" @click="$emit('closePopUp')"></i>
    <p class="text-center" :class="{'m-auto': !question}">{{message}}</p>
    <div class="popUpBlocBtnStyle" v-show="question">
      <button class="popUpBtnStyle" @click="returnAnswer(true)">{{$t('YES')}}</button>
      <button class="popUpBtnStyle" @click="returnAnswer(false)">{{$t('NO')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    question: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  methods: {
    returnAnswer(yesNo) {
      this.$emit("answer", yesNo);
    },
  },
};
</script>

<style>
.popUpStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 20px gray;
  background-color: #FFFFFF;
  width: 450px;
  height: 200px;
  position: fixed;
  border-radius: 5px;
  padding: 10px;
  color: black;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 15px solid;
}
.default {
  border-left-color: #FFFFFF;
}
.info {
  border-left-color: #81D4FA;
}
.success {
  border-left-color: #C5E1A5;
}
.warning {
  border-left-color: #FFA726;
}
.error {
  border-left-color: #E57373;
  font-weight: bold;
}

.closePopUp {
  align-self: end;
  cursor: pointer;
}
.popUpStyle > p {
  word-break: break-word;
  margin: 0px;
  padding: 10px;
}
.popUpBlocBtnStyle {
  align-self: center;
}
.popUpBtnStyle {
  background-color: inherit;
  width: 80px;
  height: 50px;
  text-align: center;
  color: black;
  border: none;
  transition: all 0.2s ease-in-out;
}
.popUpBtnStyle:first-child {
  background-color: #F1F1F1;
  border-radius: 25px;
  margin-right: 5px;
}
.popUpBtnStyle:hover {
  transform: scale(1.05);
}
.popUpStyleNoQuestion {
  justify-content: start !important;
}
</style>