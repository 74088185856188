<template>
    <div class="tab-pane active fade show">
        <div class="well">
            <form action class="form-horizontal">
                <ESGForm :esgProps="data.esg" :showTitle="true" @changeESG="changeESG"/>
            </form>
            <hr />
            <div class="text-center">
                <span
                    class="btn btn-primary next"
                    style="cursor:pointer;"
                    @click="btnClick()"
                >{{$t('CONTINUE')}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ESGForm from '@/components/agent/dashboard/resume/esgForm.vue'

export default {
    components: {
        ESGForm
    },
    data() {
        return {
            esgPaiList: [
                "greenhouse_gas_emission",
                "use_of_non_renewable_energy",
                "emissions_to_water",
                "human_rights",
                "social_and_employee_matters"
            ],
        }
    },
    computed: {
        ...mapState({
            data: (state) => state.new_Compte,
        }),
    },
    methods: {
        ...mapActions(["setNewCompte"]),
        btnClick() {
            this.$emit("next")
        },
        changeESG(esg){
            this.data.esg = esg
        }
    }
};
</script>

<style>
.row__container{
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
}
.pai__container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px;
}
.pai{
    border: 1px solid black;
    width: fit-content;
    padding: 5px;
}
</style>
