<template>
  <div class="container">
    <Preferences class="my-4" :showButtonSaveProps="false" :disablePref="true" :showTitle="false"/>
    <p class="font-weight-bold">{{ $t("LIST_OF_DEFORMATION") }}</p>

    <div>
      <ul class="d-flex p-0 mb-0">
        <li
            class="nav-link"
            :class="'assets' === cardSelected ? 'nav-link-active' : ''"
            :style="{ backgroundColor: 'assets' === cardSelected ? getSelectedTabColor : '' }"
            @click="toggleCard('assets')"
        >
          {{ $t("ASSETS") }}
        </li>
        <li
          class="nav-link"
          :class="'funds' === cardSelected ? 'nav-link-active' : ''"
          :style="{backgroundColor: 'funds' === cardSelected? getSelectedTabColor : ''}"
          @click="toggleCard('funds')"
          >
            {{ $t("FUNDS") }}
        </li>
      </ul>
    </div>
    <ul v-if="Object.keys(selectedTabData).length" class="p-0">
      <li v-for="item in selectedTabData" @click="handleCollapse(item[0])" class="p-2 pointer" :style="{backgroundColor: increase_brightness(grpAssest[item[0]].color, 60)}">
        {{ item[0] }}
        <i :id="'caret_'+item[0]" class="fa fa-caret-up rotate"></i>
        <ul :id="'list_'+item[0]+'_deformation'" class="d-none p-0 bg-white">
          <li v-for="(value, key) in item[1]" class="p-2">
            <span><u>{{ key }} <span v-if="value.name !== undefined">- {{ value.name }}</span></u></span>: 
            <span>{{ Math.round(value.old*100) }}%  <i class="fa fa-arrow-circle-right" ></i> {{ Math.round(value.new*100) }}%</span>
          </li>
        </ul>
      </li>
    </ul>
    <p v-else class="p-3 text-center bg-info text-dark">{{ cardSelected === "assets" ? $t("NO_DEFORMATION_ASSET") : $t("NO_DEFORMATION_FUND") }}</p>
    <p class="font-weight-bold">{{ $t("VIEW_ESG_GRAPH") }}</p>
    <ESGGraph :esgRes="portfoliosProps.overall.preview_distribution_esg" :esgRepartition="portfoliosProps.overall.preview_esg" :fundsNameMapping="fundsNameMapping"/>
    <Comparaison :portfoliosProps="portfoliosProps" :basepf="basepf" :noPrefFlag="true"/>
  </div>
</template>
<script>
  import { mapState } from 'vuex';

  import Comparaison from '@/components/agent/dashboard/resume/comparaison.vue';
  import Preferences from '@/components/agent/dashboard/resume/preferences.vue';
  import ESGGraph from '@/components/agent/dashboard/resume/esgGraph.vue';

  export default {
    components: {
      Comparaison,
      Preferences,
      ESGGraph
    },
    data() {
      return {
        cardSelected: "assets",
        grpAssest: {},
        assetsDeformation: {},
        fundsDeformation: {},
        fundsNameMapping: {}
      }
    },
    props: {
      portfoliosProps: {
        type: Object,
        required: true,
        default: {},
      },
      basepf: {
        type: Object,
        required: true
      },
    },
    computed: {
      ...mapState({
        baseUrl: (state) => state.baseUrl,
        client: (state) => state.client_Info,
      }),
      selectedTabData(){
        return this.cardSelected === "assets" ? this.assetsDeformation : this.fundsDeformation
      },
      getSelectedTabColor(){
        const select = this.selectedTabData
        if(!Object.keys(select).length)
          return "#67c2ef"
        
        return this.increase_brightness(this.grpAssest[[select[0][0]]].color, 60)
      }
    },
    methods: {
      handleCollapse(item){
        document.getElementById(`caret_${item}`).classList.toggle("rotate")
        document.getElementById(`list_${item}_deformation`).classList.toggle("d-none")
      },
      async getGroupePf() {
        let data = await this.$http.get(
          this.baseUrl +
          'users/dashboard/portfolios/portfolios/' +
          localStorage.getItem('priradCompany')
        );
        this.grpAssest = data.data.resultat[0].contents;
        this.comparePrefDeformation()
      },
      assetsDistribution(listAsset, listFundTmp) {
        let listFund = JSON.parse(JSON.stringify(listFundTmp));
        let distributedAssests = {};

        for (const [asset, assetVal] of Object.entries(listAsset)) {
          for (const univer of Object.keys(this.grpAssest)) {
            if (asset in this.grpAssest[univer].assets) {
              if (!(univer in distributedAssests)) {
                distributedAssests[univer] = {
                  order: this.grpAssest[univer].order,
                  assets: {},
                  color: this.grpAssest[univer].color,
                  total: [0],
                };
              }
              distributedAssests[univer].total[0] += assetVal;
              distributedAssests[univer].assets[asset] = {
                base_weights: [assetVal],
                funds: [],
              };

              for (const [isin, value] of Object.entries(listFund)) {
                let funds = this.grpAssest[univer].assets[asset].funds;
                funds.map((fund) => {
                  if (isin == fund.ISIN) {
                    fund['weight'] = value;
                    distributedAssests[univer].assets[asset].funds.push({
                      Name: fund.Name,
                      ISIN: fund.ISIN,
                      weight: value,
                    });
                    delete listFund[isin];
                  }
                });
              }

              break;
            }
          }
        }
        return distributedAssests;
      },
      mapFunds(){
        let list = this.portfoliosProps.overall.preview_esg
        let mapping = {}

        for (const isin of Object.values(list).flat(1)) {
          for (const uniV of Object.values(this.grpAssest)) {
            for(const assetV of Object.values(uniV.assets)){
              for (const fund of assetV.funds) {
                if(fund.ISIN === isin){
                  mapping[isin] = fund.Name
                }
              }
            }
          }
        }

        this.fundsNameMapping = mapping
      },
      mergeObjects(obj1, obj2) {
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
          const mergedArray = obj1.concat(obj2);
          const uniqueArray = [];

          mergedArray.forEach((item) => {
            if (!uniqueArray.some((existingItem) => this.isEqual(existingItem, item))) {
              uniqueArray.push(item);
            }
          });

          return uniqueArray;
        }

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
          return obj2;
        }

        const merged = { ...obj1 };
        for (const key in obj2) {
          if (key in merged) {
            merged[key] = this.mergeObjects(merged[key], obj2[key]);
          } else {
            merged[key] = obj2[key];
          }
        }

        return merged;
      },
      // Helper function to check object equality
      isEqual(obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
      },
      comparePrefDeformation(){
        let assetsDeformation = {}
        let fundsDeformation = {}

        for (const uniK of Object.keys(this.grpAssest)) {
          assetsDeformation[uniK] = {}
          fundsDeformation[uniK] = {}
        }

        let noPrefDistributedAssests = this.assetsDistribution(this.portfoliosProps.noPref.preview_assetsDistribution, this.portfoliosProps.noPref.preview_distribution)
        let prefDistributedAssests = this.assetsDistribution(this.portfoliosProps.overall.preview_assetsDistribution, this.portfoliosProps.overall.preview_distribution)

        this.mapFunds() 

        const mergeDistribution = this.mergeObjects(noPrefDistributedAssests, prefDistributedAssests)

        for (const [uniK, uniV] of Object.entries(mergeDistribution)) {
          for (const [assetK, assetV] of Object.entries(uniV.assets)) {
            let newA = prefDistributedAssests[uniK].assets[assetK] !== undefined ? prefDistributedAssests[uniK].assets[assetK].base_weights[0] : 0
            let oldA = noPrefDistributedAssests[uniK].assets[assetK] !== undefined ? noPrefDistributedAssests[uniK].assets[assetK].base_weights[0] : 0

            if(newA !== oldA) {
              assetsDeformation[uniK][assetK] = {
                new: newA,
                old: oldA
              }
            }

            for (const fund of assetV.funds) {
              let prefFundIdx = prefDistributedAssests[uniK].assets[assetK] !== undefined ? prefDistributedAssests[uniK].assets[assetK].funds.findIndex(el => el.ISIN === fund.ISIN) : -1
              let noPrefFundIdx = noPrefDistributedAssests[uniK].assets[assetK] !== undefined ? noPrefDistributedAssests[uniK].assets[assetK].funds.findIndex(el => el.ISIN === fund.ISIN) : -1

              let newF = prefFundIdx !== -1 ? prefDistributedAssests[uniK].assets[assetK].funds[prefFundIdx].weight : 0
              let oldF = noPrefFundIdx !== -1 ? noPrefDistributedAssests[uniK].assets[assetK].funds[noPrefFundIdx].weight : 0

              if(newF !== oldF){

                fundsDeformation[uniK][fund.ISIN] = {
                  name: fund.Name,
                  new: prefFundIdx !== -1 ? prefDistributedAssests[uniK].assets[assetK].funds[prefFundIdx].weight : 0,
                  old: noPrefFundIdx !== -1 ? noPrefDistributedAssests[uniK].assets[assetK].funds[noPrefFundIdx].weight : 0
                }
              }
            }
          }
        }

        this.fundsDeformation = Object.entries(fundsDeformation).filter(([key, val]) => Object.keys(val).length)
        this.assetsDeformation = Object.entries(assetsDeformation).filter(([key,val]) => Object.keys(val).length)
      },
      increase_brightness(hex, percent) {
        // strip the leading # if it's there
        hex = hex.replace(/^\s*#|\s*$/g, '');

        // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
        if (hex.length == 3) {
          hex = hex.replace(/(.)/g, '$1$1');
        }

        var r = parseInt(hex.substr(0, 2), 16),
          g = parseInt(hex.substr(2, 2), 16),
          b = parseInt(hex.substr(4, 2), 16);

        return (
          '#' +
          (0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
            .toString(16)
            .substr(1) +
          (0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
            .toString(16)
            .substr(1) +
          (0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
            .toString(16)
            .substr(1)
        );
      },
      toggleCard(select){
        this.cardSelected = select
      }
    },
    beforeMount(){
      this.getGroupePf()
    }
  }
</script>
<style>
  .rotate{
    rotate: 180deg;
    padding-bottom: 3px;
  }
  .pointer{
    cursor: pointer
  }
  .nav-link {
    background-color: white;
    border: none;
    cursor: pointer;
    border-left: 1px solid lightgray
  }
  .nav-link:focus {
    outline:none !important;
  }
  li.nav-link:first-child {
    border-left: none
  }
  .nav-link-active {
    /* background-color: #F1F1F1; */
    font-weight: bold;
    border-top: 1px solid lightgray;
  }
</style>