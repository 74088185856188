<template>
  <div>
      <ol class="breadcrumb">
        <li v-for="(item,index) in breadLink" class="breadcrumb-item" :key="index">
            <span :to="''" v-if="index == 1" >{{$t(item)}}</span> 
            <span v-else-if="index == breadLink.length - 1" class="breadcrumb-item active">{{$t(item)}}</span> 
            <span v-else>{{$t(item)}}</span>
        </li>
      </ol>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      breadLink: state => state.breadLink
    })
  }
};
</script>
