<template>
    <p class="volatility">{{$t("VOLATILITY")}} - {{volatility}}</p>
</template>
<script>
    import { mapState } from "vuex";
    export default {
        data() {
            return {
                volatility: 0
            }
        },
        props: {
            portfolioContent: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: false,
            },
            refreshtoken: {
	            type: String,
	            required: false
	        },
        },
        computed: {
	        ...mapState({
        	    baseUrl: (state) => state.baseUrl,
    	    })
        },
        watch: {
            portfolioContent: function() {
                this.dataInit()
            },
            refreshtoken: function() {
                this.dataInit()
            }
        },
        methods: {
            async dataInit() {
                let assets = {}
		        for (const uniV of Object.values(this.portfolioContent)) {
		        	for (const [assK, assV] of Object.entries(uniV.assets)) {
                        if(assV.base_weights != null && assV.base_weights[this.index] != 0) {
                            assets[assK] = assV.base_weights[this.index]
                        } else if (assV.weight != null & assV.weight != 0) {
                            assets[assK] = assV.weight
                        }
		        	}
		        }
      	        let data = await this.$http.post(
                	this.baseUrl + "cunit/riskFromCovariance",
		        	{ assets }
      	        );
                this.volatility = (data.data.volatility * 100).toFixed(1)
            }
        },
        mounted() {
            this.dataInit();
        },
    }
</script>
<style scoped>
    .volatility {
        margin-bottom: 10px;
        margin-top: 10px;
        background-color: #E8E8E8;
        padding: 10px;
        text-align: center;
        font-weight: bold;
        color: #707070;
        font-size: 15px;
    }
</style>