<template>
  <div class="tab-pane active fade show">
    <div class="well">
      <label>
        <strong>
          <i class="fa fa-check"></i>
          {{ $t('VALIDATION') }}
        </strong>
      </label>
      <hr />
      <div>
        <div class="row" style="flex-direction: column; align-content: center">
          <risk_dash
            :frameProps="risk.bar"
            :riskProps="risk.proposed_risk"
            :styleProps="'width: 550px;height: 170px; align-self: center'"
          />
          <div class="col-sm-8 col-md-8 text-center">
            <p
              class="alert alert-info"
              style="margin-top: 10px; font-size: 16px;"
            >
              <b>
                {{ $t('YOUR_RECOMMENDED_RISK_ZONE') }}
                <span id="spanLowSuccess">{{
                  Math.round(risk.bar.min * 10) / 10
                }}</span>
                {{ $t('TO_ALONE') }}
                <span id="spanHighSuccess">{{
                  Math.round(risk.bar.max * 10) / 10
                }}</span>
                {{ $t('YOUR_MAXIMUM_RISK_LEVEL') }}
                <span class="success" id="spanRiskSuccess">{{ note }}</span>
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <span
        class="btn btn-primary next"
        style="cursor:pointer;"
        @click="btnClick()"
        >{{ $t('CONTINUE') }}</span
      >
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import Risk_Validation from '@/components/agent/dashboard/dash/risk.vue';

Vue.component('risk_validation', Risk_Validation);

export default {
  data() {
    return {
      myObj: {},
      risk: {},
      portfolio: {},
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.new_Compte,
      note: (state) => state.note,
      baseUrl: (state) => state.baseUrl,
    }),
  },
  methods: {
    ...mapActions(['setNewCompte', 'setRisk']),
    createData() {
      let precautionary_savings = {
        amount: 0,
        savings_account: 0,
      };
      if (this.data.kyc.suit.saveCheck == 1)
        precautionary_savings.savings_account = this.parseInt(
          this.data.kyc.suit.precoSaving
        );

      if (this.data.kyc.suit.amountCheck == 1)
        precautionary_savings.amount = this.parseInt(
          this.data.kyc.suit.precoAmount
        );

      if (this.patMonthLoan == '') this.patMonthLoan == 0;
      if (this.patTermLoan == '') this.patTermLoan == 0;

      this.myObj = {
        investment_experience: {
          monetary: this.parseInt(this.data.kyc.experience.monetary),
          q1: this.parseInt(this.data.kyc.experience.q1),
          q2: this.parseInt(this.data.kyc.experience.q2),
        },
        financial_assets: this.parseInt(this.data.kyc.suit.patrimoine),
        house_loan: {
          monthly: this.parseInt(this.data.kyc.suit.patMonthLoan),
          term: this.parseInt(this.data.kyc.suit.patTermLoan),
        },
        annual_revenue: this.parseInt(this.data.kyc.suit.revenuTot),
        monthly_expenses: this.parseInt(this.data.kyc.suit.monthlyExpenses),
        precautionary_savings: precautionary_savings,
        risk_tolerance: {
          q1: this.parseInt(this.data.kyc.risk.fluctuation),
        },
        investment_objectives: {
          q1: this.parseInt(this.data.kyc.objectifs.preo),
          q2: this.parseInt(this.data.kyc.objectifs.choicePref),
        },
        evolution_5_years: this.parseInt(this.data.kyc.suit.evolution_5_years),
        revenue_type: this.parseInt(this.data.kyc.suit.revenue_type),
      };
    },
    parseInt(x) {
      return Math.round(x.toString().replace(/\s/g, ''));
    },
    async callValidation() {
      let ctx = this;
      let data = await this.$http.post(
        this.baseUrl + 'cunit/getData',
        ctx.myObj,
        {
          headers: {
            function: 'suitability_demo',
          },
        }
      );
      this.risk = data.data;
      this.setRisk(ctx.risk);
    },
    btnClick() {
      this.$emit('clicked', {
        name: 'Val',
        next: 'Visu',
      });
    },
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function(state) {
        return state.new_Compte;
      },
      function(val) {
        ctx.setNewCompte(val);
      },
      {
        deep: true,
      }
    );

    this.createData();
    this.callValidation();
  },
};
</script>
