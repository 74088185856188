<template>
  <svg />
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'ViolinSimulation',
  props: {
    proba: Number,
    cible: String,
    cibleInt: Number,
    annee: Number,
    name: String,
    quantfront: Array,
    bins: Object,
    colors: Object,
  },
  watch: {
    proba: function () {
      this.init();
    },
  },
  data() {
    return {
      width: 100,
      height: 450,
      numberOfTicks: 4,
      margin: { top: 10, right: 15, bottom: 30, left: 15 },
      ticksLabel: ['95%', '80%', '50%', '20%', '5%'],
    };
  },
  methods: {
    avoidCloseTick() {
      let idxs = [];
      for (const [index, tick] of this.ticksLabel.entries()) {
        if (Math.abs(Number(tick.replace('%', '')) - this.proba) < 4)
          idxs.push(index);
      }
      return idxs;
    },
    init() {
      const { margin, width, height, quantfront } = this;
      let bins = this.bins;

      d3.select(this.$el).selectAll('*').remove();

      const svg = d3
        .select(this.$el)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);
      svg
        .append('rect')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .style('opacity', 0);

      const idxToAvoid = this.avoidCloseTick();
      const max_bin = d3.max(bins.nbins);
      bins.values = bins.values.filter(
        (_, index) => bins.nbins[index] > max_bin * 0.01
      );
      bins.nbins = bins.nbins.filter((x) => x > max_bin * 0.01);

      let y = d3
        .scaleLinear()
        .domain([d3.min(bins.values), d3.max(bins.values)])
        .range([height, 0]);

      let x = d3
        .scaleLinear()
        .domain([0, d3.max(bins.nbins)])
        .range([0, width]);
      const data = bins.nbins.map((nbin, index) => ({
        x: nbin,
        y: bins.values[index],
      }));

      var area = d3
        .area()
        .x(x(0))
        .x1((d) => x(d.x))
        .y((d) => y(d.y))
        .curve(d3.curveCatmullRom);

      svg
        .append('path')
        .datum(data)
        .attr('d', area)
        .style('fill', this.colors.color);

      let genLine = d3.line();
      const pointsArray = quantfront.map((d) => [
        [x(0), y(d)],
        [x(max_bin), y(d)],
      ]);
      const goalPoints = [
        [x(0), y(this.cibleInt)],
        [x(max_bin), y(this.cibleInt)],
      ];
      const textColor = "rgb(78, 78, 78)";

      let goalLines = svg
        .selectAll('g')
        .data(goalPoints)
        .enter()
        .append('path')
        .attr('class', 'dotted')
        .attr('d', genLine(goalPoints))
        .style('stroke', this.colors.imgColor)
        .style('stroke-width', 4)
        .attr('stroke-dasharray', '5');

      let goalProba = svg
        .selectAll('g')
        .data(goalPoints)
        .enter()
        .append('text')
        .attr('class', 'goal-text')
        .attr('x', goalPoints[0][0])
        .attr('y', goalPoints[0][1] + 14)
        .text(`${this.proba}%`)
        .style('fill', "black");

      let goalValue = svg
        .selectAll('g')
        .data(goalPoints)
        .enter()
        .append('text')
        .attr('class', 'goal-text')
        .attr('x', goalPoints[1][0])
        .attr('y', goalPoints[0][1] + 14)
        .text(`${this.$formatNumberKM(this.cibleInt)}€`)
        .attr('text-anchor', 'end')
        .style('fill', "black");

      let lines = svg
        .selectAll('g')
        .data(pointsArray)
        .enter()
        .append('path')
        .attr('class', 'dotted')
        .attr('d', (d, i) => {
          if (idxToAvoid.includes(i)) return;
          return genLine(d);
        })
        .style('stroke', this.colors.imgColor)
        .style('stroke-width', 2)
        .attr('stroke-dasharray', '10')
        .style('opacity', 1);

      let texts = svg
        .selectAll('g')
        .data(pointsArray)
        .enter()
        .append('text')
        .attr('class', 'tick-text')
        .attr('x', (d) => d[0][0])
        .attr('y', (d) => d[0][1] + 14)
        .text((_, i) => {
          if (idxToAvoid.includes(i)) return;
          return this.ticksLabel[i];
        })
        .style('fill', textColor);

      let ticksMoneyLabel = [];
      for (let iterator of this.quantfront) {
        let tmp = this.$formatNumberKM(iterator);
        ticksMoneyLabel.push(tmp + '€');
      }

      let valuesTexts = svg
        .selectAll('g')
        .data(pointsArray)
        .enter()
        .append('text')
        .attr('class', 'tick-text')
        .attr('x', (d) => d[1][0])
        .attr('y', (d) => d[0][1] + 14)
        .text((_, i) => {
          if (idxToAvoid.includes(i)) return;
          return ticksMoneyLabel[i];
        })
        .attr('text-anchor', 'end')
        .style('fill', textColor);

      const opacityFunc = (distances) => {
        return (d, idx) => {
          const tmp = Math.max(distances[idx] - 0.04, 0);
          const val = Math.min(Math.max(1 - tmp * 4, 1), 1);
          d3.select(d).style('opacity', val);
        };
      };
      svg
        .on('mouseout', () => {
          lines.style('opacity', 1);
          texts.style('opacity', 1);
          valuesTexts.style('opacity', 1);
        })
        .on('mousemove', () => {
          lines.style('opacity', null);
          texts.style('opacity', null);
          valuesTexts.style('opacity', null);
          const yVal = y.invert(d3.mouse(d3.event.currentTarget)[1]);
          const range = quantfront[quantfront.length - 1] - quantfront[0];
          const distances = quantfront.map((x) => Math.abs(yVal - x) / range);
          lines.nodes().forEach(opacityFunc(distances));
          texts.nodes().forEach(opacityFunc(distances));
          valuesTexts.nodes().forEach(opacityFunc(distances));
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.goal-text {
  font-size: 14px;
  font-weight: bold;
}
.tick-text {
  opacity: 1;
  font-size: 12px;
  font-weight: bold;
}
</style>
