<template>
  <div>

    <div class="d-flex justify-content-between">
      <h5 v-if="show == 'projects'" class="align-self-center m-0">
          <i class="icon-target" style="color:#36a9e1;"></i>
          {{$t('GOALS')}}
      </h5>

      <h5 v-if="show == 'simu'" class="align-self-center m-0">
          <i class="fa fa-calculator" style="color:#36a9e1;"></i>
          {{$t('SIMULATION')}}
      </h5>

      <div>
        <button
          type="button"
          class="btn btn-primary next"
          @click="nav('projects')"
        > 
          {{ $t('GOALS') }}
        </button>
        <button
          type="button"
          class="btn btn-primary next"
          style="margin-left: 5px;"
          @click="nav('simu')"
        >
          {{$t('SIMULATION')}}
        </button>
      </div>
        
    </div>
    <hr />
    <div class="tab-content">
      <Projects
        @save="editProject"
        v-if="show == 'projects'"
        :objectifsProps="objectifsProps"
      />
      <Simulation
        v-if="show == 'simu'"
        @save="saveProjects"
        :currentProps="currentProps"
        :objectifsProps="objectifsProps"
        :apportsProps="apportsProps"
        :comeFromClientProps="true"
        :createdAtProps="
          'oldDateProjects' in objectifsProps
            ? objectifsProps.oldDateProjects
            : dateCreationProps
        "
        :dateCreationClientProps="dateCreationProps"
      />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import Projects from '@/components/agent/dashboard/objectifs/projects.vue';
import Simulation from '@/components/agent/nouveau_compte/simulation.vue';

export default {
  components: {
    Projects,
    Simulation,
  },
  data() {
    return {
      show: 'projects',
    };
  },
  props: {
    objectifsProps: {
      type: Array,
      required: true,
    },
    apportsProps: {
      type: Array,
      required: true,
    },
    currentProps: {
      type: Object,
      required: true,
    },
    dateCreationProps: {
      type: String,
      required: true,
    },
  },
  methods: {
    nav(value) {
      this.show = value;
    },
    saveProjects(val) {
      this.objectifsProps['oldDateProjects'] = new Date().toISOString();
      this.$emit('saveObjs', true);
    },
    editProject(val) {
      this.objectifsProps['oldDateProjects'] = new Date().toISOString();
    },
  },
};
</script>
