<template>
    <div class="tab-pane active">
        <template v-for="(item,index) in lstCom" >
            <p style="font-style: italic;font-size: 17px;" :key="index">
                <b>{{$t(lstDate[index])}}:</b>
                {{$t(item)}}
            </p>
            <br>
        </template>

    </div>
</template>
<script>
export default {
  data() {
    return {
      lstCom: [
          "10/01/2018_CONVICTION",
	  "15/09/2017_CONVICTION",
	  "31/05/2017_CONVICTION",
	  "15/12/2016_CONVICTION"
      ],
      lstDate: [
          "10/01/2018_DATE_CONVICTION",   
	  "15/09/2017_DATE_CONVICTION",
	  "31/05/2017_DATE_CONVICTION",
	  "15/12/2016_DATE_CONVICTION"
      ]
    };
  }
};
</script>
