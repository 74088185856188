<template>
    <div class="tab-pane active fade show">
        <div class="well">
            <label class="d-flex justify-content-between">
                <strong>
                    <i class="fa fa-file" />
                    {{$t('KYC')}}
                </strong>
                <div v-if="risk.proposed_risk !== null" >
                    <span class="font-weight-bold">{{$t('RISK_LEVEL')}} </span>
                    <input
                        type="number"
                        id="KYC_GENERALI_NOTE"
                        name="KYC_GENERALI_NOTE"
                        class="form-control"
                        style="display: inline-block; width: 150px; "
                        placeholder
                        v-model="risk.proposed_risk"
                        max="5"
                        min="1"
                        @change="checkGradeValue"
                    />
                </div>
            </label>
            <hr />
            <div>
                <div class="card-body">
                    <form action method="post" class="form-horizontal">
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('DURATION')}}</span>
                                <input
                                    type="text"
                                    id="_DURATION"
                                    name="_DURATION"
                                    class="form-control"
                                    placeholder
                                    v-model="apports.duration"
                                />
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('ANNUAL_VOLATILITY')}}</span>
                                <input
                                    type="text"
                                    id="_ANNUAL_VOLATILITY"
                                    name="_ANNUAL_VOLATILITY"
                                    class="form-control"
                                    placeholder
                                    v-model="kycGenerali.annualVolatility"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('LIQUID_ASSETS')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="kycGenerali.liquidAssets"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('FINANCIAL_INVESTMENTS')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="kycGenerali.financialInvestments"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('TOTAL_CURRENT_FUTURE_FINANCIAL_COMMITMENTS')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="kycGenerali.debt"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('OTHER_MAJ')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="kycGenerali.other"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('ANNUAL_INCOME_MAJ')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="kycGenerali.annualIncome"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('ANNUAL_INCOMPRESSIBLE_SPENDING')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="kycGenerali.incompressibleSpending"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>

                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('ESTIMATED_ANNUAL_CONTRIBUTION')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="apports.VersmtPeriodik"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                        </div>

                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('REAL_ESTATE_MAJ')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="kycGenerali.financialProfile.realEstate"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('INITIAL_INVESTMENT_AMOUNT')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="apports.initial"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                               />
                            </div>
                        </div>

                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('TIME_CONSISTENCY')}}</span>
                                <input
                                    type="text"
                                    id="_TIME_CONSISTENCY"
                                    name="_TIME_CONSISTENCY"
                                    class="form-control"
                                    placeholder
                                    v-model="kycGenerali.personnalRiskProfile.timeConsistency"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('AGE_CONSISTENCY')}}</span>
                                <input
                                    type="text"
                                    id="_AGE_CONSISTENCY"
                                    name="_AGE_CONSISTENCY"
                                    class="form-control"
                                    placeholder
                                    v-model="kycGenerali.personnalRiskProfile.ageConsistency"
                                />
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('INVESTMENT_OBJECTIVE_CONSISTENCY')}}</span>
                                <input
                                    type="text"
                                    id="_INVESTMENT_OBJECTIVE_CONSISTENCY"
                                    name="_INVESTMENT_OBJECTIVE_CONSISTENCY"
                                    class="form-control"
                                    placeholder
                                    v-model="kycGenerali.personnalRiskProfile.investmentObjectiveConsistency"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('CAPACITY_CONSISTENCY')}}</span>
                                <input
                                    type="text"
                                    id="_CAPACITY_CONSISTENCY"
                                    name="_CAPACITY_CONSISTENCY"
                                    class="form-control"
                                    placeholder
                                    v-model="kycGenerali.personnalRiskProfile.capacityConsistency"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <hr />
            <div class="text-center">
                <span
                    class="btn btn-primary next mr-2"
                    style="cursor:pointer;"
                    @click="getNote()"
                >{{$t('VALIDATION')}}</span>
                <span
                    v-if="risk.proposed_risk !== null && newClient"
                    class="btn btn-primary next"
                    style="cursor:pointer;"
                    @click="btnClick()"
                >{{$t('CONTINUE')}}</span>
                <span
                    v-if="!newClient"
                    class="ml-2 btn btn-primary next"
                    style="cursor:pointer;"
                    @click="save()"
                >{{ $t('SAVE') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'

function selectKyc(state) {
    return this.newClient ? state.new_Compte.kycGenerali : state.client_Info.kycGenerali;
}
function selectApport(state) {
    return this.newClient ? state.new_Compte.apports : state.client_Info.info.extra_info.apports;
}

export default {
    name: "kycGenerali",
    components: {
        BaseInputFormatted
    },
    data() {
        return {
            esgPaiList: [
                "greenhouse_gas_emission",
                "use_of_non_renewable_energy",
                "emissions_to_water",
                "human_rights",
                "social_and_employee_matters"
            ],
            numberOfEsgPrefToken: 5,
            formatInsideInput: /[^\d\.]/g,
            formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
            insideNewvalue: '',
            outsideNewvalue: '$1 '
        }
    },
    props: {
        newClient: {
            type: Boolean, 
            required: false, 
            default : true
        } 
    },
    computed: {
         ...mapState({
            kycGenerali: selectKyc,
            apports: selectApport,
            risk: state => state.risk,
            baseUrl: state => state.baseUrl,
            newAccount: state => state.new_Compte
        }),
    },
    methods: {
        ...mapActions(["setNewCompte", "setRisk"]),
        checkGradeValue(e){
            if(e.target.value < 1)
                this.risk.proposed_risk = 1
            else if(e.target.value > 5)
                this.risk.proposed_risk = 5
        },
        btnClick() {
            this.$emit("next")
        },
        async getNote(){
            try{

                let call = await this.$http.post(
                    this.baseUrl + "cunit/generali_kyc",
                    {
                        "annualVolatility": this.kycGenerali.annualVolatility/100,
                        "RealEstate" : Number(this.kycGenerali.financialProfile.realEstate),
                        "LiquidAssets" : Number(this.kycGenerali.liquidAssets),
                        "FinancialInvestments": Number(this.kycGenerali.financialInvestments),
                        "Other" : Number(this.kycGenerali.other),
                        "Debt" :  Number(this.kycGenerali.debt), //Total Current and Future Financial Commitments
                        "Income" :  Number(this.kycGenerali.annualIncome),
                        "IncompressibleSpending" : Number(this.kycGenerali.incompressibleSpending),
                        "InitialInvestment": Number(this.apports.initial),
                        "EstimatedContribution": Number(this.apports.VersmtPeriodik),
                        "age":Number(this.kycGenerali.personnalRiskProfile.ageConsistency),
                        "time":Number(this.kycGenerali.personnalRiskProfile.timeConsistency),
                        "investmentObjectives": Number(this.kycGenerali.personnalRiskProfile.investmentObjectiveConsistency),
                        "lossCapacity": Number(this.kycGenerali.personnalRiskProfile.capacityConsistency)
                    }
                );
                if(call.data.vol){
                    let newAccount = this.$_.cloneDeep(this.newAccount)
                    newAccount.kyc_vol = call.data.vol
                    this.setNewCompte(newAccount)

                    call = await this.$http.post(
                        this.baseUrl + "cunit/vol2Note",
                        {
                            vol: call.data.vol
                        }
                    )

                    let risk = this.$_.cloneDeep(this.risk)
                    risk.proposed_risk = call.data.note.toFixed(1)
                    this.setRisk(risk)
                }
            }catch(err){
            }
        },
        async save(){
            await this.getNote()
            this.$emit("saveKYCGenerali", this.kycGenerali, this.apports)
        }
    }
};
</script>

<style>
.row__container{
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
}
.pai__container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px;
}
.pai{
    border: 1px solid black;
    width: fit-content;
    padding: 5px;
}
</style>
