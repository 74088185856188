<template>
  <div>
    <div class="row">
      <!-- col -->
      <div class="col-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="icon-eyeglass bg-primary p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-primary mb-0 pt-3">{{ count +237 }}</div>
            <div
              class="text-muted text-uppercase font-weight-bold"
              style="font-size: 11px"
            >{{ $t('BENEFICIARY') }}</div>
          </div>
        </div>
      </div>
      <!--/.col-->
      <div class="col-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="icon-pie-chart bg-info p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-info mb-0 pt-3">3</div>
            <div
              class="text-muted text-uppercase font-weight-bold"
              style="font-size: 11px"
            >{{ $t('REFERENCE_ALLOWANCE') }}</div>
          </div>
        </div>
      </div>
      <!--/.col-->

      <div class="col-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-university bg-warning p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-warning mb-0 pt-3">23</div>
            <div class="text-muted text-uppercase font-weight-bold" style="font-size: 11px">
              {{ $t('PUBLISHED_MARKET_VIEWS_PART1') }}
              <br />
              {{ $t('PUBLISHED_MARKET_VIEWS_PART2') }}
            </div>
          </div>
        </div>
      </div>

      <!--/.col-->
      <div class="col-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="icon-briefcase bg-danger p-4 font-2xl mr-3 float-left"></i>
            <div class="h5 text-danger mb-0 pt-3">9 600</div>
            <div class="text-muted text-uppercase font-weight-bold" style="font-size: 11px">
              {{ $t('ACCOUNTS') }}
            </div>
          </div>
        </div>
      </div>
      <!--/.col-->
    </div>
    <p class="homeTitle">{{ $t('AUTOMATED_CONSTRUCTION_BESPOKE_PORTFOLIOS') }}</p>
    <div>
      <div class="centerContainer">
        <div v-for="(image, index) in images" :key="index" class="mx-3"
             :style="{width: image.isanimated?'160px':'30px', alignSelf: image.isanimated?'flex-start':''}" >
          
          <div v-html="image.svg.default" style="width:100%;" />
          <p v-if="image.isanimated" class="imgText">{{ $t(image.txt) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as d3 from 'd3'

d3.selection.prototype.moveToBack = function() {
    return this.each(function() {
        let firstChild = this.parentNode.firstChild;
        if (firstChild) {
            this.parentNode.insertBefore(this, firstChild);
        }
    });
};

function getBoundingBoxCenter(selection) {

  // get the DOM element from a D3 selection
  // you could also use "this" inside .each()
  let element = selection.node();
  
  // use the native SVG interface to get the bounding box
  let bbox = element.getBBox();
  // return the center of the bounding box
  return [bbox.x + bbox.width/2, bbox.y + bbox.height/2];
}

function getTransition() {
  return d3.transition()
            .duration(1500)
            .ease(d3.easeCubicOut);
}

export default {
  data() {
    return {
      count: "",
      images: [],
      animationInterval: null
    };
  },
   name: "home",
  computed: {
    ...mapState({
      breadLink: (state) => state.breadLink,
      isLog: (state) => state.isLogged,
      baseUrl: (state) => state.baseUrl,
    }),
  },
  methods: {
    ...mapActions(["setBreadLink", "setToken", "setLogged"]),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({ isLog: false, role: 0 });
        await this.$http.get(this.baseUrl + "auth/logout");
        localStorage.removeItem("priradToken");
        localStorage.removeItem("priradRole");
        localStorage.removeItem("priradCompany");
        this.$router.push("/login");
      }
    },
    createAnimatedCircle(svg, center, fillRadius, extRadius, transition, idx) {
      let circleAnim = svg.append("circle")
                        .attr("cx", center[0])
                        .attr("cy", center[1])
                        .attr("r", fillRadius)
                        .attr("fill", "rgba(0,0,0,0)")
                        .attr("stroke-width", 10)
                        .attr("stroke", this.images[idx].color).moveToBack()
      circleAnim.transition(transition).attr("r", extRadius).attr("stroke", this.images[idx].color_alpha).remove()
    },
    initSVG(){
      // D3
     let centers = new Array(this.images.length)
     const width = 1500
     const fillRadius = 500
     const extRadius = width/2-10
     const intervalDelay = 7000

     this.images.forEach(({color, isanimated}, idx)=>{
       if(!isanimated){return}
        let svg = d3.select(`#svg${idx}`)
        const center = getBoundingBoxCenter(svg)
        centers[idx] = center
        const viewboxCoords = (-width/2+center[0]).toString()+" "+(-width/2+center[1]).toString()+` ${width} ${width}`
        svg.attr("viewBox", viewboxCoords)
       svg.append("circle")
            .attr("cx", center[0])
            .attr("cy", center[1])
            .attr("r", fillRadius)
            .attr("fill", color).moveToBack()
          this.createAnimatedCircle(svg, center, fillRadius, extRadius, getTransition(), idx)
     })
        this.animationInterval = setInterval(()=>{
          this.images.forEach(({isanimated}, idx)=>{
            if(!isanimated){return}
                let svg = d3.select(`#svg${idx}`)
            
                const center = centers[idx]
                this.createAnimatedCircle(svg, center, fillRadius, extRadius, getTransition(), idx)
          })
        }, intervalDelay)
    },
    initImageData(){
      const image_files = [
        {svg:"chart-pie-solid.svg", txt: 'REFERENCE_ALLOCATION', color:"rgb(13, 186, 134)", color_alpha: "rgba(13, 186, 134, 0)"}, 
        {svg:"cog-solid.svg"}, 
        {svg:"thumbs-up-regular.svg", txt: 'MODIFIED_BY_MARKET_VIEWS', color:"rgb(255, 84, 84)", color_alpha: "rgba(255, 84, 84, 0)"}, 
        {svg:"cog-solid.svg"}, 
        {svg:"user-solid.svg", txt: 'ADAPTED_FOR_EACH_CLIENT', color:"rgb(250, 187, 61)", color_alpha: "rgba(250, 187, 61, 0)"}, 
        {svg:"equals-solid.svg"},
        {svg:"briefcase-solid.svg",txt: 'BESPOKE_PORTFOLIO', color:"rgb(54, 169, 225)", color_alpha: "rgba(54, 169, 225, 0)"}
      ]
     this.images = image_files.map(({svg, txt, color, color_alpha}, idx)=>{
       let tmp = require("!raw-loader!../../public/img/icons/"+svg);
       const isanimated = txt != undefined;
       tmp.default = tmp.default.replace("<svg", '<svg id="svg'+idx+'"');
       return {svg: tmp, txt, color, color_alpha, isanimated}
     })
    }
  },
  async mounted() {
    this.setBreadLink(["Prime Radiant", "COUSELORS", "DASHBOARD"]);
    let count = await this.$http.get(this.baseUrl + "users/client/count");
    this.count = count.data.resultat;

  },
   mounted(){
     this.initSVG()
   },
   beforeMount(){
     this.initImageData()
   },
    beforeDestroy() {
      clearInterval(this.animationInterval)
      this.animationInterval = null 
  }
};
</script>

<style>
.imgText {
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
}
.centerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeTitle {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 80px;
  margin-top: 50px;
}
</style>
