<template>
  <div class="btn-refresh-container text-center">
    <button @click="refresh()" class="btn btn-info btn-refresh">
      <i class="fa fa-refresh fa-lg" />
      <br />
      <span class="ml-2">{{ $t('REFRESH_UPDATE') }}</span>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    dataChange: {
      type: Number,
      required: false,
    },
  },
  watch: {
    dataChange: function () {
      this.$el.childNodes[0].childNodes[0].classList.add('fa-refresh-animate');
      this.$el.childNodes[0].childNodes[2].classList.add('tmp-class');
      setTimeout(() => {
        this.$el.childNodes[0].childNodes[2].classList.remove('tmp-class');
        this.$el.childNodes[0].childNodes[0].classList.remove(
          'fa-refresh-animate'
        );
      }, 1000);
    },
  },
  methods: {
    refresh() {
      this.$emit('refreshed');
    },
  },
};
</script>

<style scopped>
.btn-refresh-container {
  position: fixed;
  top: 50%;
  right: 0px;
  z-index: 150;
}
.btn-refresh span {
  display: none;
}
.btn-refresh:hover span {
  display: inline-block;
}
.tmp-class {
  display: inline-block !important;
}
.fa-refresh {
  transition: all 1s ease-in-out;
  transform: rotate(0);
}
.fa-refresh-animate {
  transform: rotate(360deg);
}
</style>
