<template>
  <div>
    <h4 style="text-align:center;">{{$t('SUMMARY_VIEWS')}}</h4>      
	  <table 
	  	v-if="filterDataProps.length"
	  	class="table table-bordered table-condensed text-center mx-auto"
		style="background-color:white; max-width: 500px;"
		>
		<thead>
			<tr>
				<th>{{ $t('ASSET_CLASSES') }}</th>
				<th>{{ $t('GRADE') }}</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="asset in filterDataProps" :key="asset[0]">
				<td>
					<span style="float: left;">
					  <i class="fa fa-angle-double-up" style="color:rgb(64, 197, 64);" v-if="asset[1] == 2"></i>
                      <i class="fa fa-angle-up" style="color:rgb(64, 197, 64);" v-if="asset[1] == 1"></i>
					  <i class="fa fa-angle-double-down" style="color:rgb(255, 64, 64);" v-if="asset[1] == -2"></i>
                      <i class="fa fa-angle-down" style="color:rgb(255, 64, 64);" v-if="asset[1] == -1"></i>
					</span>
					{{translate(asset[0])}}
				</td>
				<td>{{asset[1]}}</td>
			</tr>
		</tbody>
	</table>
	<div class="tmpMsg mx-auto" v-else>
		{{ $t('NO_VIEWS')}}
	</div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default {
    data(){
	return{
	    translated:[]
	}
    },
    props: {
	dataProps: {
	    required: true,
	    type: Array,
	},
	translateAssetsProps: {
	    required: true,
	    type: Array,
	}
    },
    computed:{
	...mapState({
	    baseUrl: (state) => state.baseUrl
	})
    },
	computed: {
		filterDataProps: function() {
			return Object.entries(this.dataProps).filter(el => el[1] !== 0)
		}
	}, 
    methods:{
	translate(n){
	    let idx = this.translateAssetsProps.findIndex(e => e.optim == n);
	    return idx !=-1 ? this.translateAssetsProps[idx][this.$i18n.locale] : n
	}
    }
};
</script>

<style scoped>
.tmpMsg{
	text-align: center;
	background: white;
	padding: 50px;
	max-width: 500px;
}
</style>
