<template>
  <div class="tab-pane active fade show">
    <notifications
      classe="n-light"
      group="foo"
      position="top center"
      :max="3"
      :width="400"
    />
    <GoalModal v-if="dataForModal !== null" :data="dataForModal" @clicked="hideModal">
      <ModalImmo
        @saved="savedModal"
        :dataProps="data.objectifs.immo.data"
        :color="goals['immo'].imgColor"
        v-if="goals.immo.showModal"/>
      <ModalValo
        @saved="savedModal"
        :dataProps="data.objectifs.transmi"
        :color="goals['transmi'].imgColor"
        v-if="goals.transmi.showModal"/>
      <ModalEpargne
        @saved="savedModal"
        :dataProps="data.objectifs.precaution"
        :color="goals['precaution'].imgColor"
        v-if="goals.precaution.showModal"/>
      <ModalRetraite
        @saved="savedModal"
        :dataProps="data.objectifs.retraite"
        :color="goals['retraite'].imgColor"
        v-if="goals.retraite.showModal"/>
      <ModalAutre
        @saved="savedModal"
        :dataProps="data.objectifs.autre"
        :color="goals['autre'].imgColor"
        v-if="goals.autre.showModal"/>
      <ModalSchool
        @saved="savedModal"
        :dataProps="data.objectifs.school.data"
        :color="goals['school'].imgColor"
        v-if="goals.school.showModal"/>
    </GoalModal>
    <div class="well">
      <label>
        <strong>
          <i class="icon-target"></i>
          {{ $t('GOALS') }}
        </strong>
      </label>
      <hr />

      <div class="goalCard__container">
        <div v-for="[goalK, goalV] in Object.entries(goals)" :key="'goal_'+goalV.title">
          <div :class="['goalCard', {goalCard__unselected:!goalV.goalIsAdded}]" :style="{ borderTop: goalV.goalIsAdded ?`8px solid ${goalV.color}`:`8px solid gray` }">
            <i class="fa fa-times goalCard__icon--delete" v-if="goalV.goalIsAdded" @click="removeGoal(goalK)"/>
            <p class="text-left font-weight-bold">{{$t(goalV.title)}}</p>
            <button 
              class="goalCard__unselected--btn" 
              v-if="!goalV.goalIsAdded"
              @click="addGoal(goalK)">
              {{$t("ADD")}}
            </button>
            <button
              v-if="goalV.goalIsAdded"              
              type="button"
              class="btn btn-edit-goal"
              @click="editGoal(goalK)"
            >
              {{$t('EDIT')}}
              <i class="fa fa-edit" />
            </button>
            <img
              :src="goalV.goalIsAdded ? goalV.img : goalV.darkImg"
              class="goalCard__img"
            />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="text-center">
      <span
        v-if="oneGoalIsSelected"
        class="btn btn-primary"
        style="cursor: pointer"
        @click="btnClick()"
        >{{ $t('CONTINUE') }}</span
      >
      <span v-else>{{ $t('PLEASE_SELECT_LEAST_ONE_GOAL') }}</span>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import Notifications from 'vue-notification';
Vue.use(Notifications);

import kycRiskValidation from '../../../mixins/kycRiskValidation';

import ModalImmo from '@/components/agent/nouveau_compte/modal_immo.vue';
import ModalRetraite from '@/components/agent/nouveau_compte/modal_retraite.vue';
import ModalAutre from '@/components/agent/nouveau_compte/modal_autre.vue';
import ModalSchool from '@/components/agent/nouveau_compte/modal_school.vue';
import ModalEpargne from '@/components/agent/nouveau_compte/modal_epargne.vue';
import ModalValo from '@/components/agent/nouveau_compte/modal_autreBis.vue';
import GoalModal from '@/components/agent/nouveau_compte/GoalModal.vue'

export default {
  components: {GoalModal, ModalImmo, ModalEpargne, ModalValo, ModalRetraite, ModalAutre, ModalSchool},
  mixins: [kycRiskValidation],
  data() {
    return {
      goals: {
        precaution: {
          title: "PRECAUTIONARY_SAVINGS",
          img: "img/icons/icon-objectif-epargne.png",
          darkImg: "img/icons/icon-objectif-epargne-dark.png",
          color: "#F4A4B3",
          imgColor: "#EB4A69",
          showModal: false,
          goalIsAdded: false
        },
        transmi: {
          title: "PROMOTION_TRANSMISSION",
          img: "img/icons/icon-objectif-transmission.png",
          darkImg: "img/icons/icon-objectif-transmission-dark.png",
          color: "#8BD6D1",
          imgColor: "#18AEA4",
          showModal: false,
          goalIsAdded: false
        },
        immo: {
          title: "PROPERTY_INVESTMENT",
          img: "img/icons/icon-objectif-immobilier.png",
          darkImg: "img/icons/icon-objectif-immobilier-dark.png",
          color: "#8BB3CC",
          imgColor: "#18699B",
          showModal: false,
          goalIsAdded: false
        },
        retraite: {
          title: "RETIREMENT_PREPARATION",
          img: "img/icons/icon-objectif-retraite.png",
          darkImg: "img/icons/icon-objectif-retraite-dark.png",
          color: "#F9D68B",
          imgColor: "#F5AE18",
          showModal: false,
          goalIsAdded: false
        },
        school: {
          title: "CHILDEN_STUDIES",
          img: "img/icons/icon-objectif-ecole.png",
          darkImg: "img/icons/icon-objectif-ecole-dark.png",
          color: "#98BF7F",
          imgColor: "#338000",
          showModal: false,
          goalIsAdded: false
        },
        autre: {
          title: "OTHER",
          img: "img/icons/icon-objectif-autre.png",
          darkImg: "img/icons/icon-objectif-autre-dark.png",
          color: "#FCAEAC",
          imgColor: "#FB5F5B",
          showModal: false,
          goalIsAdded: false
        },
      },
      dataForModal: null
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.new_Compte,
    }),
    oneGoalIsSelected(){
      for (const goal of Object.values(this.goals)) {
        if(goal.goalIsAdded) return true
      }
      return false
    }
  },
  methods: {
    ...mapActions(['setNewCompte']),
    addGoal(goalK){
      this.goals[goalK].goalIsAdded = true
      this.handleDataGoalCheck(goalK, true)
      this.goals[goalK].showModal = true
      this.dataForModal = {
        key: goalK,
        value: this.goals[goalK]
      }
    },
    removeGoal(goalK){
      this.handleDataGoalCheck(goalK, false)
      this.goals[goalK].goalIsAdded = false
    },
    handleDataGoalCheck(goalK, isCheck){
      this.data.objectifs[goalK].isCheck = isCheck
      this.setNewCompte(this.data);
    },
    hideModal(goalK) {
      this.dataForModal= null
      this.goals[goalK].showModal = false
    },
    editGoal(goalK){
      this.dataForModal = {
        key: goalK,
        value: this.goals[goalK]
      }
      this.goals[goalK].showModal = true
    },
    savedModal(val) {
      if (val.name == 'school' || val.name == 'immo') {
        this.data.objectifs[val.name]['data'] = val.data;
      } else {
        val.data['createdAt'] = new Date().toISOString();
        this.data.objectifs[val.name] = {
          ...this.data.objectifs[val.name],
          ...val.data,
        };
      }
      let title = this.$t('GOALS');
      let group = 'foo';
      let type = '';
      let text = this.$t('OBJECTIF_UPDATED');
      this.$notify({
        group,
        title,
        text,
        type,
      });
      this.hideModal(val.name)
    },
    async btnClick() {
      // await this.createData()
      // await this.callValidation()
      // await this.note2Vol()

      // this.$emit('clicked', {
      //   name: 'Obj',
      //   next: 'Simu',
      // });
      this.$emit("next")
    },
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function (state) {
        return state.new_Compte;
      },
      function (val) {
        ctx.setNewCompte(val);
      },
      {
        deep: true,
      }
    );
    this.data.objectifs = JSON.parse(JSON.stringify(this.data.old_objectifs));
    let arr = ['precaution', 'transmi', 'immo', 'retraite', 'school', 'autre'];
    for (let index = 0; index < arr.length; index++) {
      if (
        this.data.objectifs[arr[index]].isCheck ||
        this.data.objectifs[arr[index]].isCheck == 'true'
      )
        this.goals[arr[index]].goalIsAdded = this.data.objectifs[arr[index]].isCheck;
    }

    this.setNewCompte(this.data);
  },
  beforeDestroy() {
    this.data.old_objectifs = JSON.parse(JSON.stringify(this.data.objectifs));
    this.setNewCompte(this.data);
  },
};
</script>

<style>
  @import '../../../assets/css/checkbox-full-btn-v2.css';

.styleImg {
  width: 90px;
  margin-bottom: 10px;
}

.imgOpacity {
  opacity: 1;
}

.chkObj {
  background-color: #e6f8ff;
  border: 1px #000 solid;

  border-radius: 15px;
}

.styleObj {
  padding: 40px 10px;
  text-align: center;
}

.goalCard{
  width: fit-content;
  min-width: 350px;
  box-shadow: 0px 0px 4px gray;
  padding: 15px;
  position: relative;
  overflow: hidden;
  height: 170px;
}
.goalCard__container{
  display: flex;
  flex-wrap: wrap; 
  gap: 15px; 
  justify-content:center;
}
.goalCard:hover{
  scale: 1.02;
}
.goalCard__img{
  display: block;
  width: 150px;
  opacity: 0.5;
  position: absolute;
  bottom: -30px;
  right: -30px;
}
.goalCard__unselected{
  filter: grayscale(100%);
}

.goalCard__unselected--btn{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 50px;
  border: none;
  filter: grayscale(80%);
  opacity: 0.7;
}

.goalCard__icon--delete{
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 5;
  cursor: pointer;
}  
.btn-edit-goal{
  width: 140px;
}
</style>
